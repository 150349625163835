import React, { useState } from "react";
import "./Profile.css";
import { useSelector} from "react-redux";
import { getUserData} from "../../redux/features/UserSlice";

import Cryptojs from "crypto-js";
import { useNavigate } from "react-router-dom";

import Back from "../../Components/Back";
import { RandomColor } from "../../Components/ColorCombo";
import OutlookDataLoader from "../../Components/OutlookDataLoader";
import { TextCapaitalized } from "../../data/utilities";

function Profile() {

    const userData = useSelector(getUserData);
    const navigate = useNavigate();
    const [isActiveOutlook, setIsActiveOutlook] = useState(false)

    const handleEditProfile = () => {
        const SECRET_VALUE = { Id: userData.Id, goBack: "profile" };
        let SECRECT_STRING = Cryptojs.AES.encrypt(
            JSON.stringify(SECRET_VALUE),
            process.env.REACT_APP_SECRECT_KEY
        )
            .toString()
            .split("/")
            .join("|");
        navigate(`/myprofile/edit-profile/${SECRECT_STRING}`);
    };

    return (
        <>
            <div className="profile_container">
                <Back title={'/dashboard'} />
                <div className="profile_container_header">
                    <span>Profile</span>
                    {
                        userData.role === process.env.REACT_APP_ROLE_SU && (
                            <button onClick={() => setIsActiveOutlook(true)}>Outlook</button>
                        )
                    }
                </div>
                <div className="profile_container_content">
                    <div className="profile_container_content_span">
                        <div className="profile_container_image_container">
                            <div
                                className="profile_container_image_container_span"
                                style={{
                                    background: RandomColor(userData.employeeFirstName.substring(0, 1))
                                }}>
                                <span>
                                    {
                                        userData.employeeFirstName === null ?
                                            userData.email.substring(0, 2).toUpperCase()
                                            :
                                            userData.employeeFirstName.substring(0, 1).toUpperCase() + "" + userData.employeeLastName.substring(0, 1).toUpperCase()
                                    }</span>
                            </div>
                        </div>
                        <div className="profile_container_user_data">
                            <div className="profile_container_user_data_name">
                                <label style={{ textTransform: "capitalize" }}>
                                    {userData.employeeFirstName}
                                </label>
                            </div>
                            <div className="profile_container_user_data_span">
                                <div className="profile_container_user_data_span_1">
                                    <div className="profile_container_user_data_span_content">
                                        <label>Employee Name</label>
                                        <span>
                                            {TextCapaitalized(userData.employeeFirstName) + ' ' + TextCapaitalized(userData.employeeLastName)}
                                        </span>
                                    </div>
                                    <div className="profile_container_user_data_span_content">
                                        <label>Email Address</label>
                                        <span>
                                            {userData.email}
                                        </span>
                                    </div>
                                </div>
                                <div className="profile_container_user_data_span_1">
                                    <div className="profile_container_user_data_span_content">
                                        <label>Phone Number</label>
                                        <span>
                                            {"+" +
                                                userData.countryCode +
                                                " " +
                                                userData.phoneNumber}
                                        </span>
                                    </div>
                                    <div className="profile_container_user_data_span_content">
                                        <label>Organization</label>
                                        <span>
                                            {TextCapaitalized(userData.organization)}
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile_container_navigations">
                        {
                            userData.Id !== null &&
                            (
                                <button
                                    className="profile_btn_edit_profile"
                                    onClick={handleEditProfile}
                                >
                                    Edit Profile
                                </button>
                            )
                        }

                    </div>
                </div>
            </div>
            {
                isActiveOutlook && (
                    <OutlookDataLoader
                        setIsActiveOutlook={setIsActiveOutlook}
                    />
                )
            }
        </>
    );
}

export default Profile
