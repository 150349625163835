import React from 'react'
import { Route, Routes } from 'react-router-dom'

import TimesheetReports from '.'
import Timesheet from '../Timesheet'

import SubMenuLayout from '../../Layouts/SubMenuLayout'
import EmployeeSubRoute from './Feature/Employees/SubRoute'
import MonthlySummary from '../MonthlySummary'

function App({ AllocatedProjectCodes }) {

    return (
        <Routes>
            <Route element={<SubMenuLayout />}>

                <Route path='/home' element={<TimesheetReports AllocatedProjectCodes={AllocatedProjectCodes} />} />

                <Route path='/employees/*' element={<EmployeeSubRoute />} />

                <Route path='/monthly-summary' element={<MonthlySummary />} />

                <Route path='/timesheet/:timesheetId' element={<Timesheet />} />

            </Route>
        </Routes>
    )
}

export default App