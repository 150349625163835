export const Locations = [
    {
        Id: 0,
        name: "India",
        countryCode: 91
    },
    {
        Id: 1,
        name: "USA",
        countryCode: 1
    },
    {
        Id: 2,
        name: "Philippines",
        countryCode: 63
    }
]