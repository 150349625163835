import React from 'react'
import "./NoData.css"
import EmptyDataImage from '../../assets/ProductImages/07-iEmployee-NoDataFound.svg'
// import EmptyDataImage from '../../assets/NoData.png'

function NoData({ title }) {
    return (
        <div className='NoData'>
            <div className='empty_table_img_container'>
                <img src={EmptyDataImage} alt='no_data' />
                <h3>{title}</h3>
            </div>
        </div>
    )
}

export default NoData