import React, { useEffect, useState } from 'react'
import "./OutlookDataLoader.css"
import { Close } from '@mui/icons-material'
import { useMsal } from '@azure/msal-react'
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import Axios from 'axios'
import { setLoadingScreen } from '../../redux/features/ActivateSlice'
import { Tooltip } from '@mui/material'
import { Locations } from '../../data/Locations'
import { AxiosPost } from '../../data/utilities'

function OutlookDataLoader({ setIsActiveOutlook }) {

    const { instance } = useMsal()
    const dispatch = useDispatch()
    const [outlookusers, setOutlookusers] = useState([])

    const [count, setCount] = useState(0)

    useEffect(() => {

        const handleOutlookData = async (request) => {
            const response = await instance.acquireTokenSilent(request)

            dispatch(setLoadingScreen(true))

            let usersURL = 'https://graph.microsoft.com/v1.0/users?$top=800'

            let userstemp = []

            while (usersURL) {
                try {

                    let users_result = await Axios.get(usersURL, {
                        headers: {
                            Authorization: `Bearer ${response.accessToken}`
                        }
                    })
                    userstemp = userstemp.concat(users_result.data.value)
                    usersURL = users_result.data['@odata.nextLink']
                }
                catch (err) {
                    console.log('End of Cycle')
                }

            }

            // Validation 
            /* 
            
            Mail Should not be Null
            Mail should containes @genzeon.com
            Job Title Should Not Null

            */
            userstemp = userstemp.filter(item => item.mail !== null && item.mail.toString().includes('@genzeon.com') && item.jobTitle !== null)

            let output = []

            for (let i = 0; i < userstemp.length; i++) {
                let managerData = await getManager(userstemp[i].mail, response.accessToken)
                let location = userstemp[i].mobilePhone !== null ?
                    userstemp[i].mobilePhone.split(' ')[0].split("+")[1] !== undefined ?
                        Locations.filter(item => item.countryCode === Number.parseInt(userstemp[i].mobilePhone.split(' ')[0].split("+")[1]))[0].Id
                        : 0
                    : 0
                if (managerData !== null) {
                    output.push({
                        mail: userstemp[i].mail,
                        givenName: userstemp[i].givenName,
                        surname: userstemp[i].surname,
                        displayName: userstemp[i].displayName,
                        jobTitle: userstemp[i].jobTitle,
                        mobilePhone: userstemp[i].mobilePhone,
                        PracticeArea: null,
                        Education: 1,
                        Specialization: 'Not Available',
                        Client: 1,
                        Billable: 0,
                        CareerStartDate: null,
                        manager: managerData,
                        Location: location
                    })
                    setCount(i++)
                }

            }

            dispatch(setLoadingScreen(false))
            setOutlookusers(output)
        }

        const CurrentAccount = instance.getActiveAccount()

        if (CurrentAccount) {

            const request = {
                scopes: ['user.read', 'user.read.all'],
                account: CurrentAccount
            }

            handleOutlookData(request)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instance])

    const handleEmployeesData = async () => {
        dispatch(setLoadingScreen(true))
        try {
            let previousNumber = 0
            let numberOfLoops = Math.floor(outlookusers.length / 200)
            let nextNumber = 200
            for (let i = 0; i < numberOfLoops; i++) {

                let values = outlookusers.splice(previousNumber, nextNumber)

                let result = await AxiosPost(`/iemployee/load-azure-data-into-iemployee-db`, {
                    data: values
                })

                if (result.data.isSuccess) {

                    toast.success(`${values.length} Employees are Inserted/Updated`, { toastId: "iemployee-toast" })
                }

                previousNumber = nextNumber
                nextNumber = nextNumber + 200

            }
        }
        catch (err) {
            console.log(err)
        }
        finally {
            dispatch(setLoadingScreen(false))
        }
    }

    return (
        <div className='outlook-container'>
            <div className='outlook-container-card'>
                <div className='outlook-container-header'>
                    <span>
                        Outlook Users
                        <label>{count}</label>
                    </span>
                    <Close fontSize='large' sx={{ cursor: "pointer" }} onClick={() => {
                        setIsActiveOutlook(false)
                        setCount(0)
                        setOutlookusers([])
                    }} />
                </div>
                <div className='outlook-container-content'>
                    {
                        outlookusers.length === 0 ?
                            <>No data</>
                            :
                            <div>
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                tableColumns.map((item, index) => {
                                                    return <th key={index}>{item.name}</th>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            outlookusers.map((item, index) => {
                                                return <tr key={index}>
                                                    <td>{item.displayName}</td>
                                                    <td>{item.mail}</td>
                                                    <Tooltip title={`
                                                    Display Name: ${item.displayName},
                                                    Email Address: ${item.mail},
                                                    First Name: ${item.givenName},
                                                    Last Name: ${item.surname},
                                                    Job Title: ${item.jobTitle},
                                                    Mobile Phone: ${item.mobilePhone}
                                                    `} placement='bottom'>
                                                        <td>{item.manager}</td>
                                                    </Tooltip>
                                                </tr>
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                    }
                </div>
                <div className='outlook-container-actions'>
                    <button onClick={handleEmployeesData}>Upload Data</button>
                </div>
            </div>
        </div>
    )
}

export default OutlookDataLoader


const tableColumns = [
    {
        id: 0,
        name: "Name"
    },
    {
        id: 1,
        name: "Email"
    },
    {
        id: 2,
        name: "Manager"
    }
]

async function getManager(mail, accessToken) {
    try {
        let result = await Axios.get(`https://graph.microsoft.com/v1.0/users/${mail}/manager`, {
            headers: {
                Authorization: `Bearer ${accessToken}`
            }
        })
        return result.data.mail
    }
    catch (err) {
        return null
    }

}   