import { createSlice } from '@reduxjs/toolkit'

const initialState = {

    AuditEmployee: [],
    temp_Employees: []

}

export const AuditSlice = createSlice({
    name: 'audit',
    initialState,
    reducers: {

        setTempEmployees: (state, action) => {
            state.temp_Employees = action.payload
        },

        setAuditEmployees: (state, action) => {
            state.AuditEmployee = action.payload
        }

    },
})

export const { setTempEmployees, setAuditEmployees } = AuditSlice.actions


export const getTempEmployees = (state) => state.audit.temp_Employees

export const getAuditEmployees = state => state.audit.AuditEmployee

export default AuditSlice.reducer