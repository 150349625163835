import React, { useEffect, useState } from "react";
import "./TimesheetApproval.css"

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { ArrowDropDown, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";

import { getUserData } from "../../../redux/features/UserSlice";
import { getInitialValues, setInitialValues } from "../../../redux/features/InitialSlice";
import { setLoadingScreen } from '../../../redux/features/ActivateSlice'
import EmptyTable from "../../../Components/NoData";
import { MenuItem, TextField, Tooltip } from "@mui/material";
// import MultiRangeSlider from "../../Pages/MultiRangeSlider/MultiRangeSlider";

import { toast } from 'react-toastify'
import SortBy from "./Feature/SortBy";
import Messages from '../../../data/Messages.json'
// import { getCurrentDate } from "../../../data/utilities";
import { AxiosPost, getWeekEnding, AxiosGet } from "../../../data/utilities";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getWeek } from 'date-fns'

import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

function TimesheetApproval({ AllocatedProjectCodes }) {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const userData = useSelector(getUserData)
    const initialData = useSelector(getInitialValues)
    const [timesheets, setTimesheets] = useState([]);
    const [items, setItems] = useState([])

    const [comment, setComment] = useState('')
    const [isComment, setIsComment] = useState(false)
    const [currentVal, setCurrentVal] = useState('')

    // const [isActiveTimeline, setIsActiveTimeline] = useState(false);
    const [isActiveJobTitle, setIsActiveJobTitle] = useState(false);
    const [isActiveUserName, setIsActiveUsername] = useState(false);
    const [isActiveStatus, setIsActiveStatus] = useState(false);
    const [isActiveWeeklyTimeline, setIsActiveWeeklyTimeline] = useState(false)
    const [data, setData] = useState([]);

    const [status, setStatus] = useState('Submitted')

    // const [TimelineMin, setTimelineMin] = useState(1950)
    // const [TimelineMax, setTimelineMax] = useState(getCurrentDate().getFullYear())

    const [filterByJobTitle, setFilterByJobTitle] = useState('')
    const [EmployeeName, setEmployeeName] = useState('')

    const [showWeekEnding, setShowWeekEnding] = useState({
        start: null,
        startWeek: null,
        end: null,
        endWeek: null
    })

    const [filterByProjectCode, setFilterByProjectCode] = useState(null)
    const [isActiveProjectCodes, setIsActiveProjectCodes] = useState(false)

    const [toggleFilter, setToggleFilter] = useState(false)

    useEffect(() => {

        if (userData.role === process.env.REACT_APP_ROLE_MANAGER) {
            setTimesheets(initialData.TimesheetApprovals.filter(item => item.Status === 'Submitted').slice().sort((a, b) => {
                if (a.SubmittedDate < b.SubmittedDate) return 1;
                if (a.SubmittedDate > b.SubmittedDate) return -1;
                else return 0;
            }))
            setData(initialData.TimesheetApprovals.slice().sort((a, b) => {
                if (a.SubmittedDate < b.SubmittedDate) return 1;
                if (a.SubmittedDate > b.SubmittedDate) return -1;
                else return 0;
            }))

        }
        else if (userData.role === process.env.REACT_APP_ROLE_SU) {
            const handleGetAllReports = async () => {
                dispatch(setLoadingScreen(true))
                let result = await AxiosGet(`/timesheet/get-all-employee-timesheet-reports`)

                if (result.data.isSuccess) {
                    setTimesheets(result.data.AllTimesheetReports)
                    setData(result.data.AllTimesheetReports)
                    let arr = [];
                    result.data.AllTimesheetReports.forEach(item => {
                        arr.push(item.Approver)
                        removeDuplicatesFromArray(arr)
                        return arr;
                    })
                    dispatch(setLoadingScreen(false))
                }

            }
            setStatus('Submitted')
            if (userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU) {
                handleGetAllReports()
            }
            else {
                setTimesheets(initialData.TimesheetReports)
                setData(initialData.TimesheetReports)
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData, userData])

    const handleApprove = async () => {
        // Logic to handle approve action
        dispatch(setLoadingScreen(true))
        let response = await AxiosPost(`/timesheet/approve-timesheet`, {
            AllWeekIds: items,
            FKEmpId: userData.Id,
            Comment: comment === '' ? 'No Comment' : comment
        })
        if (response.data.isSuccess) {
            setItems([])
            setComment('')
            let result = await AxiosPost(`/timesheet/get-weekly-timesheet-data`, {
                Approver: userData.Id
            })

            if (result.data.isSuccess) {
                let values = []
                result.data.timesheet.forEach((emp) => {
                    values.push({ ...emp, check: false })
                })
                setTimesheets(values.filter(item => item.Status === 'Submitted'))
                setData(values)
                dispatch(setInitialValues({ ...initialData, "TimesheetApprovals": result.data.timesheet }))
                dispatch(setLoadingScreen(false))
            }
            else {
                setTimesheets([])
                dispatch(setLoadingScreen(false))
            }

            setIsComment(false)
            toast.success(Messages.Timesheet_Approval.Timesheet_Approved, { toastId: "iemployee-toast" })
        }

    };

    const handleReject = async () => {
        // Logic to handle reject action
        dispatch(setLoadingScreen(true))
        let response = await AxiosPost(`/timesheet/reject-timesheet`, {
            AllWeekIds: items,
            FKEmpId: userData.Id,
            Comment: comment === '' ? 'No Comment' : comment
        })
        if (response.data.isSuccess) {
            setItems([])
            setComment('')
            let result = await AxiosPost(`/timesheet/get-weekly-timesheet-data`, {
                Approver: userData.Id
            })

            if (result.data.isSuccess) {
                let values = []

                result.data.timesheet.forEach((emp) => {
                    values.push({ ...emp, check: false })
                })
                setTimesheets(values.filter(item => item.Status === 'Submitted'))
                setData(values)
                dispatch(setInitialValues({ ...initialData, "TimesheetApprovals": result.data.timesheet }))
                dispatch(setLoadingScreen(false))
            }
            else {
                setTimesheets([])
                dispatch(setLoadingScreen(false))
            }

            setIsComment(false)
            toast.error(Messages.Timesheet_Approval.Timesheet_Rejected, { toastId: "iemployee-toast" })
        }


    };

    const handleSelectedItem = (e, id) => {
        if (e.target.checked) {
            setItems(item => [...item, Number.parseInt(id)])
            setTimesheets(timesheets.map((e) => {
                if (e.Id === id) {
                    return { ...e, "check": true }
                }
                else {
                    return e
                }
            }))
        }
        else {
            setItems(items.filter(item => item !== Number.parseInt(id)))
            setTimesheets(timesheets.map((e) => {
                if (e.Id === id) {
                    return { ...e, "check": false }
                }
                else {
                    return e
                }
            }))
        }
    }

    const handleSelectAll = (e) => {

        if (e.target.checked) {

            let val = []

            timesheets.forEach(item => {
                val.push(item.Id)
            })
            setItems(val)
            setTimesheets(timesheets.map((e) => {
                return { ...e, "check": true }
            }))
        }
        else {
            setItems([])
            setTimesheets(timesheets.map((e) => {
                return { ...e, "check": false }
            }))
        }

    }

    const handleWeekEnding = (weeknumber, year) => {
        let date = moment().year(year).week(weeknumber).endOf('week')

        const MONTHS = [{ id: "01", name: "Jan" }, { id: "02", name: "Feb" }, { id: "03", name: "Mar" },
        { id: "04", name: "Apr" }, { id: "05", name: "May" }, { id: "06", name: "Jun" },
        { id: "07", name: "Jul" }, { id: "08", name: "Aug" }, { id: "09", name: "Sep" },
        { id: "10", name: "Oct" }, { id: "11", name: "Nov" }, { id: "12", name: "Dec" },]

        return `${MONTHS.filter(item => item.name === date._d.toString().split(' ')[1])[0].id}/${date._d.toString().split(' ')[2]}/${date._d.toString().split(' ')[3]}`
    }

    const handleComment = (val) => {
        if (items.length !== 0) {
            setCurrentVal(val)
            setIsComment(true)
        }
        else {
            toast.error(Messages.Timesheet_Approval.Timesheet_Select, { toastId: "iemployee-toast" })
        }
    }

    const handleClose = () => {
        setIsComment(false)
        setCurrentVal('')
    }

    const handleSubmit = () => {

        if (currentVal === 'Approve') {
            handleApprove()
        }
        else {
            if (comment !== '') {
                handleReject()
            }
            else {
                toast.error(Messages.Timesheet_Approval.Timesheet_Approval_Add_Comments, { toastId: "iemployee-toast" })
            }
        }

    }

    const handleDate = (date) => {

        const localZoneDate = new Date(date)
        return {
            Date: localZoneDate.toLocaleString().split(',')[0],
            Time: localZoneDate.toLocaleString().split(',')[1]
        }
        // return `${localZoneDate.toLocaleString().split(',')[0]} ${localZoneDate.toLocaleString().split(',')[1]}`
    }

    const handleStartDate = (weekNum, type, Year) => {

        const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        let result = moment().year(Year).week(weekNum).startOf('week')
        let month = 0

        if (type === 'month') {
            Months.every((e, index) => {
                if (e === result._d.toString().split(' ')[1]) {
                    month = index
                    return false
                }
                else {
                    return true
                }
            })
            return month + 1
        }
        else {
            return result._d.toString().split(' ')[2]
        }

    }

    const handleEndDate = (weekNum, type, Year) => {

        const Months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

        let result = moment().year(Year).week(weekNum).endOf('week')
        let month = 0

        if (type === 'month') {
            Months.every((e, index) => {
                if (e === result._d.toString().split(' ')[1]) {
                    month = index
                    return false
                }
                else {
                    return true
                }
            })
            return month + 1
        }
        else {
            return result._d.toString().split(' ')[2]
        }


    }

    const handleTimesheet = (Id, year, week, month, Status, FKEmpId, Submitteddate, approver, updatedDt) => {
        const SECRET_STRING = CryptoJS.AES.encrypt(JSON.stringify({
            weekId: Id,
            year: year,
            weeknumber: week,
            Status: Status,
            month: month,
            type: "Approver",
            WeekStartDate: `${handleStartDate(week, 'month', year)}/${handleStartDate(week, 'startDate', year)}`,
            WeekEndDate: `${handleEndDate(week, 'month', year)}/${handleEndDate(week, 'EndDate', year)}`,
            goback: "/mytime/my-team/home",
            FKEmpId: FKEmpId,
            SubmittedDate: Submitteddate,
            Approver: approver,
            UpdatedDate: updatedDt
        }), process.env.REACT_APP_SECRECT_KEY).toString().split('/').join("|")
        navigate(`/mytime/my-team/timesheet/${SECRET_STRING}`)
    }

    const handleClear = () => {
        // setUsers(users);
        setTimesheets([])
        setTimeout(() => {
            setTimesheets(data.filter(item => item.Status === 'Submitted'));
        }, 500)
        // setIsActiveTimeline(false);
        setIsActiveUsername(false);
        setIsActiveJobTitle(false);
        setIsActiveStatus(false);
        setIsActiveProjectCodes(false);
        setStatus('Submitted')
        setFilterByProjectCode(null)
        setFilterByJobTitle('')
        setIsActiveWeeklyTimeline(false)
        setShowWeekEnding({
            start: null,
            startWeek: null,
            end: null,
            endWeek: null
        })
    };

    useEffect(() => {

        const filteredTimesheet = data.filter((timesheet) => {

            const usernameMatch =
                EmployeeName === '' ||
                timesheet.Name.toLowerCase().includes(EmployeeName.toLowerCase());
            const jobTitleMatch = filterByJobTitle === '' || timesheet.JobTitle.toLowerCase().includes(filterByJobTitle.toLowerCase());

            const StatusMatch = status === "All" ? true : timesheet.Status.toString() === status;

            const ProjectCodeMatch =
                filterByProjectCode === null ||
                timesheet.ProjectCode.toString() === filterByProjectCode;

            return usernameMatch && jobTitleMatch && StatusMatch && ProjectCodeMatch;
        });

        setTimesheets([])
        setTimeout(() => {
            setTimesheets(filteredTimesheet)
        }, 500)

    }, [status, data, filterByJobTitle, EmployeeName, filterByProjectCode])

    const handleFilterByJobTitle = (e) => {
        setFilterByJobTitle(e.target.value)
    }

    const handleFilterbyEmployeeName = (e) => {
        setEmployeeName(e.target.value)
    }

    const handleFilterByStatus = (e) => {
        setStatus(e.target.value)
    }

    const handleWeeklyTimelineFilter = () => {

        if (showWeekEnding.start === null || showWeekEnding.end === null) {
            toast.warn(Messages.Timesheet_Approval.Timesheet_WeeklyTimeline, { toastId: "iemployee-toast" })
            return false;
        }

        let start_input = Number.parseInt(showWeekEnding.startWeek);
        let end_input = Number.parseInt(showWeekEnding.endWeek);

        if (start_input > end_input) {
            toast.warn(Messages.Timesheet_Approval.Timesheet_WeeklyTimeline, { toastId: "iemployee-toast" })
            return false
        }

        setTimesheets(timesheets.filter((item) => item.WeekNumber >= start_input && item.WeekNumber <= end_input));
    };

    const handleWeekStartDate = (date) => {
        const dt = new Date(date)
        let weekNumber = getWeek(dt)
        let weekEnding = getWeekEnding(weekNumber, dt.getFullYear())
        setShowWeekEnding({ ...showWeekEnding, "start": weekEnding, "startWeek": weekNumber })
    }

    const handleWeekEndDate = (date) => {
        const dt = new Date(date)

        if (dt.getTime() < new Date(showWeekEnding.start).getTime()) {
            toast.error(Messages.Timesheet_Approval.Timesheet_WeeklyTimeline, { toastId: "iemployee-toast" })
            return false
        }

        let weekNumber = getWeek(dt)
        let weekEnding = getWeekEnding(weekNumber, dt.getFullYear())
        setShowWeekEnding({ ...showWeekEnding, "end": weekEnding, "endWeek": weekNumber })
    }

    return (
        <>
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div className="Approval_filters_container" style={{ width: !toggleFilter ? "0%" : "20%" }}>
                    <div className="Approval_filters_container_toggle" onClick={() => setToggleFilter(!toggleFilter)}>
                        {
                            toggleFilter ?
                                <Tooltip placement="top" title={'Hide'}>
                                    <FilterListOffIcon />
                                </Tooltip>
                                :
                                <Tooltip placement="top" title={'Filter'}>
                                    <FilterListIcon />
                                </Tooltip>
                        }
                    </div>
                    <div className="TimeSheet_Approval_filters">
                        <div className="TimeSheet_Approval_filters_header">
                            <div className="TimeSheet_Approval_filters_header_title">
                                <span>Filter</span>
                            </div>
                            <div className="TimeSheet_Approval_filters_header_clear">
                                <label htmlFor="cl" className="timesheet_approval_filters_clear_all">
                                    <span>Clear all</span>
                                </label>
                                <button hidden onClick={handleClear} id="cl">
                                    Clear all
                                </button>
                            </div>
                        </div>

                        <div className="TimeSheet_Approval_filter_container">
                            <div
                                className="TimeSheet_Approval_filter_container_header"
                                style={{
                                    borderBottom: isActiveWeeklyTimeline ? "1px solid #C4C4C4" : "none",
                                }}
                            >
                                <span>Weekly Timeline</span>
                                <ArrowDropDown
                                    style={{
                                        color: "#252525",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setIsActiveWeeklyTimeline(!isActiveWeeklyTimeline);
                                    }}
                                />
                            </div>
                            {isActiveWeeklyTimeline && (
                                <div className="employees_filter_container_content">
                                    <label>Select Weeks:</label>
                                    <div className="employees_filter_container_content_span">
                                        <label htmlFor="week-start">{showWeekEnding.start === null ? "Please Select Here" : showWeekEnding.start}</label>
                                        <DatePicker
                                            selected={showWeekEnding.start === null ? new Date() : new Date(showWeekEnding.start)}
                                            value={showWeekEnding.start === null ? new Date() : showWeekEnding.start}
                                            id='week-start'
                                            showWeekNumbers
                                            onChange={handleWeekStartDate}
                                            minDate={new Date("1950-01-01")}
                                        />
                                    </div>
                                    <p style={{ textAlign: 'center', marginTop: "5px", marginBottom: "-10px" }}>To</p>
                                    <div className="employees_filter_container_content_span">
                                        <label htmlFor="week-end">{showWeekEnding.end === null ? "Please Select Here" : showWeekEnding.end}</label>
                                        <DatePicker
                                            selected={showWeekEnding.end === null ? new Date() : new Date(showWeekEnding.end)}
                                            value={showWeekEnding.end === null ? new Date() : showWeekEnding.end}
                                            id='week-end'
                                            showWeekNumbers
                                            onChange={handleWeekEndDate}
                                            minDate={new Date("1950-01-01")}
                                        />
                                    </div>
                                    <div
                                        className="timesheet_timeline_btn_1"
                                    >
                                        <button
                                            onClick={handleWeeklyTimelineFilter}
                                        >
                                            Search
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="TimeSheet_Approval_filter_container">
                            <div
                                className="TimeSheet_Approval_filter_container_header"
                                style={{
                                    borderBottom: isActiveProjectCodes ? "1px solid #C4C4C4" : "none",
                                }}
                            >
                                <span>Project Code</span>
                                <ArrowDropDown
                                    style={{
                                        color: "#252525",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setIsActiveProjectCodes(!isActiveProjectCodes);
                                    }}
                                />
                            </div>
                            {isActiveProjectCodes && (
                                <div className="employees_filter_container_content">
                                    <TextField
                                        id="filterByProjectCodes"
                                        select
                                        label="Select Project Code"
                                        onChange={(e) => setFilterByProjectCode(e.target.value)}
                                        value={filterByProjectCode}
                                        sx={{ width: "100%" }}
                                    >
                                        <MenuItem value={null}>Select Project Code</MenuItem>
                                        {AllocatedProjectCodes.map((option) => (
                                            <MenuItem
                                                key={option.ProjectCode}
                                                value={option.ProjectCode}
                                            >
                                                {option.ProjectCode}
                                            </MenuItem>
                                        ))}
                                    </TextField>
                                </div>
                            )}
                        </div>

                        <div className="TimeSheet_Approval_filter_container">
                            <div
                                className="TimeSheet_Approval_filter_container_header"
                                style={{
                                    borderBottom: isActiveJobTitle ? "1px solid #C4C4C4" : "none",
                                }}
                            >
                                <span>Job Title</span>
                                <ArrowDropDown
                                    style={{
                                        color: "#252525",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setIsActiveJobTitle(!isActiveJobTitle);
                                    }}
                                />
                            </div>
                            {isActiveJobTitle && (
                                <div className="employees_filter_container_content">
                                    <TextField
                                        id="Job Title"
                                        label="Enter Job Title"
                                        value={filterByJobTitle}
                                        sx={{ width: "100%" }}
                                        placeholder="Enter Job Title"
                                        onChange={handleFilterByJobTitle}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="TimeSheet_Approval_filter_container">
                            <div
                                className="TimeSheet_Approval_filter_container_header"
                                style={{
                                    borderBottom: isActiveUserName ? "1px solid #C4C4C4" : "none",
                                    // borderBottom: true ? "1px solid #C4C4C4" : "none",
                                }}
                            >
                                <span>Employee Name</span>
                                <ArrowDropDown
                                    style={{
                                        color: "#252525",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setIsActiveUsername(!isActiveUserName);
                                    }}
                                />
                            </div>
                            {isActiveUserName && (
                                <div className="employees_filter_container_content">
                                    <TextField
                                        id="UserName"
                                        label="Employee Name"
                                        placeholder="Enter Employee Name"
                                        onChange={handleFilterbyEmployeeName}
                                        value={EmployeeName}
                                    />
                                </div>
                            )}
                        </div>

                        <div className="TimeSheet_Approval_filter_container">
                            <div
                                className="TimeSheet_Approval_filter_container_header"
                                style={{
                                    borderBottom: isActiveStatus ? "1px solid #C4C4C4" : "none",
                                    // borderBottom: true ? "1px solid #C4C4C4" : "none",
                                }}
                            >
                                <span>Status</span>
                                <ArrowDropDown
                                    style={{
                                        color: "#252525",
                                        cursor: "pointer",
                                    }}
                                    onClick={() => {
                                        setIsActiveStatus(!isActiveStatus);
                                    }}
                                />
                            </div>
                            {
                                isActiveStatus && (
                                    <div className="employees_filter_container_content">
                                        <TextField
                                            id="Status"
                                            label="Status"
                                            select
                                            defaultValue={tempStatus[1].Status}
                                            placeholder="Enter Status"
                                            value={status}
                                            onChange={(e) => {
                                                handleFilterByStatus(e)
                                            }}
                                            sx={{ width: "100%" }}
                                        >
                                            {tempStatus.map((option) => (
                                                <MenuItem key={option.Status} value={option.Status}>
                                                    {option.Status}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>

                {/* changes end */}
                <div className="timesheet_approval_main_screen">
                    <div className="timesheet_approval_container_header">
                        <div className="timesheet_approval_container_header_title">
                            <span>Timesheet Approval</span>
                            {
                                timesheets.length !== 0 && (
                                    <label>{timesheets.length}</label>
                                )
                            }
                        </div>
                        <div
                            className="timesheet_approval_container_header_navigations"
                            style={{ gap: "10px" }}
                        >
                            <button onClick={() => handleComment("Reject")}>Reject</button>
                            <button onClick={() => handleComment("Approve")}>Approve</button>
                        </div>
                    </div>
                    <div className="timesheet_approval_container_table">
                        {
                            timesheets.length !== 0 ?
                                <table>
                                    <thead>
                                        <tr>
                                            <td>
                                                <input
                                                    defaultChecked={false}
                                                    type="checkbox"
                                                    onClick={handleSelectAll}
                                                    style={{ cursor: "pointer" }}
                                                />
                                            </td>
                                            {
                                                tableCol.map((e, index) => {
                                                    return (
                                                        <td key={index} >
                                                            <span style={{ display: 'flex', gap: "5px" }}>
                                                                {e.name}
                                                                {
                                                                    e.name === 'OT' || e.name === 'Total' || e.name === 'Project Total' ?
                                                                        <></>
                                                                        :
                                                                        <SortBy
                                                                            list={timesheets}
                                                                            setTimesheetApprovals={setTimesheets}
                                                                            column={e.name}
                                                                        />
                                                                }
                                                            </span>
                                                        </td>
                                                    );
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            timesheets.map((e, index) => {
                                                return (
                                                    <tr
                                                        key={index}
                                                        onDoubleClick={() =>
                                                            handleTimesheet(
                                                                e.Id,
                                                                e.Year,
                                                                e.WeekNumber,
                                                                e.Month,
                                                                e.Status,
                                                                e.FKEmpId,
                                                                e.SubmittedDate,
                                                                e.Approver,
                                                                null
                                                            )
                                                        }
                                                    >
                                                        {index === 0 || !areRowsSame(timesheets[index - 1], e) ? (
                                                            <td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)} >
                                                                <input
                                                                    defaultChecked={false}
                                                                    type="checkbox"
                                                                    checked={e.check}
                                                                    onChange={(event) => handleSelectedItem(event, e.Id)}
                                                                />
                                                            </td>
                                                        ) : null}
                                                        {index === 0 || !areRowsSame(timesheets[index - 1], e) ? (
                                                            <td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)} style={{ textTransform: "capitalize" }}>
                                                                {e.Name}
                                                            </td>
                                                        ) : null}
                                                        {index === 0 || !areRowsSame(timesheets[index - 1], e) ? (
                                                            <td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>
                                                                {handleWeekEnding(e.WeekNumber, e.Year)}
                                                            </td>
                                                        ) : null}

                                                        <td title={e.ProjectName}>{e.ProjectCode}</td>
                                                        <td>{e.ProjectName}</td>
                                                        <td>{e.ProjectTotal}</td>
                                                        <td>{e.JobTitle}</td>
                                                        {index === 0 || !areRowsSame(timesheets[index - 1], e) ? (
                                                            <td style={{ textAlign: "center" }} rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>
                                                                {e.TotalHours}
                                                            </td>
                                                        ) : null}
                                                        {/* {index === 0 || !areRowsSame(timesheets[index - 1], e) ? (
                                                            <td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>
                                                                {e.Regular}
                                                            </td>
                                                        ) : null} */}
                                                        <td>{e.OverTime}</td>
                                                        {/* {index === 0 || !areRowsSame(timesheets[index - 1], e) ? (
                                                            <td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>
                                                                {e.OverTime}
                                                            </td>
                                                        ) : null} */}
                                                        {index === 0 || !areRowsSame(timesheets[index - 1], e) ? (
                                                            <td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>
                                                                {handleDate(e.SubmittedDate).Date}
                                                                <br />
                                                                {handleDate(e.SubmittedDate).Time}
                                                            </td>
                                                        ) : null}
                                                        {index === 0 || !areRowsSame(timesheets[index - 1], e) ? (
                                                            <td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>
                                                                <span
                                                                    className={
                                                                        e.Status === "Rejected"
                                                                            ? "weekly_status_rejected"
                                                                            : e.Status === "Approved"
                                                                                ? "weekly_status_approved"
                                                                                : "weekly_status_inapproval"
                                                                    }
                                                                >
                                                                    {e.Status}
                                                                </span>
                                                            </td>
                                                        ) : null}
                                                        <td>{e.Approver}</td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                                :
                                <EmptyTable title={"No Data Found"} />

                        }
                    </div>
                </div>
            </div >
            {
                isComment && (
                    <div className='timesheet_comment_container'>
                        <div className='timsheet_comment_card'>
                            <div className='timesheet_comment_header'>
                                <span>Comments {currentVal === 'Approve' && "(Optional)"}</span>
                                <Close onClick={handleClose} style={{ cursor: "pointer" }} />
                            </div>
                            <div className='timesheet_comment_content'>
                                <textarea value={comment} onChange={(e) => {
                                    if (e.target.value.length <= 100) {
                                        setComment(e.target.value)
                                    }
                                }}
                                    placeholder={currentVal === 'Approve' ? 'Enter a Comment' : 'Enter a Comment*'}
                                />
                                <div className='timesheet_comment_count' style={{ color: comment.length > 100 ? "red" : "#B9B9B9" }}>
                                    <span>{comment.length}/100</span>
                                </div>
                            </div>
                            <div className='timesheet_comment_actions'>
                                <button onClick={handleSubmit}>{currentVal === "Approve" ? "Approve" : "Reject"}</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default TimesheetApproval

const tableCol = [
    {
        id: 0,
        name: "Emp.Name",
        width: "18%"
    },
    {
        id: 1,
        name: "WeekEnding",
        width: "10%"
    },
    {
        id: 11,
        name: "Project Code",
        width: "15%"
    },
    {
        id: 111,
        name: "Project Name",
        width: "15%"
    },
    {
        id: 2323232,
        name: "Project Total",
        width: "15%"
    },
    {
        id: 2,
        name: "Job Title",
        width: "15%"
    },
    {
        id: 3,
        name: "Total",
        width: "5%"
    },
    // {
    //     id: 4,
    //     name: "InHouse",
    //     width: "5%"
    // },
    {
        id: 5,
        name: "OT",
        width: "5%"
    },
    // {
    //     id: 7,
    //     name: "DT",
    //     width: "5%"
    // },
    {
        id: 8,
        name: "Submitted",
        width: "12%"
    },
    {
        id: 9,
        name: "Status",
        width: "15%"
    },
    {
        id: 10,
        name: "Approver",
        width: "15%"
    },
]


const tempStatus = [
    {
        id: 0,
        Status: "All",
    },
    {
        id: 1,
        Status: "Submitted",
    },
    {
        id: 2,
        Status: "Rejected",
    },
    {
        id: 3,
        Status: "Approved",
    },
];

function removeDuplicatesFromArray(inputArray) {
    let uniqueArray = [];
    inputArray.forEach((item) => {
        if (!uniqueArray.includes(item)) {
            uniqueArray.push(item);
        }
    });
    return uniqueArray;
}

function areRowsSame(row1, row2) {
    return (
        row1.Name === row2.Name &&
        row1.WeekNumber === row2.WeekNumber
    );
}

function countConsecutiveRowsWithSameData(timesheets, startIndex, rowData) {
    let count = 1;
    for (let i = startIndex + 1; i < timesheets.length; i++) {
        if (areRowsSame(timesheets[i], rowData)) {
            count++;
        } else {
            break;
        }
    }
    return count;
}