import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { setIsLoggedIn, setUserLoggedInData } from "../redux/features/UserSlice"
import { AxiosGetwithHeaders, AxiosPost } from "../data/utilities"
import CryptoJS from "crypto-js"

import WeekUtils from 'week-utils'
import { setInitialValues } from "../redux/features/InitialSlice"
import { useLocation, useNavigate } from "react-router-dom"
import { useIsAuthenticated } from "@azure/msal-react"
// useMsalAuthentication
const weeks = new WeekUtils();

function useGetInitialData() {

    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isAuth = useIsAuthenticated()

    useEffect(() => {

        const handleRefresh = async (res) => {

            setLoading(true)

            let userDB = await AxiosGetwithHeaders(`/iemployee/get-current-employee-by-id`, { id: res.Id })

            let SECURE_LOGIN = CryptoJS.AES.encrypt(JSON.stringify({ ...userDB.data.result, "role": res.role }), process.env.REACT_APP_SECRECT_KEY).toString()

            localStorage.setItem('__iemployee__user', SECURE_LOGIN)

            dispatch(setUserLoggedInData({ ...userDB.data.result, "role": res.role }))

            const currentDate = new Date()

            try {
                let result = await AxiosPost(`/iemployee/get-initial-details`, {
                    FKEmpId: userDB.data.result.Id,
                    NumberOfWeeks: weeks.localWeeks(currentDate.getFullYear()),
                    Year: currentDate.getFullYear()
                })

                if (result.data.iSuccess === false) {
                    localStorage.removeItem('__iemployee__user')
                    navigate('/')
                    return false
                }

                dispatch(setInitialValues(result.data.InitialDetails))
            }
            catch (err) {
                console.log(err)
                localStorage.removeItem('__iemployee__user')
                navigate('/')
            }
            dispatch(setIsLoggedIn(true))
            setLoading(false)
        }
        if (isAuth) {
            if (localStorage.getItem('__iemployee__user') !== null) {
                let value = JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('__iemployee__user'), process.env.REACT_APP_SECRECT_KEY).toString(CryptoJS.enc.Utf8))
                navigate(location.pathname === '/' || location.pathname === '/register' ? '/dashboard' : location.pathname)
                handleRefresh(value)
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAuth])

    return { loading }
}

export default useGetInitialData