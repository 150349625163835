import React from 'react';
import "../App.css"

import MyProfile from '../MyProfile';
import MyTime from '../MyTime'

import LoginFile from '../Screens/Login/Login'
// import Register from '../Screens/Register/Register';
// import ChangePassword from '../Screens/ChangePassword/ChangePassword';
import ErrorPage01 from '../Components/ErrorPage/ErrorPage';
import Registered from '../Pages/Registered/Registered';
import Profile from '../Screens/Profile/Profile'

import { Route, Routes, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import { getIsLoggedIn, getUserData } from '../redux/features/UserSlice';
import { setInitialValues } from '../redux/features/InitialSlice'
import HeaderOnly from '../Layouts/HeaderOnly';
import Dashboard from '../Screens/Dashboard';
import POPUPS from '../Components/POPUPS';

import WeekUtils from 'week-utils'
import { setLoadingScreen } from '../redux/features/ActivateSlice';
import Approvals from '../Screens/Approvals';
import IsLogin from '../Layouts/IsLogin';
import LayoutWithBackground from '../Layouts/LayoutWithBackgroundImage';
import ProjectAllocation from '../ProjectAllocation';
import ProjectCodeGeneration from '../ProjectCodeGeneration';
import { AxiosPost } from '../data/utilities';
import useGetInitialData from '../hooks/useGetInitialData';
import Loader from '../Components/Loading/Loader';
import Holidays from '../Screens/Holidays';

const weeks = new WeekUtils();

export default function MainRoute() {

    const dispatch = useDispatch()

    const { loading } = useGetInitialData()

    const navigate = useNavigate()

    const userData = useSelector(getUserData);

    const isLoggedIn = useSelector(getIsLoggedIn)

    const RefreshContent = async () => {

        const currentDate = new Date()

        let result = await AxiosPost(`/iemployee/get-initial-details`, {
            FKEmpId: userData.Id,
           // Role: userData.role,
            NumberOfWeeks: weeks.localWeeks(currentDate.getFullYear()),
            Year: currentDate.getFullYear()
        })

        if (result.data.iSuccess === false) {
            localStorage.removeItem('_dev_user')
            navigate('/')
            return false
        }

        dispatch(setInitialValues(result.data.InitialDetails))

        // // Employees
        // if (userData.organization.toLowerCase().includes("genzeon")) {
        //     dispatch(setEmployeeData(result.data.InitialDetails.Employees))
        // }
        // else {
        //     dispatch(setEmployeeData(result.data.InitialDetails.Employees.filter((item) => item.ClientName.toLowerCase() === userData.organization.toLowerCase())))
        // }

    }

    const GetInitialValues = async (FKEmpId) => {

        const currentDate = new Date()

        let result = await AxiosPost(`/iemployee/get-initial-details`, {
            FKEmpId: FKEmpId,
            NumberOfWeeks: weeks.localWeeks(currentDate.getFullYear()),
            Year: currentDate.getFullYear()
        })

        if (result.data.iSuccess === false) {
            dispatch(setLoadingScreen(false))
            localStorage.removeItem('_dev_user')
            navigate('/')
            return false
        }

        dispatch(setInitialValues(result.data.InitialDetails))

        dispatch(setLoadingScreen(false))


        // // Employees
        // if (org.toLowerCase().includes("genzeon")) {
        //     dispatch(setEmployeeData(result.data.InitialDetails.Employees))
        // }
        // else {
        //     dispatch(setEmployeeData(result.data.InitialDetails.Employees.filter((item) => item.ClientName.toLowerCase() === org.toLowerCase())))
        // }


    }

    if (loading) {
        return <Loader />
    }

    return (
        <div className='app'>
            <Routes>

                <Route path='/myprofile/*' element={<MyProfile RefreshContent={RefreshContent} />} />

                <Route path='/mytime/*' element={<MyTime />} />

                <Route path='/project-allocation/*' element={<ProjectAllocation />} />

                <Route path='/project-codes/*' element={<ProjectCodeGeneration />} />

                <Route element={<HeaderOnly isExists={isLoggedIn} />}>
                    <Route path='/profile' element={<Profile />} />
                    <Route path='/holidays' element={<Holidays />} />
                </Route>
                <Route element={<LayoutWithBackground isExists={isLoggedIn} />}>
                    <Route path='/dashboard' element={<Dashboard />} />
                </Route>

                {/* <Route path='/register' element={<Register />} /> */}

                <Route path='/success' element={<Registered />} />

                <Route path='/' element={<LoginFile GetInitialValues={GetInitialValues} />} />

                <Route path='/errorpage' element={<ErrorPage01 />} />

                <Route path={'/*'} element={<ErrorPage01 />} />

                {/* <Route path='/change-password/:secret_string' element={<ChangePassword />} /> */}

                <Route element={<IsLogin />}>
                    <Route path='/process/:process_data' element={<Approvals />} />
                </Route>

            </Routes>
            {/* Alerts */}
            <POPUPS RefreshContent={RefreshContent} />
        </div>
    );
}