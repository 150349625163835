import React, { useEffect, useState } from 'react'
import './ManageClient.css'

import { useSelector } from 'react-redux'
import CloseIcon from '@mui/icons-material/Close'

import { getUserData } from '../../../redux/features/UserSlice'
import { toast } from 'react-toastify'
import Messages from '../../../data/Messages.json'
import { AxiosGet, AxiosPost } from '../../../data/utilities'

function ManageClient({
    isVisible,
    setIsVisible,
    selectedClient,
    setSelectedClient,
    setClientsData
}) {

    const [clientName, setClientName] = useState('')
    const [typeOfContract, setTypeOfContract] = useState('')
    const [genzeonPOC, setGenzeonPOC] = useState('')
    const [clientPOC, setClientPOC] = useState('')
    const [managerFKId, setManagerFKId] = useState('')
    const userData = useSelector(getUserData)

    const [isError, setIsError] = useState({
        isClient: false,
        isType: false,
        isGenPOC: false,
        isClientPOC: false,
        isManager: false
    })

    useEffect(() => {
        // Get employees here
        if (selectedClient === 0) {
            setSelectedClient(0)
            setIsVisible(false)
            setClientName('')
            setTypeOfContract('')
            setGenzeonPOC('')
            setClientPOC('')
            setManagerFKId('')
        }
        else {
            AxiosPost(`/iemployee/get-by-client`, {
                id: selectedClient
            }).then((res) => {
                if (res.data.isSuccess) {
                    res.data.ClientData.forEach((e) => {
                        setClientName(e.ClientName)
                        setTypeOfContract(e.TypeOfContract)
                        setGenzeonPOC(e.GenzeonPOC)
                        setClientPOC(e.ClientPOC)
                        setManagerFKId(e.ProjectManager)
                    })
                }
            })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedClient, setIsVisible, setSelectedClient])

    const handleSubmit = () => {

        if (clientName === '' || typeOfContract === '' || genzeonPOC === '' || clientPOC === '' || managerFKId === '') {
            toast.error(Messages.ManageClients.ManageClients_Provide_Details, { toastId: "iemployee-toast" })

            let values = isError

            if (clientName === '') {
                values = { ...values, "isClient": true }
            }
            if (typeOfContract === '') {
                values = { ...values, "isType": true }
            }
            if (genzeonPOC === '') {
                values = { ...values, "isGenPOC": true }
            }
            if (clientPOC === '') {
                values = { ...values, "isClientPOC": true }
            }
            if (managerFKId === '') {
                values = { ...values, "isManager": true }
            }

            setIsError(values)

        }
        else {
            if (selectedClient === 0) {
                AxiosPost(`/iemployee/insert-client`, {
                    ClientName: clientName,
                    TypeOfContract: typeOfContract,
                    GenzeonPOC: genzeonPOC,
                    ClientPOC: clientPOC,
                    ProjectManager: managerFKId,
                    FKEmployeeId: userData.Id
                }).then((res) => {
                    if (res.data.isSuccess) {
                        toast.success(Messages.ManageClients.ManageClients_Success, { toastId: "iemployee-toast" })
                        handleClear()
                    }
                    else {
                        toast.error(Messages.ManageClients.ManageClients_Error, { toastId: "iemployee-toast" })
                        setIsVisible(false)
                    }
                })
            }

            else {
                AxiosPost(`/iemployee/update-client`, {
                    id: selectedClient,
                    ClientName: clientName,
                    TypeOfContract: typeOfContract,
                    GenzeonPOC: genzeonPOC,
                    ClientPOC: clientPOC,
                    ProjectManager: managerFKId,
                    FKEmployeeId: userData.Id


                }).then((res) => {
                    if (res.data.isSuccess) {
                        toast.success(Messages.ManageClients.ManageClients_Update_Success, { toastId: "iemployee-toast" })
                        handleClear()
                    }
                    else {
                        toast.error(Messages.ManageClients.ManageClients_Error, { toastId: "iemployee-toast" })
                        setIsVisible(false)
                    }
                })
            }
        }
        AxiosGet(`/iemployee/get-clients`).then((res) => {
            if (res.data.isSucess) {
                setClientsData(res.data.Clients)
            }
        })

    }

    const handleClear = () => {
        setSelectedClient(0)
        setIsVisible(false)
        setClientName('')
        setTypeOfContract('')
        setGenzeonPOC('')
        setClientPOC('')
        setManagerFKId('')
    }

    return (
        <>
            {
                isVisible ?
                    <div className='manage_client'>
                        <div className='manage_client_card'>
                            <div className='manage_client_header'>
                                <div className='manage_client_header_title'>
                                    {
                                        selectedClient === 0 ?
                                            <span>Add Client</span>
                                            :
                                            <span>Edit Client</span>
                                    }
                                </div>
                                <div className='manage_client_header_close'>
                                    <CloseIcon onClick={handleClear} style={{ cursor: "pointer" }} />
                                </div>
                            </div>
                            <div className='manage_client_content'>
                                <div className='manage_client_content_fields'>
                                    <label>Client Name</label>
                                    <input
                                        placeholder='Enter Client Name'
                                        value={clientName}
                                        className={isError.isClient ? "error_border" : ''}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 50) {
                                                setClientName(e.target.value)
                                                setIsError({ ...isError, 'isClient': false })
                                            }
                                        }}
                                    />
                                </div>
                                <div className='manage_client_content_fields'>
                                    <label>Type of Contract</label>
                                    <input
                                        placeholder='Enter Type of Contract'
                                        value={typeOfContract}
                                        className={isError.isType ? "error_border" : ''}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 50) {
                                                setTypeOfContract(e.target.value)
                                                setIsError({ ...isError, 'isType': false })
                                            }
                                        }}
                                    />
                                </div>
                                <div className='manage_client_content_fields'>
                                    <label>Genzeon POC</label>
                                    <input
                                        placeholder='Enter Genzeon POC'
                                        value={genzeonPOC}
                                        className={isError.isGenPOC ? "error_border" : ''}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 30) {
                                                setGenzeonPOC(e.target.value)
                                                setIsError({ ...isError, 'isGenPOC': false })
                                            }
                                        }}
                                    />
                                </div>
                                <div className='manage_client_content_fields'>
                                    <label>Client POC</label>
                                    <input
                                        placeholder='Enter Client POC'
                                        value={clientPOC}
                                        className={isError.isClientPOC ? "error_border" : ''}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 30) {
                                                setClientPOC(e.target.value)
                                                setIsError({ ...isError, 'isClientPOC': false })
                                            }
                                        }}
                                    />
                                </div>
                                <div className='manage_client_content_fields'>
                                    <label>Manager Name</label>
                                    <input
                                        placeholder='Enter Project Manager'
                                        value={managerFKId}
                                        className={isError.isManager ? "error_border" : ''}
                                        onChange={(e) => {
                                            if (e.target.value.length <= 30) {
                                                setManagerFKId(e.target.value)
                                                setIsError({ ...isError, 'isManager': false })
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='manage_client_action'>
                                <div>
                                    {
                                        selectedClient === 0 ?
                                            <button onClick={handleSubmit}>Create</button> :
                                            <button onClick={handleSubmit}>Update</button>
                                    }
                                </div>
                            </div>
                        </div>

                    </div>
                    :
                    <></>
            }
        </>
    )
}

export default ManageClient