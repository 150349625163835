import moment from "moment"
import { setLoadingScreen } from "../../../redux/features/ActivateSlice"
import { toast } from 'react-toastify'
import { setInitialValues } from "../../../redux/features/InitialSlice"
import { AxiosGetwithHeaders, AxiosPost, getCurrentDate, getWeekFromWeekUtils } from "../../../data/utilities"


export const handleStartDate = (weekNum, Year, value) => {

    const MONTHS = [{ id: "01", name: "Jan" }, { id: "02", name: "Feb" }, { id: "03", name: "Mar" },
    { id: "04", name: "Apr" }, { id: "05", name: "May" }, { id: "06", name: "Jun" },
    { id: "07", name: "Jul" }, { id: "08", name: "Aug" }, { id: "09", name: "Sep" },
    { id: "10", name: "Oct" }, { id: "11", name: "Nov" }, { id: "12", name: "Dec" },]

    let result = moment().year(Year).week(weekNum).startOf('week')

    if (value === 'Date') {
        return `${MONTHS.filter(item => item.name === result._d.toString().split(' ')[1])[0].id}/${result._d.toString().split(' ')[2]}`
    }
    else {
        return Number.parseInt(MONTHS.filter(item => item.name === result._d.toString().split(' ')[1])[0].id)
    }


}

export const handleEndDate = (weekNum, Year) => {

    const MONTHS = [{ id: "01", name: "Jan" }, { id: "02", name: "Feb" }, { id: "03", name: "Mar" },
    { id: "04", name: "Apr" }, { id: "05", name: "May" }, { id: "06", name: "Jun" },
    { id: "07", name: "Jul" }, { id: "08", name: "Aug" }, { id: "09", name: "Sep" },
    { id: "10", name: "Oct" }, { id: "11", name: "Nov" }, { id: "12", name: "Dec" },]

    let result = moment().year(Year).week(weekNum).endOf('week')

    return `${MONTHS.filter(item => item.name === result._d.toString().split(' ')[1])[0].id}/${result._d.toString().split(' ')[2]}`


}

export const handleTimesheetDetails = async (
    dispatch,
    currentWeek,
    FKEmpId,
    setJobTitle,
    weekArray,
    setTimesheet,
    Status,
    SpecialCondition) => {

    dispatch(setLoadingScreen(true))
    let result = []
    if (SpecialCondition === 'MonthEnd' && Status !== null && Status !== 'Pending') {
        result = await AxiosPost(`/timesheet/get-timesheet-details-for-special-condition`, {
            FKEmpId: currentWeek.FKEmpId,
            WeekNumber: currentWeek.weeknumber,
            Year: currentWeek.year
        })
    }
    else {
        result = await AxiosGetwithHeaders(`/timesheet/get-timesheet-records-of-employee`, {
            weekid: currentWeek.weekId,
            status: Status,
            fkempid: currentWeek.FKEmpId,
            Year: currentWeek.year
        })
    }

    if (result.data.isSuccess) {
        setJobTitle(result.data.ROLE)
        let new_arr = []
        result.data.Timesheet.forEach((item) => {
            if (item.ExpiryDate !== undefined) {
                if (handleExpiryDate(item.ExpiryDate, weekArray)) {
                    if (handleJoiningDate(item.JoiningDate, weekArray) && handleEndingDate(item.EndingDate, weekArray)) {
                        new_arr.push(item)
                    }
                }
            }
            else {
                new_arr.push(item)
            }
        })
        setTimesheet([])
        setTimeout(() => {
            setTimesheet(new_arr)
        }, 50)
        dispatch(setLoadingScreen(false))

    }
    else {
        setTimesheet([])
        dispatch(setLoadingScreen(false))
    }

}

const handleJoiningDate = (jnDate, weekArray) => {

    let JoiningDate = new Date(jnDate)

    let result = false

    weekArray.forEach((item) => {

        if (JoiningDate.getTime() <= item.getTime()) {
            result = true
        }
    })

    return result

}

const handleEndingDate = (endDate, weekArray) => {

    let EndingDate = new Date(endDate)
    let result = false

    weekArray.forEach((item) => {
        if (EndingDate.getTime() > item.getTime()) {
            result = true
        }
    })

    return result
}

const handleExpiryDate = (expDate, weekArray) => {
    let expiryDate = new Date(expDate)

    let result = false

    weekArray.forEach((item) => {

        if (expiryDate.getTime() >= item.getTime()) {
            result = true
        }
    })

    return result
}

export const handleUpdateTimesheets = async (type, dispatch, navigate, setIsActive, message, FKEmpId, userId, initialData) => {

    try {
        if (type === 'Manager') {
            let res = await AxiosPost(`/timesheet/get-weekly-timesheet-data`, {
                Approver: userId
            })
            if (res.data.isSuccess) {
                dispatch(setInitialValues({ ...initialData, "TimesheetApprovals": res.data.timesheet }))
            }
            navigate('/mytime/my-team/home')

        }

        if (type === 'normal') {

            const dt = getCurrentDate()

            let numberofweeks = getWeekFromWeekUtils().localWeeks(dt.getFullYear())

            let res = await AxiosPost(`/timesheet/get-weekly-timesheet-by-fk-id`, {
                FKId: FKEmpId,
                Year: dt.getFullYear(),
                NumberOfWeeks: numberofweeks
            })

            if (res.data.isSuccess) {
                dispatch(setInitialValues({ ...initialData, "WeeklyTimesheet": res.data.EmployeeTimesheet }))
            }
            navigate('/mytime/home')

        }
        toast.success(`Successfully ${message} Timesheet data`, { toastId: "iemployee-toast" })

    }
    catch (err) {
        toast.error(`Please try after sometime`, { toastId: "iemployee-toast" })
        console.error(err)
    }
    finally {
        dispatch(setLoadingScreen(false))
    }

    setIsActive(false)

}