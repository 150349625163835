import React, { useState } from 'react'
import "./SortBy.css"

import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';

function SortBy({ list, column, setEmployeeAudit }) {

    const [isActive, setIsActive] = useState(false)

    const handleSort = () => {

        if (isActive) {
            if (column === "FullName") {
                setEmployeeAudit(
                    list.slice().sort((current, next) => {
                        let currentName = current.FirstName.toLowerCase(),
                            nextName = next.FirstName.toLowerCase();
                        if (currentName < nextName) {
                            return -1;
                        }
                        if (currentName > nextName) {
                            return 1;
                        }
                        return 0;
                    })
                )
            }
            else if (column === "Status") {
                setEmployeeAudit(
                    list.slice().sort((current, next) => {
                        let currentUpdatedStatus = current.UpdatedStatus.toLowerCase(),
                            nextUpdatedStatus = next.UpdatedStatus.toLowerCase();
                        if (currentUpdatedStatus < nextUpdatedStatus) {
                            return -1;
                        }
                        if (currentUpdatedStatus > nextUpdatedStatus) {
                            return 1;
                        }
                        return 0;
                    })
                )
            }
            else if (column === "Date") {
                setEmployeeAudit(
                    list.slice().sort((current, next) => {
                        let currentCreated_dt = current.Created_dt.toLowerCase(),
                            nextCreated_dt = next.Created_dt.toLowerCase();
                        if (currentCreated_dt < nextCreated_dt) {
                            return -1;
                        }
                        if (currentCreated_dt > nextCreated_dt) {
                            return 1;
                        }
                        return 0;
                    })
                )
            }
            else {
                setEmployeeAudit(
                    list.slice().sort((current, next) => {
                        let currentEmail = current.Email.toLowerCase(),
                            nextEmail = next.Email.toLowerCase();
                        if (currentEmail < nextEmail) {
                            return -1;
                        }
                        if (currentEmail > nextEmail) {
                            return 1;
                        }
                        return 0;
                    })
                )
            }
        }
        else {
            if (column === "FullName") {
                setEmployeeAudit(
                    list.slice().sort((current, next) => {
                        let currentName = current.FirstName.toLowerCase(),
                            nextName = next.FirstName.toLowerCase();
                        if (nextName < currentName) {
                            return -1;
                        }
                        if (nextName > currentName) {
                            return 1;
                        }
                        return 0;
                    })
                )
            }
            else if (column === "Status") {
                setEmployeeAudit(
                    list.slice().sort((current, next) => {
                        let currentUpdatedStatus = current.UpdatedStatus.toLowerCase(),
                            nextUpdatedStatus = next.UpdatedStatus.toLowerCase();
                        if (nextUpdatedStatus < currentUpdatedStatus) {
                            return -1;
                        }
                        if (nextUpdatedStatus > currentUpdatedStatus) {
                            return 1;
                        }
                        return 0;
                    })
                )
            }
            else if (column === "Date") {
                setEmployeeAudit(
                    list.slice().sort((current, next) => {
                        let currentCreated_dt = current.Created_dt.toLowerCase(),
                            nextCreated_dt = next.Created_dt.toLowerCase();
                        if (nextCreated_dt < currentCreated_dt) {
                            return -1;
                        }
                        if (nextCreated_dt > currentCreated_dt) {
                            return 1;
                        }
                        return 0;
                    })
                )
            }
            else {
                setEmployeeAudit(
                    list.slice().sort((current, next) => {
                        let currentEmail = current.Email.toLowerCase(),
                            nextEmail = next.Email.toLowerCase();
                        if (nextEmail < currentEmail) {
                            return -1;
                        }
                        if (nextEmail > currentEmail) {
                            return 1;
                        }
                        return 0;
                    })
                )
            }
        }

        setIsActive(!isActive)

    }

    return (
        <div onClick={handleSort} className='sort_by_container'>
            {
                isActive ?
                    <>
                        <ChangeHistoryOutlinedIcon sx={{ fontSize: 9 }} />
                        <ChangeHistoryTwoToneIcon sx={{ rotate: "180deg", fontSize: 9 }} />
                    </>
                    :
                    <>
                        <ChangeHistoryTwoToneIcon sx={{ fontSize: 9 }} />
                        <ChangeHistoryOutlinedIcon sx={{ rotate: "180deg", fontSize: 9 }} />
                    </>
            }
        </div>
    )
}

export default SortBy