import React, {
    useState
} from 'react'
import "./Header.css"
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { getUserData, setUserLoggedInData } from '../../redux/features/UserSlice'

import { RandomColor } from '../ColorCombo'
import AlertYesNo from '../Alerts/AlertYesNo'
import { setLoadingScreen } from '../../redux/features/ActivateSlice'

import iEmployeeLogo from '../../assets/ProductImages/logo-option2.svg'

function Header() {

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const location = useLocation()

    const userData = useSelector(getUserData)

    const [isActive, setIsActive] = useState(false)

    const [activePOPUP, setActivePOPUP] = useState(false)

    const handleLogout = () => {
        localStorage.removeItem('__iemployee__user')
        dispatch(setUserLoggedInData([]))
        setTimeout(() => {
            dispatch(setLoadingScreen(false))
        }, 1000)
        // inital data remove
        setIsActive(false)
        navigate('/')
    }

    return (
        <>
            <div
                className={location.pathname !== '/dashboard' ? 'header_main_page header_main_bg' : 'header_main_page'}
            >
                {/* Logo */}
                <div className='header_logo'
                    onClick={() => {
                        navigate('/dashboard')
                    }}>
                    <img src={iEmployeeLogo} alt='logo' />
                </div>
                {/* Profile Details */}
                <div className='header_profile_details'
                    onMouseEnter={() => setActivePOPUP(true)}
                    onMouseLeave={() => {
                        setActivePOPUP(false)
                    }}>

                    {/* Welcom with Username */}
                    <div>Welcome,
                        {
                            userData.employeeFirstName === null ?
                                <b style={{ textTransform: "capitalize" }}>{userData.email.split('@')[0].split('.')}</b>
                                :
                                <b style={{ textTransform: "capitalize" }}>{userData.employeeFirstName}</b>
                        }
                    </div>
                    {/* Profile Image */}
                    <div className='header_profile_image'
                    >
                        {
                            userData.profileImage === null ?
                                <div className='dummy_image_profile' style={{ background: RandomColor(userData.employeeFirstName.substring(0, 1)) }} >
                                    {
                                        userData.employeeFirstName !== null ?
                                            <span>{userData.employeeFirstName.substring(0, 1).toUpperCase() + userData.employeeLastName.substring(0, 1).toUpperCase()}</span>
                                            :
                                            <span>{userData.email.substring(0, 2).toUpperCase()}</span>
                                    }
                                </div>
                                :
                                <div className='dummy_image_profile' style={{ background: RandomColor(userData.employeeFirstName.substring(0, 1)) }} >
                                    {
                                        userData.employeeFirstName !== null ?
                                            <span>{userData.employeeFirstName.substring(0, 1).toUpperCase() + userData.employeeLastName.substring(0, 1).toUpperCase()}</span>
                                            :
                                            <span>{userData.email.substring(0, 2).toUpperCase()}</span>
                                    }
                                </div>
                            // <img src={`${process.env.REACT_APP_PROXY}/Config/Uploads/Images/${userData.profileImage}`} alt="profile" />
                        }
                        {
                            activePOPUP && (
                                <div className='header_profile_image_popup'
                                    onClick={() => setActivePOPUP(false)}
                                    onMouseEnter={() => setActivePOPUP(true)}
                                    onMouseLeave={() => {
                                        setActivePOPUP(false)
                                    }}>
                                    <ul>
                                        <li
                                            onClick={() => {
                                                navigate('/profile')
                                            }}>Profile</li>
                                        {
                                            userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU ?
                                                <>
                                                    <li onClick={() => {
                                                        navigate('/holidays')
                                                    }}>Holidays</li>
                                                    <li onClick={() => {
                                                        navigate('/myprofile/clients')
                                                    }}>Clients</li>
                                                </>
                                                :
                                                <></>
                                        }
                                        <li onClick={() => {
                                            setIsActive(true)
                                        }}>Logout</li>
                                    </ul>
                                </div>
                            )
                        }

                    </div>
                </div>
            </div>
            <AlertYesNo
                variant={'warning'}
                message={'Are you sure?'}
                handleLogout={handleLogout}
                isActive={isActive}
                setIsActive={setIsActive}
            />
        </>
    )
}

export default Header