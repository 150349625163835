import React, { useEffect, useRef, useState } from 'react'
import "./MonthlySummary.css"
import { getUserData } from '../../../redux/features/UserSlice'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, InputLabel, MenuItem, Select, TextField, Tooltip } from '@mui/material'
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { ArrowDropDown} from '@mui/icons-material';
import { AxiosPost, TextCapaitalized } from '../../../data/utilities';
import EmptyTable from '../../../Components/NoData'
import { getInitialValues } from '../../../redux/features/InitialSlice';
import { toast } from 'react-toastify';
import { utils, writeFile } from 'xlsx';
import Messages from "../../../data/Messages.json"
import { setLoadingScreen } from '../../../redux/features/ActivateSlice';

export default function MonthlySummary() {

    const [filterToggle, setFilterToggle] = useState(false)
    const [isActiveEmployeeToggle, setIsActiveEmployeeToggle] = useState(false)
    const [isActiveManagerToggle, setIsActiveManagerToggle] = useState(false)
    const [isActiveYearToggle, setIsActiveYearToggle] = useState(false)
    const [selectedEmployee, setSelectedEmployee] = useState(null)
    const [selectedMangers, setSelectedMangers] = useState(null)


    const userData = useSelector(getUserData)
    const employeedata = useSelector(getInitialValues)
    const dispatch = useDispatch()

    const [data, setData] = useState([])
    const [allEmployeeData, setAllEmployeeData] = useState(employeedata)
    const [summarySheet, setSummarySheet] = useState([])

    const [Years, setYears] = useState({
        list: [],
        filterYear: new Date().getFullYear()
    });
    const [month, setMonth] = useState(new Date().getMonth() + 1)

    const tableRef = useRef(null);

    useEffect(() => {

        let AllEmployeeArray;
        AllEmployeeArray = [{ Id: -1, FirstName: "ALL", LastName: " " }, ...employeedata.Employees]
        setAllEmployeeData(AllEmployeeArray)


        const handleGetMonthlyTimesheetReports = async () => {

            let reportees = [...userData.reportees, ...userData.allocatedEmployees]

            reportees = reportees.filter((item, index) => {
                return index === reportees.findIndex((e) => item === e);
            });

            dispatch(setLoadingScreen(true))
            let result = await AxiosPost('/timesheet/get-monthly-summary', {
                FKIds: reportees,
                Year: new Date().getFullYear(),
                Month: (new Date().getMonth() + 1),
                Type: "Normal"
            })

            if (result.data.isSuccess) {
                dispatch(setLoadingScreen(false))
                setSummarySheet(result.data.MonthlyReport)
                setData(result.data.MonthlyReport)
            } else {
                dispatch(setLoadingScreen(false))

            }
        }

        handleGetMonthlyTimesheetReports()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData])

    useEffect(() => {

        let fixedYear = 2023

        let currentYear = new Date().getFullYear()

        let numberOfArrays = new Array((currentYear - fixedYear) + 1)

        let values = []

        for (let i = 0; i < numberOfArrays.length; i++) {
            values.push({
                year: 2023 + i
            })
        }
        setYears({ ...Years, "list": values })
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleClear = () => {
        let dt = new Date();
        setYears({ ...Years, filterYear: dt.getFullYear() })
        setMonth(dt.getMonth() + 1)
        setSelectedEmployee(null)
        setSelectedMangers(null)
        setIsActiveEmployeeToggle(false)
        setIsActiveManagerToggle(false)
        setIsActiveYearToggle(false)
        setSummarySheet(data)
    }

    const handleOnSelectedDate = async (e) => {
        e.preventDefault();

        const selectedEmployeeValue = selectedEmployee;
        const selectedYearValue = Years.filterYear;
        const selectedMonthValue = month;

        if (!selectedEmployee || !selectedMonthValue || !selectedYearValue) {
            toast.error("please select employee, year and month")
            return;
        }

        try {
            dispatch(setLoadingScreen(true))
            const result = await AxiosPost('/timesheet/get-monthly-summary', {
                FKIds: [selectedEmployeeValue],
                Month: selectedMonthValue,
                Year: selectedYearValue,
                Type: "Normal"
            });

            if (result.data.isSuccess) {
                dispatch(setLoadingScreen(false))
                setSummarySheet(result.data.MonthlyReport)
            } else {
                dispatch(setLoadingScreen(false))
                setSummarySheet([])
            }
            const result1 = await AxiosPost('/timesheet/get-generic-monthly-summary', {
                Manager: selectedEmployeeValue,
                Month: selectedMonthValue,
                Year: selectedYearValue,
                Type: "Manager"
            })
            if (result1.data.isSuccess) {
                dispatch(setLoadingScreen(false))
            }
            else {
                dispatch(setLoadingScreen(false))
            }
        } catch (error) {
            console.error('Error fetching timesheet data:', error);
        }
    };

    const handleGetDetailsBasedOnManager = async (e) => {
        e.preventDefault();

        const selectedEmployeeValue = selectedMangers;
        const selectedYearValue = Years.filterYear;
        const selectedMonthValue = month;

        if (!selectedMangers || !selectedMonthValue || !selectedYearValue) {
            toast.error("please select employee, year and month")
            return;
        }

        try {
            dispatch(setLoadingScreen(true))
            const result = await AxiosPost('/timesheet/get-monthly-summary', {
                Manager: selectedEmployeeValue,
                Month: selectedMonthValue,
                Year: selectedYearValue,
                Type: "Manager"
            });

            if (result.data.isSuccess) {
                dispatch(setLoadingScreen(false))
                setSummarySheet(result.data.MonthlyReport)
            } else {
                dispatch(setLoadingScreen(false))
                setSummarySheet([])
            }
            const result1 = await AxiosPost('/timesheet/get-generic-monthly-summary', {
                Manager: selectedEmployeeValue,
                Month: selectedMonthValue,
                Year: selectedYearValue,
                Type: "Manager"
            })
            if (result1.data.isSuccess) {
                dispatch(setLoadingScreen(false))
            }
            else {
                dispatch(setLoadingScreen(false))
            }
        } catch (error) {
            console.error('Error fetching timesheet data:', error);
        }
    }

    const handleMonthAndYear = async (e) => {
        e.preventDefault();

        let reportees = [...userData.reportees, ...userData.allocatedEmployees]

        reportees = reportees.filter((item, index) => {
            return index === reportees.findIndex((e) => item === e);
        });
        const selectedYearValue = Years.filterYear;
        const selectedMonthValue = month;

        if (!selectedMonthValue || !selectedYearValue) {
            toast.error("please select employee, year and month")
            return;
        }

        try {
            dispatch(setLoadingScreen(true))
            const result = await AxiosPost('/timesheet/get-monthly-summary', {
                FKIds: reportees,
                Month: selectedMonthValue,
                Year: selectedYearValue,
                Type: "Normal"
            });

            if (result.data.isSuccess) {
                dispatch(setLoadingScreen(false))
                setSummarySheet(result.data.MonthlyReport)
            } else {
                dispatch(setLoadingScreen(false))
                setSummarySheet([])
            }
        } catch (error) {
            console.error('Error fetching timesheet data:', error);
        }
    }

    const handleExport = () => {

        if (summarySheet.length === 0) {
            toast.error(Messages.Timesheet_Reports.Timesheet_Reports_Select_Columns, { toastId: "iemployee-toast" })
            return false
        }

        const table = tableRef.current;//refering table
        const tableRows = Array.from(table.getElementsByTagName('tr'));
        const renderedTableData = tableRows.map(row =>
            Array.from(row.getElementsByTagName('td')).reduce((rowData, cell, cellIndex) => {
                const header = tableCol[cellIndex];
                rowData[header.value] = cell.innerText;
                return rowData;
            }, {})
        );
        renderedTableData.shift();
        const workbook = utils.book_new();
        const worksheet = utils.json_to_sheet(renderedTableData);
        utils.book_append_sheet(workbook, worksheet, 'EmployeeSummary Data');
        const fileName = 'Emplooyee_Summary_data.xlsx';
        writeFile(workbook, fileName);

    }

    const getnonbillables = (location, bussiness_days, billable_day, nonBillable_day) => {
        let count = 0;
        let perDayHours = location === 1 ? 8 : 9
        count = ((bussiness_days * perDayHours) - ((billable_day * perDayHours) + nonBillable_day)) / perDayHours
        return count
    }
    
    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            {/* filters */}
            <div className="reports_filters_container" style={{ width: !filterToggle ? "0%" : "20%" }}>
                <div className="reports_filters_container_toggle" onClick={() => setFilterToggle(!filterToggle)}>
                    {
                        filterToggle ?
                            <Tooltip placement="top" title={'Hide'}>
                                <FilterListOffIcon />
                            </Tooltip>
                            :
                            <Tooltip placement="top" title={'Filter'}>
                                <FilterListIcon />
                            </Tooltip>
                    }
                </div>

                <div className="timesheet_reports_filters">
                    <div className="timesheet_reports_filters_header">
                        <div className="timesheet_reports_filters_header_title">
                            <span>Filter</span>
                        </div>
                        <div className="timesheet_reports_filters_header_clear">
                            <label onClick={handleClear} className="timesheet_reporting_filters_clear_all">
                                <span>Clear all</span>
                            </label>
                        </div>
                    </div>
                    {
                        userData.role !== process.env.REACT_APP_ROLE_ADMIN && (
                            <div className="timesheet_reports_filter_container">
                                <div
                                    className="timesheet_reports_filter_container_header"
                                    style={{
                                        borderBottom: isActiveYearToggle ? "1px solid #C4C4C4" : "none",
                                    }}
                                >
                                    <span>Year and Month</span>
                                    <ArrowDropDown
                                        style={{
                                            color: "#252525",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setIsActiveYearToggle(!isActiveYearToggle);
                                        }}
                                    />
                                </div>
                                {isActiveYearToggle && (
                                    <div className="employees_filter_container_content">
                                        <form onSubmit={handleMonthAndYear}>
                                            <div className='emp'>
                                                <FormControl sx={{ width: "100%" }}>
                                                    <InputLabel id="selected-year">Year</InputLabel>
                                                    <Select
                                                        label="Year"
                                                        id="selected-year"
                                                        sx={{ width: "100%" }}
                                                        value={Years.filterYear}
                                                        onChange={(e) => {
                                                            setYears({ ...Years, filterYear: e.target.value })
                                                        }
                                                        }
                                                    >

                                                        {Years.list.map((item, index) => (
                                                            <MenuItem key={index} value={item.year}>
                                                                {item.year}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='emp'>
                                                <FormControl sx={{ width: "100%" }}>
                                                    <InputLabel id="selected-month">Filter By Month</InputLabel>
                                                    <Select
                                                        label={"Filter By Month"}
                                                        id="selected-month"
                                                        sx={{ width: "100%" }}
                                                        value={month}
                                                        onChange={(e) => {
                                                            // setMonth(e.target.value)
                                                            // setInpMonth(e.target.value)
                                                            setMonth(e.target.value);
                                                            // handlemonthchange(e)
                                                        }}
                                                    >

                                                        <MenuItem value={1}>January</MenuItem>
                                                        <MenuItem value={2}>February</MenuItem>
                                                        <MenuItem value={3}>March</MenuItem>
                                                        <MenuItem value={4}>April</MenuItem>
                                                        <MenuItem value={5}>May</MenuItem>
                                                        <MenuItem value={6}>June</MenuItem>
                                                        <MenuItem value={7}>July</MenuItem>
                                                        <MenuItem value={8}>August</MenuItem>
                                                        <MenuItem value={9}>September</MenuItem>
                                                        <MenuItem value={10}>October</MenuItem>
                                                        <MenuItem value={11}>November</MenuItem>
                                                        <MenuItem value={12}>December</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <span style={{ fontSize: '10px', paddingLeft: "10px", display: "block", marginTop: "10px" }}>
                                                *This will filter for only employees who are reporting to you and employees who are allocated to you for projects.
                                            </span>
                                            <div
                                                className="timesheet_timeline_btn_1"
                                            >
                                                <button type='submit'
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                )}
                            </div>
                        )
                    }
                    {userData.role === process.env.REACT_APP_ROLE_SU && (
                        <>
                            <div className="timesheet_reports_filter_container">
                                <div
                                    className="timesheet_reports_filter_container_header"
                                    style={{
                                        borderBottom: isActiveEmployeeToggle ? "1px solid #C4C4C4" : "none",
                                    }}
                                >
                                    <span>Employee</span>
                                    <ArrowDropDown
                                        style={{
                                            color: "#252525",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setIsActiveEmployeeToggle(!isActiveEmployeeToggle);
                                        }}
                                    />
                                </div>
                                {isActiveEmployeeToggle && (
                                    <div className="employees_filter_container_content">

                                        <form onSubmit={handleOnSelectedDate}>
                                            <div className='emp'>
                                                <TextField
                                                    id="Employee Filter"
                                                    select
                                                    label="Select Employee"
                                                    onChange={(e) => setSelectedEmployee(e.target.value)}
                                                    value={selectedEmployee}
                                                    sx={{ width: "100%" }}
                                                >
                                                    {allEmployeeData
                                                        .map((option) => (
                                                            <MenuItem
                                                                key={option.Id}
                                                                value={option.Id}
                                                                sx={{ textTransform: "capitalize" }}
                                                            >
                                                                {TextCapaitalized(
                                                                    option.FirstName + ' ' + option.LastName)
                                                                }
                                                            </MenuItem>
                                                        ))}
                                                </TextField>
                                            </div>
                                            <div className='emp'>
                                                {/* <label id="selected-year">Filter By Year</label> */}
                                                <FormControl sx={{ width: "100%" }}>
                                                    <InputLabel id="selected-year">Year</InputLabel>
                                                    <Select
                                                        // InputLabel="hello"
                                                        label="Year"
                                                        // label="Approver"
                                                        id="selected-year"
                                                        sx={{ width: "100%" }}
                                                        value={Years.filterYear}
                                                        onChange={(e) => {
                                                            setYears({ ...Years, filterYear: e.target.value })
                                                        }

                                                        }
                                                    >

                                                        {Years.list.map((item, index) => (
                                                            <MenuItem key={index} value={item.year}>
                                                                {item.year}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='emp'>
                                                <FormControl sx={{ width: "100%" }}>
                                                    <InputLabel id="selected-month">Filter By Month</InputLabel>
                                                    <Select
                                                        label={"Filter By Month"}
                                                        id="selected-month"
                                                        sx={{ width: "100%" }}
                                                        value={month}
                                                        onChange={(e) => {
                                                            // setMonth(e.target.value)
                                                            // setInpMonth(e.target.value)
                                                            setMonth(e.target.value);
                                                            // handlemonthchange(e)
                                                        }}
                                                    >

                                                        <MenuItem value={1}>January</MenuItem>
                                                        <MenuItem value={2}>February</MenuItem>
                                                        <MenuItem value={3}>March</MenuItem>
                                                        <MenuItem value={4}>April</MenuItem>
                                                        <MenuItem value={5}>May</MenuItem>
                                                        <MenuItem value={6}>June</MenuItem>
                                                        <MenuItem value={7}>July</MenuItem>
                                                        <MenuItem value={8}>August</MenuItem>
                                                        <MenuItem value={9}>September</MenuItem>
                                                        <MenuItem value={10}>October</MenuItem>
                                                        <MenuItem value={11}>November</MenuItem>
                                                        <MenuItem value={12}>December</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div
                                                className="timesheet_timeline_btn_1"
                                            >
                                                <button type='submit'
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                )}
                            </div>
                            <div className="timesheet_reports_filter_container">
                                <div
                                    className="timesheet_reports_filter_container_header"
                                    style={{
                                        borderBottom: isActiveManagerToggle ? "1px solid #C4C4C4" : "none",
                                    }}
                                >
                                    <span>Manager</span>
                                    <ArrowDropDown
                                        style={{
                                            color: "#252525",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setIsActiveManagerToggle(!isActiveManagerToggle);
                                        }}
                                    />
                                </div>
                                {isActiveManagerToggle && (
                                    <div className="employees_filter_container_content">

                                        <form onSubmit={handleGetDetailsBasedOnManager}>
                                            <div className='emp'>
                                                <TextField
                                                    id="Managers Filter"
                                                    select
                                                    label="Select Managers"
                                                    onChange={(e) => setSelectedMangers(e.target.value)}
                                                    value={selectedMangers}
                                                    sx={{ width: "100%", textTransform: "capitalize" }}
                                                >
                                                    {allEmployeeData
                                                        .map((option) => (
                                                            <MenuItem
                                                                key={option.Id}
                                                                value={option.Id}
                                                                sx={{ textTransform: "capitalize" }}
                                                            >
                                                                {option.FirstName + ' ' + option.LastName
                                                                }
                                                            </MenuItem>
                                                        ))}
                                                </TextField>
                                            </div>
                                            <div className='emp'>
                                                {/* <label id="selected-year">Filter By Year</label> */}
                                                <FormControl sx={{ width: "100%" }}>
                                                    <InputLabel id="selected-year">Year</InputLabel>
                                                    <Select
                                                        // InputLabel="hello"
                                                        label="Year"
                                                        // label="Approver"
                                                        id="selected-year"
                                                        sx={{ width: "100%" }}
                                                        value={Years.filterYear}
                                                        onChange={(e) => {
                                                            setYears({ ...Years, filterYear: e.target.value })
                                                        }

                                                        }
                                                    >

                                                        {Years.list.map((item, index) => (
                                                            <MenuItem key={index} value={item.year}>
                                                                {item.year}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </div>
                                            <div className='emp'>

                                                <FormControl sx={{ width: "100%" }}>
                                                    <InputLabel id="selected-month">Filter By Month</InputLabel>
                                                    <Select
                                                        label={"Filter By Month"}
                                                        id="selected-month"
                                                        sx={{ width: "100%" }}
                                                        value={month}
                                                        onChange={(e) => {
                                                            // setMonth(e.target.value)
                                                            // setInpMonth(e.target.value)
                                                            setMonth(e.target.value);
                                                            // handlemonthchange(e)
                                                        }}
                                                    >

                                                        <MenuItem value={1}>January</MenuItem>
                                                        <MenuItem value={2}>February</MenuItem>
                                                        <MenuItem value={3}>March</MenuItem>
                                                        <MenuItem value={4}>April</MenuItem>
                                                        <MenuItem value={5}>May</MenuItem>
                                                        <MenuItem value={6}>June</MenuItem>
                                                        <MenuItem value={7}>July</MenuItem>
                                                        <MenuItem value={8}>August</MenuItem>
                                                        <MenuItem value={9}>September</MenuItem>
                                                        <MenuItem value={10}>October</MenuItem>
                                                        <MenuItem value={11}>November</MenuItem>
                                                        <MenuItem value={12}>December</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </div>



                                            <div
                                                className="timesheet_timeline_btn_1"
                                            >
                                                <button type='submit'
                                                >
                                                    Submit
                                                </button>
                                            </div>
                                        </form>

                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div >
            </div >
            {/* headers */}
            <div className='timesheet_reports_main_screen'>
                <div className="timesheet_reports_container_header">
                    <div className="timesheet_reports_container_header_title">
                        <span>Monthly Summary</span>
                        {
                            summarySheet.length !== 0 && (
                                <label>{summarySheet.length}</label>
                               
                            )
                            
                        }
                    </div>
                    {
                        (
                            <div className="timesheet_reports_container_header_export_btn">

                                <div className="timesheet_reports_container_header_export_span">
                                    {summarySheet.length !== 0 &&
                                        <button className="dropbtn_export" onClick={handleExport}>Export</button>
                                    }
                                </div>
                            </div>
                        )
                    }
                </div>

                {/* content */}
                <div className='timesheet_reports_container_table'>
                    {summarySheet.length !== 0 ?
                        <>
                            <table ref={tableRef}>
                                <thead>
                                    <tr>
                                        {
                                            tableCol.map((e, index) => {
                                                return (

                                                    <th key={index}>{e.value}</th>

                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        summarySheet.map((e, index) => {
                                            return (
                                                <tr key={index}>

                                                  {          /*  
                                                        <>{index === 0 || !areRowsSame(summarySheet[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(summarySheet, index, e)}>{e.PncEmployeeid}
                                                        </td>) : <td hidden>{e.PncEmployeeid }</td>}</>
                                                    */}

                                                
                                                        
                                                    {         /*  employeeName */
                                                        <>{index === 0 || !areRowsSame(summarySheet[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(summarySheet, index, e)}>{e.EmployeeName
                                                        }</td>) : <td hidden>{e.EmployeeName}</td>}</>
                                                    }
                                                      
                                                    {/*  Email {     
                                                        <>{index === 0 || !areRowsSame(summarySheet[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(summarySheet, index, e)}>{e.Email}</td>) : <td hidden>{e.Email}</td>}</>
                                                    }  */}
                                                
                                                   {
                                                       <td>{e.JobTitle}</td>  
                                                    } 
                                                    {
                                                        <td>{e.ProjectCode}</td>
                                                    }
                                                    {
                                                        <>{index === 0 || !areRowsSame(summarySheet[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(summarySheet, index, e)}>{LocationColumn.filter(location => location.value === e.Location)[0].name}</td>) : <td hidden>{LocationColumn.filter(location => location.value === e.Location)[0].name}</td>}</>
                                                    }
                                                    {
                                                        <>{index === 0 || !areRowsSame(summarySheet[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(summarySheet, index, e)}>{e.BusinessDays}</td>) : <td hidden>{e.BusinessDays}</td>}</>
                                                    }
                                                    {
                                                        <>{index === 0 || !areRowsSame(summarySheet[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(summarySheet, index, e)}>{(Math.round(e.BillableDays * 2) / 2)}</td>) : <td hidden>{(Math.round(e.BillableDays * 2) / 2)}</td>}</>
                                                    }
                                                    {
                                                        <>{index === 0 || !areRowsSame(summarySheet[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(summarySheet, index, e)}>{e.MonthlyHours}</td>) : <td hidden>{e.MonthlyHours}</td>}</>
                                                    }
                                                    {
                                                        <>{index === 0 || !areRowsSame(summarySheet[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(summarySheet, index, e)}>{
                                                            (e.RemainingMonthlyHours) / (e.Location === 1 ? 8 : 9)
                                                        }</td>) : <td hidden>{
                                                            (e.RemainingMonthlyHours) / (e.Location === 1 ? 8 : 9)
                                                        }</td>}</>
                                                    }
                                                    {
                                                        <>{index === 0 || !areRowsSame(summarySheet[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(summarySheet, index, e)}>{
                                                            Math.floor(getnonbillables(e.Location, e.BusinessDays, e.BillableDays, e.RemainingMonthlyHours))
                                                        }</td>) : <td hidden>{
                                                            Math.floor(getnonbillables(e.Location, e.BusinessDays, e.BillableDays, e.RemainingMonthlyHours))
                                                        }</td>}</>
                                                    }
                                                    {
                                                        <>{index === 0 || !areRowsSame(summarySheet[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(summarySheet, index, e)}>
                                                             { (e.OT) }</td>) : <td hidden>{ (e.OT)}</td>}</>
                                                    }

                                                          {/*
                                                        <>{index === 0 || !areRowsSame(summarySheet[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(summarySheet, index, e)}>
                                                        {(e.Billable)}</td>) : <td hidden>{(e.Billable)}</td>}</>
                                                              */  }
                                                     {/*
                                                       <td>{e.created_dt}</td>  
                                                     */ }

                                                     {/*
                                                       <td>{e.ending_dt}</td>  
                                                      */ }

                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>

                        </>
                        : <EmptyTable
                            title='No Summary Exists'
                        />
                    }
                </div>
            </div>
        </div >
    )
}

const tableCol = [
    // {
    //     id: 11,
    //     value: 'PnCEmployeeid'
    // },
    {
        id: 0,
        value: 'Employee Name'
    },

    // {
    //     id: 12,
    //     value: 'Email'
    // },

    {
        id: 1,
        value: 'Job Title'
    },
    {
        id: 2,
        value: 'ProjectCode'
    },
    {
        id: 3,
        value: 'Location'
    },
    {
        id: 4,
        value: 'Total Bussiness Days'
    },
    {
        id: 5,
        value: 'Total Billable days'
    },
    {
        id: 6,
        value: 'Total Billable hours'
    },
    {
        id: 7,
        value: 'Total Non Billable days'
    },
    {
        id: 8,
        value: 'Timesheet not entered'
    },
    {
        id: 9,
        value: 'OverTime (in Hrs.)'
    }
    // {
    //     id: 10,
    //     value: 'Billable'
    // },
    // {
    //     id: 13,
    //     value: 'Created Date'
    // },
    // {
    //     id: 14,
    //     value: 'Ending Date'
    // }

]

const LocationColumn = [
    {
        name: "All",
        value: -1,
    },
    {
        name: "India",
        value: 0,
    },
    {
        name: "USA",
        value: 1,
    },
    {
        name: "Philippines",
        value: 2,
    },
    {
        name: "Not Updated",
        value: 100,
    },
    {
        name: "India",
        value: 91,
    },
];

function areRowsSame(row1, row2) {
    return (
        row1.EmployeeName === row2.EmployeeName &&
        row1.WeekNumber === row2.WeekNumber
    );
}

function countConsecutiveRowsWithSameData(timesheets, startIndex, rowData) {
    let count = 1;
    for (let i = startIndex + 1; i < timesheets.length; i++) {
        if (areRowsSame(timesheets[i], rowData)) {
            count++;
        } else {
            break;
        }
    }
    return count;
}