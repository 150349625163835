import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Timesheet from '../Timesheet'
import WeeklyTimesheet from '.'

function App() {

    return (
        <Routes>
            <Route path='/' element={<WeeklyTimesheet />} />

            <Route path='/timesheet/:timesheetId' element={<Timesheet />} />
        </Routes>
    )
}

export default App