import React from 'react'
import "./Navigations.css"

import { NavLink } from 'react-router-dom'
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';

function Navigations() {
    return (
        <div className={'navigation_bar_main_page_projectcodegeneration'}>
            <div className='navigations_projectcodegeneration'>

                <NavLink to={'/project-codes/home'}>
                    <HomeIcon />
                    <span>Home</span>
                </NavLink>
                <NavLink to={'/project-codes/generate-project-code'}>
                    <SettingsIcon />
                    <span style={{ textAlign: "center" }}>Generate Project Code</span>
                </NavLink>
                <NavLink to={'/project-codes/edit-project-code'}>
                    <SettingsIcon />
                    <span style={{ textAlign: "center" }}>Edit Project Code</span>
                </NavLink>
            </div >
        </div>
    )
}

export default Navigations