import React from 'react'
import { Route, Routes } from 'react-router-dom'
import EmployeeReports from '.'
import ViewProfile from '../ViewProfile'

function App() {
    return (
        <Routes>
            <Route path='/' element={<EmployeeReports />} />
            <Route path='/employee-report/:type' element={<ViewProfile />} />
        </Routes>
    )
}

export default App