import React, { useEffect, useState } from 'react'
import "./SortBy.css"

import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';

function SortBy({ list, column, setProjectAllocation, sortFilter }) {

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (sortFilter) {
            setIsActive(false)
        }
    }, [sortFilter])

    const handleSort = () => {

        if (isActive) {

            if (column === "Project Code") {

                setProjectAllocation(list.slice().sort((current, next) => {
                    let currentPROJECTCODE = current.PROJECTCODE.toLowerCase(),
                        nextPROJECTCODE = next.PROJECTCODE.toLowerCase();
                    if (currentPROJECTCODE < nextPROJECTCODE) {
                        return -1;
                    }
                    if (currentPROJECTCODE > nextPROJECTCODE) {
                        return 1;
                    }
                    return 0;
                }))

            } else if (column === "Full Name") {
                setProjectAllocation(list.slice().sort((current, next) => {
                    let currentEMPLOYEENAME = current.EMPLOYEENAME.toLowerCase(),

                        nextEMPLOYEENAME = next.EMPLOYEENAME.toLowerCase();



                    if (currentEMPLOYEENAME < nextEMPLOYEENAME) {

                        return -1;

                    }

                    if (currentEMPLOYEENAME > nextEMPLOYEENAME) {

                        return 1;

                    }

                    return 0;

                }))

            } else if (column === "Billable") {

                setProjectAllocation(list.slice().sort((current, next) => {

                    let currentBILLABLE = current.BILLABLE,

                        nextBILLABLE = next.BILLABLE;



                    if (currentBILLABLE < nextBILLABLE) {

                        return -1;

                    }

                    if (currentBILLABLE > nextBILLABLE) {

                        return 1;

                    }

                    return 0;

                }))

            } else if (column === "Billing Rate") {

                setProjectAllocation(list.slice().sort((current, next) => {

                    let currentBillingRate = current.BILLINGRATE,

                        nextBillingRate = next.BILLINGRATE;

                    if (currentBillingRate < nextBillingRate) {

                        return -1;

                    }

                    if (currentBillingRate > nextBillingRate) {

                        return 1;

                    }

                    return 0;

                }))

            } else if (column === "Location") {

                setProjectAllocation(list.slice().sort((current, next) => {

                    let currentOnshore = current.LOCATION,

                        nextOnshore = next.LOCATION;

                    if (currentOnshore < nextOnshore) {

                        return -1;

                    }

                    if (currentOnshore > nextOnshore) {

                        return 1;

                    }

                    return 0;

                }))

            } else {

                if (column === "Job Title") {

                    setProjectAllocation(list.slice().sort((current, next) => {

                        let currentROLE = current.ROLE.toLowerCase(),

                            nextROLE = next.ROLE.toLowerCase();



                        if (currentROLE < nextROLE) {

                            return -1;

                        }

                        if (currentROLE > nextROLE) {

                            return 1;

                        }

                        return 0;

                    }))

                }

            }

        } else {

            if (column === "Project Code") {

                setProjectAllocation(list.slice().sort((current, next) => {

                    let currentPROJECTCODE = current.PROJECTCODE.toLowerCase(),

                        nextPROJECTCODE = next.PROJECTCODE.toLowerCase();



                    if (nextPROJECTCODE < currentPROJECTCODE) {

                        return -1;

                    }

                    if (nextPROJECTCODE > currentPROJECTCODE) {

                        return 1;

                    }

                    return 0;

                }))

            } else if (column === "Full Name") {

                setProjectAllocation(list.slice().sort((current, next) => {

                    let currentEMPLOYEENAME = current.EMPLOYEENAME.toLowerCase(),

                        nextEMPLOYEENAME = next.EMPLOYEENAME.toLowerCase();

                    if (nextEMPLOYEENAME < currentEMPLOYEENAME) {

                        return -1;

                    }

                    if (nextEMPLOYEENAME > currentEMPLOYEENAME) {

                        return 1;

                    }

                    return 0;

                }))

            } else if (column === "Billable") {

                setProjectAllocation(list.slice().sort((current, next) => {

                    let currentBILLABLE = current.BILLABLE,

                        nextBILLABLE = next.BILLABLE;



                    if (nextBILLABLE < currentBILLABLE) {

                        return -1;

                    }

                    if (nextBILLABLE > currentBILLABLE) {

                        return 1;

                    }

                    return 0;

                }))

            } else if (column === "Billing Rate") {

                setProjectAllocation(list.slice().sort((current, next) => {

                    let currentBillingRate = current.BILLINGRATE,

                        nextBillingRate = next.BILLINGRATE;

                    if (nextBillingRate < currentBillingRate) {

                        return -1;

                    }

                    if (nextBillingRate > currentBillingRate) {

                        return 1;

                    }

                    return 0;

                }))

            } else if (column === "Location") {

                setProjectAllocation(list.slice().sort((current, next) => {

                    let currentOnshore = current.LOCATION,

                        nextOnshore = next.LOCATION;

                    if (nextOnshore < currentOnshore) {

                        return -1;

                    }

                    if (nextOnshore > currentOnshore) {

                        return 1;

                    }

                    return 0;

                }))

            } else {

                if (column === "Job Title") {

                    setProjectAllocation(list.slice().sort((current, next) => {

                        let currentROLE = current.ROLE.toLowerCase(),

                            nextROLE = next.ROLE.toLowerCase();

                        if (nextROLE < currentROLE) {

                            return -1;

                        }

                        if (nextROLE > currentROLE) {

                            return 1;

                        }

                        return 0;

                    }))

                }

            }

        }

        setIsActive(!isActive)

    }

    return (
        <div onClick={handleSort} className='sort_by_container'>
            {
                isActive ?
                    <>
                        <ChangeHistoryOutlinedIcon sx={{ fontSize: 10 }} />
                        <ChangeHistoryTwoToneIcon sx={{ rotate: "180deg", fontSize: 10 }} />
                    </>
                    :
                    <>
                        <ChangeHistoryTwoToneIcon sx={{ fontSize: 10 }} />
                        <ChangeHistoryOutlinedIcon sx={{ rotate: "180deg", fontSize: 10 }} />
                    </>
            }
        </div>
    )
}

export default SortBy