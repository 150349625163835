import React, { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { SetHistoryLocation } from '../../data/utilities'
import { useIsAuthenticated } from '@azure/msal-react'

function IsLogin() {

    const location = useLocation()

    const isAuth = useIsAuthenticated()

    useEffect(() => {

        if (!isAuth) {
            SetHistoryLocation(location.pathname, 'set')
        }
        else {
            SetHistoryLocation(location.pathname, 'remove')
        }


    }, [location, isAuth])

    return isAuth ?
        <Outlet />
        :
        <>
            {
                localStorage.getItem('_dev_user') === null && (
                    <Navigate to={'/'} />
                )
            }
        </>
}

export default IsLogin