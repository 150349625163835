import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ArrowBack } from '@mui/icons-material'

function Back({ title }) {

    const navigate = useNavigate()

    return (
        <div style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
            width: "60px",
            cursor: "pointer",
            color: "#252525"
        }}
            onClick={() => {
                if (title !== undefined) {
                    navigate(`${title}`)
                }
                else {
                    navigate(-1)
                }
            }}
        >
            <ArrowBack style={{ fontSize: 16, fontWeight: "bold" }} />
            <span style={{ fontWeight: "bold" }}>Back</span>
        </div>
    )
}

export default Back