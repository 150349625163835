import React from 'react'
import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getIsLoggedIn } from '../redux/features/UserSlice'
import ProjectAllocationIsLoggedIn from './Layouts'
import AllocateProjectCode from './Screens/AllocateProjectCode'

function ProjectAllocation() {

    const isLoggedIn = useSelector(getIsLoggedIn)

    return (
        <Routes>

            <Route element={<ProjectAllocationIsLoggedIn isExists={isLoggedIn} />}>

                <Route path='/home' element={<AllocateProjectCode />} />

            </Route>
            {/* <Route path='/*' element={<ErrorPage />} /> */}
        </Routes>
    )
}

export default ProjectAllocation