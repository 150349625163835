import CryptoJS from "crypto-js"
import { setIsLoggedIn, setUserLoggedInData } from "../../../../redux/features/UserSlice"
import { toast } from 'react-toastify'
import Messages from '../../../../data/Messages.json'
import Logger from "../../../../Logger"

export const handleLoginDetails = async (result, dispatch, GetInitialValues, navigate, groupRole) => {

    let SECURE_LOGIN = CryptoJS.AES.encrypt(JSON.stringify({ ...result, 'role': groupRole }), process.env.REACT_APP_SECRECT_KEY).toString()
    

    localStorage.setItem('__iemployee__user', SECURE_LOGIN)
    dispatch(setUserLoggedInData({ ...result, 'role': groupRole }))

    dispatch(setIsLoggedIn(true))

    dispatch({ type: "SetLoginStatus", isLoggedIn: true })

    GetInitialValues(result.Id)

    toast.success(Messages.Login.Login_Success, { toastId: "iemployee-toast" })
    
    Logger.log("UI Login successfull");
   
    navigate('/dashboard')
}