import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    forgotPassword: {
        active: false,
    },
    otpRegister: false,
    loading: false,
    showSuccessPage: {
        active: false,
    },
    normalAlert: {
        active: false
    },
    methodWithYesNo: {
        active: false
    },

    CloseAlert: {
        active: false
    },

    userEdit: {
        active: false
    }
}

export const ActivateSlice = createSlice({
    name: 'activate',
    initialState,
    reducers: {

        ForgotPassword: (state, action) => {
            state.forgotPassword = action.payload
        },

        setOTPRegister: (state, action) => {
            state.otpRegister = action.payload
        },

        setLoadingScreen: (state, action) => {
            state.loading = action.payload
        },

        setShowSuccessRegisterPage: (state, action) => {
            state.showSuccessPage = action.payload
        },

        setNormalAlert: (state, action) => {
            state.normalAlert = action.payload
        },

        setAlertYesNO: (state, action) => {
            state.methodWithYesNo = action.payload
        },

        setCloseAlert: (state, action) => {
            state.CloseAlert = action.payload
        },

        setEditUser: (state, action) => {
            state.userEdit = action.payload
        }

    },
})

export const { ForgotPassword, shouldChecked, setOTPRegister, setLoadingScreen,
    setShowSuccessRegisterPage, setAlertYesNO, setNormalAlert, setCloseAlert, setEditUser } = ActivateSlice.actions

export const getForgotPassword = (state) => state.activate.forgotPassword
export const getOTPRegister = (state) => state.activate.otpRegister
export const getLoader = (state) => state.activate.loading
export const getSuccessPage = (state) => state.activate.showSuccessPage
export const getNormalAlert = (state) => state.activate.normalAlert
export const getAlertYesNo = (state) => state.activate.methodWithYesNo
export const getCloseAlert = (state) => state.activate.CloseAlert
export const getEditUser = (state) => state.activate.userEdit

export default ActivateSlice.reducer