import React, { useEffect, useState } from 'react'
import "./Alert.css"
import { Info, GppBad, CheckCircle, Warning } from '@mui/icons-material'

function Alert({ noramlAlert }) {

    const [AlertData, setAlertData] = useState({
        active: false
    })

    useEffect(() => {

        setAlertData(noramlAlert)

    }, [noramlAlert])

    return (
        <>
            {
                AlertData.active && (
                    <div className='normal_alert' style={{
                        background: AlertData.variant === 'success' ? '#9BF78C' : AlertData.variant === 'error' ? '#F5A4AF' : AlertData.variant === 'warning' ? '#FDE48C' : "#014361",
                        color: "#000000"
                    }}>
                        <div>
                            {
                                AlertData.variant === '' || AlertData.variant === undefined || AlertData.variant === 'info' ?
                                    <Info />
                                    :
                                    <>
                                        {
                                            AlertData.variant === 'error' ?
                                                <GppBad />
                                                :
                                                <>
                                                    {
                                                        AlertData.variant === 'success' ?
                                                            <CheckCircle />
                                                            :
                                                            <Warning />
                                                    }
                                                </>
                                        }
                                    </>
                            }
                        </div>
                        <span>{AlertData.message}</span>
                    </div>
                )
            }
        </>
    )
}

export default Alert