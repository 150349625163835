import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux'
import { store } from './redux/Store'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { PublicClientApplication, EventType } from '@azure/msal-browser'
import ErrorBoundary from './ErrorBoundary';
import Loader from './Components/Loading/Loader';

const pca = new PublicClientApplication({
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_CLIENT_SECRET,
    redirectUri: `${process.env.REACT_APP_URL}/`
  }
})

pca.addEventCallback(event => {
  if (event.eventType === EventType.LOGIN_SUCCESS) {
    pca.setActiveAccount(event.payload.account)
  }
})

export async function getToken() {
  const request = {
    scopes: ['user.read', 'user.read.all', 'Group.Read.All'],
    account: pca.getAllAccounts()[0]
  }

  const interactiveResponse = await pca.acquireTokenSilent(request);
  return interactiveResponse.accessToken;

}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<Loader />}>
      <Provider store={store}>
        <BrowserRouter>
          <ToastContainer position='top-center' />
          <App msalInstance={pca} />
          {/* <App /> */}
        </BrowserRouter>
      </Provider>
    </ErrorBoundary>
  </React.StrictMode>
);

