import React from 'react'
import "./UserApproval.css"

import { useDispatch, useSelector } from 'react-redux'

import Back from '../../../Components/Back'
import EmptyTable from '../../../Components/NoData'

import { getUserData } from '../../../redux/features/UserSlice'
import { getInitialValues } from '../../../redux/features/InitialSlice'
import { setLoadingScreen } from '../../../redux/features/ActivateSlice'

import CheckImage from '../../../assets/users_checked_icon.png'
import UnCheckImage from '../../../assets/users_unchecked_icon.png'

import { toast } from 'react-toastify'
import Messages from '../../../data/Messages.json'
import { AxiosPost } from '../../../data/utilities'

function UserApproval({ RefreshContent }) {

    const dispatch = useDispatch()
    const initialData = useSelector(getInitialValues)
    const userData = useSelector(getUserData)

    const MESSAGE = (username) => {
        return `

Hi ${username}

Your Profile is Approved, Now you can login and Build Your Profile in iEmployee. Click on the Link to Login to iEmployee.

Link : ${process.env.REACT_APP_URL}


Thanks and Regards
** iEmployee **
        
        `
    }

    const handleIsApprove = (id, email, username) => {
        dispatch(setLoadingScreen(true))
        AxiosPost(`/iemployee/update-is-approve`, {
            Id: id,
            EmpId: userData.Id
        }).then((response) => {
            if (response.data.isSuccess) {
                AxiosPost(`/mail/send-mail`, {
                    toMail: email,
                    toSubject: "Approval - iEmployee",
                    toMessage: MESSAGE(username)
                })
                RefreshContent()
                dispatch(setLoadingScreen(false))
                toast.success(Messages.UserApproval.UserApproval_Success, { toastId: "iemployee-toast" })
            }
        })
    }

    const handleNewUserDelete = (id) => {
        dispatch(setLoadingScreen(true))
        AxiosPost(`/iemployee/deleteuser`, {
            Id: id
        }).then((response) => {
            if (response.data.isSuccess) {
                RefreshContent()
                dispatch(setLoadingScreen(false))
                toast.success(Messages.UserApproval.UserApproval_Deleted, { toastId: "iemployee-toast" })
            }

        })
    }

    return (
        <div className='users_approval'>
            <Back />
            <div className='user_approval_header'>
                <span>User Approval</span>
            </div>

            <div className='user_approval_content'>
                {
                    initialData.Users.filter(item => item.IsApproved === 0).length === 0 ?
                        <EmptyTable title='No Data Found' />
                        :
                        <table>
                            <thead>
                                <tr>
                                    {
                                        UserTableColumns.map((item, index) => {
                                            return <td key={index}>{item.name}</td>
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    initialData.Users.filter(item => item.IsApproved === 0).map((item, index) => {
                                        return <tr key={index}>
                                            <td>{item.UserName}</td>
                                            <td>{item.Email}</td>
                                            <td>{item.Role}</td>
                                            <td>
                                                <img
                                                    src={CheckImage}
                                                    alt='approve'
                                                    onClick={() => handleIsApprove(item.Id, item.email, item.username)}
                                                />
                                                <img
                                                    src={UnCheckImage}
                                                    alt='reject'
                                                    onClick={() => handleNewUserDelete(item.Id)}
                                                />
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>
                }
            </div>

        </div>
    )
}

export default UserApproval

const UserTableColumns = [
    {
        id: 0,
        name: "Username"
    },
    {
        id: 1,
        name: "Email Address"
    },
    {
        id: 3,
        name: "Role"
    },
    {
        id: 4,
        name: "Options"
    }
]