import React from 'react'
import "./ErrorPage.css"
import ErrorI from '../../assets/error-1.png'
import ErrorII from '../../assets/error-2.jpg'

import { useNavigate } from 'react-router-dom'

function ErrorPage() {

    const navigate = useNavigate()

    return (
        <div className='error_page_container'>
            <div className='error_page_card'>
                {/* Image */}
                <div className='error_page_image_container'>
                    <img src={ErrorII} alt='error' />
                </div>
                {/* Blob */}
                <div className='error_page_details'>
                    <div className='error_details'>
                        <h4>Page Not Found</h4>
                        <p>This page is not exists</p>
                        <span onClick={() => navigate(-1)}>Go Back</span>
                    </div>
                    <div className='error_img_container'>
                        <img src={ErrorI} alt='error-blob' />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage