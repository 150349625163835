import log from 'loglevel';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import AWS from 'aws-sdk';

// Set up environment variables
const CONNECTIONSTRING = process.env.REACT_APP_APPINSIGHTS_CONNECTIONSTRING;
const CLOUD_LOG_PROVIDER = process.env.REACT_APP_CLOUD_LOGGER || 'local';
const AWS_LOG_GROUP = process.env.REACT_APP_AWS_LOG_GROUP;
const AWS_LOG_STREAM = process.env.REACT_APP_AWS_LOG_STREAM;
const AWS_REGION = process.env.REACT_APP_AWS_REGION;
const LOG_LEVEL = process.env.REACT_APP_LOG_LEVEL || 'info'; // Set default log level

// Initialize variables for cloud logging services
const appInsights = (CLOUD_LOG_PROVIDER === 'Azure' && CONNECTIONSTRING) 
  ? new ApplicationInsights({ config: { connectionString: CONNECTIONSTRING } }) 
  : null;

let cloudWatchLogs = null;
let sequenceToken = null;

// Setup Azure Application Insights if Azure is the provider
if (appInsights) {
  appInsights.loadAppInsights();
}

// Setup AWS CloudWatch Logs if AWS is the provider
if (CLOUD_LOG_PROVIDER === 'AWS' && AWS_LOG_GROUP && AWS_LOG_STREAM && AWS_REGION) {
  AWS.config.update({ region: AWS_REGION });
  cloudWatchLogs = new AWS.CloudWatchLogs();

  // Retrieve the log stream token
  cloudWatchLogs.describeLogStreams({
    logGroupName: AWS_LOG_GROUP,
    logStreamNamePrefix: AWS_LOG_STREAM
  }, (err, data) => {
    if (err) {
      console.error("Error describing CloudWatch log streams:", err);
    } else if (data.logStreams.length > 0) {
      sequenceToken = data.logStreams[0].uploadSequenceToken;
    }
  });
}

// Set log level dynamically based on environment variable
log.setLevel(LOG_LEVEL);

const Logger = () => {
  const logMessage = async (message, level = 'info') => {
    log[level](message);

    // Azure Application Insights logging
    if (appInsights && CLOUD_LOG_PROVIDER === 'Azure') {
      try {
        appInsights.trackTrace({ message });
      } catch (error) {
        console.error("Error logging to Application Insights:", error);
      }
    }

    // AWS CloudWatch Logs logging
    if (cloudWatchLogs && CLOUD_LOG_PROVIDER === 'AWS') {
      try {
        const params = {
          logEvents: [
            {
              message: `${level.toUpperCase()}: ${message}`, 
              timestamp: Date.now(),
            }
          ],
          logGroupName: AWS_LOG_GROUP,
          logStreamName: AWS_LOG_STREAM,
          sequenceToken: sequenceToken || undefined, 
        };

        const result = await cloudWatchLogs.putLogEvents(params).promise();
        sequenceToken = result.nextSequenceToken;
      } catch (error) {
        console.error("Error sending logs to CloudWatch:", error);
      }
    }
  };

  return {
    error: (error) => logMessage(error, 'error'),
    warn: (message) => logMessage(message, 'warn'),
    info: (message) => logMessage(message, 'info'),
    log: (message) => logMessage(message, 'log'),
  };
};

export default Logger();
