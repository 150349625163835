import React, { useEffect, useState } from 'react'
import "./Pagination.css"

import { NavigateBefore, NavigateNext } from '@mui/icons-material'

function Pagination({ List, selectedRow, rows, setSelectedRow, setRows, setPage, page }) {

    // Pagination
    const [pageNumber, setPageNumber] = useState(1)
    const [totalPages, setTotalPages] = useState(0)

    const [prev, setPrev] = useState({
        active: true,
        value: 1,
        disable: false
    })

    const [pres, setPres] = useState({
        active: false,
        value: 2,
        disable: false
    })

    const [next, setNext] = useState({
        active: false,
        value: 3,
        disable: false
    })

    useEffect(() => {
        if (Math.floor(List.length / selectedRow) === 0) {
            setPrev({
                active: true,
                value: 1,
                disable: true
            })
            setPres({
                active: false,
                value: 1 + 1,
                disable: true
            })
            setNext({
                active: false,
                value: 1 + 2,
                disable: true
            })
        }
        else {
            setTotalPages(Math.ceil(List.length / selectedRow))
        }
    }, [List, selectedRow])

    const handlePagination = (val) => {
        handlePages(val)
        setPageNumber(val)
        setRows(Number(selectedRow) * val)
        setPage((Number(selectedRow) * val) - Number(selectedRow))
    }

    const handlePages = (val) => {
        if (val === 1) {
            setPrev({
                active: true,
                value: val,
                disable: false
            })
            setPres({
                active: false,
                value: val + 1,
                disable: false
            })
            setNext({
                active: false,
                value: val + 2,
                disable: false
            })
            return false
        }
        else if (totalPages === 1) {
            setPrev({
                active: false,
                value: 1 - 1,
                disable: true
            })
            setPres({
                active: true,
                value: 1,
                disable: false
            })
            setNext({
                active: false,
                value: 1 + 1,
                disable: true
            })
            return false
        }
        else if (totalPages === 2) {
            setPrev({
                active: false,
                value: 2 - 1,
                disable: false
            })
            setPres({
                active: true,
                value: 2,
                disable: false
            })
            setNext({
                active: false,
                value: 2 + 1,
                disable: true
            })
            return false
        }
        else if (val === totalPages) {
            setPrev({
                active: false,
                value: val - 2,
                disable: false
            })
            setPres({
                active: false,
                value: val - 1,
                disable: false
            })
            setNext({
                active: true,
                value: val,
                disable: false
            })
            return false
        }
        else {
            setPrev({
                active: false,
                value: val - 1,
                disable: false
            })
            setPres({
                active: true,
                value: val,
                disable: false
            })
            setNext({
                active: false,
                value: val + 1,
                disable: false
            })
        }
    }

    const handleNext = () => {
        if (pageNumber < totalPages) {
            setPageNumber(pageNumber + 1)
            setRows(Number(rows) + Number(selectedRow))
            setPage(Number(page) + Number(selectedRow))
            handlePages(pageNumber + 1)
        }
    }

    const handlePrev = () => {
        if (pageNumber > 1) {
            setPageNumber(pageNumber - 1)
            setRows(Number(rows) - Number(selectedRow))
            setPage(Number(page) - Number(selectedRow))
            handlePages(pageNumber - 1)
        }
    }

    return (
        <div className='pagination_container'>
            <div className='pagination_select_row'>
                <span>Select Rows: </span>
                <select value={selectedRow} onChange={(e) => {
                    setSelectedRow(Number(e.target.value))
                }}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={30}>30</option>
                </select>
            </div>
            <div className='pagination_bar'>
                <span onClick={handlePrev}>
                    <NavigateBefore />
                </span>
                {
                    prev.disable ?
                        <span
                            className={prev.active && 'employee-pagination-active'}
                        >{prev.value}</span>
                        :
                        <span
                            className={prev.active && 'employee-pagination-active'}
                            onClick={() => handlePagination(prev.value)}
                        >{prev.value}</span>
                }
                {
                    pres.disable ?
                        <span
                            className={'employee-pagination-disable'}
                        >{pres.value}</span>
                        :
                        <span
                            className={pres.active && 'employee-pagination-active'}
                            onClick={() => handlePagination(pres.value)}
                        >{pres.value}</span>
                }
                {
                    next.disable ?
                        <span
                            className={'employee-pagination-disable'}
                        >{next.value}</span>
                        :
                        <span
                            className={next.active && 'employee-pagination-active'}
                            onClick={() => handlePagination(next.value)}
                        >{next.value}</span>
                }
                <span>...</span>
                {
                    totalPages === 0 ?
                        <span>{1}</span>
                        :
                        <span onClick={() => handlePagination(totalPages)}>{totalPages}</span>
                }
                <span onClick={handleNext}>
                    <NavigateNext />
                </span>
            </div>
        </div>
    )
}

export default Pagination