import React, { useEffect, useState } from 'react'
import "./Team.css"

import { getInitialValues } from '../../../redux/features/InitialSlice'
import { getUserData } from '../../../redux/features/UserSlice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { RandomColor } from '../../../Components/ColorCombo'
import CryptoJS from 'crypto-js'

import { Search } from '@mui/icons-material'

function Team() {

    const initialData = useSelector(getInitialValues)
    const userData = useSelector(getUserData)

    const [allocatedEmployees, setAllocatedEmployees] = useState([])
    const [tempAllocatedEmployees, setTempAllocatedEmployees] = useState([])
    const [isAllocatedProject, setIsAllocatedProject] = useState(false)
    const [searchByAllocated, setSearchByAllocated] = useState('')

    const [MyTeam, setMyTeam] = useState([])
    const [tempMyTeam, setTempMyTeam] = useState([])
    const [isActiveMyTeam, setIsActiveMyTeam] = useState(false)
    const [searchByTeam, setSearchByTeam] = useState('')

    useEffect(() => {

        let result = []

        if (userData.allocatedEmployees.length !== 0 && initialData.Employees.length !== 0) {

            userData.allocatedEmployees.forEach((item) => {
                let data = initialData.Employees.filter(emp => emp.Id === item)
                if (data.length !== 0) {
                    result.push(data[0])
                }
            })
            result = result.filter((item, index) => {
                return index === result.findIndex((e) => item.Id === e.Id)
            })
            setAllocatedEmployees(result)
            setTempAllocatedEmployees(result)
        }
    }, [userData, initialData])

    useEffect(() => {
        if (userData.reportees.length !== 0 && initialData.Employees.length !== 0) {
            let result = []
            userData.reportees.forEach((item) => {
                let data = initialData.Employees.filter(emp => emp.Id === item)
                if (data.length !== 0) {
                    result.push(data[0])
                }

            })
            result = result.filter((item, index) => {
                return index === result.findIndex((e) => item.Id === e.Id)
            })
            setMyTeam(result)
            setTempMyTeam(result)
        }

    }, [userData, initialData])

    const handleSearchMyTeam = (e) => {

        setSearchByTeam(e.target.value)

        if (e.target.value === '') {
            setMyTeam(tempMyTeam)
            return false
        }

        setMyTeam(tempMyTeam.filter(item => (item.FirstName + " " + item.LastName).includes(e.target.value)))


    }

    const handleSearchAllocatedProject = (e) => {

        setSearchByAllocated(e.target.value)

        if (e.target.value === '') {
            setAllocatedEmployees(tempAllocatedEmployees)
            return false
        }

        setAllocatedEmployees(tempAllocatedEmployees.filter(item => (item.FirstName + " " + item.LastName).includes(e.target.value)))

    }

    return (
        <div className='myteam-container'>
            {
                MyTeam.length !== 0 && (
                    <div className='myteam-team-container'>
                        <div className='myteam-team-container-header'>
                            <span>Team Members</span>
                            <div className={!isActiveMyTeam ? 'myteam-team-container-header-input ' : 'myteam-team-container-header-input-span'}>
                                <input
                                    type='text'
                                    onBlur={() => setIsActiveMyTeam(false)}
                                    placeholder={isActiveMyTeam && 'Search by Employee Name'}
                                    onChange={handleSearchMyTeam}
                                    value={searchByTeam}
                                />
                                <Search onClick={() => setIsActiveMyTeam(!isActiveMyTeam)} className='myteam-team-container-header-icon' />
                            </div>
                        </div>
                        <div className='myteam-team-container-content'>
                            {
                                MyTeam.map((item, index) => {
                                    return (
                                        <Profile
                                            key={index}
                                            Id={item.Id}
                                            EmployeeName={item.FirstName + " " + item.LastName}
                                            EmployeeRole={item.Designation}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }
            {
                allocatedEmployees.length !== 0 && (
                    <div className='myteam-allocated-emps-container'>
                        <div className='myteam-allocated-emps-container-header'>
                            <span>Allocated Employees</span>
                            <div className={!isAllocatedProject ? 'myteam-team-container-header-input ' : 'myteam-team-container-header-input-span'}>
                                <input
                                    type='text'
                                    onBlur={() => setIsAllocatedProject(false)}
                                    placeholder={isAllocatedProject && 'Search by Employee Name'}
                                    onChange={handleSearchAllocatedProject}
                                    value={searchByAllocated}
                                />
                                <Search onClick={() => setIsAllocatedProject(!isAllocatedProject)} className='myteam-team-container-header-icon' />
                            </div>
                        </div>
                        <div className='myteam-allocated-emps-container-content'>
                            {
                                allocatedEmployees.map((item, index) => {
                                    return (
                                        <Profile
                                            key={index}
                                            Id={item.Id}
                                            EmployeeName={item.FirstName + " " + item.LastName}
                                            EmployeeEmail={item.Email}
                                            EmployeeRole={item.Designation}
                                        />
                                    )
                                })
                            }
                        </div>
                    </div>
                )
            }
        </div>
    )
}

export default Team



const Profile = ({ Id, EmployeeName, EmployeeRole }) => {
    const navigate = useNavigate()

    const handleEmployeeWeeklyTimesheet = () => {

        const SECRET_STRING = CryptoJS.AES.encrypt(JSON.stringify({ Id: Id, type: 'team' }), process.env.REACT_APP_SECRECT_KEY).toString().split('/').join("|")

        navigate(`/mytime/my-team/team/weeklytimesheet/${SECRET_STRING}`);

    };

    return (
        <div className='mytime_team_container_card' onClick={handleEmployeeWeeklyTimesheet}>
            <div className='team_card_container'>
                <div className='team_card_container_dummy_img' style={{ background: RandomColor(EmployeeName.split(' ')[0].substring(0, 1)) }}>
                    <span>{EmployeeName.split(' ')[0].substring(0, 1) + EmployeeName.split(' ')[1].substring(0, 1)}</span>
                </div>
                <div className='team_card_container_details_name'>
                    <label>{EmployeeName}</label>
                    <span>{EmployeeRole}</span>
                </div>
            </div>
        </div>
    )
}