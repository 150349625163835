import React, { useEffect, useState } from 'react'
import { useSelector } from "react-redux";
import './Employees.css'
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";
import { getInitialValues } from "../../../../../redux/features/InitialSlice"
import { TextField } from '@mui/material'
import NoData from '../../../../../Components/NoData'

function Employees() {

    const initialData = useSelector(getInitialValues);
    const navigate = useNavigate()

    const [employeename, setEmployeename] = useState("")
    const [employeeData, setEmployeesData] = useState([])

    useEffect(() => {
        setEmployeesData(initialData.Employees)
    }, [initialData])

    const handleFilterEmployeeName = (e) => {
        if (e.target.value === "") {
            setEmployeesData(initialData.Employees)
        }
        else {
            setEmployeesData(initialData.Employees.filter(item => (item.FirstName.toLowerCase() + " " + item.LastName.toLowerCase()).includes(e.target.value.toLowerCase())))
        }
        setEmployeename(e.target.value)


    }

    const handleEmployeeWeeklyTimesheet = (Id) => {
        const SECRET_STRING = CryptoJS.AES.encrypt(JSON.stringify({ Id: Id, type: 'reports' }), process.env.REACT_APP_SECRECT_KEY).toString().split('/').join("|")
        navigate(`/mytime/reports/employees/weeklytimesheet/${SECRET_STRING}`);
    };

    return (
        <>
            <div className='employee-weekly--container'>
                <TextField
                    type='text'
                    value={employeename}
                    onChange={handleFilterEmployeeName}
                    label='Filter - Employee Name'
                    sx={{ width: '30%' }}
                />
            </div>
            <div className='employees_content'>
                {
                    employeeData.length !== 0 ?
                        <table>
                            <thead>
                                <tr>
                                    {
                                        tabCol.map((e, index) => {
                                            return (

                                                <th key={index}>{e.column}</th>

                                            )
                                        })
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    (employeeData).map((e, index) => {
                                        return (
                                            <tr key={index}
                                                onClick={() => handleEmployeeWeeklyTimesheet(e.Id)}
                                            >
                                                <td>{e.FirstName + ' ' + e.LastName}</td>
                                                <td>{e.Designation}</td>
                                                <td>{e.Email}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                        :
                        <NoData title='No Data Found' />
                }
            </div>
        </>
    )
}

export default Employees

const tabCol = [
    {
        id: 0,
        column: "Employee Name"
    },
    {
        id: 1,
        column: "Designation"
    },
    {
        id: 2,
        column: "Email"
    }
]