import React from "react";
import GridTable from "../../../Components/GridTable";
import { useSelector } from "react-redux";
import { getInitialValues } from "../../../redux/features/InitialSlice";
import "./ProjectCodes.css";

function ProjectCodes() {
  const initialData = useSelector(getInitialValues);
  const projectCodes = initialData ? initialData.ProjectCodes : [];

  // Filter project codes where Typename is 'PROJECT'
  const filteredProjectCodes = projectCodes.filter(
    (item) => item.typename === "PROJECT"
  );

  // List of required column IDs
  const requiredColumnIds = [
    "ProjectCode",
    "Client",
    "Expiry_dt",
    "ProjectName",
  ];

  // Generate columns based on the required column IDs
  const columns =
    filteredProjectCodes.length > 0
      ? Object.keys(filteredProjectCodes[0])
          .filter((key) => requiredColumnIds.includes(key))
          .map((key) => ({
            id: key,
            name: key,
          }))
      : [];

  return (
    <div className="ProjectCode_content_table">
      <h2>Project Codes</h2>
      <div className="ProjectCode_container_table">
        <GridTable jsonData={filteredProjectCodes} columns={columns} />
      </div>
    </div>
  );
}

export default ProjectCodes;
