import React from 'react'
import "./NavigationBar.css"

import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUserData } from '../../../redux/features/UserSlice'

import BuildProfileImage from '../../../assets/Build-profile.png'
import AddUserImage from '../../../assets/Adduser.png'
import ModifyUserImage from '../../../assets/ModifyUser.png'

//import { Article, Timeline } from '@mui/icons-material'
import HomeIcon from '@mui/icons-material/Home';

function NavigationBar() {

    const userData = useSelector(getUserData)

    return (
        <div className={'navigation_bar_main_page'}>
            <div className='navigations'>
                <NavLink to={'/myprofile/employees'}>
                    {/* <img src={HomeImage} alt='home' width={25} /> */}
                    <HomeIcon />
                    <span>Home</span>
                </NavLink>
                {/* <NavLink to={'/myprofile/reports'}> */}
                {/* <img src={ReportsImage} alt='reports' width={25} /> */}
                {/* <Article />
                    <span style={{ textAlign: "center" }}>Reports</span>
                </NavLink> */}
                {/* {
                    userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU ?
                        <NavLink to={'/myprofile/audit'}>
                            <Timeline className='icon' />
                            <span>Audit</span>
                        </NavLink>
                        :
                        <></>
                } */}

                {
                    userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU ?
                        <NavLink to={'/myprofile/AddEmployee'}>
                            <img src={AddUserImage} alt='AddUserProfile' width={25} />
                            <span style={{ textAlign: "center" }}>Add User</span>
                        </NavLink>
                        :
                        <></>
                }

                {
                    userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU ?
                        <NavLink to={'/myprofile/ModifyUser'}>
                            <img src={ModifyUserImage} alt='RemoveUser' width={25} />
                            <span style={{ textAlign: "center" }}>Remove User</span>
                        </NavLink>
                        :
                        <></>
                }

                {
                    userData.Id === null && userData.organization.includes('genzeon') ?
                        <>
                            {
                                userData.role === process.env.REACT_APP_ROLE_ADMIN ?
                                    <></>
                                    :
                                    <NavLink to={'/myprofile/build-profile'}>
                                        <img src={BuildProfileImage} alt='build-profile' width={25} />
                                        <span>Build Profile</span>
                                    </NavLink>
                            }
                        </>
                        :
                        <></>
                }
            </div >
        </div>
    )
}

export default NavigationBar