import React from 'react'
import "./SubMenu.css"
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUserData } from '../../../../redux/features/UserSlice'

function SubMenu() {

    const location = useLocation()
    const userData = useSelector(getUserData)

    return (
        <div className='sub_menu_container'>
            {
                location.pathname.includes('/my-team') ?
                    <>
                        <NavLink to={`/mytime/my-team/home`}>Approvals</NavLink>
                        <NavLink to={`/mytime/my-team/team`}>Team</NavLink>
                    </>
                    :
                    <>
                        <NavLink to={`/mytime/reports/home`}>Reports</NavLink>
                        {
                            (userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU) && (
                                <>
                                    <NavLink to={`/mytime/reports/employees`}>Employees</NavLink>
                                </>
                            )
                        }
                        <NavLink to={`/mytime/reports/monthly-summary`}>Monthly Summary</NavLink>
                    </>
            }
        </div>
    )
}

export default SubMenu