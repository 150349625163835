import React from 'react'
import Header from '../../Components/Header/Header'
import "./LayoutWithBackgroundImage.css"
import { Navigate, Outlet } from 'react-router-dom'

function LayoutWithBackground({ isExists }) {
    return isExists ?
        <div className='iemployee-layout-bg-container'>
            <Header />
            <Outlet />
        </div>
        :
        <>
            {
                localStorage.getItem('__iemployee__user') === null && (
                    <Navigate to={'/'} />
                )
            }
        </>
}

export default LayoutWithBackground