import React, { useState } from 'react'
import "./SortBy.css"

import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';

function SortBy({ list, column, setEmployeeData }) {

    const [isActive, setIsActive] = useState(false)

    const handleSort = () => {

        if (isActive) {

            if (column === "Full Name") {

                setEmployeeData(list.slice().sort((current, next) => {

                    let currentName = current.FirstName.toLowerCase(),

                        nextName = next.FirstName.toLowerCase();



                    if (currentName < nextName) {

                        return -1;

                    }

                    if (currentName > nextName) {

                        return 1;

                    }

                    return 0;

                }))

            }
            else if (column === "Job Title") {
                setEmployeeData(list.slice().sort((current, next) => {

                    let currentDesignation = current.Designation.toLowerCase(),

                        nextDesignation = next.Designation.toLowerCase();



                    if (currentDesignation < nextDesignation) {

                        return -1;

                    }

                    if (currentDesignation > nextDesignation) {

                        return 1;

                    }

                    return 0;

                }))
            }
            else {
                if (column === "Email Address") {

                    setEmployeeData(list.slice().sort((current, next) => {

                        let currentEmail = current.Email.toLowerCase(),

                            nextEmail = next.Email.toLowerCase();



                        if (currentEmail < nextEmail) {

                            return -1;

                        }

                        if (currentEmail > nextEmail) {

                            return 1;

                        }

                        return 0;

                    }))

                }

            }

        }
        else {

            if (column === "Full Name") {
                setEmployeeData(list.slice().sort((current, next) => {

                    let currentName = current.FirstName.toLowerCase(),

                        nextName = next.FirstName.toLowerCase();



                    if (nextName < currentName) {

                        return -1;

                    }

                    if (nextName > currentName) {

                        return 1;

                    }

                    return 0;

                }))

            }
            else if (column === "Job Title") {

                setEmployeeData(list.slice().sort((current, next) => {

                    let currentDesignation = current.Designation.toLowerCase(),

                        nextDesignation = next.Designation.toLowerCase();

                    if (nextDesignation < currentDesignation) {

                        return -1;

                    }

                    if (nextDesignation > currentDesignation) {

                        return 1;

                    }

                    return 0;

                }))

            }
            else {

                if (column === "Email Address") {

                    setEmployeeData(list.slice().sort((current, next) => {

                        let currentEmail = current.Email.toLowerCase(),

                            nextEmail = next.Email.toLowerCase();

                        if (nextEmail < currentEmail) {
                            return -1;
                        }
                        if (nextEmail > currentEmail) {
                            return 1;
                        }
                        return 0;
                    }))
                }
            }
        }

        setIsActive(!isActive)

    }

    return (
        <div onClick={handleSort} className='sort_by_container'>
            {
                isActive ?
                    <>
                        <ChangeHistoryOutlinedIcon sx={{ fontSize: 10 }} />
                        <ChangeHistoryTwoToneIcon sx={{ rotate: "180deg", fontSize: 10 }} />
                    </>
                    :
                    <>
                        <ChangeHistoryTwoToneIcon sx={{ fontSize: 10 }} />
                        <ChangeHistoryOutlinedIcon sx={{ rotate: "180deg", fontSize: 10 }} />
                    </>
            }
        </div>
    )
}

export default SortBy