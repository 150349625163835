import { configureStore } from '@reduxjs/toolkit'
import EmployeeReducer from './features/EmployeesSlice'
import ExtraOptionsReducer from './features/ExtraOptionsSlice'
import UserReducer from './features/UserSlice'
import ActivateReducer from './features/ActivateSlice'
import AuditReducer from './features/EmployeeAudit'
import TimesheetReducer from '../MyTime/redux'
import InitialReducer from './features/InitialSlice'

export const store = configureStore({
    reducer: {
        users: UserReducer,
        employee: EmployeeReducer,
        options: ExtraOptionsReducer,
        activate: ActivateReducer,
        audit: AuditReducer,
        timesheet: TimesheetReducer,
        initial: InitialReducer
    }
})