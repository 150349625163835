import React from 'react'
import { useSelector } from 'react-redux';

import { getAlertYesNo, getCloseAlert, getNormalAlert } from '../../redux/features/ActivateSlice';
import LoadingScreen from '../Loading';

import EditUserData from '../../MyProfile/Screens/EditUserProfile';
import AlertClose from '../Alerts/CloseAlert';
import AlertWithMethod from '../Alerts/AlertWithMethod';

import Alert from '../Alerts/Alert'

function POPUPS({ RefreshContent }) {

    const alertYesno = useSelector(getAlertYesNo)
    const closeAlert = useSelector(getCloseAlert)

    const noramlAlert = useSelector(getNormalAlert)

    return (
        <>
            <LoadingScreen />
            <EditUserData
                RefreshContent={RefreshContent}
            />
            <Alert noramlAlert={noramlAlert} />
            {
                closeAlert.active && (
                    <AlertClose
                        variant={closeAlert.variant}
                        message={closeAlert.message}
                    />
                )
            }
            {
                alertYesno.active && (
                    <AlertWithMethod
                        variant={alertYesno.variant}
                        message={alertYesno.message}
                        method={alertYesno.method}
                        id={alertYesno.id}
                    />
                )
            }
        </>
    )
}

export default POPUPS