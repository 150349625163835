import React from 'react'
import { Route, Routes } from 'react-router-dom'

import Employees from '.'
import EmployeeProfile from '../ViewProfile'
import EmployeeApproval from '../EmployeeApproval'
import EmployeeReview from '../EmployeeReview'
import UpdateEmployeeSummary from '../UpdateEmployeeSummary'

function App({ RefreshContent }) {
    return (
        <Routes>
            <Route path='/' element={<Employees />} />
            <Route path='/employee-summary/:type' element={<EmployeeProfile />} />
            <Route path='/edit-employee/:type' element={<UpdateEmployeeSummary />} />
            <Route path='/approval' element={<EmployeeApproval />} />
            <Route path='/review/:employeeData' element={<EmployeeReview RefreshContent={RefreshContent} />} />
        </Routes>
    )
}

export default App