import { Component } from 'react'

export default class ErrorBoundary extends Component {

    constructor(props) {
        super(props)

        this.state = { hasError: false }
    }

    static getDerivedStateFromError(err) {
        console.log(err)
        return { hasError: true }
    }

    componentDidCatch(err) {
        console.log(this.state)
        console.group('Error')
        console.log(err)
        console.groupEnd()
    }
    render() {
        if (this.state.hasError) {
            return this.props.fallback
        }

        return this.props.children
    }
}
