import React from 'react'
import "./MyProfileIsLoggedIn.css"

import Header from '../../../Components/Header/Header'
import NavigationBar from '../../Components/NavigationBar'
import { Navigate, Outlet } from 'react-router-dom'

function MyProfileIsLoggedIn({ isExists }) {
    return isExists ?
        <div className='iemployee_main_page'>
            <Header />
            <div className='iemployee_display_page'>
                <NavigationBar />
                <div className='iemployee_screens'>
                    <Outlet />
                </div>
            </div>
        </div>
        :
        <>
            {
                localStorage.getItem('__iemployee__user') === null && (
                    <Navigate to={'/'} />
                )
            }
        </>
}

export default MyProfileIsLoggedIn