import React, { useEffect, useState } from 'react'
import "./Login.css"
import GenzeonLogo from '../../assets/ProductImages/06-iEmployee-Genzeon.svg'
import LoginImage from '../../assets/ProductImages/01-iEmployee-Login.svg'
// import GenzeonLogo from '../../assets/genzeon-logo-2.png'
// import LoginImage from '../../assets/LoginBg.png'
import Axios from 'axios'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ForgotPassword, setLoadingScreen } from '../../redux/features/ActivateSlice'
import { toast } from 'react-toastify'
import { handleLoginDetails } from './Feature/LoginHandler'
import { useMsal, useIsAuthenticated } from '@azure/msal-react'
import Messages from '../../data/Messages.json'
import { Locations } from '../../data/Locations'
import { RandomColor } from '../../Components/ColorCombo'
import { AxiosGetwithHeaders, AxiosPost, GetHistoryLocation, getUserGroup } from '../../data/utilities'
import { Tilt } from 'react-tilt'


function Login({ GetInitialValues }) {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const isAuth = useIsAuthenticated()

    const { instance } = useMsal()

    const [azureMail, setAzureMail] = useState('')
    const [name, setName] = useState('')
    const [azure_image, setAzureImage] = useState(null)
    const [Id, setId] = useState(null)

    const [show_azure_login, setShowAzureLogin] = useState(false)

    const [isAnotherAccount, setIsAnotherAccount] = useState(false)
    const [autoLogin, setAutoLogin] = useState(false)

    const handleLogin = async (e) => {

        e.preventDefault()

        dispatch(setLoadingScreen(true))

        let result = await Axios.post(`${process.env.REACT_APP_PROXY}/iemployee/login`, {
            Username: e.target.username.value.toLowerCase(),
            Password: e.target.password.value
        })

        if (result.data.isSuccess) {

            handleLoginDetails(
                result.data.User,
                dispatch,
                GetInitialValues,
                navigate
            )

        }
        else {
            dispatch(setLoadingScreen(false))
            if (result.data.error_type === 'notapproved') {
                toast.info(Messages.Login.Login_Approval_Progress, { toastId: "iemployee-toast" })

                setTimeout(() => {
                    toast.info(Messages.Login.Login_Try_Again, { toastId: "iemployee-toast" })
                }, 3000)
            }
            else {
                toast.error(Messages.Login.Login_Failed, { toastId: "iemployee-toast" })
            }
            navigate('/')
        }

    }

    const handleLoginWithMicrosoft = () => {
        instance.loginRedirect({
            scopes: ['user.read', 'user.read.all', 'Group.Read.All']
        })
    }

    const handleRedirectToDashboard = async () => {
        dispatch(setLoadingScreen(true))
        let result = null
        try {
            result = await AxiosGetwithHeaders(`/iemployee/get-current-employee-by-mail`, {
                mail: azureMail
            })
        }
        catch (err) {
            dispatch(setLoadingScreen(false))
            console.log(err)
            return false
        }

        try {
            if (Id === undefined) {
                toast.error(`You don't have access to iEmployee portal, Please contact the admin`, { toastId: "iemployee-toast" })
                dispatch(setLoadingScreen(false))
                return false
            }

            // let's get the userGroup here
            let userGroup = await getUserGroup(Id)

            if (userGroup === null) {
                toast.error(`You don't have access to iEmployee portal, Please contact the admin`, { toastId: "iemployee-toast" })
                dispatch(setLoadingScreen(false))
                return false
            }

            if (result.data.isSuccess) {
                handleLoginDetails(
                    result.data.result,
                    dispatch,
                    GetInitialValues,
                    navigate,
                    userGroup
                )
            }
            else {
                const CurrentAccount = instance.getActiveAccount()
                const request = {
                    scopes: ['user.read', 'user.read.all'],
                    account: CurrentAccount
                }
                const response = await instance.acquireTokenSilent(request)
                let user_data_azure_account = await Axios.get(`https://graph.microsoft.com/v1.0/users/${azureMail}`, {
                    headers: {
                        Authorization: `Bearer ${response.accessToken}`
                    }
                })

                let user_data_azure_account_manager = await Axios.get(`https://graph.microsoft.com/v1.0/users/${azureMail}/manager`, {
                    headers: {
                        Authorization: `Bearer ${response.accessToken}`
                    }
                })
                let user_data_azure = {
                    ...user_data_azure_account.data,
                    "Manager": user_data_azure_account_manager.data.mail,
                    "PracticeArea": null,
                    "Education": 1,
                    "Specialization": "Not Available",
                    "Client": 1,
                    "Billable": 0,
                    "CareerStartDate": null,
                    "Location": user_data_azure_account.data.mobilePhone !== null ? Locations.filter(item => item.countryCode === Number.parseInt(user_data_azure_account.data.mobilePhone.split(' ')[0].split("+")[1]))[0].Id : 0
                }

                let user_registered_data = await AxiosPost(`/iemployee/new-user-from-azure`, {
                    User_Data_Azure: user_data_azure
                })

                if (user_registered_data.data.isSuccess) {
                    setShowAzureLogin(false)
                    handleLoginDetails(
                        user_registered_data.data.result,
                        dispatch,
                        GetInitialValues,
                        navigate,
                        userGroup
                    )
                }
                else {
                    setShowAzureLogin(false)
                    dispatch(setLoadingScreen(false))
                    toast.error(Messages.Login.Login_Error, { toastId: "iemployee-toast" })
                }
            }
        }
        catch (err) {
            console.error(err)
        }

        // and then call the handleLoginDetails method to go to dashboard and compelete the login.
    }

    useEffect(() => {

        const handleAzureImage = async (CurrentAccount) => {
            try {
                const request = {
                    scopes: ['user.read', 'user.read.all', 'Group.Read.All'],
                    account: CurrentAccount
                }

                const response = await instance.acquireTokenSilent(request)

                let azure_image_response = await Axios.get(`https://graph.microsoft.com/v1.0/me/photo/$value`, {
                    headers: {
                        Authorization: `Bearer ${response.accessToken}`
                    },
                    responseType: "arraybuffer"
                })
                const blob = new Blob([azure_image_response.data], { type: 'image/jpeg' });
                setAzureImage(URL.createObjectURL(blob))


            }
            catch (err) {
                setAzureImage(null)
            }
        }

        if (isAuth) {

            const CurrentAccount = instance.getActiveAccount()

            if (CurrentAccount) {
                if (CurrentAccount.username.toString().includes('@genzeon.com')) {
                    handleAzureImage(CurrentAccount)
                    setShowAzureLogin(true)
                    setAzureMail(CurrentAccount.username)
                    if (CurrentAccount.localAccountId !== undefined) {
                        setId(CurrentAccount.localAccountId)
                    }
                    else {
                        if (CurrentAccount.homeAccountId !== undefined) {
                            setId(CurrentAccount.homeAccountId.split('.')[0])
                        }
                        else {
                            setId(null)
                        }
                    }

                    setName(CurrentAccount.name)
                }
                else {
                    setShowAzureLogin(false)
                    toast.error(Messages.Login.Login_Invalid, { toastId: "iemployee-toast" })
                    instance.logoutRedirect()
                }
            }

        }
    }, [isAuth, instance])

    // this is to check the history url and navigate it back once the login is done...
    useEffect(() => {

        if (azureMail !== '' && GetHistoryLocation() !== false) {
            dispatch(setLoadingScreen(true))
            setTimeout(() => {
                dispatch(setLoadingScreen(false))
                navigate(GetHistoryLocation(), { state: { email: azureMail } })
            }, 2000)
        }
// eslint-disable-next-line
    }, [azureMail])

    useEffect(() => {
        try {
            if (sessionStorage.getItem("_user_logged_in") === null) {
                setTimeout(() => {
                    setAutoLogin(true)
                    sessionStorage.setItem("_user_logged_in", false)
                }, 1000)
            }
        }
        catch (err) {
            console.log(err)
        }
    }, [])

    useEffect(() => {

        if (autoLogin) {
            setAutoLogin(false)
            instance.loginRedirect({
                scopes: ['user.read', 'user.read.all', 'Group.Read.All']
            })
        }

    }, [instance, autoLogin])

    return (
        <div className='login_container'>
            <div className='login_image_container'>
                <img src={LoginImage} alt='login_image' />
            </div>
            <div className='login_form'>
                {
                    isAnotherAccount ?
                        <form className='login_form_card_1' onSubmit={handleLogin}>
                            <div className='login_form_card_1_header'>
                                <span>Login</span>
                            </div>
                            <div className='login_form_card_1_content'>
                                <div className='login_form_card_1_content_span'>
                                    <label htmlFor='username'>USER NAME</label>
                                    <input
                                        type='text'
                                        name='username'
                                        id='username'
                                        placeholder='Enter Username'
                                    />
                                </div>
                                <div className='login_form_card_1_content_span'>
                                    <label htmlFor='password'>PASSWORD</label>
                                    <input
                                        type='password'
                                        name='password'
                                        id='password'
                                        placeholder='Enter Password'
                                    />
                                </div>
                            </div>
                            <div className='login_form_card_1_forgot'>
                                <span onClick={() => {
                                    dispatch(ForgotPassword({
                                        active: true,
                                        where: "login"
                                    }))
                                }}>Forgot Password?</span>
                            </div>
                            <div className='login_form_card_1_btn'>
                                <button>Login</button>
                            </div>
                            <div className='login_form_card_1_go_back'>
                                <span onClick={() => setIsAnotherAccount(false)}>Go Back</span>
                            </div>
                        </form>
                        :
                        <Tilt options={defaultOptions}>
                            <div className='login_form_card'>
                                <div className='login_form_card_header'>
                                    <span>Welcome {show_azure_login ? name : ""},</span>
                                    <span>to iEmployee</span>
                                </div>
                                <div className='login_form_card_image_container'>
                                    {
                                        show_azure_login ?
                                            <>
                                                {
                                                    azure_image === null ?
                                                        <div className='login_form_card_image' style={{ background: RandomColor(name !== '' && name.split(' ')[0].substring(0, 1).toLowerCase()) }}>
                                                            <span style={{ "textTransform": "capitalize" }}>{name !== '' &&
                                                                <>
                                                                    {
                                                                        name.split(' ').length > 1 ?
                                                                            <>{name.split(' ')[0].substring(0, 1) + name.split(' ')[1].substring(0, 1)}</>
                                                                            :
                                                                            <>{name.split(' ')[0].substring(0, 1)}</>
                                                                    }
                                                                </>
                                                            }</span>
                                                        </div>
                                                        :
                                                        <div className='login_form_azure_image_container'>
                                                            <img src={azure_image} alt='user' />
                                                        </div>
                                                }
                                            </>
                                            :
                                            <div className='login_form_card_image'>
                                                <span>User</span>
                                            </div>
                                    }
                                </div>
                                <div className='login_form_other_account'>
                                    {/* <span onClick={() => setIsAnotherAccount(true)}>Use another account?</span> */}
                                    <span style={{ color: "#252525" }}>{name}</span>
                                </div>
                                <div className='login_form_card_btn'>
                                    {
                                        show_azure_login ?
                                            <button className='login_form_card_btn_continue' onClick={handleRedirectToDashboard}>Continue with Microsoft</button>
                                            :
                                            <button className='login_form_card_btn_redirect' onClick={handleLoginWithMicrosoft}>Login with Microsoft</button>
                                    }
                                </div>
                            </div>
                        </Tilt>
                }
                <div className='login_genzeon-logo'>
                    <img src={GenzeonLogo} alt='genzeon' />
                </div>

            </div>
        </div>
    )
}

export default Login


const defaultOptions = {
    reverse: false,  // reverse the tilt direction
    max: 20,     // max tilt rotation (degrees)
    perspective: 1000,   // Transform perspective, the lower the more extreme the tilt gets.
    scale: 1,    // 2 = 200%, 1.5 = 150%, etc..
    speed: 1000,   // Speed of the enter/exit transition
    transition: true,   // Set a transition on enter/exit.
    axis: null,   // What axis should be disabled. Can be X or Y.
    reset: true,    // If the tilt effect has to be reset on exit.
    easing: "cubic-bezier(.03,.98,.52,.99)",    // Easing on enter/exit.
}