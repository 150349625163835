import React from "react";
import "./Footer.css"
const Footer = () => {
    return (
        <>
            <div className='footer_version'> Version: {process.env.REACT_APP_VERSION}</div>
        </>
    )
}

export default Footer;