import React from 'react'
import "./CloseAlert.css"

import { Info, GppBad, CheckCircle, Warning, Close } from '@mui/icons-material'
import { useDispatch } from 'react-redux'
import { setCloseAlert } from '../../../redux/features/ActivateSlice'

function CloseAlert({ variant, message }) {
    const dispatch = useDispatch()

    return (
        <div className='alert_close' style={{
            background: variant === 'success' ? '#9BF78C' : variant === 'error' ? '#F5A4AF' : variant === 'warning' ? '#FDE48C' : "#014361",
            color: "#000000"
        }}>
            <div className='alert_close_message'>
                {
                    variant === '' || variant === undefined || variant === 'info' ?
                        <Info />
                        :
                        <>
                            {
                                variant === 'error' ?
                                    <GppBad />
                                    :
                                    <>
                                        {
                                            variant === 'success' ?
                                                <CheckCircle />
                                                :
                                                <Warning />
                                        }
                                    </>
                            }
                        </>
                }
                <span>{message}</span>
            </div>
            <Close fontSize='small' style={{ cursor: "pointer" }} onClick={() => dispatch(setCloseAlert({
                active: false
            }))} />
        </div>
    )
}

export default CloseAlert