import React from 'react'
import "./Layouts.css"

import Header from '../../Components/Header/Header'
import Navigations from '../Components/Navigations'
import { Navigate, Outlet } from 'react-router-dom'

function ProjectCodeGenerationIsLoggedIn({ isExists }) {
    return isExists ?
        <div className='project_code_generation_main_page'>
            <Header />
            <div className='project_code_generation_display_page'>
                <Navigations />
                <div className='project_code_generation_screens'>
                    <Outlet />
                </div>
            </div>
        </div>
        :
        <>
            {
                localStorage.getItem('__iemployee__user') === null && (
                    <Navigate to={'/'} />
                )
            }
        </>
}

export default ProjectCodeGenerationIsLoggedIn