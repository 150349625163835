import React, { useEffect, useState } from 'react'
import "./Holidays.css"
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { AxiosGet, AxiosPost, getLocalDate } from '../../data/utilities';
import { useDispatch, useSelector } from 'react-redux';
import { setLoadingScreen } from '../../redux/features/ActivateSlice';
import { utils, read } from 'xlsx'
import DragDropImage from '../../assets/drag-drop.png'
import FileImage from '../../assets/image-file.png'
import { Close } from '@mui/icons-material';
import { getUserData } from '../../redux/features/UserSlice';
import { toast } from 'react-toastify';
import NoData from '../../Components/NoData';

function Holidays() {

    const dispatch = useDispatch()

    const [Years, setYears] = useState({
        list: [],
        filterYear: "Select Year"
    })

    const [month, setMonth] = useState("All")
    const [location, setLocation] = useState(-1)
    const [isEnable, setIsEnable] = useState(false)

    const [holidays, setHolidays] = useState([])
    const [tempholidays, setTempHolidays] = useState([])

    useEffect(() => {

        let fixedYear = 2023

        let currentYear = new Date().getFullYear()

        let numberOfArrays = new Array((currentYear - fixedYear) + 1)

        let values = []

        for (let i = 0; i < numberOfArrays.length; i++) {
            values.push({
                year: 2023 + i
            })
        }
        setYears({ ...Years, "list": values })
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        const handleGetHolidays = async () => {
            dispatch(setLoadingScreen(true))
            let result = await AxiosGet(`/timesheet/get-holidays/${new Date().getFullYear()}`)

            if (result.data.isSuccess) {
                dispatch(setLoadingScreen(false))
                setHolidays(result.data.Holidays)
                setTempHolidays(result.data.Holidays)
            }
            else {
                dispatch(setLoadingScreen(false))
            }

        }

        handleGetHolidays()


        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        let filterHoliday = tempholidays.filter(item => {

            let filterYear = Years.filterYear === 'Select Year' ? true : item.HolidayYear === Years.filterYear

            let filterLocation = location === -1 ? true : item.OfficeLocation === Number.parseInt(location)

            let filterMonth = month === 'All' ? true : item.HolidayMonth === Number.parseInt(month)


            return filterYear && filterLocation && filterMonth

        })


        setHolidays(filterHoliday)

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [month, location, Years.filterYear])

    return (
        <>
            <div className='holidays--container'>
                <div className='holidays--header'>
                    <div className='holidays-title'>
                        <span>Holidays</span>
                    </div>
                    <div className='holidays--actions'>
                        <button onClick={() => setIsEnable(true)}>Import Holidays</button>
                    </div>
                </div>
                {
                    holidays.length === 0 ?
                        <div style={{
                            boxShadow: "0 0 10px rgba(0,0,0,0.2)",
                            marginTop: "20px",
                            borderRadius: "10px",
                            height: "60dvh"
                        }}>
                            <NoData title={'No holidays are found for this year'} />
                        </div>
                        :
                        <div className='holidays--content'>
                            {/* filters */}
                            <div className='holidays--filters'>
                                <div className='holidays--filters--field'>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel id="selected-item">Filter By Year</InputLabel>
                                        <Select
                                            label={"Filter By Year"}
                                            id="selected-item"
                                            sx={{ width: "100%" }}
                                            value={Years.filterYear}
                                            onChange={(e) => {
                                                setYears({ ...Years, "filterYear": e.target.value })
                                            }}
                                        >
                                            <MenuItem value={"Select Year"}>Select Year</MenuItem>
                                            {Years.list.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.year}>
                                                        {item.year}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='holidays--filters--field'>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel id="selected-item">Filter By Month</InputLabel>
                                        <Select
                                            label={"Filter By Month"}
                                            id="selected-item"
                                            sx={{ width: "100%" }}
                                            value={month}
                                            onChange={(e) => {
                                                setMonth(e.target.value)
                                            }}
                                        >
                                            <MenuItem value={'All'}>All</MenuItem>
                                            <MenuItem value={'1'}>January</MenuItem>
                                            <MenuItem value={'2'}>February</MenuItem>
                                            <MenuItem value={'3'}>March</MenuItem>
                                            <MenuItem value={'4'}>April</MenuItem>
                                            <MenuItem value={'5'}>May</MenuItem>
                                            <MenuItem value={'6'}>June</MenuItem>
                                            <MenuItem value={'7'}>July</MenuItem>
                                            <MenuItem value={'8'}>August</MenuItem>
                                            <MenuItem value={'9'}>September</MenuItem>
                                            <MenuItem value={'10'}>October</MenuItem>
                                            <MenuItem value={'11'}>November</MenuItem>
                                            <MenuItem value={'12'}>December</MenuItem>
                                        </Select>
                                    </FormControl>
                                </div>
                                <div className='holidays--filters--field'>
                                    <FormControl sx={{ width: "100%" }}>
                                        <InputLabel id="selected-item">Filter By Location</InputLabel>
                                        <Select
                                            label={"Filter By Year"}
                                            id="selected-item"
                                            sx={{ width: "100%" }}
                                            value={location}
                                            onChange={(e) => {
                                                setLocation(e.target.value)
                                            }}
                                        >
                                            {LocationColumn.map((item, index) => {
                                                return (
                                                    <MenuItem key={index} value={item.value}>
                                                        {item.name}
                                                    </MenuItem>
                                                );
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            {/* table */}
                            <div className='holidays--table'>
                                <table>
                                    <thead>
                                        <tr>
                                            <td>Name</td>
                                            <td>Date</td>
                                            <td>Office Location</td>
                                            <td>Uploaded By</td>
                                            <td>Uploaded Date</td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            holidays.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{item.HolidayName}</td>
                                                        <td>{new Date(item.HolidayDate).toLocaleDateString()}</td>
                                                        <td>{LocationColumn.filter(location => location.value === item.OfficeLocation)[0].name}</td>
                                                        <td>{item.UploadedBy}</td>
                                                        <td>{item.Created_dt === null ? "Nothing" : getLocalDate(item.Created_dt)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                }
            </div>
            {
                isEnable && (
                    <ImportHoliday
                        setIsEnable={setIsEnable}
                        setHolidays={setHolidays}
                        setTempHolidays={setTempHolidays}
                    />
                )
            }
        </>
    )
}

export default Holidays



function ImportHoliday({ setIsEnable, setHolidays, setTempHolidays }) {

    const dispatch = useDispatch()

    const userData = useSelector(getUserData)

    const [uploadedData, setUploadedData] = useState([])

    const handleDragOver = (e) => {
        e.preventDefault()
    };

    const handleDrop = (e) => {
        e.preventDefault();
        console.log(e.dataTransfer.files[0])
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = read(data, { type: "array" });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const excelData = utils.sheet_to_json(worksheet, {
                header: 1,
                raw: false,
            });
            // Use the extracted data as needed
            excelData.splice(0, 1)
            setUploadedData(excelData)
        };
        reader.readAsArrayBuffer(file);

    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = read(data, { type: 'array' });

            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            let excelData = utils.sheet_to_json(worksheet, { header: 1, raw: false });
            // Use the extracted data as needed
            excelData.splice(0, 1)

            excelData = excelData.filter(item => item.length !== 0)

            setUploadedData(excelData)
        };

        reader.readAsArrayBuffer(file);
    }

    const handleInsertHolidays = async () => {

        let values = []

        uploadedData.forEach((item) => {
            values.push({
                HolidayName: item[0],
                HolidayDate: item[1],
                Location: LocationColumn.filter(location => location.name.toLowerCase() === item[2].toLowerCase()).length === 0 ?
                    0
                    :
                    LocationColumn.filter(location => location.name.toLowerCase() === item[2].toLowerCase())[0].value
            })

        })

        dispatch(setLoadingScreen(true))

        let result = await AxiosPost('/timesheet/insert-holidays', {
            HolidayData: values,
            FKEmpId: userData.Id
        })

        if (result.data.isSuccess) {
            dispatch(setLoadingScreen(false))
            setIsEnable(false)
            setUploadedData([])
            toast.success(result.data.message)
        }
        else {
            dispatch(setLoadingScreen(false))
            toast.info(result.data.message)
        }

        setHolidays(result.data.updatedHolidays)
        setTempHolidays(result.data.updatedHolidays)

    }

    return (
        <div className='import--holiday--container'>
            <div className='import--holiday--card'>
                <div className='import--holiday--header'>
                    <div className='import--holiday--header_title'>
                        <span>Holidays</span>
                    </div>
                    <div className='import--holiday--header_close'>
                        <Close style={{ cursor: "pointer" }} onClick={() => {
                            setIsEnable(false)
                            setUploadedData([])
                        }} />
                    </div>
                </div>
                {
                    uploadedData.length === 0 ?
                        <div className='import--holiday--span-1'>
                            <div className='project_codes_card_upload_box'>
                                <div className='import--holiday--span-1--drag_drop'
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                >
                                    <div style={{ textAlign: "center" }}>
                                        <img src={DragDropImage} alt='img-upload' />
                                        <br />
                                        <span>Drag and Drop File</span>
                                    </div>
                                </div>
                                <p style={{ textAlign: "center", margin: "20px 0" }}>Or</p>
                                <div className='import--holiday--span-1--upload_file'>
                                    <label htmlFor='upload_file'>
                                        <img src={FileImage} alt='choosefile' />
                                        Choose File
                                    </label>
                                    <input
                                        type='file'
                                        id='upload_file'
                                        style={{ display: "none" }}
                                        onChange={handleFileUpload}
                                    />
                                </div>
                            </div>
                        </div>
                        :
                        <div className='import--holiday--table'>
                            <table>
                                <thead>
                                    <tr>
                                        <td>Holiday Name</td>
                                        <td>Holiday Date (MM/DD/YYYY)</td>
                                        <td>Location</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        uploadedData.map((item, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{item[0]}</td>
                                                    <td>{item[1]}</td>
                                                    <td>{item[2]}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                }
                {
                    uploadedData.length !== 0 && (
                        <div className='import--holiday--actions'>
                            <button onClick={handleInsertHolidays}>Upload</button>
                        </div>
                    )
                }
            </div>
        </div>
    )

}

const LocationColumn = [
    {
        name: "All",
        value: -1,
    },
    {
        name: "India",
        value: 0,
    },
    {
        name: "USA",
        value: 1,
    },
    {
        name: "Philippines",
        value: 2,
    },
];