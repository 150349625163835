import React from 'react'
import "./Registered.css"
import CheckImage from '../../assets/checked.gif'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getSuccessPage } from '../../redux/features/ActivateSlice'

function Registered() {

    const navigate = useNavigate()
    const showPage = useSelector(getSuccessPage)

    return (
        <>
            {
                showPage.active && (
                    <div className='registered_main_page'>
                        <div className='registered_card'>
                            <div className='checked_image_container'>
                                <img src={CheckImage} alt='checked' />
                            </div>
                            <div className='registered_info'>
                                <h3>{showPage.message}</h3>
                                <p>{showPage.sub_message}</p>
                                <button onClick={() => navigate(`/${showPage.goBack}`)}>Go Back</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default Registered