import React, { useEffect, useState } from 'react'
import "./Timesheet.css"

import { Close, KeyboardDoubleArrowLeft, KeyboardDoubleArrowRight, NavigateBefore, NavigateNext } from '@mui/icons-material'
import { useParams, useNavigate } from 'react-router-dom'
import CryptoJS from 'crypto-js'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import { setLoadingScreen } from '../../../redux/features/ActivateSlice'
import { getInitialValues } from '../../../redux/features/InitialSlice'
import EmptyTable from '../../../Components/NoData'
import TextBox from './Features/TextBox'

import { toast } from 'react-toastify'
import AlertYesNo from '../../../Components/Alerts/AlertYesNo'
import { handleEndDate, handleStartDate, handleTimesheetDetails, handleUpdateTimesheets } from '../../Pages/TimesheetHandler'
import { getUserData } from '../../../redux/features/UserSlice'
import { Tooltip } from '@mui/material'

import Messages from '../../../data/Messages.json'
import { AxiosPost, Locations, TextCapaitalized, getCurrentDate, getWeekFromWeekUtils } from '../../../data/utilities'

const weeks = getWeekFromWeekUtils();

function Timesheet() {

    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const initialData = useSelector(getInitialValues)
    const userData = useSelector(getUserData)

    const [isActive, setIsActive] = useState(false)

    const [currentWeek, setCurrentWeek] = useState(null)
    const [type, setType] = useState('')

    const [currentOption, setCurrentOption] = useState('')

    const [jobTitle, setJobTitle] = useState('')

    const [weekArray, setWeekArray] = useState([])
    const [timesheet, setTimesheet] = useState([])

    const [isAlertActive, setIsActiveAlert] = useState(false)

    const [comment, setComment] = useState('')
    const [isActiveComment, setIsActiveComment] = useState(false)

    const [location, setLoctions] = useState(null)
    const [employeeName, setEmployeeName] = useState(null)
    const [count, setCount] = useState(0)
    const [SpecialCondition, setSpecialCondition] = useState(null)

    const [weeklyTimesheetData, setWeeklyTimesheetData] = useState([])

    useEffect(() => {
        if (currentWeek !== null && weekArray.length !== 0 && SpecialCondition !== null) {
            handleTimesheetDetails(
                dispatch,
                currentWeek,
                currentWeek.FKEmpId,
                setJobTitle,
                weekArray,
                setTimesheet,
                currentWeek.Status,
                SpecialCondition
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentWeek, weekArray, SpecialCondition])

    useEffect(() => {
        let value = JSON.parse(CryptoJS.AES.decrypt(params.timesheetId.split('|').join("/"), process.env.REACT_APP_SECRECT_KEY).toString(CryptoJS.enc.Utf8))
        setCurrentWeek(value)
        setType(value.type)
        DisplayDateChange(value.weeknumber, value.year)
    }, [params])

    useEffect(() => {

        const handleGetEmployeeInfo = async () => {

            if (userData.Id !== currentWeek.FKEmpId) {

                let WeeklyTimesheetResult = await AxiosPost('/timesheet/get-weekly-timesheet-by-fk-id', {
                    FKId: currentWeek.FKEmpId,
                    Year: currentWeek.year,
                    NumberOfWeeks: currentWeek.TotalNumberOfWeeks
                })

                if (WeeklyTimesheetResult.data.isSuccess) {
                    setWeeklyTimesheetData(WeeklyTimesheetResult.data.EmployeeTimesheet)
                }
            }
            else {
                setWeeklyTimesheetData(initialData.WeeklyTimesheet)
            }


            let response = await AxiosPost(`/iemployee/getbyidemployee`, {
                id: currentWeek.FKEmpId
            })

            if (response.data.isSuccess) {
                setLoctions(response.data.Employee[0].Location);
                setEmployeeName(response.data.Employee[0].FirstName + ' ' + response.data.Employee[0].LastName)
            }
        }

        if (currentWeek !== null && currentWeek.FKEmpId !== null) {
            handleGetEmployeeInfo();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData.Id, currentWeek])

    useEffect(() => {
        if (weekArray.length !== 0 && location === 1) {
            if (weekArray.filter(item => {
                const dt = new Date(item)
                return dt.getDate() === 15
            }).length !== 0) {
                let cc = 0
                weekArray.forEach((item) => {
                    if (Number.parseInt(item.toString().split(' ')[2]) <= 15) {
                        cc = cc + 1
                    }
                })
                if (cc !== 0) {
                    setSpecialCondition('PayCycle')
                    setCount(cc)
                }
                else {
                    setSpecialCondition('Nothing')
                    setCount(0)
                }
            }
            else {
                setSpecialCondition('Nothing')
            }

        }
    }, [weekArray, location])

    useEffect(() => {
        if (weekArray.length !== 0) {
            let cc = 0
            if (weekArray.filter(item => {
                const dt = new Date(item)
                return dt.getDate() === 1
            }).length !== 0) {
                weekArray.some(item => {
                    if (item.getDate() === 1) {
                        return true;
                    }
                    else {
                        cc = cc + 1;
                        return false;
                    }
                });
                if (cc !== 0) {
                    setSpecialCondition('MonthEnd')
                    setCount(cc)
                }
                else {
                    setSpecialCondition('Nothing')
                    setCount(0)
                }
            }
            else {
                setSpecialCondition('Nothing')
            }
        }
    }, [weekArray])

    const handleGetDesignation = () => {
        return new Promise((success, rejected) => {
            AxiosPost(`/iemployee/get-employee-designation`, {
                FKEmpId: currentWeek.FKEmpId
            }).then((result) => {
                if (result.data.isSuccess) {
                    success(result.data.Designation)
                }
            })
        })

    }

    const DisplayDateChange = (weekNumber, year) => {
        const firstDay = moment().year(year).week(weekNumber).startOf('week');
        const dates = Array.from({ length: 7 }, (_, i) => firstDay.clone().add(i, 'days').toDate());
        setCount(0)
        setWeekArray(dates)
    }

    const handleSave = (value) => {

        if ((handleTotalHours() - handleOverTime()) === 0) {
            toast.error('Please provide the hours', { toastId: "iEmployee-alert" })
            return false
        }

        if (value === 'Save') {
            if (handleTotalHours() <= 0) {
                toast.error(Messages.Timesheet.Timesheet_Fail, { toastId: "iemployee-toast" })
                return false
            }

            setCurrentOption(value)
            setIsActiveAlert(true)
            return false
        }

        if (SpecialCondition === 'MonthEnd') {
            if (count !== 0 && count !== 7 && value !== 'Save') {


                if (handleOverTime() > 0) {
                    setCurrentOption(value)
                    setIsActiveComment(true)
                }
                else {
                    setCurrentOption(value)
                    setIsActiveAlert(true)
                }
            }

            if ((handleTotalHours() - handleOverTime()) !== ((count - 1) * (location === 1 ? 8 : 9))) {
                toast.warn(Messages.Timesheet.Timesheet_Check_MonthEnd_Hours, { toastId: "iemployee-toast" })
                return false
            }

            if (handleOverTime() > 0) {
                setCurrentOption(value)
                setIsActiveComment(true)
            }
            else {
                setCurrentOption(value)
                setIsActiveAlert(true)
            }

            return false
        }


        if (SpecialCondition === 'PayCycle') {

            if (count !== 0 && count !== 7 && value !== 'Save') {


                if (handleOverTime() > 0) {
                    setCurrentOption(value)
                    setIsActiveComment(true)
                }
                else {
                    setCurrentOption(value)
                    setIsActiveAlert(true)
                }
            }

            if ((handleTotalHours() - handleOverTime()) !== ((count - 1) * 8)) {
                toast.warn(Messages.Timesheet.Timesheet_Check_Paycycle_Hours, { toastId: "iemployee-toast" })
                return false
            }

            if (handleOverTime() > 0) {
                setCurrentOption(value)
                setIsActiveComment(true)
            }
            else {
                setCurrentOption(value)
                setIsActiveAlert(true)
            }

            return false
        }

        if (handleOverTime() > 0) {
            setCurrentOption(value)
            setIsActiveComment(true)
        }
        else {
            setCurrentOption(value)
            setIsActiveAlert(true)
        }
    }


    const handleTotalHoursColumn = (day) => {

        let result = 0
        if (timesheet.lenght !== 0) {
            result = timesheet.reduce((acc, timesheet) => acc + (timesheet[day] === '' ? 0 : timesheet[day]), 0)
            return result
        }
        else {
            return result
        }
    }

    const handleTotalHours = () => {

        let result = 0
        if (timesheet.lenght !== 0) {
            timesheet.forEach((e) => {
                result = e.WK_Day1 + e.WK_Day2 + e.WK_Day3 + e.WK_Day4 + e.WK_Day5 + e.WK_Day6 + e.WK_Day7 + result
            })
            return result
        }
        else {
            return result
        }

    }

    const handleSaveTimeSheet = async () => {

        let currentMonth = SpecialCondition === 'MonthEnd' && currentWeek.Status === 'Approved'
            ? (weekArray[6].getMonth() + 1)
            : count === 1 ? (weekArray[6].getMonth() + 1) : currentWeek.month


        if (currentOption === 'Submit' && handleOverTime() > 0) {
            if (comment === '') {
                toast.warn(Messages.Timesheet.Timesheet_Add_Comments, { toastId: "iemployee-toast" })
                return false
            }
        }

        if (currentWeek.weekId === null || (SpecialCondition === 'MonthEnd' && currentWeek.Status === 'Approved')) {
            // insert
            dispatch(setLoadingScreen(true))
            setIsActiveAlert(false)
            let result = await AxiosPost(`/timesheet/insert-timesheet`, {
                WeekNumber: currentWeek.weeknumber,
                Month: currentMonth,
                Year: currentWeek.year,
                JobTitle: jobTitle === '' ? await handleGetDesignation() : jobTitle,
                Status: currentOption === 'Save' ? 'Pending' : "Submitted",
                TotalHours: handleTotalHours(),
                FKEmployeeId: currentWeek.FKEmpId,
                Comment: comment,
                WeekData: timesheet,
                Type: currentWeek.type,
                currentStatus: currentWeek.Status,
                SpecialCondition: SpecialCondition,
                count: count
            })

            if (result.data.isSuccess) {

                handleUpdateTimesheets(
                    currentWeek.type,
                    dispatch,
                    navigate,
                    setIsActive,
                    currentOption === 'Save' ? "Saved" : "Submitted",
                    currentWeek.FKEmpId,
                    userData.Id,
                    initialData
                )
            }
        }
        else {
            // update
            dispatch(setLoadingScreen(true))
            setIsActiveAlert(false)
            let result = await AxiosPost(`/timesheet/update-timesheet`, {
                WeekId: currentWeek.weekId,
                Status: currentOption === 'Save' ? 'Pending' : "Submitted",
                TotalHours: handleTotalHours(),
                FKEmployeeId: currentWeek.FKEmpId,
                Comment: comment,
                WeekData: timesheet,
                Type: currentWeek.type,
                Year: currentWeek.year,
                WeekNumber: currentWeek.weeknumber
            })

            if (result.data.isSuccess) {
                handleUpdateTimesheets(
                    currentWeek.type,
                    dispatch,
                    navigate,
                    setIsActive,
                    currentOption === 'Save' ? "Saved" : "Submitted",
                    currentWeek.FKEmpId,
                    userData.Id,
                    initialData
                )
            }

        }

    }

    const handleApproverUpdate = () => {
        setIsActive(true)
        if (type !== 'Approver') {
            handleTimesheetDetails(
                dispatch,
                currentWeek,
                currentWeek.FKEmpId,
                setJobTitle,
                weekArray,
                setTimesheet,
                "Pending"
            )
        }
    }

    const handleDate = (date) => {
        const MONTHS = [{ id: "01", name: "Jan" }, { id: "02", name: "Feb" }, { id: "03", name: "Mar" },
        { id: "04", name: "Apr" }, { id: "05", name: "May" }, { id: "06", name: "Jun" },
        { id: "07", name: "Jul" }, { id: "08", name: "Aug" }, { id: "09", name: "Sep" },
        { id: "10", name: "Oct" }, { id: "11", name: "Nov" }, { id: "12", name: "Dec" },]

        return `${MONTHS.filter(item => Number.parseInt(item.id) === Number.parseInt(date.split('/')[0]))[0].name} ${date.split('/')[1]}`

    }

    const handleNavigation = (year, month, weekId, weekNumber, totalNumberOfWeeks, status, activeWeek, approver, submitted, updated) => {
        setCurrentWeek({
            year: year,
            goback: currentWeek.goback,
            type: currentWeek.type,
            month: month,
            WeekStartDate: handleStartDate(weekNumber, year, 'Date'),
            WeekEndDate: handleEndDate(weekNumber, year),
            weekId: weekId,
            weeknumber: weekNumber,
            TotalNumberOfWeeks: totalNumberOfWeeks,
            Status: status,
            activeWeekNumber: activeWeek,
            FKEmpId: currentWeek.FKEmpId,
            Approver: approver,
            SubmittedDate: submitted,
            UpdatedDate: updated
        })
        setIsActive(false)
        DisplayDateChange(weekNumber, year)
    }

    const handleNext = () => {

        if (currentWeek.year === getCurrentDate().getFullYear()) {
            if (currentWeek.activeWeekNumber < (currentWeek.weeknumber + 1)) {

                toast.error(Messages.Timesheet.Timesheet_Unavailable, { toastId: "iemployee-toast" })
                return false

            }
        }

        let year = currentWeek.year
        let month = handleStartDate((currentWeek.weeknumber + 1), currentWeek.year, 'month')
        let week = currentWeek.weeknumber + 1
        let totalNumberOfWeeks = currentWeek.TotalNumberOfWeeks

        if (week > totalNumberOfWeeks) {

            year = currentWeek.year + 1
            let newYear = Array.from({ length: (weeks.localWeeks(Number.parseInt(year)) + 1) }, (_, index) => index + 1)
            week = 1
            totalNumberOfWeeks = newYear.length

            // return false
        }

        let timesheetData = weeklyTimesheetData.filter(item => item.WeekNumber === (currentWeek.weeknumber + 1))

        if (timesheetData.length === 0) {

            handleNavigation(
                year,
                month,
                null,
                week,
                totalNumberOfWeeks,
                null,
                currentWeek.activeWeekNumber
            )
        }
        else {
            timesheetData = timesheetData[0]

            handleNavigation(
                year,
                handleStartDate((currentWeek.weeknumber - 1), year, 'month'),
                timesheetData.WeekId,
                week,
                totalNumberOfWeeks,
                timesheetData.Status,
                currentWeek.activeWeekNumber,
                timesheetData.Approver,
                timesheetData.SubmittedDate,
                timesheetData.UpdatedBy,
                timesheetData.Comment
            )
        }

    }

    const handlePrevious = () => {

        let year = currentWeek.year
        let month = handleStartDate((currentWeek.weeknumber - 1), currentWeek.year, 'month')
        let week = currentWeek.weeknumber - 1

        let totalNumberOfWeeks = currentWeek.TotalNumberOfWeeks

        if ((currentWeek.weeknumber - 1) < 1) {
            year = currentWeek.year - 1

            let newYear = Array.from({ length: (weeks.localWeeks(Number.parseInt(year)) + 1) }, (_, index) => index + 1)

            week = newYear.length

            totalNumberOfWeeks = newYear.length

            // return false
        }

        let result = weeklyTimesheetData.filter(item => item.WeekNumber === week)

        if (result.length === 0) {

            handleNavigation(
                year,
                month,
                null,
                week,
                totalNumberOfWeeks,
                null,
                currentWeek.activeWeekNumber
            )
        }
        else {
            result = result[0]

            handleNavigation(
                year,
                handleStartDate((currentWeek.weeknumber - 1), year, 'month'),
                result.WeekId,
                result.WeekNumber,
                totalNumberOfWeeks,
                result.Status,
                currentWeek.activeWeekNumber,
                result.Approver,
                result.SubmittedDate,
                result.UpdatedBy
            )
        }

    }

    const handleFarPrevious = () => {

        let result = weeklyTimesheetData.filter(item => item.WeekNumber === 1)

        if (result.length === 0) {

            let month = handleStartDate(1, currentWeek.year, 'month')

            handleNavigation(
                currentWeek.year,
                month,
                null,
                1,
                currentWeek.TotalNumberOfWeeks,
                null,
                currentWeek.activeWeekNumber
            )
        }
        else {
            result = result[0]
            handleNavigation(
                currentWeek.year,
                handleStartDate((currentWeek.weeknumber - 1), currentWeek.year, 'month'),
                result.WeekId,
                result.WeekNumber,
                currentWeek.TotalNumberOfWeeks,
                result.Status,
                currentWeek.activeWeekNumber,
                result.Approver,
                result.SubmittedDate,
                result.UpdatedBy
            )
        }
    }

    const handleFarNext = () => {

        if (currentWeek.year === getCurrentDate().getFullYear()) {

            let timesheetData = weeklyTimesheetData.filter(item => item.WeekNumber === currentWeek.activeWeekNumber)

            if (timesheetData.length === 0) {

                let month = handleStartDate(currentWeek.TotalNumberOfWeeks, currentWeek.year, 'month')

                handleNavigation(
                    currentWeek.year,
                    month,
                    null,
                    currentWeek.TotalNumberOfWeeks,
                    currentWeek.TotalNumberOfWeeks,
                    null,
                    currentWeek.activeWeekNumber
                )
            }
            else {

                timesheetData = timesheetData[0]

                handleNavigation(
                    currentWeek.year,
                    handleStartDate((currentWeek.weeknumber - 1), currentWeek.year, 'month'),
                    timesheetData.WeekId,
                    timesheetData.WeekNumber,
                    currentWeek.TotalNumberOfWeeks,
                    timesheetData.Status,
                    currentWeek.activeWeekNumber,
                    timesheetData.Approver,
                    timesheetData.SubmittedDate,
                    timesheetData.UpdatedBy
                )
            }
            return false
        }

        let timesheetData1 = weeklyTimesheetData.filter(item => item.WeekNumber === (currentWeek.TotalNumberOfWeeks))

        if (timesheetData1.length === 0) {

            let month = handleStartDate(currentWeek.TotalNumberOfWeeks, currentWeek.year, 'month')

            handleNavigation(
                currentWeek.year,
                month,
                null,
                currentWeek.TotalNumberOfWeeks,
                currentWeek.TotalNumberOfWeeks,
                null,
                currentWeek.activeWeekNumber
            )
        }
        else {
            timesheetData1 = timesheetData1[0]
            handleNavigation(
                currentWeek.year,
                handleStartDate((currentWeek.weeknumber - 1), currentWeek.year, 'month'),
                timesheetData1.WeekId,
                timesheetData1.WeekNumber,
                currentWeek.TotalNumberOfWeeks,
                timesheetData1.Status,
                currentWeek.activeWeekNumber,
                timesheetData1.Approver,
                timesheetData1.SubmittedDate,
                timesheetData1.UpdatedBy
            )
        }

    }

    const handleOverTime = () => {

        let OverTimeHours = 0

        if (timesheet.filter(item => item.Type === 'OT').length !== 0) {
            timesheet.filter(item => item.Type === 'OT').forEach((item) => {
                OverTimeHours = OverTimeHours + item.WK_Day1 + item.WK_Day2 + item.WK_Day3 + item.WK_Day4 + item.WK_Day5 + item.WK_Day6 + item.WK_Day7
            })
        }
        return OverTimeHours
    }

    const handleLocalDate = (date) => {
        const dt = new Date(date)
        let date1 = dt.toLocaleString()
        return date1.replace(',', " ")
    }

    return (
        <>
            <div className='timesheet_container'>
                {/* header */}
                <div className='timesheet_container_header'>
                    <div className='timesheet_container_header_navigate_btns'>
                        {
                            isActive ?
                                <button style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center"
                                }}
                                    onClick={() => setIsActive(false)}
                                > Back</button>
                                :
                                <button style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center"
                                }}
                                    onClick={() => navigate(`${currentWeek.goback}`)}
                                > Back</button>
                        }
                        <>
                            {
                                type !== 'Approver' && (
                                    <>
                                        {
                                            currentWeek !== null && (
                                                <>
                                                    {
                                                        currentWeek.Status === 'Submitted' || currentWeek.Status === 'Approved' ?
                                                            <></>
                                                            :
                                                            <>
                                                                {
                                                                    !isActive ?
                                                                        <button onClick={() => setIsActive(true)}>
                                                                            Edit</button>
                                                                        :
                                                                        <button onClick={() => handleSave('Save')}>Save</button>
                                                                }
                                                                <button onClick={() => handleSave('Submit')}>Submit</button>
                                                            </>
                                                    }
                                                </>
                                            )
                                        }
                                    </>
                                )
                            }
                        </>
                        {
                            (type === 'Approver' || (currentWeek === null ? false : currentWeek.Status === 'Approved' && count > 2)) && (
                                <>
                                    {
                                        isActive ?
                                            <button onClick={() => handleSave('Submit')}>Submit</button>
                                            :
                                            <>
                                                {

                                                    handleTotalHours() >= 0 ?
                                                        <></>
                                                        :
                                                        <button onClick={handleApproverUpdate}>Edit</button>

                                                }
                                            </>
                                    }
                                </>
                            )}
                    </div>
                    {
                        type !== 'Approver' && (
                            <div className='timesheet_container_header_navigate_show_week'>
                                <div className='timesheet_container_header_week_btn'
                                    onClick={handleFarPrevious}
                                >
                                    <KeyboardDoubleArrowLeft />
                                </div>
                                <div className='timesheet_container_header_week_btn'
                                    onClick={handlePrevious}
                                >
                                    <NavigateBefore />
                                </div>
                                <div>
                                    <b>{currentWeek !== null && `${handleDate(currentWeek.WeekStartDate)} to ${handleDate(currentWeek.WeekEndDate)}, ${currentWeek.year}`}</b>
                                </div>
                                <div className='timesheet_container_header_week_btn'
                                    onClick={handleNext}
                                >
                                    <NavigateNext />
                                </div>
                                <div className='timesheet_container_header_week_btn'
                                    onClick={handleFarNext}
                                >
                                    <KeyboardDoubleArrowRight />
                                </div>
                            </div>
                        )
                    }
                </div>
                {/* content */}
                <div className='timesheet_content'>
                    {
                        currentWeek !== null && (
                            <div className='timesheet_content_data'>
                                <div className='timesheet_content_span_1'>
                                    <div>
                                        <label>Employee Name</label>
                                        <span>
                                            {TextCapaitalized(employeeName)}
                                        </span>
                                    </div>
                                    <div>
                                        <label>Job Title</label>
                                        <span>{jobTitle}</span>
                                    </div>
                                    <div>
                                        <label>Approver</label>
                                        <span style={{ textTransform: "capitalize" }}>{currentWeek.Approver === null ? "Not Started" : currentWeek.Approver}</span>
                                    </div>
                                    <div>
                                        <label>Status</label>
                                        <span>{currentWeek.Status === null ? "Not Started" : currentWeek.Status}</span>
                                    </div>
                                    <div>
                                        <label>Comment</label>
                                        <span>{currentWeek.Comment}</span>
                                    </div>
                                </div>
                                <div className='timesheet_content_span_1'>
                                    <div>

                                        <label>Location</label>
                                        {Locations.filter(item => item.value === location).map((item, index) => (
                                            <span key={index}>{item.name}</span>
                                        ))}
                                    </div>
                                    <div>
                                        <label>Week Ending</label>
                                        <span>{currentWeek.WeekEndDate + "/" + currentWeek.year}</span>
                                    </div>
                                    <div>
                                        <label>Submitted Date</label>
                                        <span>{currentWeek.SubmittedDate === null
                                            ? "Not Started" :
                                            handleLocalDate(currentWeek.SubmittedDate)}</span>
                                    </div>
                                    <div>
                                        <label>Approved Date</label>
                                        <span>{currentWeek.UpdatedDate === null ? "Not Started" : handleLocalDate(currentWeek.UpdatedDate)}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                    {
                        timesheet.length !== 0 ?
                            <table>
                                <thead>
                                    <tr>
                                        <td style={{ width: "10%" }}>Work</td>
                                        <td style={{ width: "20%" }}>Project Name</td>
                                        {
                                            weekArray.length !== 0 ?
                                                <>
                                                    {
                                                        weekArray.map((e, index) => {
                                                            return (
                                                                <td style={{ width: "6%", textAlign: "center" }} key={index}>
                                                                    {/* <span>{e.toString().split(' ')[1]}/{e.toString().split(' ')[2]}</span> */}
                                                                    <span>{index === 0 ? "SUN" : index === 1 ? "MON" : index === 2 ? "TUE" : index === 3 ? "WED" : index === 4 ? "THU" : index === 5 ? "FRI" : "SAT"}</span>
                                                                    <span>{" " + e.toString().split(' ')[2]}</span>
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </>
                                                :
                                                <></>
                                        }
                                        <td style={{ width: "10%", textAlign: "center" }}>Total Hours</td>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        !isActive ?
                                            <>
                                                {
                                                    timesheet.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <Tooltip placement='right' title={item.ProjectName !== undefined ? item.ProjectName : ''}>
                                                                        <span>{item.Work}</span>
                                                                    </Tooltip>
                                                                </td>
                                                                <td>{item.ProjectName}</td>
                                                                <td style={{ textAlign: 'center' }}>{item.WK_Day1}</td>
                                                                <td style={{ textAlign: 'center' }}>{item.WK_Day2}</td>
                                                                <td style={{ textAlign: 'center' }}>{item.WK_Day3}</td>
                                                                <td style={{ textAlign: 'center' }}>{item.WK_Day4}</td>
                                                                <td style={{ textAlign: 'center' }}>{item.WK_Day5}</td>
                                                                <td style={{ textAlign: 'center' }}>{item.WK_Day6}</td>
                                                                <td style={{ textAlign: 'center' }}>{item.WK_Day7}</td>
                                                                <td style={{ fontWeight: "bold", textAlign: "center" }}>{item.WK_Day1 + item.WK_Day2 + item.WK_Day3 + item.WK_Day4 + item.WK_Day5 + item.WK_Day6 + item.WK_Day7}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                            :
                                            <>
                                                {
                                                    timesheet.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td>
                                                                    <Tooltip placement='right' title={item.ProjectName !== undefined ? item.ProjectName : ''}>
                                                                        <span>{item.Work}</span>
                                                                    </Tooltip>
                                                                </td>
                                                                <td>{item.ProjectName}</td>
                                                                {
                                                                    weekArray.map((days, index) => {
                                                                        return (
                                                                            <td style={{ textAlign: 'center' }}>
                                                                                <TextBox
                                                                                    setTimesheet={setTimesheet}
                                                                                    Id={item.Id}
                                                                                    name={`WK_Day${index + 1}`}
                                                                                    Value={item[`WK_Day${index + 1}`]}
                                                                                    timesheet={timesheet}
                                                                                    type={item.Type}
                                                                                    ExpiryDt={item.ExpiryDate === undefined ? null : item.ExpiryDate}
                                                                                    JoiningDt={item.JoiningDate === undefined ? null : item.JoiningDate}
                                                                                    EndingDt={item.EndingDate === undefined ? null : item.EndingDate}
                                                                                    currentDt={days}
                                                                                    Status={currentWeek !== null && currentWeek.Status}
                                                                                    count={count}
                                                                                    process={type}
                                                                                    spCondition={SpecialCondition}
                                                                                    location={location}
                                                                                />
                                                                            </td>
                                                                        )
                                                                    })
                                                                }
                                                                <td style={{ fontWeight: "bold", textAlign: "center" }}>
                                                                    {item.WK_Day1 + item.WK_Day2 + item.WK_Day3 + item.WK_Day4 + item.WK_Day5 + item.WK_Day6 + item.WK_Day7}
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </>
                                    }
                                    <tr>
                                        <td>Total Hours</td>
                                        <td style={{ content: "" }}></td>
                                        {
                                            [1, 2, 3, 4, 5, 6, 7].map((item, index) => {
                                                return <td
                                                    style={{ textAlign: 'center' }}
                                                    key={index}
                                                >
                                                    {handleTotalHoursColumn(('WK_Day' + item))}
                                                </td>
                                            })
                                        }
                                        <td style={{ fontWeight: "bold", textAlign: "center" }}>{handleTotalHours()}</td>
                                    </tr>
                                </tbody>
                            </table>
                            :
                            <EmptyTable
                                title='Waiting for Data'
                            />
                    }
                </div>
            </div>
            <AlertYesNo
                isActive={isAlertActive}
                setIsActive={setIsActiveAlert}
                variant='warning'
                message={`Are you ready to ${currentOption === "Save" ? 'save' : 'submit'} the timesheet`}
                handleLogout={handleSaveTimeSheet}
            />
            {
                isActiveComment && (
                    <div className='timesheet_comment_container'>
                        <div className='timsheet_comment_card'>
                            <div className='timesheet_comment_header'>
                                <span>Comments*</span>
                                <Close onClick={() => setIsActiveComment(false)} style={{ cursor: "pointer" }} />
                            </div>
                            <div className='timesheet_comment_content'>
                                <textarea value={comment} onChange={(e) => {
                                    if (e.target.value.length <= 100) {
                                        setComment(e.target.value)
                                    }
                                }}
                                    placeholder={'Enter a Comment*'}
                                />
                                <div className='timesheet_comment_count' style={{ color: comment.length > 100 ? "red" : "#B9B9B9" }}>
                                    <span>{comment.length}/100</span>
                                </div>
                            </div>
                            <div className='timesheet_comment_actions'>
                                <button onClick={handleSaveTimeSheet}>Submit</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )

}

export default Timesheet