import React, { useEffect, useRef, useState } from "react";
import "./TimesheetReports.css"

import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ArrowDropDown } from "@mui/icons-material";

import { getUserData } from "../../../redux/features/UserSlice";
import EmptyTable from "../../../Components/NoData";
import { MenuItem, TextField, Tooltip } from "@mui/material";
// import MultiRangeSlider from "../../Pages/MultiRangeSlider/MultiRangeSlider";
import { utils, writeFile } from 'xlsx'

import { toast } from 'react-toastify'
import SortBy from "./Feature/SortBy";
import { getInitialValues } from "../../../redux/features/InitialSlice";
import { setLoadingScreen } from "../../../redux/features/ActivateSlice";

import Messages from '../../../data/Messages.json'
import jsPDF from 'jspdf';
import 'jspdf-autotable';
// import { getCurrentDate, getWeekEnding } from "../../../data/utilities";
import { AxiosGet, AxiosPost } from "../../../data/utilities";
import { getWeek } from "date-fns";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

function TimesheetReports({ AllocatedProjectCodes }) {

    const dispatch = useDispatch()
    const userData = useSelector(getUserData)
    const initialData = useSelector(getInitialValues)

    const [timesheets, setTimesheets] = useState([]);

    // const [isActiveTimeline, setIsActiveTimeline] = useState(false);
    const [isActiveJobTitle, setIsActiveJobTitle] = useState(false);
    const [isActiveUserName, setIsActiveUsername] = useState(false);
    const [isActiveStatus, setIsActiveStatus] = useState(false);
    const [isActiveApprover, setIsActiveApprover] = useState(false);
    const [data, setData] = useState([]);

    const [status, setStatus] = useState('All')
    const [Approver_List, SetApprover_List] = useState([])

    // const [TimelineMin, setTimelineMin] = useState(1950)
    // const [TimelineMax, setTimelineMax] = useState(getCurrentDate().getFullYear())

    const [FilterByJobTitle, setFilterByJobTitle] = useState('')
    const [EmployeeName, setEmployeeName] = useState('')
    const [sortFilter, setSortFilter] = useState(false)

    const [selectedColumns, setSelectedColumns] = useState([]);

    const tableRef = useRef(null);

    const [isActiveWeeklyTimeline, setIsActiveWeeklyTimeline] = useState(false)

    const [showWeekEnding, setShowWeekEnding] = useState({
        start: null,
        startWeek: null,
        end: null,
        endWeek: null
    })

    const [filterByProjectCode, setFilterByProjectCode] = useState(null)
    const [isActiveProjectCodes, setIsActiveProjectCodes] = useState(false)
    const [filterByApprover, setFilterByApprover] = useState("")

    const [filterToggle, setFilterToggle] = useState(false)
    const [tableColumns, setTableColumns] = useState([])

    useEffect(() => {
        const handleGetAllReports = async () => {
            dispatch(setLoadingScreen(true))

            let result = await AxiosGet(`/timesheet/get-all-employee-timesheet-reports`)

            if (result.data.isSuccess) {
                setTimesheets(result.data.AllTimesheetReports)
                setData(result.data.AllTimesheetReports)
                let arr = [];
                result.data.AllTimesheetReports.forEach(item => {
                    arr.push(item.Approver)
                })
                SetApprover_List(removeDuplicatesFromArray(arr))
                dispatch(setLoadingScreen(false))
            }

        }
        setStatus('Approved')
        if (userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU) {
            setTableColumns(tableColadmin)
            handleGetAllReports()
        }
        else {
            setTableColumns(tableCol)
            setTimesheets(initialData.TimesheetReports)
            console.log(initialData.TimesheetReports)
            setData(initialData.TimesheetReports)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData, userData])

    useEffect(() => {

        const filteredTimesheet = data.filter((timesheet) => {

            const usernameMatch =
                EmployeeName === '' ||
                timesheet.Name.toLowerCase().includes(EmployeeName.toLowerCase());
            const jobTitleMatch = FilterByJobTitle === '' || timesheet.JobTitle.toLowerCase().includes(FilterByJobTitle.toLowerCase());

            const StatusMatch = status === "All" ? true : timesheet.Status.toString() === status;

            const ProjectCodeMatch =
                filterByProjectCode === null ||
                timesheet.ProjectCode.toString() === filterByProjectCode;

            const weeklyTimesheetMatch = (showWeekEnding.start === null || showWeekEnding.end === null) ? true :
                (timesheet.WeekNumber >= showWeekEnding.startWeek && timesheet.WeekNumber <= showWeekEnding.endWeek)

            if (userData.role === process.env.REACT_APP_ROLE_SU || userData.role === process.env.REACT_APP_ROLE_ADMIN) {
                const approver = filterByApprover === '' ? true : timesheet.Approver === filterByApprover
                return usernameMatch && jobTitleMatch && StatusMatch && ProjectCodeMatch && approver && weeklyTimesheetMatch;
            }
            else {
                return usernameMatch && jobTitleMatch && StatusMatch && ProjectCodeMatch && weeklyTimesheetMatch;
            }


        });

        setTimesheets([])
        setTimeout(() => {
            setTimesheets(filteredTimesheet)
        }, 500)

    }, [status, data, FilterByJobTitle, EmployeeName, filterByProjectCode, filterByApprover, userData.role, showWeekEnding])

    useEffect(() => {
        if (userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU) {
            setSelectedColumns(tableColumns.filter(item => item.name !== 'Total'))
        }
        else {
            setSelectedColumns(tableColumns.filter(item => item.name !== 'Project Name'))
        }

    }, [tableColumns, userData])

    const handleWeekEnding = (weeknumber, year) => {
        let date = moment().year(year).week(weeknumber).endOf('week')

        const MONTHS = [{ id: "01", name: "Jan" }, { id: "02", name: "Feb" }, { id: "03", name: "Mar" },
        { id: "04", name: "Apr" }, { id: "05", name: "May" }, { id: "06", name: "Jun" },
        { id: "07", name: "Jul" }, { id: "08", name: "Aug" }, { id: "09", name: "Sep" },
        { id: "10", name: "Oct" }, { id: "11", name: "Nov" }, { id: "12", name: "Dec" },]

        return `${MONTHS.filter(item => item.name === date._d.toString().split(' ')[1])[0].id}/${date._d.toString().split(' ')[2]}/${date._d.toString().split(' ')[3]}`
    }

    const handleDate = (date) => {

        const localZoneDate = new Date(date)
        return {
            Date: localZoneDate.toLocaleString().split(',')[0],
            Time: localZoneDate.toLocaleString().split(',')[1]
        }
        // return `${localZoneDate.toLocaleString().split(',')[0]} ${localZoneDate.toLocaleString().split(',')[1]}`
    }

    const handleClear = () => {
        setTimesheets(data.filter(item => item.Status === 'Approved'));
        setIsActiveUsername(false);
        setIsActiveJobTitle(false);
        setIsActiveStatus(false);
        setIsActiveWeeklyTimeline(false)
        setIsActiveProjectCodes(false)
        setFilterByProjectCode(null)
        setStatus('Approved')
        setSortFilter(true)
        setTimeout(() => {
            setSortFilter(false)
        }, 50)
        setShowWeekEnding({
            start: null,
            startWeek: null,
            end: null,
            endWeek: null
        })
        setFilterByJobTitle('')

    }

    const handleFilterByJobTitle = (e) => {
        setFilterByJobTitle(e.target.value)
    }

    const handleFilterEmployeeName = (e) => {
        setEmployeeName(e.target.value)
    }

    const handleFilterByStatus = (e) => {
        setStatus(e.target.value)
    }

    const handleExport = () => {

        if (selectedColumns.length === 0) {
            toast.error(Messages.Timesheet_Reports.Timesheet_Reports_Select_Columns, { toastId: "iemployee-toast" })
            return false
        }

        const table = tableRef.current;//refering table
        const tableRows = Array.from(table.getElementsByTagName('tr'));
        const renderedTableData = tableRows.map(row =>
            Array.from(row.getElementsByTagName('td')).reduce((rowData, cell, cellIndex) => {
                const header = (userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU) ? tableColadmin[cellIndex] : tableCol[cellIndex];
                if (selectedColumns.includes(header)) {
                    rowData[header.name] = cell.innerText;
                }
                return rowData;
            }, {})
        );
        renderedTableData.shift();
        const workbook = utils.book_new();
        const worksheet = utils.json_to_sheet(renderedTableData);
        utils.book_append_sheet(workbook, worksheet, 'Employee Data');
        const fileName = 'Emplooyee_data.xlsx';
        writeFile(workbook, fileName);

    }

    const handleExportPDF = () => {
        if (selectedColumns.length === 0) {
            toast.error(Messages.Timesheet_Reports.Timesheet_Reports_Select_Columns, { toastId: "iemployee-toast" })
            return false
        }

        const table = tableRef.current;//refering table
        const tableRows = Array.from(table.getElementsByTagName('tr'));
        const renderedTableData = tableRows.map(row =>
            Array.from(row.getElementsByTagName('td')).reduce((rowData, cell, cellIndex) => {
                const header = (userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU) ? tableColadmin[cellIndex] : tableCol[cellIndex];
                if (selectedColumns.includes(header)) {
                    rowData[header.name] = cell.innerText;
                }
                return rowData;
            }, {})
        );
        const doc = new jsPDF({
            orientation: 'landscape',
        });

        const newArr = [];
        tableColumns.forEach(element => {
            if (selectedColumns.includes(element))
                newArr.push(element.name)
        });
        const rows = renderedTableData.map(item => newArr.map(column => item[column]));
        const headerStyles = {
            fillColor: [0, 51, 102],
            textColor: 255,
        };
        doc.autoTable({
            head: [newArr],
            body: rows,
            startY: 20,
            theme: 'grid',
            headStyles: headerStyles,
        });
        doc.save(`Employee_data.pdf`);
    }

    const handleSelectColumn = (column) => {
        if (selectedColumns.includes(column)) {
            //checking that object is avaliable or not in the selectedcolumn 
            // it is unchecked then remove it from the selected column
            setSelectedColumns(selectedColumns.filter(col => col !== column));
        } else {
            //if selected column is not present in the array then we add it and make it checked
            setSelectedColumns([...selectedColumns, column]);
        }

    }

    const handleSendTimesheetReports = async () => {

        let reportingTo = initialData.Employees.filter((employee) => employee.Id === userData.Id)[0].ReportingTo;
        if (reportingTo === null) {
            toast.error(Messages.Timesheet_Reports.Timesheet_Report_Manager_NotFound, { toastId: "iemployee-toast" })
            return false;
        }

        let Manager = initialData.Employees.filter((employee) => employee.Id === reportingTo);

        const table = tableRef.current;
        dispatch(setLoadingScreen(true))
        let result = await AxiosPost(
            `/timesheet/send-timesheet-reports`,
            {
                mailaddress: Manager[0].Email,
                Subject: "Timesheet Report",
                message: table.innerHTML.split('<td').join(`<td style='padding:10px;border-bottom: 1px solid black;'`),
                ManagerName: Manager[0].FirstName + " " + Manager[0].LastName
            }
        );
        dispatch(setLoadingScreen(false))
        if (result.data.isSuccess) {
            toast.success(`Timesheet Report is send to ${Manager[0].FirstName + " " + Manager[0].LastName}`, { toastId: "iemployee-toast" })
        } else {
            toast.warn(Messages.Timesheet_Reports.Timesheet_Report_Error, { toastId: "iemployee-toast" })
        }
    }

    // const handleWeeklyTimelineFilter = () => {

    //     if (showWeekEnding.start === null || showWeekEnding.end === null) {
    //         toast.warn(Messages.Timesheet_Approval.Timesheet_WeeklyTimeline, { toastId: "iemployee-toast" })
    //         return false;
    //     }

    //     // let start_input = Number.parseInt(showWeekEnding.startWeek);
    //     // let end_input = Number.parseInt(showWeekEnding.endWeek);

    //     // if (start_input > end_input) {
    //     //     toast.warn(Messages.Timesheet_Approval.Timesheet_WeeklyTimeline, { toastId: "iemployee-toast" })
    //     //     return false
    //     // }

    //     setTimesheets(timesheets.filter((item) => item.WeekNumber >= showWeekEnding.startWeek && item.WeekNumber <= showWeekEnding.endWeek));
    // };

    // const handleWeekStartDate = (date) => {
    //     const dt = new Date(date)
    //     let weekNumber = getWeek(dt)
    //     let weekEnding = getWeekEnding(weekNumber, dt.getFullYear())
    //     setShowWeekEnding({ ...showWeekEnding, "start": weekEnding, "startWeek": weekNumber })
    // }

    // const handleWeekEndDate = (date) => {
    //     const dt = new Date(date)

    //     if (dt.getTime() < new Date(showWeekEnding.start).getTime()) {
    //         toast.error(Messages.Timesheet_Approval.Timesheet_WeeklyTimeline, { toastId: "iemployee-toast" })
    //         return false
    //     }

    //     let weekNumber = getWeek(dt)
    //     let weekEnding = getWeekEnding(weekNumber, dt.getFullYear())
    //     setShowWeekEnding({ ...showWeekEnding, "end": weekEnding, "endWeek": weekNumber })
    // }

    const handleWeeklyTimesheet = (dates) => {
        const [start, end] = dates;

        setShowWeekEnding({
            ...showWeekEnding,
            "start": start,
            "end": end,
            "startWeek": Number.parseInt(getWeek(new Date(start))),
            "endWeek": Number.parseInt(getWeek(new Date(end)))
        });
    }

    return (
        <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="reports_filters_container" style={{ width: !filterToggle ? "0%" : "20%" }}>
                <div className="reports_filters_container_toggle" onClick={() => setFilterToggle(!filterToggle)}>
                    {
                        filterToggle ?
                            <Tooltip placement="top" title={'Hide'}>
                                <FilterListOffIcon />
                            </Tooltip>
                            :
                            <Tooltip placement="top" title={'Filter'}>
                                <FilterListIcon />
                            </Tooltip>
                    }
                </div>

                <div className="timesheet_reports_filters">
                    <div className="timesheet_reports_filters_header">
                        <div className="timesheet_reports_filters_header_title">
                            <span>Filter</span>
                        </div>
                        <div className="timesheet_reports_filters_header_clear">
                            <label onClick={handleClear} className="timesheet_reporting_filters_clear_all">
                                <span>Clear all</span>
                            </label>
                        </div>
                    </div>

                    <div className="timesheet_reports_filter_container">
                        <div
                            className="timesheet_reports_filter_container_header"
                            style={{
                                borderBottom: isActiveWeeklyTimeline ? "1px solid #C4C4C4" : "none",
                            }}
                        >
                            <span>Weekly Timeline</span>
                            <ArrowDropDown
                                style={{
                                    color: "#252525",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setIsActiveWeeklyTimeline(!isActiveWeeklyTimeline);
                                }}
                            />
                        </div>
                        {isActiveWeeklyTimeline && (
                            <div className="employees_filter_container_content">
                                <label>Select Date Range</label>
                                <div className="timesheet_reports_filter_container_content_span">
                                    {/* <label htmlFor="week-start">{showWeekEnding.start === null || showWeekEnding.end === null ? "Please Select Here" : "Date is selected"}</label> */}
                                    <DatePicker
                                        selected={showWeekEnding.start === null ? new Date() : showWeekEnding.start}
                                        showWeekNumbers
                                        minDate={new Date("1950-01-01")}
                                        onChange={handleWeeklyTimesheet}
                                        startDate={showWeekEnding.start}
                                        endDate={showWeekEnding.end}
                                        selectsRange
                                        inline
                                        placeholderText="Select Date Range"

                                    />
                                </div>
                                {/* <div
                                    className="timesheet_timeline_btn_1"
                                >
                                    <button
                                        onClick={handleWeeklyTimelineFilter}
                                    >
                                        Search
                                    </button>
                                </div> */}
                            </div>
                        )}
                    </div>

                    <div className="timesheet_reports_filter_container">
                        <div
                            className="timesheet_reports_filter_container_header"
                            style={{
                                borderBottom: isActiveProjectCodes ? "1px solid #C4C4C4" : "none",
                            }}
                        >
                            <span>Project Code</span>
                            <ArrowDropDown
                                style={{
                                    color: "#252525",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setIsActiveProjectCodes(!isActiveProjectCodes);
                                }}
                            />
                        </div>
                        {isActiveProjectCodes && (
                            <div className="employees_filter_container_content">
                                <TextField
                                    id="filterByProjectCodes"
                                    select
                                    label="Select Project Code"
                                    onChange={(e) => setFilterByProjectCode(e.target.value)}
                                    value={filterByProjectCode}
                                    sx={{ width: "100%" }}
                                >
                                    {AllocatedProjectCodes.map((option) => (
                                        <MenuItem
                                            key={option.ProjectCode}
                                            value={option.ProjectCode}
                                        >
                                            {option.ProjectCode}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        )}
                    </div>

                    <div className="timesheet_reports_filter_container">
                        <div
                            className="timesheet_reports_filter_container_header"
                            style={{
                                borderBottom: isActiveJobTitle ? "1px solid #C4C4C4" : "none",
                            }}
                        >
                            <span>Job Title</span>
                            <ArrowDropDown
                                style={{
                                    color: "#252525",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setIsActiveJobTitle(!isActiveJobTitle);
                                }}
                            />
                        </div>
                        {isActiveJobTitle && (
                            <div className="employees_filter_container_content">
                                <TextField
                                    id="Job Title"
                                    label="Enter Job Title"
                                    value={FilterByJobTitle}
                                    placeholder="Enter Job Title"
                                    sx={{ width: "100%" }}
                                    onChange={handleFilterByJobTitle}
                                />
                            </div>
                        )}
                    </div>

                    <div className="timesheet_reports_filter_container">
                        <div
                            className="timesheet_reports_filter_container_header"
                            style={{
                                borderBottom: isActiveUserName ? "1px solid #C4C4C4" : "none",
                                // borderBottom: true ? "1px solid #C4C4C4" : "none",
                            }}
                        >
                            <span>Employee Name</span>
                            <ArrowDropDown
                                style={{
                                    color: "#252525",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setIsActiveUsername(!isActiveUserName);
                                }}
                            />
                        </div>
                        {isActiveUserName && (
                            <div className="employees_filter_container_content">
                                <TextField
                                    id="UserName"
                                    label="Employee Name"
                                    placeholder="Enter EmployeeName"
                                    onChange={handleFilterEmployeeName}
                                    value={EmployeeName}
                                />
                            </div>
                        )}
                    </div>

                    <div className="timesheet_reports_filter_container">
                        <div
                            className="timesheet_reports_filter_container_header"
                            style={{
                                borderBottom: isActiveStatus ? "1px solid #C4C4C4" : "none",
                                // borderBottom: true ? "1px solid #C4C4C4" : "none",
                            }}
                        >
                            <span>Status</span>
                            <ArrowDropDown
                                style={{
                                    color: "#252525",
                                    cursor: "pointer",
                                }}
                                onClick={() => {
                                    setIsActiveStatus(!isActiveStatus);
                                }}
                            />
                        </div>
                        {isActiveStatus && (
                            <div className="employees_filter_container_content">
                                <TextField
                                    label="Status"
                                    select
                                    defaultValue={tempStatus[1].Status}
                                    placeholder="Enter Status"
                                    value={status}
                                    onChange={handleFilterByStatus}
                                    sx={{ width: "100%" }}
                                >
                                    {tempStatus.map((option) => (
                                        <MenuItem key={option.Status} value={option.Status}>
                                            {option.Status}
                                        </MenuItem>
                                    ))}{" "}
                                </TextField>
                            </div>
                        )}
                    </div>

                    {
                        userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU ?
                            <div className="timesheet_reports_filter_container">
                                <div
                                    className="timesheet_reports_filter_container_header"
                                    style={{
                                        borderBottom: isActiveStatus ? "1px solid #C4C4C4" : "none",
                                    }}
                                >
                                    <span>Approver</span>
                                    <ArrowDropDown
                                        style={{
                                            color: "#252525",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => {
                                            setIsActiveApprover(!isActiveApprover);
                                        }}
                                    />
                                </div>
                                {isActiveApprover && (
                                    <div className="employees_filter_container_content">
                                        <TextField
                                            label="Approver"
                                            select
                                            defaultValue={""}
                                            placeholder="Select approver"
                                            value={filterByApprover}
                                            onChange={e => setFilterByApprover(e.target.value)}
                                            sx={{ width: "100%", textTransform: "capitalize" }}
                                        >
                                            {Approver_List.map((option) => (
                                                <MenuItem key={option} value={option} style={{ textTransform: "capitalize", width: "100%" }}>
                                                    {option}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </div>
                                )}{" "}
                            </div>
                            :
                            <></>
                    }

                    <div className="timesheet_reports_customization_container">
                        <div className="timesheet_reports_customization_container_heading">
                            <span>Export Option</span>
                        </div>
                        <div className="timesheet_reports_customization_container_list">
                            {
                                tableColumns.map((item, ind) => {
                                    return (
                                        <div key={ind} className="timesheet-reports-export-content-element"
                                            onClick={() => handleSelectColumn(item)}
                                        >
                                            <input type='checkbox'
                                                checked={selectedColumns.includes(item)}
                                                onChange={() => handleSelectColumn(item)}
                                            />
                                            <span>{item.value}</span>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>
            {/* changes end */}
            <div className="timesheet_reports_main_screen">
                <div className="timesheet_reports_container_header">
                    <div className="timesheet_reports_container_header_title">
                        <span>Timesheet Reports</span>
                        {
                            timesheets.length !== 0 && (
                                <label>{timesheets.length}</label>
                            )
                        }
                    </div>
                    {
                        timesheets.length !== 0 && (
                            <div className="timesheet_reports_container_header_export_btn">
                                <div className="timesheet_reports_container_header_export">
                                    <button onClick={handleSendTimesheetReports}>Send Mail</button>
                                </div>
                                <div className="timesheet_reports_container_header_export_span">
                                    <button className="dropbtn_export">Export</button>
                                    <div className="dropdown-content_export">
                                        <ul>
                                            <li
                                                onClick={handleExportPDF}>PDF</li>
                                            <li
                                                onClick={handleExport}>Excel</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>
                <div className="timesheet_reports_container_table">
                    {
                        timesheets.length !== 0 ?
                            <table ref={tableRef}>
                                <thead>
                                    <tr>
                                        {
                                            tableColumns.map((e, index) => {
                                                return (
                                                    <>
                                                        {
                                                            selectedColumns.includes(e)
                                                                ?
                                                                <th key={index} style={{ width: e.width }}>
                                                                    <span>
                                                                        {e.name}
                                                                        {
                                                                            e.name === 'Total' || e.name === 'Project Total'
                                                                                ?
                                                                                <></>
                                                                                :
                                                                                <SortBy
                                                                                    list={timesheets}
                                                                                    columnName={e.name}
                                                                                    setEmployeeData={setTimesheets}
                                                                                    sortFilter={sortFilter}
                                                                                />
                                                                        }
                                                                    </span>
                                                                    {/* </th> : <th>{!selectedColumns.includes(e) ? <th>{e.name}</th> : <th></th>}</th> */}
                                                                </th> : <th hidden></th>
                                                        }
                                                    </>
                                                );
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        timesheets.map((e, index) => {
                                            return (
                                                <tr key={index}>
                                                     
                                                         {
                                                        selectedColumns.find(col => col.name === "PnCEmployeeId")
                                                            ?
                                                            <>{index === 0 || !areRowsSame(timesheets[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>{e.PnCEmployeeId}</td>) : <td hidden>{e.PnCEmployeeId}</td>}</>
                                                            :
                                                            <td hidden ></td>
                                                       } 
                                                    {
                                                        selectedColumns.find(col => col.name === "Emp.Name")
                                                            ?
                                                            <>{index === 0 || !areRowsSame(timesheets[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>{e.Name}</td>) : <td hidden>{e.Name}</td>}</>
                                                            :
                                                            <td hidden></td>
                                                    }                                                                                         
                                                        {/*
                                                        selectedColumns.find(col => col.name === "Email")
                                                            ?
                                                            <>{index === 0 || !areRowsSame(timesheets[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>{e.Email}</td>) : <td hidden>{e.Email}</td>}</>
                                                            :
                                                            <td ></td>
                                                      */ }                                                              
                                                    {
                                                        selectedColumns.find(col => col.name === "WeekEnding")
                                                            ?
                                                            <>{index === 0 || !areRowsSame(timesheets[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>{handleWeekEnding(e.WeekNumber, e.Year)}</td>) : <td hidden>{handleWeekEnding(e.WeekNumber, e.Year)}</td>}</>
                                                            :
                                                            <td hidden></td>
                                                    }
                                                    {
                                                        selectedColumns.find(col => col.name === "Project Code")
                                                            ? <td >{e.ProjectCode}</td>
                                                            :
                                                            <td hidden></td>
                                                    }
                                                    {
                                                        selectedColumns.find(col => col.name === "Project Name")
                                                            ? <td >{e.ProjectName}</td>
                                                            :
                                                            <td hidden></td>
                                                    }
                                                    {
                                                        selectedColumns.find(col => col.name === "Project Total")
                                                            ? <td >{e.ProjectTotal}</td>
                                                            :
                                                            <td hidden></td>
                                                    }
                                                    {
                                                        selectedColumns.find(col => col.name === "Job Title")
                                                            ? <td >{e.JobTitle}</td>
                                                            :
                                                            <td hidden></td>
                                                    }
                                                    {
                                                        selectedColumns.find(col => col.name === "Total")
                                                            ?
                                                            <>{index === 0 || !areRowsSame(timesheets[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>{e.TotalHours}</td>) : <td hidden>{e.TotalHours}</td>}</>
                                                            :
                                                            <td hidden></td>
                                                    }
                                                    {/* {
                                                        selectedColumns.find(col => col.name === "InHouse")
                                                            ? <td>NA</td>
                                                            :
                                                            <td hidden></td>
                                                    } */}

                                                    {
                                                        selectedColumns.find(col => col.name === "Submitted")
                                                            ?
                                                            <>{index === 0 || !areRowsSame(timesheets[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>
                                                                {handleDate(e.SubmittedDate).Date}
                                                                <br />
                                                                {handleDate(e.SubmittedDate).Time}
                                                            </td>) : <td hidden>{handleDate(e.SubmittedDate).Date}
                                                                <br />
                                                                {handleDate(e.SubmittedDate).Time}</td>}</>
                                                            : <td hidden></td>}
                                                    {
                                                        selectedColumns.find(col => col.name === "Status") ?
                                                            <>{index === 0 || !areRowsSame(timesheets[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>
                                                                <span
                                                                    className={
                                                                        e.Status === "Rejected"
                                                                            ? "weekly_status_rejected"
                                                                            : e.Status === "Approved"
                                                                                ? "weekly_status_approved"
                                                                                : "weekly_status_inapproval"
                                                                    }
                                                                >
                                                                    {e.Status}
                                                                </span>
                                                            </td>) : <td hidden> {e.Status}</td>}</>
                                                            :
                                                            <td hidden></td>
                                                    }
                                                    {
                                                        userData.role !== process.env.REACT_APP_ROLE_ADMIN || userData.role !== process.env.REACT_APP_ROLE_SU ?
                                                            selectedColumns.find(col => col.name === "Approver")
                                                                ?
                                                                <>{index === 0 || !areRowsSame(timesheets[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)} style={{ textTransform: "capitalize" }}>{e.Approver}</td>) : <td hidden> {e.Approver}</td>}</>
                                                                :
                                                                <td hidden></td>
                                                            : <></>
                                                    }

                                                         {/*
                                                             userData.role !== process.env.REACT_APP_ROLE_ADMIN || userData.role !== process.env.REACT_APP_ROLE_SU ?
                                                            selectedColumns.find(col => col.name === "Manager")
                                                                ?
                                                                <>{index === 0 || !areRowsSame(timesheets[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)} style={{ textTransform: "capitalize" }}>{e.Manager}</td>) : <td hidden> {e.Manager}</td>}</>
                                                                :
                                                                <td hidden></td>
                                                            : <></>
                                                        */}

                                                     {/*
                                                        selectedColumns.find(col => col.name === "Billable")
                                                            ?
                                                            <>{index === 0 || !areRowsSame(timesheets[index - 1], e) ? (<td rowSpan={countConsecutiveRowsWithSameData(timesheets, index, e)}>{e.Billable}</td>) : <td hidden>{e.Billable}</td>}</>
                                                            :
                                                            <td hidden></td>
                                                    */ }  
                                                   {
                                                        selectedColumns.find(col => col.name === "Project Start Date")
                                                            ? <td>{handleDate(e.Created_dt).Date}</td>
                                                            :
                                                            <td hidden></td>
                                                    } 

                                                    {
                                                        selectedColumns.find(col => col.name === "Project End Date")
                                                            ? <td >{ handleDate(e.ending_dt).Date}</td>
                                                            :
                                                            <td hidden></td>
                                                    } 

                                                </tr>
                                            );
                                        })
                                    }
                                </tbody>
                            </table>
                            :
                            <EmptyTable title={"No Data Found"} />
                    }
                </div>
            </div>
        </div>
    )
}

export default TimesheetReports

const tableCol = [
   
   
    {
        id: 0,
        name: "Emp.Name",
        width: "14%",
        value: "Employee Name"
    },
   
    {
        id: 1,
        name: "WeekEnding",
        width: "10%",
        value: "Week Ending"
    },
    {
        id: 11,
        name: "Project Code",
        width: "15%",
        value: "Project Code"
    },
    {
        id: 12,
        name: "Project Name",
        width: "15%",
        value: "Project Name"
    },
    {
        id: 999,
        name: "Project Total",
        width: "10%",
        value: "Project Total"
    },
    {
        id: 2,
        name: "Job Title",
        width: "15%",
        value: "Job Title"
    },
    {
        id: 3,
        name: "Total",
        width: "5%",
        value: "Total Hours"
    },

    {
        id: 8,
        name: "Submitted",
        width: "12%",
        value: "Submitted Date"
    },
    {
        id: 10,
        name: "Status",
        width: "15%",
        value: "Status"
    }
]

const tableColadmin = [
   
    {
        id: 112,
        name: "PnCEmployeeId",
        width: "14%",
        value: "PnCEmployeeId"
    },
   
    {
        id: 0,
        name: "Emp.Name",
        width: "17%",
        value: "Employee Name"
    },
    // {
    //     id: 101,
    //     name: "Email",
    //     width: "10%",
    //     value: "Email"
    // },

    {
        id: 1,
        name: "WeekEnding",
        width: "10%",
        value: "Week Ending"
    },
    {
        id: 11,
        name: "Project Code",
        width: "15%",
        value: "Project Code"
    },
    {
        id: 12,
        name: "Project Name",
        width: "18%",
        value: "Project Name"
    },
    {
        id: 999,
        name: "Project Total",
        width: "10%",
        value: "Project Total"
    },
    {
        id: 2,
        name: "Job Title",
        width: "15%",
        value: "Job Title"
    },
    {
        id: 3,
        name: "Total",
        width: "5%",
        value: "Total Hours"
    },
    {
        id: 8,
        name: "Submitted",
        width: "12%",
        value: "Submitted Date"
    },
    {
        id: 10,
        name: "Status",
        width: "15%",
        value: "Status"
    },
    {
        id: 14,
        name: "Approver",
        width: "18%",
        value: "Approver"
    },
   
    {
        id: 15,
        name: "Project Start Date",
        width: "18%",
        value: "Project Start Date"
    },
    {
        id: 17,
        name: "Project End Date",
        width: "18%",
        value: "Project End Date"
    },
    // { 
    //     id: 16,
    //     name: "Billable",
    //     width: "10%",
    //     value: "Billable"
    // },
    
    // {
    //     id: 1122,
    //     name: "Project ",
    //     width: "10%",
    //     value: "Project Name"
    // },
    
    // {
    //     id: 18,
    //     name: "Project start date",
    //     width: "10%",
    //     value: "Project start date"
    // },
    
    // {
    //     id: 19,
    //     name: "Project end date",
    //     width: "10%",
    //     value: "Project end date"
    // }
   
    
   
]

const tempStatus = [
    {
        id: 0,
        Status: "All"
    },
    {
        id: 4,
        Status: "Submitted"
    },
    {
        id: 2,
        Status: "Rejected"
    },
    {
        id: 3,
        Status: "Approved"
    },
];

function removeDuplicatesFromArray(inputArray) {
    let uniqueArray = [];
    inputArray.forEach((item) => {
        if (!uniqueArray.includes(item)) {
            uniqueArray.push(item);
        }
    });
    return uniqueArray;
}

function areRowsSame(row1, row2) {
    return (
        row1.Name === row2.Name &&
        row1.WeekNumber === row2.WeekNumber
    );
}

function countConsecutiveRowsWithSameData(timesheets, startIndex, rowData) {
    let count = 1;
    for (let i = startIndex + 1; i < timesheets.length; i++) {
        if (areRowsSame(timesheets[i], rowData)) {
            count++;
        } else {
            break;
        }
    }
    return count;
}

