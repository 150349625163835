import React from 'react'
import "./NavigationBar.css"

import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getUserData } from '../../../redux/features/UserSlice'

import { Article, PlaylistAddCheck } from '@mui/icons-material'
import HomeIcon from '@mui/icons-material/Home';

function NavigationBar() {

    const userData = useSelector(getUserData)

    return (
        <div className={'navigation_bar_main_page_mytime'}>
            <div className='navigations_mytime'>
                {
                    userData.role !== process.env.REACT_APP_ROLE_ADMIN && (
                        <NavLink to={'/mytime/home'}>
                            <HomeIcon />
                            <span>Home</span>
                        </NavLink>
                    )
                }

                {
                    (userData.role === process.env.REACT_APP_ROLE_MANAGER || userData.role === process.env.REACT_APP_ROLE_SU) && (
                        <>
                            {
                                (userData.role === process.env.REACT_APP_ROLE_SU && (userData.reportees.length !== 0
                                    || userData.allocatedEmployees.length !== 0)) ? (
                                    <NavLink to={`/mytime/my-team/home`}>
                                        <PlaylistAddCheck />
                                        <span>My Team</span>
                                    </NavLink>
                                )
                                    :
                                    <NavLink to={`/mytime/my-team/home`}>
                                        <PlaylistAddCheck />
                                        <span>My Team</span>
                                    </NavLink>
                            }
                            <NavLink to={'/mytime/reports/home'}>
                                <Article />
                                <span style={{ textAlign: "center" }}>Reports</span>
                            </NavLink>
                        </>
                    )
                }
                {
                    userData.role === process.env.REACT_APP_ROLE_ADMIN && (
                        <NavLink to={'/mytime/reports/home'}>
                            <Article />
                            <span style={{ textAlign: "center" }}>Reports</span>
                        </NavLink>
                    )
                }
            </div >
        </div>
    )
}

export default NavigationBar