import { createSlice } from '@reduxjs/toolkit'

const initialState = {

    EmployeeTimesheet: [],
    ProjectAllocatedData: [],
    EmployeeApprovals: [],
    EmployeeTimesheetReports: [],
    reportedEmployees: [],
    ProjectCodes: []

}

export const Timesheet = createSlice({
    name: 'timesheet',
    initialState,
    reducers: {

        setEmployeeTimesheet: (state, action) => {
            state.EmployeeTimesheet = action.payload
        },

        setProjectAllocatedData: (state, action) => {
            state.ProjectAllocatedData = action.payload
        },

        setEmployeeApprovals: (state, action) => {
            state.EmployeeApprovals = action.payload
        },

        setEmployeeTimesheetReports: (state, action) => {
            state.EmployeeTimesheetReports = action.payload
        },

        setReportedEmployees: (state, action) => {
            state.reportedEmployees = action.payload
        },

        setProjectCodes: (state, action) => {
            state.ProjectCodes = action.payload
        }

    },
})

export const { setEmployeeTimesheet, setEmployeeApprovals, setProjectAllocatedData, setEmployeeTimesheetReports, setReportedEmployees, setProjectCodes } = Timesheet.actions


export const getEmployeeTimesheets = (state) => state.timesheet.EmployeeTimesheet
export const getProjectAllocatedData = (state) => state.timesheet.ProjectAllocatedData
export const getEmployeeApprovals = (state) => state.timesheet.EmployeeApprovals
export const getEmployeeTimesheetReports = (state) => state.timesheet.EmployeeTimesheetReports
export const getReportedEmployees = (state) => state.timesheet.reportedEmployees
export const getProjectCodes = (state) => state.timesheet.ProjectCodes

export default Timesheet.reducer