import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    employeesList: [],
    isSubmited: false,
    profileImage: '',
    reportedBy: [],
    activeEmployee: []
}

export const employeeSlice = createSlice({
    name: 'employee',
    initialState,
    reducers: {

        setEmployeeData: (state, action) => {
            state.employeesList = action.payload
        },

        setIsSubmited: (state, action) => {
            state.isSubmited = action.payload
            setTimeout(() => {
                state.isSubmited = false
            }, 1000)
        },

        setProfileImage: (state, action) => {
            state.profileImage = action.payload
        },

        setReportedBy: (state, action) => {
            state.reportedBy = action.payload
        },

        setActiveEmployee: (state, action) => {
            state.activeEmployee = action.payload
        }

    },
})

export const { setEmployeeData, setProfileImage, setIsSubmited, setReportedBy, setActiveEmployee } = employeeSlice.actions

export const getEmployees = (state) => state.employee.employeesList

export const getProfileImage = (state) => state.employee.profileImage

export const getreportedBy = (state) => state.employee.reportedBy

export const getActiveEmployee = (state) => state.employee.activeEmployee

export default employeeSlice.reducer