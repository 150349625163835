import React from 'react'
import SubMenu from '../../Components/NavigationBar/SubMenu'
import { Outlet, useLocation } from 'react-router-dom'

function SubMenuLayout() {

    const location = useLocation('')
    return (
        <>
            {
                location.pathname.includes('reports') ?
                    <SubMenu />
                    :
                    location.pathname.includes('my-team') ?
                        <SubMenu />
                        :
                        <></>
            }
            <Outlet />
        </>
    )
}

export default SubMenuLayout