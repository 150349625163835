import React, { useState } from "react";
import "./SendMails.css";

import { useDispatch } from "react-redux";

import {
    GeneratePassword,
    LoginMessage,
    RegisterLinkMessage,
} from "../../Pages/SendMailHandler";

import {
    setLoadingScreen
} from "../../../redux/features/ActivateSlice";
import Back from "../../../Components/Back";

import { toast } from 'react-toastify'
import Messages from '../../../data/Messages.json'
import { AxiosPost } from "../../../data/utilities";

function SendMails() {

    const dispatch = useDispatch();

    const [loginMail, setLoginMail] = useState("");
    const [loginMailMessage, setLoginMailMessage] = useState({
        active: false,
    });

    const [registerMails, setRegisterMails] = useState("");
    const [registerMailMessage, setRegisterMailMessage] = useState({
        active: false,
    });

    const handleLoginEmail = (e) => {
        const EMAIAL_CHECK = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;

        if (EMAIAL_CHECK.test(e.target.value)) {
            setLoginMailMessage({
                active: false,
            });
        } else {
            setLoginMailMessage({
                active: true,
                message: "Please Provide Valid Mail Address..!",
            });
        }
        if (e.target.value.length <= 100) {
            setLoginMail(e.target.value);
        }
    }

    const handleLoginMailLink = async (e) => {
        e.preventDefault();

        if (loginMail === "" || loginMailMessage.active === true || loginMail.toLowerCase().includes("genzeon.com")) {
            toast.error(Messages.Sendmails.Sendmails_Email_Invalid, { toastId: "iemployee-toast" })
        }
        else {
            dispatch(setLoadingScreen(true));

            let isMailExists = await AxiosPost(
                `/iemployee/check-email`,
                {
                    MailAddress: e.target.login_mail.value,
                }
            );

            if (isMailExists.data.isSuccess) {
                dispatch(setLoadingScreen(false));
                toast.error(Messages.Sendmails.Sendmails_Email_Exist, { toastId: "iemployee-toast" })
            }
            else {
                let password = GeneratePassword();

                let res = await AxiosPost(
                    `/mail/send-mail`,
                    {
                        toMail: loginMail,
                        toSubject: "Guest Login - iEmployee",
                        toMessage: LoginMessage(loginMail, loginMail, password),
                    }
                );

                if (res.data.isSuccess) {
                    await AxiosPost(
                        `/iemployee/insertuser`,
                        {
                            username: loginMail,
                            password: password,
                            email: loginMail.toLowerCase(),
                            countryCode: "1",
                            phoneNumber: "0000000000",
                            org: loginMail.split("@")[1].split(".")[0],
                            role: 3,
                            IsApproved: 1,
                        }
                    );
                    dispatch(setLoadingScreen(false));
                    setLoginMail('')
                    toast.success(Messages.Sendmails.Sendmails_Success, { toastId: "iemployee-toast" })
                } else {
                    dispatch(setLoadingScreen(false));
                    toast.error(Messages.Sendmails.Sendmails_Email_Invalid, { toastId: "iemployee-toast" })
                }
            }
        }
    }

    const handleRegisterMail = (e) => {
        const EMAIAL_CHECK = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;

        if (EMAIAL_CHECK.test(registerMails)) {
            setRegisterMailMessage({
                active: false,
            });
        } else {
            setRegisterMailMessage({
                active: true,
                message: "Invalid Mail is Provided..!",
            });
        }
        setRegisterMails(e.target.value);
    }

    const handleRegisterMailLink = async (e) => {
        e.preventDefault();

        if (registerMails === "" || registerMailMessage.active === true) {
            toast.error(Messages.Sendmails.Sendmails_Fail, { toastId: "iemployee-toast" })
        }
        else {
            dispatch(setLoadingScreen(true));

            let selected_mails = registerMails.split(";")

            for (let i = 0; i < selected_mails.length; i++) {

                let res = await AxiosPost(
                    `/mail/send-mail`,
                    {
                        toMail: selected_mails[i],
                        toSubject: "iEmployee - Registeration",
                        toMessage: RegisterLinkMessage,
                    }
                );

                if (res.data.isSuccess) {
                    toast.success(Messages.Sendmails.Sendmails_Success, { toastId: "iemployee-toast" })
                } else {
                    toast.error(`${selected_mails[i].mail.trim()} ${Messages.Sendmails.Sendmails_Mail_Invalid}`, { toastId: "iemployee-toast" })
                }

            }
            setRegisterMails('')
            dispatch(setLoadingScreen(false));

        }
    }

    return (
        <div className="sendmail_container">
            <Back />
            {/* header */}
            <div className="sendmail_header">
                {/* back Button */}
                {/* <div className='send_mail_header_goback_btn' onClick={() => navigate('/users')}>
                    <ArrowBack fontSize='large' />
                </div> */}
                {/* Employee Name */}
                <div className="client_header_title">
                    <span>Send Mail</span>
                </div>
            </div>

            {/* Send Mail Dialog */}
            <div className="send_mail_fiex_div">
                <form
                    className="send_mail_login_link"
                    onSubmit={handleLoginMailLink}
                    autoComplete="off"
                >
                    <div className="send_mail_login_header">
                        <span>
                            <b>Login Link</b>
                        </span>

                        <div className="send_mail_login_input_box">
                            <div className="change_password_container_span">
                                <label>Enter Mail Address</label>
                                <input
                                    className="login_link_abc_MailAdress"
                                    type={"text"}
                                    placeholder="Enter Mail Id"
                                    name="login_mail"
                                    id="login_link"
                                    value={loginMail}
                                    onChange={handleLoginEmail}
                                />
                                {loginMailMessage.active && (
                                    <small style={{ color: "red", padding: "10px" }}>
                                        {loginMailMessage.message}
                                    </small>
                                )}
                            </div>
                            <div className="send_mail_login_instructions">
                                <span>Note</span>
                                <div className="sendmail_textarea">
                                    By Providing Mail here it will create Guest Login Credentails.
                                    <br></br>
                                    It Doesn't need Approval from anyone.
                                    <br></br>
                                    Username and Password will be mentioned in Mail...
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sendmail_container_navigations">
                        {/* <div className="sendmail_container_navigation_btn"> */}
                        <div className="send_mail_login_btn">
                            <input type={"submit"} value={"Send"} />
                        </div>
                    </div>
                </form>

                <form
                    className="send_mail_login_link_two"
                    onSubmit={handleRegisterMailLink}
                    autoComplete="off"
                >
                    <div className="send_mail_login_header_two">
                        <span>
                            <b>Registration Link</b>
                        </span>

                        <div className="send_mail_login_input_box">
                            <div className="send_mail_login_input_box_registration">
                                <label htmlFor="login_link">Enter Mail Addresses</label>
                                <textarea
                                    className="registration_link_textarea"
                                    cols={40}
                                    rows={10}
                                    placeholder="Mail Addresses"
                                    value={registerMails}
                                    onChange={handleRegisterMail}
                                />
                                {registerMailMessage.active && (
                                    <small style={{ color: "red", padding: "10px" }}>
                                        {registerMailMessage.message}
                                    </small>
                                )}
                            </div>
                            <div className="send_mail_login_instructions">
                                <span>Note</span>
                                <div className="sendmail_textarea">
                                    Mails are separated by SEMI-COLAN [ ; ]
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="sendmail_container_navigations">
                        <div className="sendmail_container_navigation_btn">
                            <div className="send_mail_login_btn_clear">
                                <input type={"submit"} value={"Clear"} />
                            </div>
                            <div className="send_mail_login_btn">
                                <input type={"submit"} value={"Send"} />
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default SendMails;
