import React, { useEffect, useState } from 'react'
import "./DownloadReport.css"

import { jsPDF } from 'jspdf'
import canvas from 'html2canvas'
import DownloadImage from '../../../assets/download-icon.png'
import GenzeonLogo from '../../../assets/genzeon-logo-2.png'
import ClipLoader from "react-spinners/ClipLoader"

function DownloadProfile({ mode, profiles, isDisplay, setIsDisplay }) {

    const [Base64Images, setBase64Images] = useState([])

    useEffect(() => {

        const handleGetImage = async () => {

            setBase64Images([])
            let values = []

            for (let i = 0; i < profiles.length; i++) {

                let inputElement = document.getElementById(profiles[i])
                let data = await canvas(inputElement, {
                    allowTaint: true,
                    useCORS: true,
                    scale: 2
                })

                values.push({
                    image: data.toDataURL('image/png'),
                    height: inputElement.clientHeight,
                    width: inputElement.clientWidth
                })

            }

            setTimeout(() => {
                setIsDisplay(false)
                setBase64Images(values)
            }, 50)

        }

        if (isDisplay) {
            handleGetImage()
        }
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDisplay])

    const handleDownload = () => {
        if (mode === 'p') {
            let pdf = new jsPDF('p', 'px', 'a4');
            pdf.setFontSize(12)
            Base64Images.forEach((e, index) => {
                let height = ((e.height * 350) / e.width)
                pdf.addImage(e.image, 'JPEG', 30, 40, 350, height)
                pdf.addImage(GenzeonLogo, 'JPEG', 325, 600, 120, 35)
                pdf.text(`Page - ${index + 1}`, 10, 620)
                if (Base64Images.length - 1 !== index) {
                    pdf.addPage()
                }
                if (Base64Images.length - 1 === index) {
                    pdf.save("Employee Profile Report portrait view")
                }
            })
        }
        else {
            let pdf = new jsPDF('landscape', 'px', 'a4');
            pdf.setFontSize(12)
            Base64Images.forEach((e, index) => {
                let height = ((e.height * 600) / e.width)
                pdf.addImage(e.image, 'JPEG', 10, 40, 600, height)
                pdf.addImage(GenzeonLogo, 'JPEG', 500, 420, 120, 35)
                pdf.text(`Page - ${index + 1}`, 10, 420)
                if (Base64Images.length - 1 !== index) {
                    pdf.addPage()
                }
                if (Base64Images.length - 1 === index) {
                    pdf.save("Employee Profile Report landscape view")
                }
            })
        }
    }

    return (
        <>
            {
                isDisplay ?
                    <div className='employee_profile_download_btn'>
                        <ClipLoader
                            color={"#fffff"}
                            size={20}
                            aria-label="Loading Spinner"
                            data-testid="loader"
                        />
                    </div>
                    :
                    <button className='employee_profile_download_btn' onClick={handleDownload}>
                        <img src={DownloadImage} alt='download' width={22} />
                        Download
                    </button>
            }
        </>
    )
}

export default DownloadProfile 