import { React, useEffect, useState } from "react";
import "./ProjectCodeGenerator.css"

import { getInitialValues, setInitialValues } from "../../../redux/features/InitialSlice";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setLoadingScreen } from "../../../redux/features/ActivateSlice";
import { getUserData } from "../../../redux/features/UserSlice";
import { FormControl, InputLabel, MenuItem, Select, TextField, Autocomplete } from "@mui/material";
import { AxiosGet, AxiosPost, getCurrentDate } from "../../../data/utilities";
import Messages from "../../../data/Messages.json";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

function GenerateProjectCode() {

    const initialData = useSelector(getInitialValues)
    const userData = useSelector(getUserData)
    const dispatch = useDispatch()

    const [selectedClient, setSelectedClient] = useState('Select Client Name');
    const [shortCode, setShortCode] = useState('');

    const [projectName, setProjectName] = useState('')

    const [projectCodes, setProjectCodes] = useState('');
    const [expiryDate, setExpiryDate] = useState(null)

    const [practice, setPractice] = useState("Select Practice")
    const [market, setMarket] = useState("Select Market")
    const [incomeclass, setIncomeClass] = useState("Select IncomeClass")

    const [Markets, setMarkets] = useState([]);
    const [IncomeClasses, setIncomeClasses] = useState([]);

    const [projectCode, setProjectCode] = useState("")

    const [isError, setIsError] = useState({
        isClient: false,
        isClientCode: false,
        isProject: false,
        isMarket: false,
        isPracitce: false,
        isIncomeClass: false
    })

    useEffect(() => {
        if (initialData.length !== 0) {
            setProjectCodes(initialData.ProjectCodes)
        }
    }, [initialData])

    useEffect(() => {
        AxiosGet(`/timesheet/get-markets`).then(
            (result) => {
                if (result.data.isSuccess) {
                    setMarkets(result.data.Markets);
                }
            }
        );
        AxiosGet(
            `/timesheet/get-income-classes`
        ).then((result) => {
            if (result.data.isSuccess) {
                setIncomeClasses(result.data.InComeClasses);
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        let filterMarket = "";
        if (market !== "") {
            Markets.forEach((item, index) => {
                if (item.Market === market) {
                    filterMarket = index + 1;
                }
            });
        }

        let filterpractice = "";
        if (practice !== "") {
            initialData.PracticeAreas.forEach((item, index) => {
                if (item.PracticeArea === practice) {
                    filterpractice = index + 1;
                }
            });
        }

        let filterIncomeclass = "";
        if (incomeclass !== "") {
            IncomeClasses.forEach((item, index) => {
                if (item.Incomeclass === incomeclass) {
                    filterIncomeclass = index + 1;
                }
            });
        }

        if (shortCode !== "" &&
            filterMarket !== 0 &&
            filterpractice !== 0 &&
            filterIncomeclass !== 0) {

            let projectCreation = shortCode.toString().toUpperCase() + filterMarket + filterpractice + filterIncomeclass;

            const projectNumber = projectCodes.filter(item => item.Typename === 'PROJECT').filter(item => item.Client === selectedClient).length + 1;
            // Append the project number to the project code
            const finalProjectCode = `${projectCreation}P${projectNumber}`;

            setProjectCode(finalProjectCode);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [market, practice, incomeclass, initialData, selectedClient, shortCode]);

    const handleGenerateProjectCode = async () => {

        if (
            selectedClient === "Select Client Name" ||
            shortCode === "" ||
            market === "Select Market" ||
            practice === "Select Practice" ||
            incomeclass === "Select IncomeClass" ||
            projectName === ''
        ) {
            toast.error(Messages.Assign_ProjectCodes.AllocationProvideFields, { toastId: "iEmployee-id" });

            let values = isError

            if (selectedClient === "Select Client Name") {
                values = { ...values, 'isClient': true }
            }
            if (shortCode === "") {
                values = { ...values, 'isClientCode': true }
            }
            if (projectName === '') {
                values = { ...values, 'isProject': true }
            }
            if (market === "Select Market") {
                values = { ...values, 'isMarket': true }
            }
            if (practice === "Select Practice") {
                values = { ...values, 'isPracitce': true }
            }
            if (incomeclass === "Select IncomeClass") {
                values = { ...values, 'isIncomeClass': true }
            }

            setIsError(values)

        } else {
            dispatch(setLoadingScreen(true))
            let result = await AxiosPost(`/timesheet/generate-project-code`, {
                ClientName: selectedClient,
                ClientCode: shortCode.toString().toUpperCase(),
                ProjectName: projectName,
                ProjectCode: projectCode,
                ExpiryDate: expiryDate === null ? '9999-12-31' : expiryDate,
                FKEmpId: userData.Id
            })

            dispatch(setLoadingScreen(false))

            if (result.data.isSuccess) {
                handleClear()
                dispatch(setInitialValues({ ...initialData, "ProjectCodes": result.data.AllocatedProjectCodes }))
                toast.success(result.data.Message, { toastId: "iEmployee-id" });
            }
            else {
                toast.error(result.data.Message, { toastId: "iEmployee-id" });
            }
        }
    }

    const handleValiadteClient = Array.from(
        new Set(
            initialData.ProjectCodes
                .filter(item => item.Client)
                .map(option => option.Client)
        )
    ).map(client => ({ Client: client }));

    const handleClear = () => {


        setSelectedClient("Select Client Name")
        setShortCode("")
        setProjectName('')
        setProjectCode('')

        setMarket('Select Market')
        setPractice('Select Practice')
        setIncomeClass('Select IncomeClass')

    }
    
    return (
        <div className="generate_project_code_container">
            <div className="generate_project_code_container_header">
                <div className="generate_project_code_container_header_title">
                    <span>Project Code Generation</span>
                </div>
            </div>
            {/* generate_project_code */}
            <div className="generate_project_code_flex_div">
                <div className="generate_project_code">
                    <div className="generate_project_code_header">
                        {/* client Name  & Client code */}
                        <div className="generate_project_code_input_box">
                            <div className="generate_project_code_container_span">
                                <Autocomplete
                                    required
                                    onChange={(event, newValue) => {
                                        setSelectedClient(newValue === null ? "Select Client Name" : newValue.Client);
                                        const selectedOption = initialData.ProjectCodes.find(
                                            option =>
                                                option.Client === (newValue === null ? "" : newValue.Client)
                                        );
                                        setShortCode(selectedOption ? selectedOption.ShortCode : '');
                                    }}
                                    options={handleValiadteClient}
                                    getOptionLabel={(option) => {
                                        if (option.Client === undefined) {
                                            return option
                                        }
                                        else {
                                            return option.Client
                                        }
                                    }}
                                    sx={{ width: '100%' }}
                                    value={selectedClient}
                                    filterOptions={(options, { inputValue }) => {
                                        if (!inputValue) {
                                            return [];
                                        }
                                        return options.filter(
                                            (option) =>
                                                option.Client.toLowerCase().includes(inputValue.toLowerCase())
                                        );
                                    }}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            label="Client Name"
                                            onChange={(e) => setSelectedClient(e.target.value)}
                                        />
                                    )}
                                />
                            </div>
                            <div className="generate_project_code_container_span">
                                <TextField
                                    required
                                    type="text"
                                    label='Client Short Code'
                                    sx={{ width: "100%" }}
                                    onChange={(e) => setShortCode(e.target.value)}
                                    value={shortCode}
                                    placeholder="Enter Client Code"
                                />

                            </div>
                        </div>

                        {/* Markets & Practices */}
                        <div className="generate_project_code_input_box">
                            <div className="generate_project_code_container_span">
                                <TextField
                                    required
                                    type="text"
                                    placeholder='Enter Project Name'
                                    label='Project Name'
                                    sx={{ width: "100%" }}
                                    value={projectName}
                                    error={isError.isProject}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 50) {
                                            setProjectName(e.target.value)
                                            setIsError({ ...isError, 'isProject': false })
                                        }
                                    }}
                                />
                            </div>
                            <div className="generate_project_code_container_span">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Markets*</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={market}
                                        error={isError.isMarket}
                                        onChange={(e) => {
                                            if (selectedClient !== 'Select Client Name') {
                                                setMarket(e.target.value)
                                                setIsError({ ...isError, 'isMarket': false })
                                            }
                                            else {
                                                toast.warning("Select client first before selecting market", { toastId: "iemployee-toast", });
                                            }
                                        }}
                                        label="Markets"
                                        sx={{ width: "100%" }}

                                    >
                                        <MenuItem value={"Select Market"}>Select Market</MenuItem>
                                        {Markets.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.Market}>
                                                    {item.Market}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>

                        <div className="generate_project_code_input_box">
                            <div className="generate_project_code_container_span">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Practice*</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={practice}
                                        error={isError.isPracitce}
                                        onChange={(e) => {
                                            if (market !== 'Select Market') {
                                                setPractice(e.target.value)
                                                setIsError({ ...isError, 'isPracitce': false })
                                            }
                                            else {
                                                toast.warning("Select market first before selecting practice.", {
                                                    toastId: "iemployee-toast",
                                                })
                                            }
                                        }}
                                        label="Practice"
                                        sx={{ width: "100%" }}

                                    >
                                        <MenuItem value={"Select Practice"}>Select Practice</MenuItem>
                                        {initialData.PracticeAreas.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.PracticeArea}>
                                                    {item.PracticeArea}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            <div className="generate_project_code_container_span">
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Income Classes*</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={incomeclass}
                                        error={isError.isIncomeClass}
                                        onChange={(e) => {
                                            if (practice !== 'Select Practice') {
                                                setIncomeClass(e.target.value)
                                                setIsError({ ...isError, 'isIncomeClass': false })
                                            }
                                            else {
                                                toast.warning("Select practice first before selecting income class", {
                                                    toastId: "iemployee-toast",
                                                });
                                            }
                                        }}
                                        label="Income Classes"
                                        sx={{ width: "100%" }}

                                    >
                                        <MenuItem value={"Select IncomeClass"}>
                                            Select IncomeClass
                                        </MenuItem>
                                        {IncomeClasses.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.Incomeclass}>
                                                    {item.Incomeclass}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="generate_project_code_input_box">
                            <div className="generate_project_code_container_span">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        label="Expiry Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={expiryDate}
                                        onChange={(value) => {
                                            const dt = new Date(value)
                                            if (dt.getTime() > getCurrentDate().getTime()) {
                                                setExpiryDate(value)
                                            }
                                            else {
                                                toast.warning("Please select the proper expiry date", {
                                                    toastId: "iemployee-toast",
                                                });
                                            }
                                        }}
                                        renderInput={(params) => <TextField size='small' name='expiry_date' {...params} sx={{ width: "100%", height: "35px !important" }} />}
                                    />
                                </LocalizationProvider>
                            </div>
                                <div className="generate_project_code_container_span">
                                        <TextField
                                            required
                                            type="text"
                                            label='Project Code'
                                            sx={{ width: "100%" }}
                                            onChange={(e) => setProjectCode(e.target.value)}
                                            value={projectCode}
                                            placeholder="ProjectCode"
                                            disabled
                                        />

                            </div>
                        </div>

                    </div>
                    <div className="generate_project_code_container_navigations">
                        <div className="generate_project_code_btn">
                            <button onClick={handleGenerateProjectCode}>Generate</button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default GenerateProjectCode