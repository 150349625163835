import React from 'react'
import "./AlertYesNo.css"
import { Info, GppBad, CheckCircle, Warning } from '@mui/icons-material'

function AlertYesNo({ isActive, setIsActive, variant, message, handleLogout }) {
    return (
        <>
            {
                isActive && (
                    <div className='alert-with-method-container'>
                        <div className='alert_with_method' style={{
                            background: variant === 'success' ? '#9BF78C' : variant === 'error' ? '#F5A4AF' : variant === 'warning' ? '#FDE48C' : "#014361",
                            color: "#000000"
                        }}>
                            <div className='alert_with_method_message'>
                                {
                                    variant === '' || variant === undefined || variant === 'info' ?
                                        <Info />
                                        :
                                        <>
                                            {
                                                variant === 'error' ?
                                                    <GppBad />
                                                    :
                                                    <>
                                                        {
                                                            variant === 'success' ?
                                                                <CheckCircle />
                                                                :
                                                                <Warning />
                                                        }
                                                    </>
                                            }
                                        </>
                                }
                                <span>{message}</span>
                            </div>
                            <div className='alert_with_method_btn'>
                                <button onClick={handleLogout}>Yes</button>
                                <button onClick={() => {
                                    setIsActive(false)
                                }}>No</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default AlertYesNo