import React, { useState } from 'react' 
import "./ModifyUser.css"

import { Autocomplete, TextField } from '@mui/material'

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { getUserData, setUserLoggedInData } from '../../../redux/features/UserSlice'
import { getInitialValues } from '../../../redux/features/InitialSlice'
import { setLoadingScreen } from '../../../redux/features/ActivateSlice'
import { handleUserStatus } from '../../Pages/UserStatus';
import Back from '../../../Components/Back'

import { toast } from 'react-toastify'
import Messages from '../../../data/Messages.json'


function ModifyUser({ RefreshContent }) {

    const initialData = useSelector(getInitialValues)
    const userData = useSelector(getUserData)
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const  [status,setStatus] = useState()
    const [email, setEmail] = useState('')
    const [isEmailCheck, setIsEmailCheck] = useState('')


    const handleEmail = (e) => {
        if (e.target.value === "") {
            setIsEmailCheck(false);
        }
        if (e.target.value.length <= 50) {
            setEmail(e.target.value);
        }
    };



    const handleSubmit = async () => {

        if (email === '' || status === '') 
        { 
            toast.error(Messages.BuildProfile.BuildProfile_Provide_Details, { toastId: "iemployee-toast" })
        }
        else { 
            dispatch(setLoadingScreen(true))
            let result = handleUserStatus(
                email,
                userData,
                dispatch,
                setUserLoggedInData,
                RefreshContent,
                navigate,
                setLoadingScreen,                
                initialData.Employees,
                StatusColumn.filter((item) => item.name === status)[0].value
            )
            if (result) {
                alert('User Status Updated')
   
            }
            else{
                alert('Error in adding the details')
            }
        }

    }


    return (
        <>
            <div className='build_profile'>
                <Back title={"/myprofile/employees"} />
                {/* header */}
                <div className='build_profile_header'>
                    <div className='build_profile_header_name'>
                        <span>Remove User</span>
                    </div>
                </div>

                <form className='build_profile_container' autoComplete='off'>
                    {/* Email and Status*/}
                    <div className="build_profile_span_1">

                    <div className='build_profile_span_field_input'>
                            <TextField
                                required
                                size='small'
                                label='Email'
                                value={email}
                                onChange={handleEmail}
                                sx={{ width: "95%" }}
                            />
                            <small className={isEmailCheck ? "build_profile_error_show" : "build_profile_error_hide"}>Email doesn't Match</small>
                        </div>

                        <div className="build_profile_span_field">
                            <Autocomplete
                                onChange={(name, value) => setStatus(value)}
                                options={StatusColumn.map((options) => options.name)}
                                sx={{ width: "95%" }}
                                value={status}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name="Status"
                                        required
                                        label="Status"
                                        size="small"
                                        defaultValue={'Inactive'}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='build_profile_btn' align='center'>
                        <input
                            type={'submit'}
                            value={"Set Status"}
                            onClick={handleSubmit}
                        />
                    </div>

                </form>

            </div>
        </>
    )
}

export default ModifyUser;


const StatusColumn = [
  {
    name: "Inactive",
    value: 0,
  },
  {
    name: "Active",
    value: 1,
  },
]