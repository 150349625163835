export const RandomColor = (value) => {

    if (value.toString().toLowerCase() === 'c' || value.toString().toLowerCase() === 'f' || value.toString().toLowerCase() === 'x' || value.toString().toLowerCase() === 'l' || value.toString().toLowerCase() === 'p' || value.toString().toLowerCase() === 't') {
        return "#D2FFDC"
    }
    else if (value.toString().toLowerCase() === 'e' || value.toString().toLowerCase() === 'r' || value.toString().toLowerCase() === 'v' || value.toString().toLowerCase() === 'h' || value.toString().toLowerCase() === 'n') {
        return "#FFEAD2"
    }
    else if (value.toString().toLowerCase() === 's' || value.toString().toLowerCase() === 'y' || value.toString().toLowerCase() === 'b' || value.toString().toLowerCase() === 'j' || value.toString().toLowerCase() === 'm') {
        return "#D2DFFF"
    }
    else if (value.toString().toLowerCase() === 'a' || value.toString().toLowerCase() === 'g' || value.toString().toLowerCase() === 'o' || value.toString().toLowerCase() === 'u' || value.toString().toLowerCase() === 'z') {
        return "#FFDFE5"
    }
    else {
        return "#C6F3F9"
    }

}