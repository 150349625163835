import React, { useEffect, useState } from "react";
import "./EmployeeReview.css";

import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CryptoJS from "crypto-js";

import {
    setLoadingScreen
} from "../../../redux/features/ActivateSlice";
import {
    getInitialValues
} from "../../../redux/features/InitialSlice";

import Back from '../../../Components/Back'

import { toast } from 'react-toastify'
import Messages from '../../../data/Messages.json'
import { AxiosPost } from "../../../data/utilities";

function EmployeeReview({ RefreshContent }) {

    const initialData = useSelector(getInitialValues)

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const empData = useParams();

    const [oldVersion, setOldVersion] = useState([]);
    const [oldDomains, setOldDomains] = useState([]);
    const [oldClients, setOldClients] = useState([]);

    const [inApprove, setInApprove] = useState([]);
    const [tempDomains, setTempDomains] = useState([]);
    const [tempClients, setTempClients] = useState([]);

    const [commentAlert, setCommentAlert] = useState({
        isActive: false
    });

    const [comment, setComment] = useState("");

    const [ParamsValue, setParamsValue] = useState([]);

    useEffect(() => {
        let value = JSON.parse(
            CryptoJS.AES.decrypt(
                empData.employeeData.split("|").join("/"),
                process.env.REACT_APP_SECRECT_KEY
            ).toString(CryptoJS.enc.Utf8)
        );
        setParamsValue(value);
        AxiosPost(
            `/temp-iemployee/get-temp-employee-by-id`,
            {
                Id: value.Id
            }
        ).then((employee) => {
            setInApprove(employee.data.TempEmployee);
        });

        AxiosPost(
            `/temp-iemployee/get-temp-employee-client-by-emp-id`,
            {
                Id: value.Id
            }
        ).then((result) => {
            if (result.data.isSuccess) {
                setTempClients(result.data.ClientId);
            }
        });

        AxiosPost(
            `/temp-iemployee/get-temp-employee-domain-by-id`,
            {
                Id: value.Id
            }
        ).then((result) => {
            if (result.data.isSuccess) {
                setTempDomains(result.data.temp_employee_domain);
            }
        });

        // // FkEmployeeId  // post request
        if (value.FK_EmployeeId !== null) {
            AxiosPost(`/iemployee/getbyidemployee`, {
                id: value.FK_EmployeeId
            }).then((Employee) => {
                if (Employee.data.isSuccess) {
                    setOldVersion(Employee.data.Employee);
                }
            });

            // Get Employee Domains
            AxiosPost(
                `/iemployee/get-employee-domains-by-id`,
                {
                    Id: value.FK_EmployeeId
                }
            ).then((result) => {
                setOldDomains(result.data.Domains);
            });

            // Get Employee Client
            AxiosPost(
                `/iemployee/get-employee-client-ByEmployee`,
                {
                    FKId: value.FK_EmployeeId
                }
            ).then((result) => {
                if (result.data.isSuccess) {
                    setOldClients(result.data.ClientId);
                }
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [empData])

    const handleApproved = async () => {
        if (comment === "") {
            toast.error(Messages.EmployeeReview.EmployeeReview_Comment, { toastId: "iemployee-toast" })
        } else {
            dispatch(setLoadingScreen(true));
            let result = await AxiosPost(
                `/temp-iemployee/approve-employee`,
                {
                    Id: inApprove[0].Id,
                    Comment: comment,
                    UpdatedStatus: "Approved",
                    Email: inApprove[0].Email,
                    FKEmpId: inApprove[0].Fk_EmployeeId
                }
            );

            if (result.data.isSuccess) {
                RefreshContent();

                // Send Mail for Approval

                navigate("/myprofile/employees");
                dispatch(setLoadingScreen(false));
                setCommentAlert(false);
                setComment("");
                toast.success(Messages.EmployeeReview.EmployeeReview_Profile_Added, { toastId: "iemployee-toast" })
            }
        }
    }

    const handleRejected = async () => {

        if (comment === "") {
            toast.error(Messages.EmployeeReview.EmployeeReview_Comment, { toastId: "iemployee-toast" })
        } else {
            dispatch(setLoadingScreen(true));

            let result = await AxiosPost(
                `/temp-iemployee/reject-employee`,
                {
                    Id: inApprove[0].Id,
                    comment: comment,
                    updatedStatus: "Rejected"
                }
            );

            if (result.data.isSuccess) {
                RefreshContent();

                // Send Mail for Approval

                navigate("/myprofile/employees/approval");
                dispatch(setLoadingScreen(false));
                setCommentAlert(false);
                setComment("");
                toast.success(Messages.EmployeeReview.EmployeeReview_Profile_Rejected, { toastId: "iemployee-toast" })
            }
        }
    }

    const handleSubmit = (value) => {
        if (value === "approve") {
            setCommentAlert({ isActive: true, task: "approve" });
        } else {
            setCommentAlert({ isActive: true, task: "rejected" });
        }
    }

    return (
        <>
            {
                inApprove.length !== 0 && (
                    <>
                        <div className="employee-review-container">
                            <Back title={'/myprofile/employees/approval'} />
                            <div className="employee-review-header">
                                <span>Employee Profile Review</span>
                            </div>
                            <div className="employee-review-actions">
                                <div className="employee-review-actions-comment-container">
                                    <div className="employee-review-actions-comment-header">
                                        <span>Comment</span>
                                    </div>
                                    <div className="employee-review-actions-comment-content">
                                        <p>{inApprove[0].Comment}</p>
                                    </div>
                                </div>
                                <div className="employee-review-actions-btns">
                                    <button onClick={() => handleSubmit("approve")}>Approve</button>
                                    {ParamsValue.isApprove === 0 && (
                                        <button onClick={() => handleSubmit("rejected")}>Reject</button>
                                    )}
                                </div>
                            </div>
                            {/* oldVersion[0].FirstName */}
                            {/* inApprove[0].FirstName */}
                            {/* item.Certifications.split(';') */}
                            {/* item.CareerHighlights.split('@$') */}
                            <div className="employee-review-content">
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                TableCols.map((item, index) => {
                                                    return <td key={index} style={{ width: item.width }}>{item.name}</td>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {/* Firstname */}
                                        <tr>
                                            <td>First Name</td>
                                            <td>{oldVersion.length === 0 ? "No Profile Exists" : oldVersion[0].FirstName}</td>
                                            <td>{inApprove[0].FirstName}</td>
                                        </tr>

                                        {/* Lastname */}
                                        <tr>
                                            <td>Last Name</td>
                                            <td>{oldVersion.length === 0 ? "No Profile Exists" : oldVersion[0].LastName}</td>
                                            <td>{inApprove[0].LastName}</td>
                                        </tr>

                                        {/* Email */}
                                        <tr>
                                            <td>Email Address</td>
                                            <td>{oldVersion.length === 0 ? "No Profile Exists" : oldVersion[0].Email}</td>
                                            <td>{inApprove[0].Email}</td>
                                        </tr>

                                        {/* PnC Employee ID */}    
                                        <tr>                                
                                            <td>PnC Employee ID</td>
                                            <td>{oldVersion.length === 0 ? "No Profile Exists" : oldVersion[0].PnCEmployeeId}</td>
                                            <td>{inApprove[0].PnCEmployeeId}</td>
                                        </tr>

                                        {/* Phone Number */}
                                        <tr>
                                            <td>Phone Number</td>
                                            <td>{oldVersion.length === 0 ? "No Profile Exists" : oldVersion[0].PhoneNumber}</td>
                                            <td>{inApprove[0].PhoneNumber}</td>
                                        </tr>

                                        {/* Client */}
                                        <tr>
                                            <td>Client</td>
                                            <td> {oldClients.length !== 0 && (
                                                <>
                                                    {
                                                        initialData.Clients.length !== 0 &&
                                                        initialData.Clients.filter(
                                                            (item) => item.Id === oldClients[0].FK_ClientId)[0].ClientName
                                                    }
                                                </>
                                            )}</td>
                                            <td> {
                                                tempClients.length !== 0 && (
                                                    <>
                                                        {
                                                            initialData.Clients.length !== 0 &&
                                                            initialData.Clients.filter(
                                                                (item) => item.Id === tempClients[0].FK_ClientId)[0].ClientName
                                                        }
                                                    </>)
                                            }</td>
                                        </tr>

                                        {/* Billable */}
                                        {/* <tr>
                                            <td>Billable</td>
                                            <td>{
                                                oldClients.length !== 0 &&
                                                (
                                                    oldClients[0].Billable === 0 ? "No" : "Yes"
                                                )
                                            }</td>
                                            <td> {
                                                tempClients.length !== 0 &&
                                                (
                                                    tempClients[0].Billable === 0 ? "No" : "Yes"
                                                )
                                            }</td>
                                        </tr> */}

                                        {/* Designation */}
                                        <tr>
                                            <td>Designation</td>
                                            <td>
                                                {
                                                    oldVersion.length === 0 ? "No Profile Exists" : <>
                                                        {
                                                            initialData.Designations.map((e) => {
                                                                return e.Id === oldVersion[0].FK_DesignationId
                                                                    ? e.Designation
                                                                    : "";
                                                            })
                                                        }
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    initialData.Designations.map((e) => {
                                                        return e.Id === inApprove[0].FK_DesignationId
                                                            ? e.Designation
                                                            : "";
                                                    })
                                                }</td>
                                        </tr>

                                        {/* Domains */}
                                        <tr>
                                            <td>Domains</td>
                                            <td>
                                                {
                                                    oldDomains.length === 0 ? "No Domains Exists" :
                                                        <>
                                                            {
                                                                oldDomains.map((item, index) => {
                                                                    return (
                                                                        <>{item.DomainExperience} in {initialData.Domains.filter(items => items.Id === item.FK_DomainId)[0].Domain} </>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                }
                                            </td>
                                            <td>{tempDomains.length === 0 ? "No Domains Exists" :
                                                <>
                                                    {
                                                        tempDomains.map((item, index) => {
                                                            return (
                                                                <>{item.DomainExperience} in {initialData.Domains.filter(items => items.Id === item.FK_DomainId)[0].Domain} </>
                                                            )
                                                        })
                                                    }
                                                </>
                                            }</td>
                                        </tr>

                                        {/* Education */}
                                        <tr>
                                            <td>Education</td>
                                            <td>
                                                {
                                                    oldVersion.length === 0 ? "No Profile Exists" :
                                                        <>
                                                            {initialData.Educations.map((e) => {
                                                                return e.Id === oldVersion[0].FK_HighestEducationId
                                                                    ? e.Education
                                                                    : "";
                                                            })}
                                                        </>
                                                }

                                            </td>
                                            <td>{initialData.Educations.map((e) => {
                                                return e.Id === inApprove[0].FK_EducationId
                                                    ? e.Education
                                                    : "";
                                            })}</td>
                                        </tr>

                                        {/* Specialization */}
                                        <tr>
                                            <td>Specialization</td>
                                            <td style={{ textTransform: "uppercase" }}>{oldVersion.length === 0 ? "No Profile Exists" : oldVersion[0].Specialization}</td>
                                            <td style={{ textTransform: "uppercase" }}>{inApprove[0].Specialization}</td>
                                        </tr>

                                        {/* Location */}
                                        <tr>
                                            <td>Location</td>
                                            <td>{oldVersion.length === 0 ? "No Profile Exists" : (oldVersion[0].Location === null ? "NA" : LocationColumn.filter(item => item.value === oldVersion[0].Location)[0].name)}</td>
                                            <td>{inApprove.length === 0 ? "No Profile Exists" : (inApprove[0].Location === null ? "NA" : LocationColumn.filter(item => item.value === inApprove[0].Location)[0].name)}</td>
                                        </tr>

                                        {/* certifications */}
                                        <tr>
                                            <td>Certifications</td>
                                            <td style={{ textTransform: "capitalize" }}>{oldVersion.length === 0 ? "No Profile Exists" : oldVersion[0].Certifications}</td>
                                            <td style={{ textTransform: "capitalize" }}>{inApprove[0].Certifications}</td>
                                        </tr>

                                        {/* Career Higlights */}
                                        <tr>
                                            <td>Career Highlights</td>
                                            <td>{oldVersion.length === 0 ? "No Profile Exists" : oldVersion[0].CareerHighlights}</td>
                                            <td>{inApprove[0].CareerHighlights}</td>
                                        </tr>

                                        {/* Career Start date */}
                                        <tr>
                                            <td>Career Start Date</td>
                                            <td>{oldVersion.length === 0 ? "No Profile Exists" : new Date(oldVersion[0].CareerStartDate).toLocaleString()}</td>
                                            <td>{new Date(inApprove[0].CareerStartDate).toLocaleString()}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </>
                )
            }
            {/* Comment Alert */}
            {
                commentAlert.isActive && (
                    <div className="build_profile_comment_alert_container">
                        <div className="build_profile_comment_alert">
                            {/* Comment */}
                            <div className="build_profile_comment_text_box">
                                <label>
                                    Comment <span style={{ color: "red" }}>*</span>
                                </label>
                                <textarea
                                    type={"text"}
                                    placeholder="Add a Comment"
                                    value={comment}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 100) {
                                            setComment(e.target.value);
                                        }
                                    }}
                                />
                                <p style={{ color: comment.length >= 100 ? "red" : "black" }}>
                                    {comment.length}/100
                                </p>
                            </div>
                            {/* Buttons */}
                            <div className="build_profile_comment_btns">
                                {commentAlert.task === "approve" ? (
                                    <button onClick={handleApproved}>Approve</button>
                                ) : (
                                    <button onClick={handleRejected}>Reject</button>
                                )}
                                <button onClick={() => setCommentAlert(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default EmployeeReview



const TableCols = [
    {
        id: 0,
        name: "Label Names",
        width: "20%"
    },
    {
        id: 1,
        name: "Old Version",
        width: "40%"
    },
    {
        id: 2,
        name: "In Approval",
        width: "40%"
    }
]

const LocationColumn = [
    {
        name: "India",
        value: 0,
    },
    {
        name: "USA",
        value: 1,
    },
    {
        name: "Philippines",
        value: 2,
    },
];