import WeekUtils from "week-utils";
import moment from "moment";
// import useGetAdToken from "../hooks/useGetAdToken";
import Axios from "axios";
import { getToken } from "..";

const weeks = new WeekUtils();

const dt = new Date();

let customDate = new Date();


export function getCurrentDate() {
    return dt
}

export function getLocalDate(localdate) {

    return new Date(localdate).toLocaleString()

}

export function getCustomDate(inputDt) {

    let arr = inputDt.split('-')
    customDate.setFullYear(arr[0])
    customDate.setMonth(arr[1])
    customDate.setDate(arr[2].slice(0, 2))
    customDate.setHours(arr[2].slice(3, 5))
    customDate.setMinutes(arr[2].slice(6, 8))
    customDate.setSeconds(arr[2].slice(9, 11))
    return customDate

}

export function getWeekFromWeekUtils() {
    return weeks
}

export function getWeekEnding(weekNumber, year) {
    let endingDate = moment().year(year).week(weekNumber).endOf('week')
    const dt = new Date(endingDate._d)
    return `${dt.getMonth() + 1}/${dt.getDate()}/${dt.getFullYear()}`

}

export function TextCapaitalized(value) {
    if (typeof value === "string")

        return value
            .split(" ")
            .map(word => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

    else return value
}

export async function AxiosGet(url) {

    let axios = Axios.create({
        baseURL: process.env.REACT_APP_PROXY,
        headers: {
            'Authorization': `bearer ${await getToken()}`,
            'X-Frame-Options': "DENY"
        },
    })

    let result = await axios.get(url)

    return result
}

export async function AxiosPost(url, body) {

    let axios = Axios.create({
        baseURL: process.env.REACT_APP_PROXY,
        headers: {
            'Authorization': `bearer ${await getToken()}`,
            'X-Frame-Options': "DENY"
        },
    })

    let result = await axios.post(url, body)

    return result

}

export async function AxiosGetwithHeaders(url, userheaders) {

    let headers = {
        ...userheaders,
        'Authorization': `bearer ${await getToken()}`,
        'X-Frame-Options': "DENY"
    }

    try {
        let axios = Axios.create({
            baseURL: process.env.REACT_APP_PROXY,
            headers: headers
        })
        let result = await axios.get(url)

        return result
    }
    catch (err) {
        console.log(err)
    }
}

export async function getUserGroup(Id) {

    let accessToken = await getToken()
    try {
        let adminUrl = `https://graph.microsoft.com/v1.0/users/${Id}/memberOf?$filter=id eq '${process.env.REACT_APP_ROLE_ADMIN}'`

        let result = await checkMemberOf(adminUrl, accessToken)
        if (result.data.value.length) {
            return process.env.REACT_APP_ROLE_ADMIN
        }
    }
    catch (err) {
        console.error(err.name)
    }

    try {
        let superUsreUrl = `https://graph.microsoft.com/v1.0/users/${Id}/memberOf?$filter=id eq '${process.env.REACT_APP_ROLE_SU}'`

        let result = await checkMemberOf(superUsreUrl, accessToken)
        if (result.data.value.length) {
            return process.env.REACT_APP_ROLE_SU
        }
    }
    catch (err) {
        console.error(err.name)
    }

    try {
        let managerUrl = `https://graph.microsoft.com/v1.0/users/${Id}/memberOf?$filter=id eq '${process.env.REACT_APP_ROLE_MANAGER}'`

        let result = await checkMemberOf(managerUrl, accessToken)
        if (result.data.value.length) {
            return process.env.REACT_APP_ROLE_MANAGER
        }
    }
    catch (err) {
        console.error(err.name)
    }

    try {
        let userUrl = `https://graph.microsoft.com/v1.0/users/${Id}/memberOf?$filter=id eq '${process.env.REACT_APP_ROLE_USER}'`

        let result = await checkMemberOf(userUrl, accessToken)
        if (result.data.value.length) {
            return process.env.REACT_APP_ROLE_USER
        }
    }
    catch (err) {
        console.error(err.name)
    }

    return null

}

async function checkMemberOf(url, accessToken) {
    return await Axios.get(url, {
        headers: {
            'Authorization': `Bearer ${accessToken}`
        }
    })
}


export const Locations = [
    {
        name: "India",
        value: 0,
    },
    {
        name: "USA",
        value: 1,
    },
    {
        name: "Philippines",
        value: 2,
    },
];


export function GetHistoryLocation() {

    if (localStorage.getItem('__history__process') === null) {
        return false
    }
    else {
        return localStorage.getItem('__history__process')
    }


}

export function SetHistoryLocation(value, operation) {
    if (operation === 'set') {
        localStorage.setItem('__history__process', value)
    }
    else {
        localStorage.removeItem('__history__process')
    }
}