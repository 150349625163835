import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    InitialValues: [],
}

export const usersSlice = createSlice({
    name: 'initial',
    initialState,
    reducers: {

        setInitialValues: (state, action) => {
            state.InitialValues = action.payload
        }

    }
})

export const { setInitialValues } = usersSlice.actions

export const getInitialValues = (state) => state.initial.InitialValues

export default usersSlice.reducer