import React, { useEffect, useRef, useState } from "react";
import { Grid } from "gridjs";
import "gridjs/dist/theme/mermaid.css";
import "./GridStyle.css";

function GridTable({ jsonData, columns: initialColumns }) {
  const gridRef = useRef(null);
  const [visibleColumns, setVisibleColumns] = useState(
    initialColumns.map((col) => col.id)
  );

  useEffect(() => {
    if (jsonData && jsonData.length > 0 && visibleColumns.length > 0) {
      const gridInstance = new Grid({
        columns: [
          { id: "index", name: "S.No", width: "50px" }, // Index column
          ...initialColumns.filter((col) => visibleColumns.includes(col.id)),
        ],
        data: jsonData.map((row, index) => ({
          index: index + 1, // Add index value
          ...row,
        })),
        search: {
          enabled: true,
          selector: (cell, rowIndex, cellIndex) => {
            const column = initialColumns[cellIndex];
            if (column && column.search !== false) {
              return cell;
            }
            return null;
          },
        },
        pagination: {
          limit: 30,
          nextButton: true,
          prevButton: true,
          resetPageOnUpdate: true,
        },
        resizable: true,
        sort: true,
        fixedHeader: true,
        style: {
          table: {
            "border-collapse": "collapse",
          },
          th: {
            background: "#6e85b4",
            color: "white",
            padding: "10px",
            borderBottom: "2px solid #ddd",
          },
          td: {
            padding: "10px",
            borderBottom: "1px solid #ddd",
          },
          tr: {
            ":nth-child(even)": {
              backgroundColor: "#f9f9f9",
            },
          },
        },
      });

      if (gridRef.current) {
        gridRef.current.destroy();
      }
      gridRef.current = gridInstance.render(
        document.getElementById("grid-table")
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jsonData, visibleColumns]);

  const toggleColumnVisibility = (columnId) => {
    setVisibleColumns((prevVisibleColumns) =>
      prevVisibleColumns.includes(columnId)
        ? prevVisibleColumns.filter((id) => id !== columnId)
        : [...prevVisibleColumns, columnId]
    );
  };

  const exportCSV = () => {
    // Get the visible columns and data
    const allColumns = initialColumns.filter((col) =>
      visibleColumns.includes(col.id)
    );
    const csvData = [
      [`"S.No"`,allColumns.map((col) => col.name)].join(","), // Add header row
      ...jsonData.map((row, index) =>
        [
          index + 1, // Add the index as the first column
          ...allColumns.map((col) => `"${row[col.id]}"`), // Add the row data
        ].join(",")
      ),
    ].join("\n");
  
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "projects-data.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  

  const showAllColumns = () => {
    setVisibleColumns(initialColumns.map((col) => col.id));
  };

  return (
    <div>
      <div className="filters mb-4">
        <div className="filter-buttons">
          {initialColumns.map((col) => (
            <button
              key={col.id}
              onClick={() => toggleColumnVisibility(col.id)}
              className={`filter-button ${
                visibleColumns.includes(col.id) ? "active" : "inactive"
              }`}
            >
              {col.name}
            </button>
          ))}
        </div>
        <div>
          <br />
        </div>
        <div className="action-buttons">
          <button onClick={showAllColumns} className="action-button">
            Show All Columns
          </button>

          <button onClick={exportCSV} className="action-button">
            Download as CSV
          </button>
        </div>
      </div>
      {visibleColumns.length > 0 ? (
        <div id="grid-table"></div>
      ) : (
        <div className="no-columns-message">No columns selected</div>
      )}
    </div>
  );
}

export default GridTable;
