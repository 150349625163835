import { toast } from 'react-toastify'
import Messages from '../../../data/Messages.json'
import { AxiosPost } from '../../../data/utilities';

const handleDesignations = (list, id) => {
    return list.filter((item) => item.Id === id)[0].Designation;
};

// const handlePracticeArea = (list, id) => {
//     return list.filter((item) => item.Id === id)[0].PracticeArea;
// };

const handleEducation = (list, id) => {
    if (id === null) {
        return null
    }
    return list.filter((item) => item.Id === id)[0].Education;
};

const handleReporting = (list, id) => {
    if (id === null) {
        return null;
    } else {
        return list.filter((item) => item.Id === id)[0].FirstName + " " + list.filter((item) => item.Id === id)[0].LastName;
    }
}

const handleUpdateProfile = async (props) => {
    // let Designationresults = await handleDesignationId(designations, designation);
    let Designationresults = await handleDesignationId(
        props.Designations,
        props.designation
    );

    let Educationresults = await handleEducationId(
        props.Educations,
        props.education
    );

    AxiosPost(`/iemployee/updateemployee`, {
        Id: props.currentEmp,
        firstName: props.firstName.toLowerCase(),
        lastName: props.lastName.toLowerCase(),
        email: props.email.toLowerCase(),
        pnCEmployeeId: props.pnCEmployeeId,
        org: props.org,
        countryCode: props.countryCode,
        phoneNumber: props.phoneNumber,
        designationId: Designationresults.Id,
        practiceAreaId: null,
        educationId: Educationresults.Id,
        specialization: props.specialization.toLowerCase(),
        certifications: props.certifications
            .split(";")
            .filter((item) => item !== "")
            .map((item) => item.trim().toLowerCase())
            .join(";"),
        careerHighlights: props.careerHighlights
            .split("\u2022")
            .filter((item) => item !== "")
            .join("@$")
            .replace("\n", ""),
        careerStartDate:
            props.careerStartDate.$y +
            "-" +
            (props.careerStartDate.$M + 1) +
            "-" +
            (props.careerStartDate.$D),
        isApprove: 0,
        updatedStatus:
            props.userData.role === process.env.REACT_APP_ROLE_ADMIN ? "Updated/Approved" : "Updated",
        comment: props.comment,
        FkEmployeeId: props.currentEmp,
        Role: props.userData.role,
        reportingTo: handlereportedBy(props.Employees, props.reportingTo),
        Location: props.LOCATION
    }).then((res) => {
        if (res.data.isSuccess) {

            if (Designationresults.inserted) {
                AxiosPost(
                    `/iemployee/alter-designation`,
                    {
                        FK_EmployeeId: props.currentEmp,
                        Id: Designationresults.Id
                    }
                );
            }

            //education alter
            if (Educationresults.inserted) {
                AxiosPost(`/iemployee/alter-education`, {
                    FK_EmployeeId: props.currentEmp,
                    Id: Educationresults.Id
                });
            }

            if (props.updated_Domains.length !== 0) {
                props.updated_Domains.forEach((domain) => {
                    handleDomain(props.Domains, domain, res.data.Id, props.userData.role);
                });
            }
            // Employee Client
            if (props.userData.role === process.env.REACT_APP_ROLE_ADMIN) {
                AxiosPost(
                    `/iemployee/insert-employee-client`,
                    {
                        FKEmpId: res.data.Id,
                        FKClientId: handleClientId(props.Clients, props.client),
                        Billable: 0
                    }
                );
            } else {
                AxiosPost(
                    `/temp-iemployee/insert-temp-employee-client`,
                    {
                        FK_TempEmployeeId: res.data.Id,
                        FK_ClientId: handleClientId(props.Clients, props.client),
                        Billable: 0
                    }
                );
            }

            props.setCurrentEmp(0);

            props.userData.role === process.env.REACT_APP_ROLE_ADMIN
                ? toast.success(Messages.EditProfileHandler.EditProfileHandler_Success, { toastId: "iemployee-toast" })
                : toast.success(Messages.EditProfileHandler.EditProfileHandler_Success, { toastId: "iemployee-toast" })


            props.RefreshContent();
            props.dispatch(props.setLoadingScreen(false));
            props.navigate("/myprofile/employees");
        }
    });
};


const handleDesignationId = (list, value) => {
    return new Promise((Success, Failed) => {
        if (list.filter((item) => item.Designation === value).length === 0) {
            AxiosPost(
                `/iemployee/insert-designation`,
                {
                    designation: value
                }
            ).then((res) => {
                Success({ Id: res.data.Id, inserted: true });
            });
        } else {
            let id = list.filter((item) => item.Designation === value)[0].Id;
            Success({ Id: id, inserted: false });
        }
    });
}

const handleEducationId = (list, value) => {
    return new Promise((Success, Failed) => {
        if (list.filter((item) => item.Education === value).length === 0) {
            AxiosPost(`/iemployee/insert-education`, {
                education: value
            }).then((res) => {
                Success({ Id: res.data.Id, inserted: true });
            });
        } else {
            const id = list.filter((item) => item.Education === value)[0].Id;
            Success({ Id: id, inserted: false });
        }
    });
};


const handleDomain = (list, value, fkid, role) => {
    // Delete the Domains if role is admin
    if (role === "Admin") {
        AxiosPost(
            `/iemployee/delete-domains-with-employee-id`,
            {
                FKEmpId: fkid
            }
        );
    }

    let val = false;
    let domainId = 0;

    list.every((e) => {
        if (e.Domain === value.Domain) {
            val = true;
            domainId = e.Id;
            return false;
        } else {
            return true;
        }
    });

    if (val) {
        // temp
        if (role === "Admin") {
            // add emp domain
            AxiosPost(`/iemployee/postdomains`, {
                FKEmpId: fkid,
                FKDomainId: domainId,
                domainExp: value.DomainExperience
            });
        } else {
            AxiosPost(
                `/temp-iemployee/add-temp-employee-domain`,
                {
                    FK_TempEmployeeId: fkid,
                    FK_DomainId: domainId,
                    DomainExperience: value.DomainExperience
                }
            );
        }
    } else {
        AxiosPost(`/iemployee/add-domain`, {
            domain: value.Domain,
            comment: value.Domain,
            FKId: null
        }).then((res) => {
            if (res.data.isSuccess) {
                // temp
                if (role === "Admin") {
                    AxiosPost(`/iemployee/postdomains`, {
                        FKEmpId: fkid,
                        FKDomainId: domainId,
                        domainExp: value.DomainExperience
                    });
                } else {
                    AxiosPost(
                        `/temp-iemployee/add-temp-employee-domain`,
                        {
                            FK_TempEmployeeId: fkid,
                            FK_DomainId: domainId,
                            DomainExperience: value.DomainExperience
                        }
                    );
                }
            }
        });
    }
};

const handleBulletDots = (list) => {
    let value = "";

    let careerHighlights = list.split("@$");
    careerHighlights.forEach((e, index) => {
        if (careerHighlights.length - 1 !== index) {
            value = value + "\u2022" + e + "\n";
        } else {
            value = value + "\u2022" + e;
        }
    });

    return value;
};

const handlereportedBy = (list, value) => {
    if (value === null) {
        return null
    }
    else {
        return list.filter((item) => item.FirstName.toString().toLowerCase() + " " + item.LastName.toString().toLowerCase() === value.toLowerCase())[0].Id;
    }

}

const handleClientId = (list, value) => {
    return list.filter((item) => item.ClientName === value)[0].Id;
}

export {
    handleDesignations,
    // handlePracticeArea,
    handleEducation,
    handleUpdateProfile,
    handleBulletDots,
    handleReporting
};
