import React, { useEffect, useState } from 'react'

import { toast } from 'react-toastify'
import Messages from '../../../../../data/Messages.json'

function TextBox({ setTimesheet, Id, name, Value, timesheet, type, ExpiryDt, JoiningDt, EndingDt, currentDt, Status, count, process, spCondition, location }) {

    const [hour, setHour] = useState(0)
    const [isDisabled, setIsDisabled] = useState(false)
    const [isSpecialDisabled, setIsSpecialDisabled] = useState(false)

    useEffect(() => {

        setHour(Value)

    }, [Value])

    useEffect(() => {

        if (process !== 'Approver') {
            if (type === 'PROJECT') {

                const Expdate = new Date(ExpiryDt)
                Expdate.setDate(Expdate.getDate() + 1)

                const Jndate = new Date(JoiningDt)
                Jndate.setDate(Jndate.getDate() - 1)

                const EndDate = new Date(EndingDt)

                if (Expdate.getTime() < currentDt.getTime() || EndDate.getTime() < currentDt.getTime()) {
                    setIsDisabled(true)
                }

                if (Jndate.getTime() > currentDt.getTime()) {
                    setIsDisabled(true)
                }

            }
        }

    }, [type, ExpiryDt, JoiningDt, currentDt, EndingDt, process])

    useEffect(() => {

        if (Status === 'Approved' && count > 2) {
            if (spCondition === 'PayCycle') {
                if (currentDt.getDate() <= 15) {
                    setIsDisabled(true)
                }
            }
        }

    }, [Status, currentDt, count, spCondition])

    useEffect(() => {

        if (count >= 1) {
            setIsSpecialDisabled(false)
        }
        else {

            if (spCondition === 'MonthEnd' && Status === 'Approved') {
                if (Number.parseInt(name.split('WK_Day')[1]) <= count) {
                    setIsSpecialDisabled(true)
                }
            }
            else if (spCondition === 'MonthEnd' && (Status === null || Status === 'Rejected')) {
                if (count < Number.parseInt(name.split('WK_Day')[1])) {
                    setIsSpecialDisabled(true)
                }
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, count, Status])

    const handleGetNonWorkingHours = () => {
        if (type === TYPES.WorkingHours) {
            return timesheet.filter(item => item.Type !== TYPES.WorkingHours && item.Type !== TYPES.OverTime).reduce((acc, timesheet) => acc + (timesheet[name] === '' ? 0 : timesheet[name]), 0)
        }
        else if (type === TYPES.Project) {
            return timesheet.filter(item => item.Type !== TYPES.OverTime).reduce((acc, timesheet) => acc + (timesheet[name] === '' ? 0 : timesheet[name]), 0)
        }
        else {
            if (type === TYPES.OverTime) {
                return timesheet.filter(item => item.Type === TYPES.OverTime || item.Type === TYPES.NONWorkingHours).reduce((acc, timesheet) => acc + (timesheet[name] === '' ? 0 : timesheet[name]), 0)
            }
            else {
                return timesheet.reduce((acc, timesheet) => acc + (timesheet[name] === '' ? 0 : timesheet[name]), 0)
            }
        }
    }

    function handlePointHours(hour) {

        if (hour.toString().includes('.2')) {
            return (hour + 0.05)
        }
        else if (hour.toString().includes('.7')) {
            return (hour + 0.05)
        }
        else {
            return hour
        }
    }

    const handleChange = (Id, value) => {
        setTimesheet(timesheet.map((e) => {
            if (e.Id === Id) {
                return {
                    ...e, [name]: value === '' ? 0 : handlePointHours(Number.parseFloat(value))
                }
            }
            else {
                return e
            }

        }))
    }

    const handleUpdate = (e) => {

        if (location === null) {
            toast.error("Please update your location in Profile", { toastId: "iemployee-toast" })
            return false
        }

        let value = location === 1 ? e.target.value : e.target.value

        const REGEX_FLOAT = /^\d+(\.00|\.0|\.|\.2|\.5|\.7|\.25|\.50|\.75)?$/

        const NUMBER_REGEX = /^(0|4|9|4.5)$/

        const NUMBER_REGEX_US = /^[048]$/

        const Location_Hours = location === 1 ? 8 : 9

        const Location_Check_Hours = location === 1 ? NUMBER_REGEX_US.test(Number.parseFloat(value)) : NUMBER_REGEX.test(Number.parseFloat(value))

        if (value.toString().includes('-')) {
            setHour(0)
            handleChange(Id, 0)
            return false
        }

        if (value === '') {
            setHour('')
            handleChange(Id, 0)
            return false
        }
        // Over Time Configuration
        if (type === TYPES.OverTime) {

            if (handleGetNonWorkingHours() !== 0) {
                if (handleGetNonWorkingHours() > (Location_Hours )) {
                    toast.error(Messages.TextBox.TextBox_Invalid, { toastId: "iemployee-toast" })
                    handleChange(Id, 0)
                    setHour(0)
                    return false
                }
            }

            if (Number.parseInt(value) <= Location_Hours) {
                if (REGEX_FLOAT.test(Number.parseFloat(value))) {
                    setHour(value)
                    handleChange(Id, value)
                }
            }
            return false
        }
        // NON Working Hours Configuration
        if (type === TYPES.NONWorkingHours) {
            if ((Number.parseFloat(handleGetNonWorkingHours()) + Number.parseFloat(value)) <= Location_Hours) {
                if (Location_Check_Hours) {
                    setHour(value)
                    handleChange(Id, value)
                }
            }
            else {
                toast.error(Messages.TextBox.TextBox_Invalid, { toastId: "iemployee-toast" })
                handleChange(Id, 0)
                setHour(0)
            }
            return false
        }
        // Project and Working Hours configurations
        

        if ((Number.parseFloat(value)) <= Location_Hours) {
            if (REGEX_FLOAT.test(Number.parseFloat(value))) {
                setHour(value)
                handleChange(Id, value)
            }
        }
        else {
            toast.error(Messages.TextBox.TextBox_Invalid, { toastId: "iemployee-toast" })
            handleChange(Id, 0)
            setHour(0)
        }
    }

    return (
        <input
            type='number'
            name={name}
            value={hour}
            onChange={handleUpdate}
            onKeyDownCapture={(e) => {
                if (e.key === 'Backspace') {
                    setHour(0)
                    handleChange(Id, 0)
                }
            }}
            disabled={(isDisabled || isSpecialDisabled)}
            style={{ cursor: (isDisabled || isSpecialDisabled) ? "not-allowed" : "text" }}
        />
    )
}

export default TextBox



const TYPES = {
    WorkingHours: "WORKING HOURS",
    NONWorkingHours: "NON WORKING HOURS",
    Project: "PROJECT",
    OverTime: "OT"
}