import React from 'react'
import "./HeaderOnly.css"
import { Navigate, Outlet } from 'react-router-dom'
import Header from '../../Components/Header/Header'

function HeaderOnly({ isExists }) {
    return isExists ?
        <div className='iemployee-layout-header-only'>
            <Header />
            <Outlet />
        </div>
        :
        <>
            {
                localStorage.getItem('__iemployee__user') === null && (
                    <Navigate to={'/'} />
                )
            }
        </>
}

export default HeaderOnly