import React, { useEffect, useState } from 'react'
import "./Allocations.css"

import { useDispatch, useSelector } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { Cancel, Close } from '@mui/icons-material'
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, OutlinedInput, Select, TextField } from '@mui/material'

import { getInitialValues, setInitialValues } from '../../../redux/features/InitialSlice'
import { setLoadingScreen } from '../../../redux/features/ActivateSlice'
import { getUserData } from '../../../redux/features/UserSlice'

import { toast } from 'react-toastify'
import Messages from '../../../data/Messages.json'
import { AxiosGet, AxiosPost, getCurrentDate } from '../../../data/utilities'

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function Allocations({ setIsActiveProjectAssign }) {

    const dispatch = useDispatch()
    const initialData = useSelector(getInitialValues)
    const userData = useSelector(getUserData)

    const [selectedProjectCodes, setSelectedProjectCodes] = useState([])
    const [selectedEmployees, setSelectedEmployees] = useState([])

    const [role, setRole] = useState('')
    const [joiningDate, setJoiningDate] = useState(dayjs(getCurrentDate()))
    const [endingDate, setEndingDate] = useState(dayjs(getCurrentDate()))
    const [billable, setBillable] = useState(-1)
    const [billingRate, setBillingRate] = useState(0)
    const [Location, setLocation] = useState(-1)
    const [approver, setApprover] = useState('')
    const [overTime, setOverTime] = useState(true)

    const [tempEmployees, setTempEmployees] = useState([])

    const [FilterData, setFilterData] = useState({
        Clients: [],
        Markets: [],
        Practices: [],
        InComeClasses: []
    })

    const [filterBy, setFilterBy] = useState({
        client: 'Select Client',
        market: 'Select Market',
        practice: 'Select Practice',
        incomeclass: 'Select Income Class'
    })

    const [ProjectCodes, setProjectCodes] = useState([])

    const [isError, setIsError] = useState({
        isProjects: false,
        isEmployees: false,
        isBillable: false,
        isLocation: false,
        isManager: false,
        isJobTitle: false
    })

    useEffect(() => {

        const dt = getCurrentDate()
        setJoiningDate(dayjs(dt.getFullYear() + '-' + (dt.getMonth() + 1) + '-' + dt.getDate()))

    }, [])

    useEffect(() => {
        if (initialData.length !== 0) {
            setTempEmployees(initialData.Employees)
            setProjectCodes(initialData.ProjectCodes)
        }
    }, [initialData])

    useEffect(() => {
        if (selectedEmployees.length !== 0) {
            let result = initialData.Employees
            selectedEmployees.forEach((item) => {
                result = result.filter(e => (e.FirstName + ' ' + e.LastName) !== item)
            })
            setTempEmployees(result)
        }
    }, [selectedEmployees, initialData])

    useEffect(() => {

        setApprover(userData.Id)

    }, [userData.Id])

    useEffect(() => {

        const handleGetInitialData = async () => {

            let Clients = await AxiosGet(`/timesheet/get-project-codes-clients`)

            let Markets = await AxiosGet(`/timesheet/get-markets`)

            let Practices = await AxiosGet(`/timesheet/get-practices`)

            let InComeClasses = await AxiosGet(`/timesheet/get-income-classes`)

            setFilterData({
                "Clients": Clients.data.isSuccess ? Clients.data.Clients : [],
                "Markets": Markets.data.isSuccess ? Markets.data.Markets : [],
                "Practices": Practices.data.isSuccess ? Practices.data.Practices : [],
                "InComeClasses": InComeClasses.data.isSuccess ? InComeClasses.data.InComeClasses : []
            })

        }
        handleGetInitialData()

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

        let filterMarket = "";
        if (filterBy.market !== "Select Market") {
            FilterData.Markets.forEach((item, index) => {
                if (item.Market === filterBy.market) {
                    filterMarket = index + 1;
                }
            });
        }

        let filterpractice = "";
        if (filterBy.practice !== "Select Practice") {
            FilterData.Practices.forEach((item, index) => {
                if (item.PracticeArea === filterBy.practice) {
                    filterpractice = index + 1;
                }
            });
        }

        let filterIncomeclass = "";
        if (filterBy.incomeclass !== "Select Income Class") {
            FilterData.InComeClasses.forEach((item, index) => {
                if (item.Incomeclass === filterBy.incomeclass) {
                    filterIncomeclass = index + 1;
                }
            });
        }

        let filterShortcode = "";
        if (filterBy.client !== "Select Client") {
            FilterData.Clients.filter((item) => {
                if (item.Client === filterBy.client) {
                    filterShortcode = item.ShortCode;
                }
                return item.ShortCode
            });
        }

        if (filterShortcode !== "") {
            let selectedCode = filterShortcode + filterMarket + filterpractice + filterIncomeclass;

            setProjectCodes(initialData.ProjectCodes.filter((item) => item.ProjectCode.includes(selectedCode)));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterBy])

    const handleProjectCodes = (e) => {

        if (selectedEmployees.length > 1 && selectedProjectCodes.length !== 0) {
            toast.error(Messages.Assign_ProjectCodes.Assign_ProjectCodes_Multiple_Selection, { toastId: "iemployee-toast" })
            return false
        }

        if (e.target.value[selectedProjectCodes.length] !== undefined) {
            setSelectedProjectCodes(item => [...item, e.target.value[selectedProjectCodes.length]])
            setIsError({ ...isError, "isProjects": false })
        }
    }

    const handleSelectedEmployees = (e) => {

        if (selectedProjectCodes.length > 1 && selectedEmployees.length !== 0) {
            toast.error(Messages.Assign_ProjectCodes.Assign_ProjectCodes_Multiple_Selection, { toastId: "iemployee-toast" })
            return false
        }

        if (e.target.value[selectedEmployees.length] !== undefined) {
            setSelectedEmployees(item => [...item, e.target.value[selectedEmployees.length]])
            setIsError({ ...isError, "isEmployees": false })
        }
    }

    const handleAssignProject = async () => {

        if (!isEndingDateValid()) {
            toast.error("Please Check the Provided Dates Properly", { toastId: "iemployee-toast" });
            return false
        }

        // check the data is provided or not 
        if (role === '' || selectedProjectCodes.length === 0 || selectedEmployees.length === 0 ||
            billable === -1 || Location === -1 || approver === '') {
            toast.error(Messages.Assign_ProjectCodes.Assign_ProjectCodes_Check_Data, { toastId: "iemployee-toast" })

            let values = isError

            if (role === '') {
                values = { ...values, "isJobTitle": true }
            }
            if (selectedProjectCodes.length === 0) {
                values = { ...values, "isProjects": true }
            }
            if (selectedEmployees.length === 0) {
                values = { ...values, "isEmployees": true }
            }
            if (billable === -1) {
                values = { ...values, "isBillable": true }
            }
            if (Location === -1) {
                values = { ...values, "isLocation": true }
            }
            if (approver === '') {
                values = { ...values, "isManager": true }
            }

            setIsError(values)
        }
        else {
            dispatch(setLoadingScreen(true))

            let result = await AxiosPost(`/timesheet/allocate-project`, {
                SelectedProject: selectedProjectCodes,
                SelectedEmp: selectedEmployees,
                Role: role,
                joiningDate: `${joiningDate.$y}-${joiningDate.$M + 1}-${joiningDate.$D}`,
                endingDate: `${endingDate.$y}-${endingDate.$M + 1}-${endingDate.$D}`,
                Billable: billable,
                BillingRate: billingRate === '' ? 0 : billingRate,
                Location: Location,
                Approver: approver,
                Creator: userData.Id,
                OverTime: overTime
            })

            if (result.data.isSuccess) {

                result.data.Allocations.forEach((item) => {
                    if (item.isAllocated) {
                        toast.error(`${item.EmployeeName} is already allocated to ${item.ProjectCode} project.`, { toastId: "iemployee-toast" })
                    }
                    else {
                        toast.success(`${item.EmployeeName} is allocated to ${item.ProjectCode} project.`, { toastId: "iemployee-toast" })
                    }
                })

                AxiosGet(`/timesheet/get-allocated-projects`).then((result) => {
                    if (result.data.isSuccess) {
                        dispatch(setLoadingScreen(false))
                        dispatch(setInitialValues({ ...initialData, "AllocatedProjects": result.data.AllocatedProjects }))
                    }
                })

                setIsActiveProjectAssign(false)
            }
            else {
                toast.error(Messages.Assign_ProjectCodes.Assign_ProjectCodes_Error, { toastId: "iemployee-toast" })
            }
        }

    }

    const handleRemoveProjectCode = (value) => {
        setSelectedProjectCodes(selectedProjectCodes.filter(item => item !== value))
    }

    const handleRemoveEmployees = (value) => {
        setSelectedEmployees(selectedEmployees.filter(item => item !== value))
    }

    const isEndingDateValid = () => {
        return endingDate !== null && joiningDate !== null && endingDate > joiningDate;
    }

    const handleClear = () => {

        setFilterBy({
            client: 'Select Client',
            market: 'Select Market',
            practice: 'Select Practice',
            incomeclass: 'Select Income Class'
        })
        setProjectCodes(initialData.ProjectCodes)
    }

    return (
        <div className='assign_project_code_container'>
            <div className='assign_project_code_container_card'>
                <div className='assign_project_code_container_header'>
                    <div className='assign_project_code_container_header_title'>
                        <span>Project Allocation</span>
                    </div>
                    <div className='assign_project_code_container_header_close'>
                        <Close sx={{ cursor: "pointer" }} onClick={() => setIsActiveProjectAssign(false)} />
                    </div>
                </div>
                <div className='allocations_container'>
                    <div className='allocations_container_filters'>
                        {/* Clients */}
                        <div className='allocations_container_filters_header'>
                            <span>Filters</span>
                            <button onClick={handleClear}>Clear All</button>
                        </div>
                        <div className='assign_project_code_container_content_span_1'>
                            {/* Client */}
                            <div className='allocations_container_filters_box'>
                                <FormControl fullWidth>
                                    <InputLabel>Clients</InputLabel>
                                    <Select
                                        label="Clients"
                                        id="client"
                                        sx={{ width: "100%" }}
                                        value={filterBy.client}
                                        onChange={(e) => setFilterBy({ ...filterBy, "client": e.target.value })}
                                        MenuProps={MenuProps}
                                    >
                                        <MenuItem value={"Select Client"}>Select Client</MenuItem>
                                        {
                                            FilterData.Clients.map((item, index) => {
                                                return <MenuItem key={index} value={item.Client}>{item.Client + ` - ${item.ShortCode}`}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                            {/* Market */}
                            <div className='allocations_container_filters_box'>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        Market
                                    </InputLabel>
                                    <Select
                                        label="Market"
                                        id="market"
                                        sx={{ width: "100%" }}
                                        value={filterBy.market}
                                        onChange={(e) => {
                                            if (filterBy.client !== 'Select Client') {
                                                setFilterBy({ ...filterBy, 'market': e.target.value })
                                            }
                                            else {
                                                toast.warn('Please select Client and try again', { toastId: "ClientId" })
                                            }
                                        }}
                                    >
                                        <MenuItem value={"Select Market"}>Select Market</MenuItem>
                                        {FilterData.Markets.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.Market}>
                                                    {item.Market}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            {/* Practice */}
                            <div className='allocations_container_filters_box'>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        Practice
                                    </InputLabel>
                                    <Select
                                        label="Practice"
                                        id="Practice"
                                        sx={{ width: "100%" }}
                                        value={filterBy.practice}
                                        onChange={(e) => {
                                            if (filterBy.market !== 'Select Market') {
                                                setFilterBy({ ...filterBy, "practice": e.target.value })
                                            }
                                            else {
                                                toast.warn('Please select Market and try again', { toastId: "MarketId" })
                                            }
                                        }}
                                    >
                                        <MenuItem value={"Select Practice"}>Select Practice</MenuItem>
                                        {FilterData.Practices.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.PracticeArea}>
                                                    {item.PracticeArea}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                            {/* Income classes */}
                            <div className='allocations_container_filters_box'>
                                <FormControl fullWidth>
                                    <InputLabel>
                                        Income Class
                                    </InputLabel>
                                    <Select
                                        label="IncomeClass"
                                        id="incomeclass"
                                        sx={{ width: "100%" }}
                                        value={filterBy.incomeclass}
                                        onChange={(e) => {
                                            if (filterBy.practice !== 'Select Practice') {
                                                setFilterBy({ ...filterBy, "incomeclass": e.target.value })
                                            }
                                            else {
                                                toast.warn('Please select Practice and try again', { toastId: "MarketId" })
                                            }
                                        }}
                                    >
                                        <MenuItem value={"Select Income Class"}>
                                            Select Income Class
                                        </MenuItem>
                                        {FilterData.InComeClasses.map((item, index) => {
                                            return (
                                                <MenuItem key={index} value={item.Incomeclass}>
                                                    {item.Incomeclass}
                                                </MenuItem>
                                            );
                                        })}
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className='assign_project_code_container_content'>
                        {/* Projects and Employees */}
                        <div className='assign_project_code_container_content_span'>
                            <div className='assign_project_code_container_span_box'>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel required id="projects-select">Select Projects</InputLabel>
                                    <Select
                                        required
                                        placeholder='Select Projects'
                                        multiple
                                        value={selectedProjectCodes}
                                        onChange={handleProjectCodes}
                                        error={isError.isProjects}
                                        sx={{ width: "100%" }}
                                        input={<OutlinedInput id="projects-select" label={"Select Projects"} />}
                                        MenuProps={MenuProps}
                                    >
                                    {ProjectCodes.filter((item) =>
                                        !item.ProjectCode.includes("OT-") // Exclude OT- projects
                                        ).map((item, index) => (
                                        <MenuItem key={index} value={item.ProjectCode}>
                                            {item.ProjectCode + " - " + item.ProjectName}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                                <div className='assign_project_selected_items'>
                                    {
                                        ProjectCodes.length === 0 && (
                                            <span>No Project Codes Exists</span>
                                        )
                                    }
                                    {
                                        selectedProjectCodes.map((item) => {
                                            return <span>{item} <Cancel
                                                fontSize='small'
                                                cursor='pointer'
                                                sx={{ color: "#aeaeae" }}
                                                onClick={() => handleRemoveProjectCode(item)}
                                            /></span>
                                        })
                                    }
                                </div>
                            </div>
                            <div className='assign_project_code_container_span_box'>
                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel required id="employees-select">Select Employees</InputLabel>
                                    <Select
                                        required
                                        labelId="demo-multiple-chip-label"
                                        id="demo-multiple-chip"
                                        multiple
                                        value={selectedEmployees}
                                        onChange={handleSelectedEmployees}
                                        error={isError.isEmployees}
                                        sx={{ width: "100%" }}
                                        input={<OutlinedInput id="employees-select" label="Select Employees" />}
                                        MenuProps={MenuProps}
                                    >
                                        {
                                            initialData.Employees.map((item, index) => (

                                                <MenuItem
                                                    key={index}
                                                    value={item.FirstName + " " + item.LastName}
                                                >
                                                    <span style={{ textTransform: "capitalize" }}>{item.FirstName + " " + item.LastName}</span>
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                                <div className='assign_project_selected_items'>
                                    {
                                        selectedEmployees.map((item) => {
                                            return <span>{item} <Cancel
                                                fontSize='small'
                                                cursor='pointer'
                                                sx={{ color: "#aeaeae" }}
                                                onClick={() => handleRemoveEmployees(item)}
                                            /></span>
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        {/* Joining Date and Ending Date */}
                        <div className='assign_project_code_container_content_span'>
                            <div className='assign_project_code_container_span_box'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        label="Joining Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={joiningDate}
                                        onChange={(value) => {
                                            setJoiningDate(dayjs(value))
                                        }}
                                        renderInput={(params) => <TextField size='small' required name='joining_date' {...params} sx={{ width: "100%", height: "35px" }} />}
                                    />
                                </LocalizationProvider>
                            </div>
                            {/* calender */}
                            <div className='assign_project_code_container_span_box'>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <MobileDatePicker
                                        label="Ending Date"
                                        inputFormat="MM/DD/YYYY"
                                        value={endingDate}
                                        onChange={(value) => {
                                            setEndingDate(dayjs(value))
                                        }}
                                        renderInput={(params) => <TextField size='small' required name='ending_date' {...params} sx={{ width: "100%", height: "35px" }} />}
                                    />
                                </LocalizationProvider>
                            </div>
                        </div>
                        {/* Billable and Billing rate */}
                        <div className='assign_project_code_container_content_span' style={{ marginTop: "40px" }}>
                            <div className='assign_project_code_container_span_box'>
                                <FormControl fullWidth>
                                    <InputLabel required id="Billable">Billable</InputLabel>
                                    <Select
                                        label="Billable"
                                        id="Billable"
                                        placeholder='Select Billable'
                                        sx={{ width: "100%" }}
                                        value={billable}
                                        error={isError.isBillable}
                                        onChange={(e) => {
                                            if (e.target.value === 0) {
                                                setBillingRate(0)
                                            }
                                            setIsError({ ...isError, "isBillable": false })
                                            setBillable(e.target.value)
                                        }}
                                    >
                                        <MenuItem value={-1}>Select Billable</MenuItem>
                                        <MenuItem value={1}>Yes</MenuItem>
                                        <MenuItem value={0}>No</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='assign_project_code_container_span_box'>
                                <TextField
                                    placeholder='Enter the Billing Rate'
                                    label='Billing Rate'
                                    sx={{ width: "100%" }}
                                    value={billingRate}
                                    disabled={billable === 0}
                                    onChange={(e) => {

                                        const NUMBER_REGEX = /([0-9]).{0,24}$/;

                                        if (Number.parseInt(e.target.value) !== undefined) {
                                            if (NUMBER_REGEX.test(e.target.value)) {
                                                setBillingRate(Number.parseInt(e.target.value));
                                            }
                                        }

                                        if (e.target.value === '' || e.target.value === 0) {
                                            setBillingRate('')
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        {/* Location and Manager / Approver */}
                        <div className='assign_project_code_container_content_span'>
                            <div className='assign_project_code_container_span_box'>
                                <FormControl fullWidth>
                                    <InputLabel required id="location">Location</InputLabel>
                                    <Select
                                        label="Location"
                                        id="location"
                                        placeholder='Select Location'
                                        sx={{ width: "100%" }}
                                        value={Location}
                                        error={isError.isLocation}
                                        onChange={(e) => {
                                            setLocation(e.target.value)
                                            setIsError({ ...isError, "isLocation": false })
                                        }}
                                    >
                                        <MenuItem value={-1}>Select Location</MenuItem>
                                        <MenuItem value={0}>India</MenuItem>
                                        <MenuItem value={1}>USA</MenuItem>
                                        <MenuItem value={2}>Philippines</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                            <div className='assign_project_code_container_span_box'>
                                {/* select manager approver */}
                                <FormControl fullWidth>
                                    <InputLabel required id="ManagerApprover">Manager / Approver</InputLabel>
                                    <Select

                                        label="Manager / Approver"
                                        id="ManagerApprover"
                                        placeholder='Select Manager / Approver'
                                        sx={{ width: "100%", textTransform: "capitalize" }}
                                        value={approver}
                                        error={isError.isManager}
                                        onChange={(e) => {
                                            setApprover(e.target.value)
                                            setIsError({ ...isError, "isManager": false })
                                        }}
                                    >
                                        {
                                            tempEmployees.map((item, index) => {
                                                return (
                                                    <MenuItem value={item.Id} key={index} sx={{ textTransform: "capitalize" }}>{item.FirstName + " " + item.LastName}</MenuItem>
                                                )
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        {/* Role */}
                        <div className='assign_project_code_container_content_span_1'>
                            <div className='assign_project_code_container_span_box'>
                                <TextField
                                    required
                                    placeholder='Enter the Job Title'
                                    label='Job Title'
                                    sx={{ width: "100%" }}
                                    value={role}
                                    error={isError.isJobTitle}
                                    onChange={(e) => {
                                        if (e.target.value.length <= 30) {
                                            setRole(e.target.value)
                                            setIsError({ ...isError, "isJobTitle": false })
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className='assign_project_code_container_actions'>
                    <div className='assign_project_code_container_span_box'>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                value={overTime}
                                checked={overTime}
                                onClick={() => setOverTime(!overTime)}
                            />} label='Over Time' />
                        </FormGroup>
                    </div>
                    <div>
                        <button onClick={() => setIsActiveProjectAssign(false)}>Cancel</button>
                        <button onClick={handleAssignProject}>Assign</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Allocations