import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    designations: [],
    domains: [],
    practiceAreas: [],
    educations: [],
    clients: [],
}

export const extraOptionsSlice = createSlice({
    name: 'options',
    initialState,
    reducers: {

        setDesignation: (state, action) => {
            state.designations = action.payload
        },

        setDomains: (state, action) => {
            state.domains = action.payload
        },
        setPracticeArea: (state, action) => {
            state.practiceAreas = action.payload
        },
        setEducations: (state, action) => {
            state.educations = action.payload
        },

        setClients: (state, action) => {
            state.clients = action.payload
        },

    },
})

export const { setDesignation, setDomains, setPracticeArea, setEducations, setClients } = extraOptionsSlice.actions

export const getDesignations = (state) => state.options.designations

export const getDomains = (state) => state.options.domains

export const getPracticeAreas = (state) => state.options.practiceAreas

export const getEducations = (state) => state.options.educations

export const getClietns = (state) => state.options.clients


export default extraOptionsSlice.reducer