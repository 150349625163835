import React from 'react'
import "./Loader.css"
import iEmployeeLogo from '../../assets/ProductImages/logo-option2.svg'
import iEmployeeLoader from '../../assets/ProductImages/Loading-noBG.gif'

function Loader() {

    // const [text, setText] = useState('Loading Please wait')

    // useEffect(() => {

    //     function showText() {
    //         setTimeout(() => {
    //             setText('Collecting data from server')
    //         }, 5000)

    //         setTimeout(() => {
    //             setText('We are almost done')
    //         }, 10000)

    //         setTimeout(() => {
    //             setText('Loading Please wait')
    //         }, 15000)
    //     }

    //     setTimeout(() => {
    //         showText()
    //     }, 16000)


    // }, [])

    return (
        <div className='dashboard-loader--container'>
            <div className='dashboard--loader--image'>
                <img src={iEmployeeLogo} alt='logo' className='dashboard--loader--image--logo' />
                <img src={iEmployeeLoader} alt='loader' className='dashboard--loader--image--loader' />
            </div>
        </div>
    )
}

export default Loader