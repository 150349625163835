import React, { useEffect, useState } from 'react'
import "./UpdateEmployeeSummary.css"
import { Autocomplete, FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { AxiosGetwithHeaders, AxiosPost, TextCapaitalized, getCurrentDate } from '../../../data/utilities'
import CryptoJS from 'crypto-js'
import { useDispatch, useSelector } from 'react-redux'
import { getUserData } from '../../../redux/features/UserSlice'
import { setLoadingScreen } from '../../../redux/features/ActivateSlice'
import { useNavigate, useParams } from 'react-router-dom'
import { getInitialValues } from '../../../redux/features/InitialSlice'
import { CountryCodes } from '../../../data/CountryCodeWithLength'
// import { handleDesignations, handleEducation, handleReporting } from '../../Pages/EditProfileHandler'
import dayjs from 'dayjs'
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Add, Close } from '@mui/icons-material'
import { toast } from 'react-toastify'
import Messages from '../../../data/Messages.json'
import { handleBulletDots } from '../../Pages/EditProfileHandler'


function UpdateEmployeeSummary() {

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()

    const userData = useSelector(getUserData)
    const initialData = useSelector(getInitialValues)

    const [currentEmp, setCurrentEmp] = useState(null)

    const [employeeData, setEmployeeData] = useState(InitialValues)

    const [domain, setDomain] = useState('')
    const [domainexp, setDomainexp] = useState('')

    const [IsCheck, setIsCheck] = useState({
        email: false,
        careerStartDate: false
    })

    const [isError, setIsError] = useState({
        isFirstName: false,
        isLastName: false,
        isEmailAddress: false,
        isPnCEmployeeId: false,
        isPhoneNumber: false,
        isCountry: false,
        isEducation: false,
        isSpecialization: false,
        isDesignation: false,
        isClient: false,
        isReportingManager: false,
        isLocation: false,
        isCareerStartDate: false
    })

    useEffect(() => {

        const handleGetEmployeeDetails = async () => {

            dispatch(setLoadingScreen(true))

            let result = await AxiosGetwithHeaders(`/iemployee/get-employee-summary-details`, { id: currentEmp })

            try {
                if (result.data.isSuccess) {
                    let employeeDetails = { ...employeeData }
                    employeeDetails = result.data.result
                    let totalYears = 0
                    let updatedDomains = []
                    let initialDomains = []
                    let year = getCurrentDate().getFullYear()

                    if (result.data.result.CAREERSTARTDATE !== null) {
                        employeeDetails = { ...employeeDetails, "CAREERSTARTDATE": dayjs(result.data.result.CAREERSTARTDATE) }
                        year = year - Number.parseInt(dayjs(result.data.result.CAREERSTARTDATE.split("T")[0]).$y);
                    }

                    let domains_data = await AxiosPost(`/iemployee/getdomainsbyid`, { Id: currentEmp })

                    if (domains_data.data.isSuccess) {
                        if (domains_data.data.data.length === 0) {
                            totalYears = year
                        }
                        else {
                            domains_data.data.data.forEach((item) => {
                                year = year - Number.parseInt(item.DomainExperience);
                            });

                            totalYears = year

                            initialDomains = domains_data.data.data

                            updatedDomains = domains_data.data.data.map((e, index1) => {

                                return { ...e, Id: index1 };
                            })
                        }
                    }
                    else {
                        totalYears = year
                    }



                    setEmployeeData({
                        ...employeeDetails, "TOTALEXPERIENCE": totalYears,
                        "UPDATEDDOMAINS": updatedDomains,
                        "INITIALDOMAINS": initialDomains
                    })
                }
            }
            catch (err) {
                console.log(err)
                console.log(currentEmp)
                navigate('/dashboard')
            }
            finally {
                dispatch(setLoadingScreen(false))
            }
        }

        if (currentEmp !== null) {
            handleGetEmployeeDetails()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentEmp])

    useEffect(() => {

        let value = JSON.parse(CryptoJS.AES.decrypt(params.type.split("|").join("/"), process.env.REACT_APP_SECRECT_KEY).toString(CryptoJS.enc.Utf8));

        if (userData.role === process.env.REACT_APP_ROLE_ADMIN ||
            userData.role === process.env.REACT_APP_ROLE_SU ||
            userData.Id === value.Id) {
            setCurrentEmp(value.Id);
        }
        else {
            navigate('/errorpage')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params])

    const handleFirstName = (e) => {
        const REGEX_FirstName = /^[a-zA-Z ]{0,20}$/;

        if (REGEX_FirstName.test(e.target.value)) {
            setIsError({ ...isError, "isFirstName": false })
            setEmployeeData({ ...employeeData, "FIRSTNAME": e.target.value });
        }

        if (e.target.value === "") {
            setIsError({ ...isError, "isFirstName": false })
            setEmployeeData({ ...employeeData, "FIRSTNAME": e.target.value });
        }
    };

    const handleLastName = (e) => {
        const REGEX_LastName = /^[a-zA-Z ]{0,20}$/;

        if (REGEX_LastName.test(e.target.value)) {
            setIsError({ ...isError, "isLastName": false })
            setEmployeeData({ ...employeeData, "LASTNAME": e.target.value });
        }

        if (e.target.value === "") {
            setIsError({ ...isError, "isLastName": false })
            setEmployeeData({ ...employeeData, "LASTNAME": e.target.value });
        }
    };

    const handleEmail = (e) => {
        if (e.target.value.toLowerCase() === userData.email.toLowerCase()) {
            setIsCheck({ ...IsCheck, "email": false });
        } else {
            setIsCheck({ ...IsCheck, "email": true });
        }

        if (e.target.value === "") {
            setIsCheck({ ...IsCheck, "email": false });
        }
        if (e.target.value.length <= 50) {
            setIsError({ ...isError, "isEmailAddress": false })
            setEmployeeData({ ...employeeData, "EMAIL": e.target.value });
        }
    };

    const handlePnCEmployeeId = (e) => {
        const inputValue = e.target.value.replace(/[^0-9]/g, '');

        const REGEX_PnCEmployeeId = /^[0-9]*$/;
        let PnCEmployeeIdLength = 5;

        if (e.target.value.length > PnCEmployeeIdLength || !REGEX_PnCEmployeeId.test(inputValue)) {
            setIsError({ ...isError, "isPnCEmployeeId": true })
        }

        else {
            setIsError({ ...isError, "isPnCEmployeeId": false })
            setEmployeeData({ ...employeeData, "PNCEMPLOYEEID": e.target.value });
        }

    };


    const handlePhoneNumber = (e) => {

        if (employeeData.COUNTRYCODE === null) {
            toast.error('Please Select the Country Code')
            return false
        }

        const REGEX_PhoneNumber = /^[0-9]*$/

        let countryCodeLength = CountryCodes.filter(item => Number.parseInt(item.country_code) === employeeData.COUNTRYCODE).length !== 0 ?
            Number.parseInt(CountryCodes.filter(item => Number.parseInt(item.country_code) === employeeData.COUNTRYCODE)[0].phoneLength)
            : 10

        if (e.target.value.length <= countryCodeLength) {
            if (REGEX_PhoneNumber.test(e.target.value)) {
                setIsError({ ...isError, "isPhoneNumber": false })
                setEmployeeData({ ...employeeData, "PHONENUMBER": e.target.value });
            }
        }
    }

    const handleCountryCode = (selectedCountry) => {
        if (selectedCountry) {
            const countryCode = selectedCountry.country_code;
            setEmployeeData({ ...employeeData, "COUNTRYCODE": countryCode, "PHONENUMBER": '' });
            setIsError({ ...isError, "isCountry": false });
        } else {
            setEmployeeData({ ...employeeData, "COUNTRYCODE": '', "PHONENUMBER": '' });
            setIsError({ ...isError, "isCountry": true });
        }
    };

    const handleCertification = (e) => {
        if (e.target.value.length <= 1000) {
            setEmployeeData({ ...employeeData, "CERTIFICATIONS": e.target.value });
        }
    };

    const handleCareerStartDate = (e) => {

        const dt = new Date(e)
        const currentDate = new Date()

        if (dt.getTime() < getCurrentDate().getTime()) {
            setEmployeeData({
                ...employeeData,
                "CAREERSTARTDATE": dayjs(e),
                "TOTALEXPERIENCE": (currentDate.getFullYear() - dt.getFullYear()),
                "INITIALDOMAINS": [],
                "UPDATEDDOMAINS": []
            })
            setIsCheck({ ...IsCheck, 'careerStartDate': false })
        }
        else {
            setIsCheck({ ...IsCheck, 'careerStartDate': true })
        }
    }

    const handleDomainsUpdate = () => {

        const POSITIVE_VALUES_REGEX = /^\d+$/

        // No zero value should be allowed for domain experience
        if (Number.parseInt(domainexp) === 0 || Number.parseInt(domainexp) === '') {
            toast.warn(Messages.EditEmployeeProfile.EditEmployeeProfile_Domain_Expeirence, { toastId: "iemployee-toast" })
            setDomain(null);
            setDomainexp("");
            return false;
        }

        if (!POSITIVE_VALUES_REGEX.test(Number.parseInt(domainexp))) {
            toast.warn(Messages.EditEmployeeProfile.EditEmployeeProfile_Domain_Expeirence, { toastId: "iemployee-toast" })
            return false
        }

        // domain experience should be less then or equal to the total expierence
        if (Number.parseInt(domainexp) > Number.parseInt(employeeData.TOTALEXPERIENCE)) {
            toast.error(Messages.EditEmployeeProfile.EditEmployeeProfile_Domain_Limit_Exceed, { toastId: "iemployee-toast" })
            return false;
        }

        // No Empty domain or domain experience is allowed
        if (domain === "" || domainexp === "" || domain === null) {
            toast.error(Messages.EditEmployeeProfile.EditEmployeeProfile_Provide_Details, { toastId: "iemployee-toast" })
            return false;
        } else {
            // If employee do not have any domain, without any validations adding the domains
            if (employeeData.INITIALDOMAINS.length === 0) {

                let domainId = initialData.Domains.filter(item => item.Domain === domain)

                setEmployeeData({
                    ...employeeData, "UPDATEDDOMAINS": [{
                        ...employeeData.UPDATEDDOMAINS,
                        "FK_EmployeeId": currentEmp,
                        "Domain": domain,
                        "DomainExperience": domainexp,
                        "FK_DomainId": domainId[0].Id
                    }],
                    "INITIALDOMAINS": [{
                        ...employeeData.INITIALDOMAINS,
                        "FK_EmployeeId": currentEmp,
                        "Domain": domain,
                        "DomainExperience": domainexp,
                        "FK_DomainId": domainId[0].Id
                    }],
                    "TOTALEXPERIENCE": (Number.parseInt(employeeData.TOTALEXPERIENCE) - Number.parseInt(domainexp))
                })
                setDomain(null);
                setDomainexp('');
            } else {
                // checking if the domain is already exists in the alldomains list
                let result = employeeData.INITIALDOMAINS.filter((item) => item.Domain === domain);

                // No Duplicate Domains are allowed
                if (result.length !== 0) {
                    toast.error(Messages.EditEmployeeProfile.EditEmployeeProfile_No_Duplicate_Domain, { toastId: "iemployee-toast" })
                } else {
                    let domainId = initialData.Domains.filter(item => item.Domain === domain)
                    // adding domain in updated list and also in the alldomains
                    setEmployeeData({
                        ...employeeData, "UPDATEDDOMAINS": [
                            ...employeeData.UPDATEDDOMAINS, {
                                "FK_EmployeeId": currentEmp,
                                "Domain": domain,
                                "DomainExperience": domainexp,
                                "FK_DomainId": domainId[0].Id
                            }
                        ],
                        "INITIALDOMAINS": [
                            ...employeeData.INITIALDOMAINS, {
                                "FK_EmployeeId": currentEmp,
                                "Domain": domain,
                                "DomainExperience": domainexp,
                                "FK_DomainId": domainId[0].Id
                            }
                        ],
                        "TOTALEXPERIENCE": (Number.parseInt(employeeData.TOTALEXPERIENCE) - Number.parseInt(domainexp))
                    })
                    setDomain(null);
                    setDomainexp('');
                }
            }
        }
    };

    const handleDomainDelete = (domainVal, domainExp) => {
        setEmployeeData({
            ...employeeData, "UPDATEDDOMAINS": employeeData.UPDATEDDOMAINS.filter((item) => item.Domain !== domainVal),
            "INITIALDOMAINS": employeeData.INITIALDOMAINS.filter((item) => item.Domain !== domainVal),
            "TOTALEXPERIENCE": (Number.parseInt(employeeData.TOTALEXPERIENCE) + Number.parseInt(domainExp))
        })
    };

    let previousLength = 0;
    const handleCareerHighlights = (event) => {
        const bullet = "\u2022";
        const newLength = event.target.value.length;
        const characterCode = event.target.value.substr(-1).charCodeAt(0);
        if (event.target.value.length <= 750) {
            if (newLength > previousLength) {
                if (characterCode === 10) {
                    event.target.value = `${event.target.value}${bullet} `;
                } else if (newLength === 1) {
                    event.target.value = `${bullet} ${event.target.value}`;
                }
                setEmployeeData({ ...employeeData, "CAREERHIGHLIGHTS": event.target.value });
            }
            previousLength = newLength;
            if (characterCode === 8226) {
                let values = "";
                employeeData.CareerHighlights
                    .split("\n")
                    .filter((item) => item !== "\u2022 ")
                    .forEach((e) => {
                        values = values + e + "\n";
                    });
                setEmployeeData({ ...employeeData, "CAREERHIGHLIGHTS": values });
            }
        }
    };

    const handleUpdateEmployeeDetails = async () => {

        if (employeeData.FIRSTNAME === '' ||
            employeeData.LASTNAME === '' ||
            employeeData.PHONENUMBER === '' ||
            employeeData.PNCEMPLOYEEID === '' ||
            employeeData.COUNTRYCODE === null ||
            employeeData.EDUCATION === null ||
            employeeData.EDUCATION === "" ||
            employeeData.DESIGNATION === null ||
            employeeData.DESIGNATION === "" ||
            employeeData.CLIENT === null ||
            employeeData.CLIENT === "" ||
            employeeData.SPECIALIZATION === '' ||
            employeeData.LOCATION === '' ||
            employeeData.REPORTINGTO === null ||
            employeeData.REPORTINGTO === "" ||
            employeeData.CAREERSTARTDATE === null ||
            IsCheck.careerStartDate === true ||
            IsCheck.email === true ||
            employeeData.EMAIL === ''
        ) {

            let values = isError

            if (employeeData.FIRSTNAME === '') {
                values = { ...values, "isFirstName": true }
            }
            if (employeeData.LASTNAME === '') {
                values = { ...values, "isLastName": true }
            }
            if (employeeData.EMAIL === '') {
                values = { ...values, "isEmailAddress": true }
            }
            if (employeeData.PNCEMPLOYEEID === '') {
                values = { ...values, "isPnCEmployeeId": true }
            }
            if (employeeData.COUNTRYCODE === null) {
                values = { ...values, "isCountry": true }
            }
            if (employeeData.CAREERSTARTDATE === null) {
                values = { ...values, "isCareerStartDate": true }
            }
            if (employeeData.PHONENUMBER === '') {
                values = { ...values, "isPhoneNumber": true }
            }
            if (employeeData.EDUCATION === null || employeeData.EDUCATION === "") {
                values = { ...values, "isEducation": true }
            }
            if (employeeData.SPECIALIZATION === '') {
                values = { ...values, "isSpecialization": true }
            }
            if (employeeData.DESIGNATION === null || employeeData.DESIGNATION === "") {
                values = { ...values, "isDesignation": true }
            }
            if (employeeData.CLIENT === null || employeeData.CLIENT === "") {
                values = { ...values, "isClient": true }
            }
            if (employeeData.REPORTINGTO === null || employeeData.REPORTINGTO === "") {
                values = { ...values, "isReportingManager": true }
            }
            if (employeeData.LOCATION === '') {
                values = { ...values, "isLocation": true }
            }
            if (IsCheck.careerStartDate === true) {
                values = { ...values, "isCareerStartDate": true }
            }

            setIsError(values)
            toast.error("Please Provide the details", { toastId: "update" })
            return false
        }

        dispatch(setLoadingScreen(true))
        try {

            let result = await AxiosPost('/iemployee/update-employee-summary', {
                ...employeeData,
                "ROLE": userData.role,
                'ISAPPROVED': 0,
                'COMMENT': "Comment",
                "REPORTINGTO": initialData.Employees.filter(item => (item.FirstName + ' ' + item.LastName).toLowerCase() === employeeData.REPORTINGTO.toString().toLowerCase())[0].Id,
                "CAREERSTARTDATE":
                    employeeData.CAREERSTARTDATE.$y +
                    "-" +
                    (employeeData.CAREERSTARTDATE.$M + 1) +
                    "-" +
                    (employeeData.CAREERSTARTDATE.$D + 1),
            })
            if (result.data.isSuccess) {
                toast.success('Successfully Updated the Employee Summary', { toastId: "update" })
                navigate('/myprofile/employees')
            }
            else {
                toast.info('Please try after sometime', { toastId: "update" })
            }
        }
        catch (err) {
            console.log(err.name)
            toast.info('Please try after sometime', { toastId: "update" })
        }
        finally {
            dispatch(setLoadingScreen(false))
        }

    }

    return (
        <div className='update-employee-container'>
            <div className='update-employee-title'>
                <span>Update Employee Summary</span>
                <button onClick={handleUpdateEmployeeDetails}>Save Changes</button>
            </div>

            {/* Personal Data */}
            <div className='update-employee-card'>
                <div className='update-employee-card-title'>
                    <span>Personal Details</span>
                </div>
                <div className='update-employee-content'>
                    <div className='update-employee-span'>
                        <div className='update-employee-field'>
                            <TextField
                                required
                                label='First Name'
                                sx={{ width: "100%" }}
                                value={TextCapaitalized(employeeData.FIRSTNAME)}
                                onChange={handleFirstName}
                                error={isError.isFirstName}
                            />
                        </div>
                        <div className='update-employee-field'>
                            <TextField
                                required
                                label='Last Name'
                                sx={{ width: "100%" }}
                                value={TextCapaitalized(employeeData.LASTNAME)}
                                onChange={handleLastName}
                                error={isError.isLastName}
                            />
                        </div>
                    </div>
                    <div className='update-employee-span'>
                        <div className='update-employee-field'>
                            <TextField
                                required
                                label='Email Address'
                                sx={{ width: "100%" }}
                                value={TextCapaitalized(employeeData.EMAIL)}
                                onChange={handleEmail}
                                error={isError.isEmailAddress}
                                helperText={IsCheck.email === false ? "" : "Please Provide the valid Mail Address"}
                            />
                        </div>
                        <div className='update-employee-field'>
                            <TextField
                                required
                                type='number'
                                label='PnC Employee ID'
                                sx={{ width: "100%" }}
                                value={employeeData.PNCEMPLOYEEID}
                                onChange={handlePnCEmployeeId}
                                error={isError.isPnCEmployeeId}
                            />
                        </div>
                    </div>
                    <div className='update-employee-span'>
                        <div className='update-employee-field'>
                            <Autocomplete
                                fullWidth
                                options={CountryCodes}
                                getOptionLabel={(option) => option.country}
                                id="select--country"
                                value={CountryCodes.find((option) => option.country_code === employeeData.COUNTRYCODE) || null}
                                onChange={(event, newValue) => handleCountryCode(newValue)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Select Country *"
                                        error={isError.isCountry}
                                    />
                                )}
                            />
                        </div>
                        <div className='update-employee-field'>
                            <TextField
                                required
                                type='number'
                                label='Phone Number'
                                sx={{ width: "100%" }}
                                value={employeeData.PHONENUMBER}
                                onChange={handlePhoneNumber}
                                error={isError.isPhoneNumber}
                            />
                        </div>
                    </div>
                    <div className='update-employee-span'>
                        <div className='update-employee-field'>
                            <Autocomplete
                                required
                                sx={{ width: "100%" }}
                                onChange={(event, value) => {
                                    console.log(value)
                                    setIsError({ ...isError, "isEducation": false })
                                    setEmployeeData({ ...employeeData, "EDUCATION": value })
                                }}
                                options={initialData.Educations.map((options) => options.Education)}
                                value={employeeData.EDUCATION}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        name="Education"
                                        label="Education"
                                        error={isError.isEducation}
                                        onChange={(e) => {
                                            console.log(e.target.value)
                                            setIsError({ ...isError, "isEducation": false })
                                            setEmployeeData({ ...employeeData, "EDUCATION": e.target.value })
                                        }}
                                    />
                                )}
                            />
                        </div>
                        <div className='update-employee-field'>
                            <TextField
                                required
                                label='Specialization'
                                sx={{ width: "100%" }}
                                value={employeeData.SPECIALIZATION}
                                error={isError.isSpecialization}
                                onChange={(e) => {
                                    if (e.target.value.length <= 20) {
                                        const REGEX_Specialization = /^[a-zA-Z ]{0,20}$/
                                        if (REGEX_Specialization.test(e.target.value)) {
                                            setIsError({ ...isError, "isSpecialization": false })
                                            setEmployeeData({ ...employeeData, "SPECIALIZATION": e.target.value })
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {/* Job Details */}
            <div className='update-employee-card'>
                <div className='update-employee-card-title'>
                    <span>Job Details</span>
                </div>
                <div className='update-employee-content'>
                    <div className='update-employee-span'>
                        <div className='update-employee-field'>
                            <Autocomplete
                                required
                                onChange={(event, value) => {
                                    setIsError({ ...isError, "isDesignation": false })
                                    setEmployeeData({ ...employeeData, "DESIGNATION": value })
                                }}
                                options={initialData.Designations.map((options) => options.Designation)}
                                sx={{ width: "100%" }}
                                value={employeeData.DESIGNATION}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        name="Designation"
                                        label="Designation"
                                        error={isError.isDesignation}
                                        onChange={(e) => setEmployeeData({ ...employeeData, "DESIGNATION": e.target.value })}
                                    />
                                )}
                            />
                        </div>
                        <div className='update-employee-field'>
                            <Autocomplete
                                required
                                onChange={(event, value) => {
                                    setIsError({ ...isError, "isClient": false })
                                    setEmployeeData({ ...employeeData, "CLIENT": value })
                                }}
                                options={initialData.Clients.map((options) => options.ClientName)}
                                sx={{ width: "100%" }}
                                value={employeeData.CLIENT}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        name="client"
                                        label="Client"
                                        error={isError.isClient}
                                    />
                                )}
                            />
                        </div>
                    </div>
                    <div className='update-employee-span'>
                        <div className='update-employee-field'>
                            <Autocomplete
                                required
                                onChange={(event, value) => {
                                    setIsError({ ...isError, "isReportingManager": false })
                                    setEmployeeData({ ...employeeData, 'REPORTINGTO': value })
                                }}
                                options={initialData.Employees.map((options) => TextCapaitalized(options.FirstName + ' ' + options.LastName))}
                                sx={{ width: "100%" }}
                                value={TextCapaitalized(employeeData.REPORTINGTO)}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        required
                                        name="Reporting Manager"
                                        label="Reporting Manager"
                                        error={isError.isReportingManager}
                                    />
                                )}
                            />
                        </div>
                        <div className='update-employee-field'>
                            <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="location">Select Location*</InputLabel>
                                <Select
                                    label="Select Location"
                                    id="location"
                                    placeholder="Select Location"
                                    error={isError.isLocation}
                                    sx={{ width: "100%" }}
                                    value={employeeData.LOCATION}
                                    onChange={(e) => {
                                        setIsError({ ...isError, "isLocation": false })
                                        setEmployeeData({ ...employeeData, "LOCATION": e.target.value })
                                    }}
                                >
                                    {
                                        LocationColumn.map((item, index) => {
                                            return <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                    </div>
                </div>
            </div>

            {/* Experiences */}
            <div className='update-employee-card'>
                <div className='update-employee-card-title'>
                    <span>Experiences</span>
                </div>
                <div className='update-employee-content'>
                    <div className='update-employee-span'>
                        <div className='update-employee-field'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    required
                                    label="Career Start Date"
                                    inputFormat="MM/DD/YYYY"
                                    value={employeeData.CAREERSTARTDATE}
                                    onChange={handleCareerStartDate}
                                    renderInput={(params) => <TextField
                                        required
                                        {...params}
                                        sx={{ width: "100%", height: "35px" }}
                                        size='small'
                                        name='ending_date'
                                        error={isError.isCareerStartDate}
                                    />}
                                />
                            </LocalizationProvider>
                        </div>
                        <div className='update-employee-field'>
                            {
                                IsCheck.careerStartDate && (
                                    <span style={{ marginLeft: '-10px', color: 'red', width: "100%" }}>Please provide a valid Date</span>
                                )
                            }
                        </div>
                    </div>
                    <div className='update-employee-span' style={{ marginTop: "20px" }}>
                        <div className='update-employee-field'>
                            <FormControl sx={{ width: "100%" }}>
                                <InputLabel id="domain-select">Select Domain</InputLabel>
                                <Select
                                    sx={{ width: "100%" }}
                                    value={domain}
                                    label="Select Domain"
                                    onChange={(e) => setDomain(e.target.value)}
                                >
                                    {
                                        initialData.Domains.map((item, index) => {
                                            return <MenuItem key={index} value={item.Domain}>{item.Domain}</MenuItem>
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className='update-employee-field'>
                            <TextField
                                type='number'
                                label='Domain Year'
                                sx={{ flex: 1 }}
                                value={domainexp}
                                onChange={(e) => {
                                    if (Number.parseInt(e.target.value) < 100 && Number.parseInt(e.target.value) >= 0) {
                                        setDomainexp(Number.parseInt(e.target.value))
                                    }
                                    else if (e.target.value === '') {
                                        setDomainexp('')
                                    }
                                    else {
                                        setDomainexp(0)
                                        return false
                                    }
                                }}
                            />
                            <div className='update-employe-domain-add'>
                                <Add onClick={handleDomainsUpdate} />
                            </div>
                        </div>
                    </div>
                    <div style={{ marginTop: '-10px', padding: '0 10px' }}>
                        {
                            (domain !== null && domainexp !== '') && (
                                <span style={{ color: 'red' }}>Please Click the Plus button to add the domain</span>
                            )
                        }
                    </div>
                    {employeeData.INITIALDOMAINS.length !== 0 && (
                        <div className="update-employee-table">
                            <table>
                                <thead>
                                    <tr>
                                        <th>Domain</th>
                                        <th>Domain Experience</th>
                                        <th>Options</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {employeeData.INITIALDOMAINS.map((e) => {
                                        return (
                                            <tr key={e.Id}>
                                                <td>{e.Domain}</td>
                                                <td>{e.DomainExperience}</td>
                                                <td
                                                    onClick={() => {
                                                        handleDomainDelete(e.Domain, e.DomainExperience);
                                                    }}
                                                >
                                                    <Close />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>
                    )}
                </div>
            </div>

            {/* Certifications */}
            <div className='update-employee-card'>
                <div className='update-employee-card-title'>
                    <span>Certifications</span>
                </div>
                <div className='update-employee-content'>
                    <div className='update-employee-span'>
                        <div className='update-employee-field' style={{ width: '70%' }}>
                            <TextField
                                label='Certifications'
                                placeholder="Enter Certifications"
                                value={employeeData.CERTIFICATIONS}
                                onChange={handleCertification}
                                style={{
                                    width: "100%",
                                }}
                                helperText='Please use semi-colon [;] to saperate certifications'
                            />
                        </div>
                    </div>
                    <div className='update-employee-span'>
                        <div className='update-employee-field' style={{ width: '100%' }}>
                            <TextField
                                multiline
                                placeholder="Career Highlights"
                                label="Career Highlights"
                                name="career_highlights"
                                style={{
                                    width: "100%",
                                }}
                                value={handleBulletDots(employeeData.CAREERHIGHLIGHTS)}
                                onChange={handleCareerHighlights}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UpdateEmployeeSummary



const LocationColumn = [
    {
        name: "India",
        value: 0,
    },
    {
        name: "USA",
        value: 1,
    },
    {
        name: "Philippines",
        value: 2,
    },
];

const InitialValues = {
    FIRSTNAME: "",
    LASTNAME: "",
    EMAIL: "",
    PNCEMPLOYEEID: '',
    COUNTRYCODE: null,
    PHONENUMBER: '',
    ORGANIZATION: "",
    SPECIALIZATION: "",
    CERTIFICATIONS: "",
    CAREERHIGHLIGHTS: "",
    CAREERSTARTDATE: null,
    REPORTINGTO: null,
    DESIGNATION: null,
    CLIENT: null,
    LOCATION: '',
    EDUCATION: null,
    "INITIALDOMAINS": [],
    "UPDATEDDOMAINS": [],
    "TOTALEXPERIENCE": 0
}