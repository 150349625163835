import React, { useEffect, useState } from 'react'
import "./EditAllocation.css"
import { useDispatch, useSelector } from 'react-redux'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs from 'dayjs';
import { getInitialValues, setInitialValues } from '../../../redux/features/InitialSlice'
import { setLoadingScreen } from '../../../redux/features/ActivateSlice'
import { Close } from '@mui/icons-material'
import { Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { AxiosPost } from '../../../data/utilities';
import { toast } from 'react-toastify';


function EditAllocation({ element, setIsActiveAssignProjectEdit }) {

    const dispatch = useDispatch()
    const initialData = useSelector(getInitialValues)

    const [joiningDate, setJoiningDate] = useState(dayjs(element.JOINING_DT))
    const [endingDate, setEndingDate] = useState(dayjs(element.ENDING_DT))

    const [approver, setApprover] = useState('')

    const [tempEmployees, setTempEmployees] = useState([])
    const [overTime, setOverTime] = useState(element.OT)
    const [role, setRole] = useState(element.ROLE)


    useEffect(() => {
        if (initialData.length !== 0) {
            setTempEmployees(initialData.Employees)
            let result = initialData.AllocatedProjects.filter(item => item.PROJECTCODE === `OT-${element.PROJECTCODE}`)
            if (result.length !== 0) {
                setOverTime(result[0].OT)
            }
        }
    }, [initialData, element.PROJECTCODE])

    useEffect(() => {
        if (tempEmployees.length !== 0) {
            setApprover(tempEmployees.filter(item =>
                (item.FirstName.toString().toLowerCase() + ' ' + item.LastName.toString().toLowerCase())
                === element.APPROVER.toString().toLowerCase())[0].Id)
        }
    }, [element, tempEmployees])

    const handleAssignProjectEdit = async () => {
        if (role === '') {
            toast.error("Please fill Job Title", { toastId: "iemployee-toast" });
            return false
        }
        else {

            dispatch(setLoadingScreen(true))
            let result = await AxiosPost(`/timesheet/update-allocate-project`, {
                joiningDate: `${joiningDate.$y}-${joiningDate.$M + 1}-${joiningDate.$D}`,
                endingDate: `${endingDate.$y}-${endingDate.$M + 1}-${endingDate.$D}`,
                Approver: approver,
                Id: element.ID,
                FKEmpId: element.FK_EMPLOYEEID,
                overTimeChecked: overTime,
                role: role
            })

            if (result.data.isSuccess) {
                dispatch(setLoadingScreen(false))
                dispatch(setInitialValues({ ...initialData, "AllocatedProjects": result.data.LatestAllocatedProjects }))
                setIsActiveAssignProjectEdit(false)
            }
        }
    }

    return (
        <div className='edit_assign_project_code_container'>
            <div className='edit_assign_project_code_container_card'>
                <div className='edit_assign_project_code_container_header'>
                    <div className='edit_assign_project_code_container_header_title'>
                        <span>Edit Assign Project</span>
                    </div>
                    <div className='edit_assign_project_code_container_header_close'>
                        <Close sx={{ cursor: "pointer" }} onClick={() => {
                            setIsActiveAssignProjectEdit({ active: false, Id: null })
                        }} />
                    </div>
                </div>
                <div className='edit_assign_project_code_container_content'>
                    {/* Project Code and Project Name */}
                    <div className='edit_assign_project_code_container_content_span'>
                        <div className='edit_assign_project_code_container_span_box'>
                            <label>Project Code</label>
                            <span>{element.PROJECTCODE}</span>
                        </div>
                        <div className='edit_assign_project_code_container_span_box'>
                            <label>Project Name</label>
                            <span>{element.PROJECTNAME}</span>
                        </div>
                    </div>
                    {/* Client Name and Employee Name*/}
                    <div className='edit_assign_project_code_container_content_span'>
                        <div className='edit_assign_project_code_container_span_box'>
                            <label>Client Name</label>
                            <span>{element.CLIENT}</span>
                        </div>
                        <div className='edit_assign_project_code_container_span_box'>
                            <label>Employee Name</label>
                            <span style={{ textTransform: "capitalize" }}>{element.EMPLOYEENAME}</span>
                        </div>
                    </div>
                    {/* Role and Location  */}
                    <div className='edit_assign_project_code_container_content_span'>
                        <div className='edit_assign_project_code_container_span_box'>
                            <label>Location</label>
                            <span>{
                                Locations.filter(item => item.id === element.LOCATION).length !== 0 ? Locations.filter(item => item.id === element.LOCATION)[0].Status : 'Not Available'
                            }</span>
                        </div>
                        <div className='edit_assign_project_code_container_span_box'>
                            {/* <label>Job Title</label>
                            <span style={{ textTransform: "capitalize" }}>{element.ROLE}</span> */}
                        </div>

                    </div>
                    {/* Joining Date and Ending Date */}
                    <div className='edit_assign_project_code_container_content_span'>
                        <div className='edit_assign_project_code_container_span_box'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    label="Project Start Date"
                                    inputFormat="MM/DD/YYYY"
                                    value={joiningDate}
                                    onChange={(value) => {
                                        setJoiningDate(dayjs(value))
                                    }}
                                    renderInput={(params) => <TextField size='small' required name='joining_date' {...params} sx={{ width: "100%", height: "35px" }} />}
                                />
                            </LocalizationProvider>
                        </div>
                        {/* calender */}
                        <div className='edit_assign_project_code_container_span_box'>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <MobileDatePicker
                                    label="Project End Date"
                                    inputFormat="MM/DD/YYYY"
                                    value={endingDate}
                                    onChange={(value) => {
                                        setEndingDate(dayjs(value))
                                    }}
                                    renderInput={(params) => <TextField size='small' required name='ending_date' {...params} sx={{ width: "100%", height: "35px" }} />}
                                />
                            </LocalizationProvider>
                        </div>
                    </div>

                    {/* Manager / Approver */}
                    <div className='edit_assign_project_code_container_content_span_1'>

                        <div className='edit_assign_project_code_container_span_box'>
                            {/* Manager / Approver selection */}
                            <FormControl fullWidth>
                                <InputLabel required id="ManagerApprover">Manager / Approver</InputLabel>
                                <Select
                                    label="Manager / Approver"
                                    id="ManagerApprover"
                                    placeholder='Select Manager / Approver'
                                    sx={{ width: "100%", textTransform: "capitalize" }}
                                    value={approver}
                                    onChange={(e) => {
                                        setApprover(e.target.value)
                                    }}
                                >
                                    {
                                        tempEmployees.map((item, index) => {
                                            return (
                                                <MenuItem value={item.Id} key={index} sx={{ textTransform: "capitalize" }}>{item.FirstName + " " + item.LastName}</MenuItem>
                                            )
                                        })
                                    }
                                </Select>
                            </FormControl>
                        </div>
                        <div className='edit_assign_project_code_container_span_box'>
                            <TextField
                                required
                                placeholder='Enter the Job Title'
                                label='Job Title'
                                sx={{ width: "100%" }}
                                value={role}
                                onChange={(e) => {
                                    if (e.target.value.length <= 30) {
                                        setRole(e.target.value)
                                    }
                                    else {
                                        setRole(role);
                                    }
                                }}
                            />
                        </div>
                    </div>

                </div>
                <div className='edit_assign_project_code_container_actions'>
                    <div className='assign_project_code_container_span_box'>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox
                                value={overTime}
                                checked={overTime}
                                onClick={() => setOverTime(!overTime)}
                            />} label='Over Time' />
                        </FormGroup>
                    </div>
                    <div>
                        <button onClick={() => setIsActiveAssignProjectEdit({ active: false, Id: null })}>Cancel</button>
                        <button onClick={handleAssignProjectEdit}>Update</button>  {/* to be reviewed */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditAllocation;


const Locations = [
    {
        id: -1,
        Status: "All",
        value: 'All'
    },
    {
        id: 0,
        Status: "India",
        value: "0",
    },
    {
        id: 1,
        Status: "USA",
        value: 1,
    },
    {
        id: 2,
        Status: "Philippines",
        value: 2,
    },
]