import React, { useEffect, useState } from 'react'
import "./Approvals.css"
import { NavLink, useLocation, useParams } from "react-router-dom";
import CryptoJS from 'crypto-js'
import { Close } from "@mui/icons-material";
import { AxiosGetwithHeaders, AxiosPost } from '../../data/utilities';

function Approvals() {

    const params = useParams()
    const location = useLocation()
    const [processData, setProcessData] = useState(null)
    const [showMessage, setShowMessage] = useState('')

    const [isComment, setIsComment] = useState(false)
    const [comment, setComment] = useState('')

    useEffect(() => {
        try {
            let value = JSON.parse(CryptoJS.AES.decrypt(params.process_data.split("|").join("/"), process.env.REACT_APP_SECRECT_KEY).toString(CryptoJS.enc.Utf8));
            setProcessData(value)
        }
        catch (err) {
            setShowMessage('error')
            setProcessData(null)
        }

    }, [params])

    useEffect(() => {

        const handleProcess = async () => {
            setShowMessage('loading')

            // get the employee id based on the email

            let FKEmployeeId = await AxiosGetwithHeaders('/iemployee/get-current-employee-by-mail', {
                mail: location.state.email
            })

            FKEmployeeId = FKEmployeeId.data.result.Id

            let result = await AxiosPost(`/timesheet/checkstatus`,
                {
                    WeekId: processData.WeekId,
                    FKEmpId: processData.FKEmpId,
                })

            if (result.data.isSuccess) {
                if (processData.Process === 'Approved') {
                    if (result.data.Status === 'Approved') {
                        setShowMessage('already-approve')
                        return false
                    }
                    let result1 = await AxiosPost(
                        `/timesheet/approve-timesheet`, {
                        AllWeekIds: [processData.WeekId],
                        FKEmpId: FKEmployeeId,
                        Comment: processData.Comment,
                    })
                    if (result1.data.isSuccess) {
                        if (result1.data.Status === 'Rejected') {
                            setShowMessage('already-rejected')
                            return false
                        }
                        setShowMessage('approve')
                    }
                }
                else {
                    setIsComment(true)
                }
            }

        }

        if (processData !== null) {
            handleProcess()
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [processData])

    const handleReject = async () => {
        setIsComment(false)
        setShowMessage('loading')

        let FKEmployeeId = await AxiosGetwithHeaders('/iemployee/get-current-employee-by-mail', {
            mail: location.state.email
        })

        FKEmployeeId = FKEmployeeId.data.result.Id

        let result = await AxiosPost(`/timesheet/reject-timesheet`, {
            AllWeekIds: [processData.WeekId],
            FKEmpId: FKEmployeeId,
            Comment: processData.Comment,
        })

        if (result.data.isSuccess) {
            setShowMessage('reject')
        }

    }

    const handleClose = () => {
        setIsComment(false)
    }

    return (
        <>
            <div className='approvals-container'>
                <div className='approvals-card'>
                    <div style={{ textAlign: "center" }}>
                        <p>
                            {showMessage === 'approve' ? "Approved" :
                                showMessage === 'reject' ? "Rejected" :
                                    showMessage === 'already-approve' ? "Already Approved" :
                                        showMessage === 'loading' ? "Loading Please Wait" :
                                            showMessage === 'already-rejected' ? "Already Rejected" :
                                                "Error Page Not Found"}
                        </p>
                        <NavLink to={'/Dashboard'}>Go Back</NavLink>
                    </div>
                </div>
            </div>
            {isComment && (
                <div className="timesheet_comment_container">
                    <div className="timsheet_comment_card">
                        <div className="timesheet_comment_header">
                            <span>Comments* </span>
                            <Close onClick={handleClose} style={{ cursor: "pointer" }} />
                        </div>
                        <div className="timesheet_comment_content">
                            <textarea
                                value={comment}
                                onChange={(e) => {
                                    if (e.target.value.length <= 100) {
                                        setComment(e.target.value);
                                    }
                                }}
                                placeholder="Enter Comment*"
                            />
                            <div
                                className="timesheet_comment_count"
                                style={{ color: comment.length > 100 ? "red" : "#B9B9B9" }}
                            >
                                <span>{comment.length}/100</span>
                            </div>
                        </div>
                        <div className="timesheet_comment_actions">
                            <button onClick={handleReject}>Reject</button>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}

export default Approvals