import React, { useEffect, useState } from "react";
import "./EditUserProfile.css";

import CryptoJS from "crypto-js";
import { useSelector, useDispatch } from "react-redux";
import { Close } from "@mui/icons-material";
import { InputLabel, Select, FormControl, MenuItem, TextField, } from "@mui/material";

import {
    getUserData,
    setUserLoggedInData,
} from "../../../redux/features/UserSlice";

import { getInitialValues } from "../../../redux/features/InitialSlice";

import {
    getEditUser,
    setEditUser,
    setLoadingScreen,
} from "../../../redux/features/ActivateSlice";
import { CountryCodes } from "../../../data/CountryCodeWithLength";
import { toast } from 'react-toastify'
import Messages from '../../../data/Messages.json'
import { AxiosGetwithHeaders, AxiosPost } from "../../../data/utilities";

function EditUserProfile({ RefreshContent }) {

    const initialData = useSelector(getInitialValues)
    const editUser = useSelector(getEditUser);
    const userdata = useSelector(getUserData);

    const dispatch = useDispatch();
    const [username, setUsername] = useState("");
    const [isUsernameExists, setIsUsernameExists] = useState(false);

    const [email, setEmail] = useState("");
    const [disableEmail, setDisableEmail] = useState(true);

    const [isEmailCheck, setIsEmailCheck] = useState({
        active: false,
        message: "",
    });

    const [PnCemployeeId, setPnCEmployeeId] = useState("");
    const [PnCemployeeIdCheck,setPnCEmployeeIdCheck] = useState(false);

    const [phoneNumber, setPhoneNumber] = useState("");
    const [phoneNumberCheck, setPhoneNumberCheck] = useState(false);

    const [organization, setOrganization] = useState("");

    const [allUsers, setAllUsers] = useState([]);

    const [country, setCountry] = useState(0);
    const [role, setRole] = useState("");


    useEffect(() => {

        const handleGetUserData = async () => {
            dispatch(setLoadingScreen(true))
            let response = await AxiosGetwithHeaders(`/iemployee/get-current-employee-by-id`, {
                id: editUser.currentUser
            })
            if (response.data.isSuccess) { // #########
                dispatch(setLoadingScreen(false))
                let user = response.data.UserData
                setUsername(user.username);
                setEmail(user.email);
                setPhoneNumber(user.phoneNumber);
                setPnCEmployeeId(user.PnCemployeeId);
                setCountry(user.countryCode);
                setRole(user.FkRoleId);
                setOrganization(
                    user.organization.toString().substring(0, 1).toUpperCase() +
                    user.organization.toString().substring(1).toLowerCase()
                );

            }
        }
        if (initialData.length !== 0) {
            if (editUser.active) {
                handleGetUserData()
            }
            setAllUsers(initialData.Users.filter((item) => item.Id !== userdata.Id));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userdata, editUser]);

    const OnValidUsername = (e) => {
        if (e.target.value.length <= 50) {
            setUsername(e.target.value);
        }

        allUsers.every((user) => {
            if (user.username === e.target.value) {
                setIsUsernameExists(true);
                return false;
            } else {
                setIsUsernameExists(false);
                return true;
            }
        });
    };

    const handleEmailCheck = (e) => {
        const EMAIAL_CHECK = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/;

        if (e.target.value.length <= 50) {
            setEmail(e.target.value);
        }

        if (
            initialData.Users.filter((item) => item.email === e.target.value).length !== 0
        ) {
            setIsEmailCheck({
                active: true,
                message: "Mail Address is Already Exists",
            });
        } else {
            if (EMAIAL_CHECK.test(e.target.value)) {
                setIsEmailCheck({
                    active: false,
                    message: "",
                });
            } else {
                setIsEmailCheck({
                    active: true,
                    message: "Please Provide Valid Mail Address",
                });
            }
        }

        if (e.target.value.length === 0) {
            setIsEmailCheck({
                active: true,
                message: "Please Provide Valid Mail Address",
            });
        }
    };

    const handlePhoneNumber = (e) => {

        // const Phone_REGEX = /^(?=.*[a-z][@#$%^&*!]).{0,24}$/;
        const Phone_REGEX = /[0-9]/;

        let CountryLength = Number.parseInt(CountryCodes.filter(item => Number.parseInt(item.country_code) === Number.parseInt(country))[0].phoneLength)

        if (e.target.value === "") {
            setPhoneNumberCheck(false);
            setPhoneNumber('')
            return false
        }

        if (Phone_REGEX.test(e.target.value)) {
            if (e.target.value.length <= CountryLength) {
                setPhoneNumberCheck(false);
                setPhoneNumber(e.target.value);
            }
        }
        if (e.target.value.length < CountryLength) {
            setPhoneNumberCheck(true);
        }

    };

    const handlePnCEmployeeId = (e) => {
        e.target.value = e.target.value.replace(/[^0-9]/g, '');
      
        const PnCEmployeeID_REGEX = /^[0-9]{5}$/;  
      
        if (e.target.value === '') {
          setPnCEmployeeIdCheck(false);
          setPnCEmployeeId('');
          return false;
        }
      
        if (!PnCEmployeeID_REGEX.test(e.target.value)) {
          setPnCEmployeeIdCheck(false);
        } else {
          setPnCEmployeeIdCheck(true);
          setPnCEmployeeId(e.target.value);
        }
      };
      

    // const handleRole = (id) => {
    //     let values = initialData.Roles.filter((item) => item.Id === Number.parseInt(id));
    //     return values[0].role;
    // };

    const handleUpdateUserAccount = async () => {
        if (
            username === "" ||
            email === "" ||
            phoneNumber === "" ||
            country === 0 ||
            role === ""||
            PnCemployeeId === ""
        ) {
            toast.error(Messages.EditUserProfile.EditUserProfile_Provide_Details, { toastId: "iemployee-toast" })
        } else {
            if (
                phoneNumberCheck === true ||
                isUsernameExists === true ||
                isEmailCheck.active === true||
                PnCemployeeIdCheck === true
            ) {
                toast.warn(Messages.EditUserProfile.EditUserProfile_ReCheck_Details, { toastId: "iemployee-toast" })
            } else {
                dispatch(setLoadingScreen(true));
                let result = await AxiosPost(
                    `/iemployee/updateUser`,
                    {
                        Id: editUser.currentUser,
                        userName: username,
                        email: email,
                        phoneNumber: phoneNumber,
                        countryCode: country,
                        FKRoleId: role,
                        FKEmployeeId: userdata.Id,
                        employeeId: PnCemployeeId
                    }
                );

                if (result.data.isSuccess) {
                    // dispatch new user data

                    if (editUser.where === "profile") {
                        let SECURE_LOGIN = CryptoJS.AES.encrypt(
                            JSON.stringify({
                                id: userdata.Id,
                                username: username,
                                email: email,
                                password: userdata.password,
                                phoneNumber: phoneNumber,
                                organization: userdata.organization,
                                //role: handleRole(role),
                                FkRoleId: role,
                                FKEmpId: userdata.Id,
                                countryCode: country,
                            }),
                            process.env.REACT_APP_SECRECT_KEY
                        ).toString();

                        localStorage.setItem("_user", SECURE_LOGIN);

                        dispatch(
                            setUserLoggedInData({
                                id: userdata.Id,
                                username: username,
                                email: email,
                                phoneNumber: phoneNumber,
                                organization: userdata.organization,
                                //role: handleRole(role),
                                FkRoleId: role,
                                FKEmpId: userdata.Id,
                                countryCode: country,
                            })
                        );
                    }
                    // loading off
                    dispatch(setLoadingScreen(false));
                    // user edit off
                    dispatch(
                        setEditUser({
                            active: false,
                            currentUser: 0,
                        })
                    );
                    toast.success(Messages.EditUserProfile.EditUserProfile_Success, { toastId: "iemployee-toast" })
                    RefreshContent();
                }
            }
        }
    };

    const handleEmailDisable = (e) => {
        if (e.ctrlKey && e.key === "0") {
            setDisableEmail(!disableEmail);
        }
    };

    return (
        <>
            {
                editUser.active && (
                    <div className="edit_user_data">
                        <div className="edit_user_data_center">
                            {/* Header */}
                            <div className="edit_user_data_header">
                                <span>Edit User Data</span>
                            </div>
                            {/* Text Fields */}
                            <div className="edit_user_data_text_fields">
                                {/* Username */}
                                <div className="edit_user_span_1">
                                    <div className="edit_user_span_field_input">
                                        <TextField
                                            size="small"
                                            label="User Name"
                                            name="username"
                                            sx={{ width: "100%" }}
                                            value={username}
                                            onChange={OnValidUsername}
                                            onKeyDownCapture={handleEmailDisable}
                                        />
                                    </div>
                                </div>
                                {isUsernameExists && (
                                    <small className="show_error">
                                        Username is already Exists...!
                                    </small>
                                )}
                                {/* Email Address */}
                                <div className="edit_user_span_1">
                                    <div className="edit_user_span_field_input">
                                        <TextField
                                            size="small"
                                            label="Email Address"
                                            name="email"
                                            sx={{ width: "100%" }}
                                            value={email}
                                            onChange={handleEmailCheck}
                                            disabled={disableEmail}
                                        />
                                    </div>

                                {isEmailCheck.active && (
                                    <small className="show_error">{isEmailCheck.message}</small>
                                    )}

                                    {/* Employee ID */}
                                    <div className="edit_user_span_1">
                                    <div className="edit_user_span_field_input">
                                        <TextField
                                            type="number"
                                            size="small"
                                            label="PnC Employee ID"
                                            name="PnCEmployee ID"
                                            sx={{ width: "100%" }}
                                            value={PnCemployeeId}
                                            onChange={handlePnCEmployeeId}
                                        />
                                        {PnCemployeeIdCheck && (
                                            <small className="show_error">
                                                Please provide the correct ID..!
                                            </small>
                                        )}
                                    </div>
                                    </div>
                                </div>
                                {/* country code */}
                                <div className="edit_user_span_1">
                                    <div className="edit_user_span_field_input">
                                        <FormControl sx={{ width: "100%" }}>
                                            <InputLabel id="country-select">Select Country</InputLabel>
                                            <Select
                                                label="Select Country "
                                                id="country-select"
                                                placeholder="Select Country "
                                                sx={{ width: "100%", height: "42px" }}
                                                value={country}
                                                onChange={(e) => {
                                                    setCountry(e.target.value)
                                                    setPhoneNumber(0)
                                                }}
                                            >
                                                {CountryCodes.map((code, index) => {
                                                    return (
                                                        <MenuItem key={index} value={code.country_code}>
                                                            {code.country}
                                                        </MenuItem>
                                                    );
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>
                                {/* Phone Number */}
                                <div className="edit_user_span_1">
                                    <div className="edit_user_span_field_input">
                                        <TextField
                                            type="number"
                                            size="small"
                                            label="Phone Number"
                                            name="Phone Number"
                                            sx={{ width: "100%" }}
                                            value={phoneNumber}
                                            onChange={handlePhoneNumber}
                                        />
                                        {phoneNumberCheck && (
                                            <small className="show_error">
                                                Please provide the correct Number..!
                                            </small>
                                        )}
                                    </div>
                                </div>
                                {/* Organization */}
                                <div className="edit_user_span_1">
                                    <div className="edit_user_span_field_input">
                                        <TextField
                                            size="small"
                                            label="Enter Organization"
                                            name="Enter Organization"
                                            sx={{ width: "100%" }}
                                            disabled={true}
                                            value={organization}
                                            onChange={(e) => setOrganization(e.target.value)}
                                        />
                                    </div>
                                </div>
                                {/* role */}
                                {
                                    editUser.where === "profile" ?
                                        <></>
                                        :
                                        <div className="edit_user_span_1">
                                            <div className="edit_user_span_field_input">
                                                <FormControl sx={{ width: "100%" }}>
                                                    <InputLabel id="role">Select Role</InputLabel>
                                                    <Select
                                                        label="Select Role "
                                                        id="role"
                                                        placeholder="Select Role "
                                                        sx={{ width: "100%", height: "42px" }}
                                                        value={role}
                                                        onChange={(e) => setRole(e.target.value)}
                                                    >
                                                        {
                                                            initialData.Roles.filter(item => item.Role !== 'Admin').map((user) => {
                                                                return (
                                                                    <MenuItem value={user.Id} key={user.Id}>
                                                                        {user.Role}
                                                                    </MenuItem>
                                                                );
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                            </div>
                                        </div>
                                }
                            </div>
                            <div className="edit_user_navigations">
                                <div className="edit_user_btn">
                                    <button onClick={handleUpdateUserAccount}>Update</button>
                                </div>
                            </div>
                            {/* Close */}
                            <div className="edit_user_clsoe">
                                <Close
                                    fontSize="large"
                                    onClick={() => {
                                        dispatch(
                                            setEditUser({
                                                active: false,
                                                currentUser: 0,
                                            })
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )
            }
        </>
    );
}

export default EditUserProfile;
