import { setLoadingScreen } from '../../../redux/features/ActivateSlice'
import { getWeek } from 'date-fns'
import { AxiosPost, getCurrentDate } from '../../../data/utilities'

export const handleGetWeeklyTimesheetDetails = async (
    dispatch,
    FKEmpId,
    numberOfWeeks,
    setScroll,
    setEmployeeSheet,
    setTempEmployeeSheet,
    year,
    setCurrentWeek
) => {

    dispatch(setLoadingScreen(true))

    let result = await AxiosPost(`/timesheet/get-weekly-timesheet-by-fk-id`, {
        FKId: FKEmpId,
        Year: year,
        NumberOfWeeks: numberOfWeeks
    })

    if (result.data.isSuccess) {

        if (Number.parseInt(year) === getCurrentDate().getFullYear()) {
            setScroll(true)
            setCurrentWeek(getWeek(getCurrentDate()))
        }
        else {
            setCurrentWeek(0)
            setScroll(false)
        }

        dispatch(setLoadingScreen(false))
        setEmployeeSheet(result.data.EmployeeTimesheet)
        setTempEmployeeSheet(result.data.EmployeeTimesheet)
    }
    else {
        dispatch(setLoadingScreen(false))
    }

}