import React from 'react'
import { Route, Routes } from 'react-router-dom'
import WeeklyTimesheet from '../../../WeeklyTimesheet'
import Timesheet from '../../../Timesheet'
import Employees from '.'

function App() {
    return (
        <Routes>
            <Route path='/' element={<Employees />} />

            <Route path='/weeklytimesheet/:EmpId' element={<WeeklyTimesheet />} />

            <Route path='/timesheet/:timesheetId' element={<Timesheet />} />
        </Routes>
    )
}

export default App