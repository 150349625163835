import React from 'react'

import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getIsLoggedIn } from '../redux/features/UserSlice'

import ProjectCodeGenerationIsLoggedIn from './Layouts'
import ProjectCodes from './Screens/ProjectCodes'
import GenerateProjectCode from './Screens/ProjectCodeGenerator'
import EditProjectCode from './Screens/EditProjectCode'

function ProjectCodeGeneration() {

    const isLoggedIn = useSelector(getIsLoggedIn)

    return (
        <Routes>

            <Route element={<ProjectCodeGenerationIsLoggedIn isExists={isLoggedIn} />}>

                <Route path='/home' element={<ProjectCodes />} />

                <Route path='/generate-project-code' element={<GenerateProjectCode />} />
                
                <Route path = '/edit-project-code' element = {<EditProjectCode/>} />

            </Route>
            {/* <Route path='/*' element={<ErrorPage />} /> */}
        </Routes>
    )
}

export default ProjectCodeGeneration