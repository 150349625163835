import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    usersData: [],
    isLoggedIn: false,
    userData: [],
    roles: []
}

export const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {

        setIsLoggedIn: (state, action) => {
            state.isLoggedIn = action.payload
        },

        setUsersData: (state, action) => {
            state.usersData = action.payload
        },

        setUserLoggedInData: (state, action) => {
            state.userData = action.payload
        },

        setRoles: (state, action) => {
            state.roles = action.payload
        },

        setLogout: (state) => {
            state.userData = []
            state.isLoggedIn = false
        }

    },
})

export const { setIsLoggedIn, setUsersData, setUserLoggedInData, setLogout, setRoles } = usersSlice.actions

export const getUsersData = (state) => state.users.usersData

export const getIsLoggedIn = (state) => state.users.isLoggedIn

export const getUserData = (state) => state.users.userData

export const getRoles = (state) => state.users.roles

export default usersSlice.reducer