import CryptoJS from 'crypto-js'
import { AxiosPost } from '../../../data/utilities';

export const handleSubmitProfile = async (
    firstName,
    lastName,
    email,
    pnCEmployeeId,
    phoneNumber,
    designation,
    education,
    specialization,
    userData,
    dispatch,
    client,
    setUserLoggedInData,
    RefreshContent,
    navigate,
    setLoadingScreen,
    reportingTo,
    billable,
    location
) => {
    let res = await AxiosPost(`/iemployee/add-new-employee`, {
        FirstName: firstName.toLowerCase(),
        LastName: lastName.toLowerCase(),
        Email: email.toLowerCase(),
        PnCEmployeeId: Number.parseInt(pnCEmployeeId),
        Location: location,
        PhoneNumber: phoneNumber === '' ? 0 : Number.parseInt(phoneNumber),
        Designation: designation,
        Specialization: specialization,
        Education: education,
        ReportingTo: reportingTo,
        Client: Number.parseInt(client),
        Billable: Number.parseInt(billable)
    });

    if (res.data.submitted) {
        let SECURE_LOGIN = CryptoJS.AES.encrypt(JSON.stringify({
            Id: userData.Id,
            username: userData.username,
            email: userData.email,
            password: userData.password,
            phoneNumber: userData.phoneNumber,
            organization: userData.organization,
            role: userData.role,
            FkRoleId: userData.FKRoleId,
            FKEmpId: userData.Id,
            countryCode: userData.countryCode
        }), process.env.REACT_APP_SECRECT_KEY).toString();

        localStorage.setItem('__iemployee__user', SECURE_LOGIN);

        dispatch(setUserLoggedInData({
            Id: userData.Id,
            username: userData.username,
            email: userData.email,
            password: userData.password,
            phoneNumber: userData.phoneNumber,
            organization: userData.organization,
            role: userData.role,
            FkRoleId: userData.FkRoleId,
            FkEmpId: userData.Id,
            countryCode: userData.countryCode
        }));

        RefreshContent();
    }

    dispatch(setLoadingScreen(false));
    return true;
}
