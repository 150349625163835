import React, { useEffect, useState } from 'react'
import "./AllocateProjectCode.css"

import { ArrowDropDown } from '@mui/icons-material'
import { MenuItem, TextField, Tooltip } from '@mui/material'

import AddImage from '../../../assets/Plus-Add-Icon.png'
import { getInitialValues } from '../../../redux/features/InitialSlice'
import { useSelector } from 'react-redux'

import EmptyTable from '../../../Components/NoData';
import SortBy from '../../Pages/SortBy'
import Allocations from '../Allocations'
import EditAllocation from '../EditAllocation'
import UploadProjectCode from '../UploadProjectCode'

import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';

function AllocateProjectCode() {
    const initialData = useSelector(getInitialValues)

    const [filterProjectCode, setFilterProjectCode] = useState({
        value: 'Select Project',
        isActive: false
    })
    const [filterEmployeeName, setFilterEmployeeName] = useState({
        value: null,
        isActive: false
    })
    const [filterLocation, setFilterLocation] = useState({
        value: -1,
        isActive: false
    })
    const [filterApprover, setFilterApprover] = useState({
        value: "Select Approver",
        isActive: false
    })


    const [isActiveProjectCodes, setIsActiveProjectCodes] = useState(false)
    const [isActiveAssignProject, setIsActiveProjectAssign] = useState(false)
    const [AllocatedProjects, setAllocatedProjects] = useState([])
    const [isActiveAssignProjectEdit, setIsActiveAssignProjectEdit] = useState({ active: false, element: null })

    const [toggleFilter, setToggleFilter] = useState(false)

    useEffect(() => {

        if (initialData.length !== 0) {
            setAllocatedProjects(initialData.AllocatedProjects)
        }

    }, [initialData])

    useEffect(() => {

        const filteredAllocatedProject = initialData.AllocatedProjects.filter((item) => {

            const projectCode = filterProjectCode.value === "Select Project" ? true : item.PROJECTCODE === filterProjectCode.value;

            const employeeName = filterEmployeeName.value === null ? true : item.EMPLOYEENAME.toString().toLowerCase().includes(filterEmployeeName.value.toString().toLowerCase());

            const location = filterLocation.value === -1 ? true : item.LOCATION === filterLocation.value;

            const approver = filterApprover.value === "Select Approver" ? true : item.APPROVER === filterApprover.value;

            return projectCode && employeeName && location && approver;


        })

        setAllocatedProjects([])
        setTimeout(() => {
            setAllocatedProjects(filteredAllocatedProject)
        }, 50)

    }, [initialData, filterProjectCode.value, filterEmployeeName.value, filterLocation.value, filterApprover.value])

    const handleClear = () => {

        setFilterProjectCode({
            value: 'Select Project',
            isActive: false
        })
        setFilterEmployeeName({
            value: null,
            isActive: false
        })
        setFilterLocation({
            value: -1,
            isActive: false
        })
        setFilterApprover({
            value: "Select Approver",
            isActive: false
        })
    }

    const handleDate = (date) => {

        const localZoneDate = new Date(date)
        return `${localZoneDate.toLocaleString().split(',')[0]}`
    }

    return (
        <>
            <div className="project_allocation_main_screen">

                <div className='project_allocation_filters_container' style={{ width: !toggleFilter ? "0%" : "20%" }}>
                    <div className="project_allocation_filters_container_toggle" onClick={() => setToggleFilter(!toggleFilter)}>
                        {
                            toggleFilter ?
                                <Tooltip placement="top" title={'Hide'}>
                                    <FilterListOffIcon />
                                </Tooltip>
                                :
                                <Tooltip placement="top" title={'Filter'}>
                                    <FilterListIcon />
                                </Tooltip>
                        }
                    </div>
                    <div className="project_allocation_filters">
                        <div className="project_allocation_filters_header">
                            <div className="project_allocation_filters_header_title">
                                <span>Filter</span>
                            </div>
                            <div className="project_allocation_filters_header_clear">
                                <label htmlFor="cl" className='project_allocation_filters_clear_all'>
                                    <span>Clear all</span>
                                </label>
                                <button hidden onClick={handleClear} id="cl">
                                    Clear all
                                </button>
                            </div>
                        </div>

                        {/* Project Code */}
                        <div className="project_allocation_filter_container">
                            <div
                                className="project_allocation_filter_container_header"
                                style={{
                                    borderBottom: filterProjectCode.isActive ? "1px solid #C4C4C4" : "none"
                                }}
                            >
                                <span>Project Code</span>
                                <ArrowDropDown
                                    style={{
                                        color: "#252525",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setFilterProjectCode({ ...filterProjectCode, "isActive": !filterProjectCode.isActive })}
                                />
                            </div>
                            {
                                filterProjectCode.isActive && (
                                    <div className="project_allocation_filter_container_content">
                                        <TextField
                                            id="filterByProject"
                                            select
                                            value={filterProjectCode.value}
                                            helperText="Please select Project Code"
                                            onChange={(e) => setFilterProjectCode({ ...filterProjectCode, "value": e.target.value })}
                                        >
                                            <MenuItem value={'Select Project'}>Select Project</MenuItem>
                                            {
                                                initialData.ProjectCodes.filter(item => item.Typename === 'PROJECT').map((option, index) => (
                                                    <MenuItem key={index} value={option.ProjectCode}>
                                                        {
                                                            option.ProjectCode
                                                        }
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </div>
                                )
                            }
                        </div>

                        {/* Employee Name */}
                        <div className="project_allocation_filter_container">
                            <div
                                className="project_allocation_filter_container_header"
                                style={{
                                    borderBottom: filterEmployeeName.isActive ? "1px solid #C4C4C4" : "none"
                                }}
                            >
                                <span>Employee Name</span>
                                <ArrowDropDown
                                    style={{
                                        color: "#252525",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setFilterEmployeeName({ ...filterEmployeeName, "isActive": !filterEmployeeName.isActive })}
                                />
                            </div>
                            {
                                filterEmployeeName.isActive && (
                                    <div className="project_allocation_filter_container_content">
                                        <TextField
                                            id="filterByClient"
                                            label="Employee Name"
                                            value={filterEmployeeName.value}
                                            helperText="Enter Employee Name"
                                            onChange={(e) => setFilterEmployeeName({ ...filterEmployeeName, "value": e.target.value })}
                                        />
                                    </div>
                                )
                            }
                        </div>

                        {/* Location */}
                        <div className="project_allocation_filter_container">
                            <div
                                className="project_allocation_filter_container_header"
                                style={{
                                    borderBottom: filterLocation.isActive ? "1px solid #C4C4C4" : "none"
                                }}
                            >
                                <span>Location</span>
                                <ArrowDropDown
                                    style={{
                                        color: "#252525",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setFilterLocation({ ...filterLocation, "isActive": !filterLocation.isActive })}
                                />
                            </div>
                            {
                                filterLocation.isActive && (
                                    <div className="project_allocation_filter_container_content">
                                        <TextField
                                            id="Location"
                                            label="Location"
                                            select
                                            value={filterLocation.value}
                                            onChange={(e) => setFilterLocation({ ...filterLocation, "value": e.target.value })}
                                            sx={{ width: "100%" }}
                                        >
                                            {Locations.map((option) => (
                                                <MenuItem key={option.Status} value={option.value}>
                                                    {option.Status}
                                                </MenuItem>
                                            ))}
                                        </TextField>

                                    </div>
                                )
                            }
                        </div>

                        {/* Approver */}
                        <div className="project_allocation_filter_container">
                            <div
                                className="project_allocation_filter_container_header"
                                style={{
                                    borderBottom: filterApprover.isActive ? "1px solid #C4C4C4" : "none"
                                }}
                            >
                                <span>Approver</span>
                                <ArrowDropDown
                                    style={{
                                        color: "#252525",
                                        cursor: "pointer"
                                    }}
                                    onClick={() => setFilterApprover({ ...filterApprover, "isActive": !filterApprover.isActive })}
                                />
                            </div>
                            {
                                filterApprover.isActive && (
                                    <div className="project_allocation_filter_container_content">
                                        <TextField
                                            id="filterByapprove"
                                            select
                                            label="Select Approver"
                                            value={filterApprover.value}
                                            helperText="Please Select Approver"
                                            onChange={(e) => setFilterApprover({ ...filterApprover, "value": e.target.value })}
                                            sx={{ textTransform: "capitalize", width: "100%" }}
                                        >
                                            <MenuItem value={"Select Approver"}>Select Approver</MenuItem>
                                            {
                                                initialData.Employees.map((option, index) => (
                                                    <MenuItem key={index} value={option.FirstName + " " + option.LastName}
                                                        sx={{ textTransform: "capitalize" }}>
                                                        {
                                                            option.FirstName + " " + option.LastName
                                                        }
                                                    </MenuItem>
                                                ))
                                            }
                                        </TextField>
                                    </div>
                                )
                            }
                        </div>

                    </div>
                </div>

                <div className="project_allocation_container">
                    <div className="project_allocation_container_header">
                        <div className="project_allocation_container_header_title">
                            <span>Project Allocation</span>
                            {
                                AllocatedProjects.length !== 0 && (
                                    <label>{AllocatedProjects.length}</label>
                                )
                            }
                        </div>
                        <div className="project_allocation_container_header_navigations">
                            {/* button */}
                            <button
                                onClick={() => setIsActiveProjectCodes(true)}
                            >Add Project Code</button>
                            {/* <button className='project_allocation_btn_1'>Remove</button> */}
                            <button onClick={() => setIsActiveProjectAssign(true)}>
                                <img src={AddImage} alt='add' />
                                Assign
                            </button>
                        </div>
                    </div>
                    <div className="project_allocation_container_table">
                        {
                            AllocatedProjects.length === 0 ?
                                <EmptyTable title="No Data Found" />
                                :
                                <table>
                                    <thead>
                                        <tr style={{ fontWeight: 600 }}>
                                            {
                                                tableCol.map((items, index) => {
                                                    return (
                                                        <td key={index}>
                                                            <span style={{ display: 'flex', gap: "5px" }}>
                                                                {items.name}
                                                                {
                                                                    items.name === 'Joining Date' || items.name === 'Approver' ?
                                                                        <></>
                                                                        :
                                                                        <SortBy
                                                                            list={AllocatedProjects.filter((item) => item.OT === true)}
                                                                            setProjectAllocation={setAllocatedProjects}
                                                                            column={items.name}
                                                                        />
                                                                }
                                                            </span>
                                                        </td>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            AllocatedProjects.filter((item) => item.OT === true && !item.PROJECTCODE.includes('OT-')).map((item, index) => {
                                                return (
                                                    <tr key={index}
                                                        onClick={() => setIsActiveAssignProjectEdit({ active: true, element: item })}>
                                                        <td>{item.PROJECTCODE}</td>
                                                        <td style={{ textTransform: "capitalize" }}>{item.EMPLOYEENAME}</td>
                                                        <td>{item.ROLE}</td>
                                                        <td>{handleDate(item.JOINING_DT)}</td>
                                                        <td>{item.BILLABLE === 0 ? "No" : "Yes"}</td>
                                                        <td>{item.BILLINGRATE}</td>
                                                        <td>
                                                            {
                                                                item.LOCATION === 1
                                                                    ? "USA"
                                                                    : item.LOCATION === 0
                                                                        ? "India"
                                                                        : "Philippines"
                                                            }
                                                        </td>
                                                        <td style={{ textTransform: "capitalize" }}>{item.APPROVER}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                        }
                    </div>
                </div>
            </div>
            {
                isActiveProjectCodes && (
                    <UploadProjectCode
                        setIsActiveProjectCodes={setIsActiveProjectCodes}
                    />
                )
            }
            {
                isActiveAssignProject && (
                    <Allocations
                        setIsActiveProjectAssign={setIsActiveProjectAssign}
                    />
                )
            }
            {
                isActiveAssignProjectEdit.active && (
                    <EditAllocation
                        element={isActiveAssignProjectEdit.element}
                        setIsActiveAssignProjectEdit={setIsActiveAssignProjectEdit}
                    />
                )
            }
        </>
    )
}

export default AllocateProjectCode



const tableCol = [
    {
        id: 0,
        name: "Project Code"
    },
    {
        id: 1,
        name: "Full Name"
    },
    {
        id: 2,
        name: "Job Title"
    },
    {
        id: 3,
        name: "Joining Date"
    },
    {
        id: 4,
        name: "Billable"
    },
    {
        id: 5,
        name: "Billing Rate"
    },
    {
        id: 6,
        name: "Location"
    },
    {
        id: 7,
        name: "Approver"
    }
]

const Locations = [
    {
        id: -1,
        Status: "All",
        value: -1
    },
    {
        id: 0,
        Status: "India",
        value: 0,
    },
    {
        id: 1,
        Status: "USA",
        value: 1,
    },
    {
        id: 2,
        Status: "Philippines",
        value: 2,
    },
];