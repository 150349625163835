export const CountryCodes = [
    {
        country: "Afghanistan",
        phoneLength: "9",
        country_code: 93,
    },
    {
        country: "Algeria",
        phoneLength: "9",
        country_code: 213,
    },
    {
        country: "American Samoa",
        phoneLength: "10",
        country_code: 1684,
    },
    {
        country: "Anguilla",
        phoneLength: "10",
        country_code: 1264,
    },
    {
        country: "Antigua and Barbuda",
        phoneLength: "10",
        country_code: 1268,
    },
    {
        country: "Armenia",
        phoneLength: "8",
        country_code: 374,
    },
    {
        country: "Aruba",
        phoneLength: "7",
        country_code: 297,
    },
    {
        country: "Australia",
        phoneLength: "9",
        country_code: 61,
    },
    {
        country: "Austria",
        phoneLength: "10",
        country_code: 43,
    },
    {
        country: "Azerbaijan",
        phoneLength: "9",
        country_code: 994,
    },
    {
        country: "Bahamas",
        phoneLength: "10",
        country_code: 1242,
    },
    {
        country: "Bahrain",
        phoneLength: "8",
        country_code: 973,
    },
    {
        country: "Bangladesh",
        phoneLength: "10",
        country_code: 880,
    },
    {
        country: "Barbados",
        phoneLength: "10",
        country_code: 1246,
    },
    {
        country: "Belarus",
        phoneLength: "9",
        country_code: 375,
    },
    {
        country: "Belgium",
        phoneLength: "9",
        country_code: 32,
    },
    {
        country: "Belize",
        phoneLength: "7",
        country_code: 501,
    },
    {
        country: "Benin",
        phoneLength: "9",
        country_code: 229,
    },
    {
        country: "Bermuda",
        phoneLength: "10",
        country_code: 1441,
    },
    {
        country: "Bosnia and Herzegovina",
        phoneLength: "8",
        country_code: 387,
    },
    {
        country: "Brazil",
        phoneLength: "11",
        country_code: 55,
    },
    {
        country: "Burkina Faso",
        phoneLength: "8",
        country_code: 226,
    },
    {
        country: "Cambodia",
        phoneLength: "9",
        country_code: 855,
    },
    {
        country: "Cameroon",
        phoneLength: "9",
        country_code: 237,
    },
    {
        country: "Canada",
        phoneLength: "10",
        country_code: 1,
    },
    {
        country: "Cayman Islands",
        phoneLength: "10",
        country_code: 1345,
    },
    {
        country: "Chad",
        phoneLength: "8",
        country_code: 235,
    },
    {
        country: "Chile",
        phoneLength: "9",
        country_code: 56,
    },
    {
        country: "China",
        phoneLength: "13",
        country_code: 86,
    },
    {
        country: "Colombia",
        phoneLength: "10",
        country_code: 57,
    },
    {
        country: "Cook Islands",
        phoneLength: "5",
        country_code: 682,
    },
    {
        country: "Costa Rica",
        phoneLength: "8",
        country_code: 506,
    },
    {
        country: "Croatia",
        phoneLength: "9",
        country_code: 385,
    },
    {
        country: "Cyprus",
        phoneLength: "8",
        country_code: 357,
    },
    {
        country: "Czech Republic",
        phoneLength: "9",
        country_code: 420,
    },
    {
        country: "Denmark",
        phoneLength: "8",
        country_code: 45,
    },
    {
        country: "Dominica",
        phoneLength: "10",
        country_code: 1767,
    },
    {
        country: "Dominican Republic",
        phoneLength: "10",
        country_code: 1849,
    },
    {
        country: "Ecuador",
        phoneLength: "9",
        country_code: 593,
    },
    {
        country: "Egypt",
        phoneLength: "10",
        country_code: 20,
    },
    {
        country: "El Salvador",
        phoneLength: "8",
        country_code: 503,
    },
    {
        country: "Estonia",
        phoneLength: "8",
        country_code: 372,
    },
    {
        country: "Gabon",
        phoneLength: "7",
        country_code: 241,
    },
    {
        country: "Georgia",
        phoneLength: "9",
        country_code: 995,
    },
    {
        country: "Germany",
        phoneLength: "10",
        country_code: 49,
    },
    {
        country: "Ghana",
        phoneLength: "9",
        country_code: 233,
    },
    {
        country: "Greece",
        phoneLength: "10",
        country_code: 30,
    },
    {
        country: "Greenland",
        phoneLength: "6",
        country_code: 299,
    },
    {
        country: "Grenada",
        phoneLength: "10",
        country_code: 1473,
    },
    {
        country: "Guadeloupe",
        phoneLength: "9",
        country_code: 590,
    },
    {
        country: "Guam",
        phoneLength: "10",
        country_code: 1671,
    },
    {
        country: "Guatemala",
        phoneLength: "8",
        country_code: 502,
    },
    {
        country: "Honduras",
        phoneLength: "8",
        country_code: 504,
    },
    {
        country: "Hong Kong",
        phoneLength: "8",
        country_code: 852,
    },
    {
        country: "Hungary",
        phoneLength: "9",
        country_code: 36,
    },
    {
        country: "India",
        phoneLength: "10",
        country_code: 91,
    },
    {
        country: "Indonesia",
        phoneLength: "9",
        country_code: 62,
    },
    {
        country: "Iran",
        phoneLength: "10",
        country_code: 98,
    },
    {
        country: "Ireland",
        phoneLength: "9",
        country_code: 353,
    },
    {
        country: "Israel",
        phoneLength: "9",
        country_code: 972,
    },
    {
        country: "Italy",
        phoneLength: "10",
        country_code: 39,
    },
    {
        country: "Jamaica",
        phoneLength: "10",
        country_code: 1876,
    },
    {
        country: "Japan",
        phoneLength: "10",
        country_code: 81,
    },
    {
        country: "Jordan",
        phoneLength: "9",
        country_code: 962,
    },
    {
        country: "Kazakhstan",
        phoneLength: "10",
        country_code: 7,
    },
    {
        country: "Kenya",
        phoneLength: "10",
        country_code: 254,
    },
    {
        country: "Kiribati",
        phoneLength: "8",
        country_code: 686,
    },
    {
        country: "Kuwait",
        phoneLength: "8",
        country_code: 965,
    },
    {
        country: "Latvia",
        phoneLength: "8",
        country_code: 371,
    },
    {
        country: "Lebanon",
        phoneLength: "7",
        country_code: 961,
    },
    {
        country: "Liberia",
        phoneLength: "7",
        country_code: 231,
    },
    {
        country: "Lithuania",
        phoneLength: "8",
        country_code: 370,
    },
    {
        country: "Luxembourg",
        phoneLength: "9",
        country_code: 352,
    },
    {
        country: "Malaysia",
        phoneLength: "7",
        country_code: 60,
    },
    {
        country: "Maldives",
        phoneLength: "7",
        country_code: 960,
    },
    {
        country: "Mali",
        phoneLength: "8",
        country_code: 223,
    },
    {
        country: "Malta",
        phoneLength: "8",
        country_code: 356,
    },
    {
        country: "Marshall Islands",
        phoneLength: "7",
        country_code: 692,
    },
    {
        country: "Martinique",
        phoneLength: "9",
        country_code: 596,
    },
    {
        country: "Mauritius",
        phoneLength: "8",
        country_code: 230,
    },
    {
        country: "Mexico",
        phoneLength: "10",
        country_code: 52,
    },
    {
        country: "Moldova",
        phoneLength: "8",
        country_code: 373,
    },
    {
        country: "Montserrat",
        phoneLength: "10",
        country_code: 1664,
    },
    {
        country: "Mozambique",
        phoneLength: "12",
        country_code: 258,
    },
    {
        country: "Myanmar",
        phoneLength: "8",
        country_code: 95,
    },
    {
        country: "Nepal",
        phoneLength: "10",
        country_code: 977,
    },
    {
        country: "Netherlands",
        phoneLength: "9",
        country_code: 31,
    },
    {
        country: "New Caledonia",
        phoneLength: "6",
        country_code: 687,
    },
    {
        country: "New Zealand",
        phoneLength: "9",
        country_code: 64,
    },
    {
        country: "Nicaragua",
        phoneLength: "8",
        country_code: 505,
    },
    {
        country: "Niger",
        phoneLength: "8",
        country_code: 227,
    },
    {
        country: "Nigeria",
        phoneLength: "8",
        country_code: 234,
    },
    {
        country: "Niue",
        phoneLength: "4",
        country_code: 683,
    },
    {
        country: "North Macedonia",
        phoneLength: "8",
        country_code: 389,
    },
    {
        country: "Northern Mariana Islands",
        phoneLength: "10",
        country_code: 1670,
    },
    {
        country: "Norway",
        phoneLength: "8",
        country_code: 47,
    },
    {
        country: "Oman",
        phoneLength: "8",
        country_code: 968,
    },
    {
        country: "Pakistan",
        phoneLength: "10",
        country_code: 92,
    },
    {
        country: "Palau",
        phoneLength: "7",
        country_code: 680,
    },
    {
        country: "Panama",
        phoneLength: "8",
        country_code: 507,
    },
    {
        country: "Paraguay",
        phoneLength: "9",
        country_code: 595,
    },
    {
        country: "Peru",
        phoneLength: "9",
        country_code: 51,
    },
    {
        country: "Philippines",
        phoneLength: "10",
        country_code: 63,
    },
    {
        country: "Poland",
        phoneLength: "9",
        country_code: 48,
    },
    {
        country: "Portugal",
        phoneLength: "9",
        country_code: 351,
    },
    {
        country: "Puerto Rico",
        phoneLength: "10",
        country_code: 1939,
    },
    {
        country: "Qatar",
        phoneLength: "8",
        country_code: 974,
    },
    {
        country: "Romania",
        phoneLength: "10",
        country_code: 40,
    },
    {
        country: "Saint Kitts and Nevis",
        phoneLength: "10",
        country_code: 1869,
    },
    {
        country: "Saint Lucia",
        phoneLength: "10",
        country_code: 1758,
    },
    {
        country: "Samoa",
        phoneLength: "5",
        country_code: 685,
    },
    {
        country: "Saudi Arabia",
        phoneLength: "9",
        country_code: 966,
    },
    {
        country: "Serbia",
        phoneLength: "8",
        country_code: 381,
    },
    {
        country: "Singapore",
        phoneLength: "8",
        country_code: 65,
    },
    {
        country: "Slovakia",
        phoneLength: "9",
        country_code: 421,
    },
    {
        country: "Solomon Islands",
        phoneLength: "7",
        country_code: 677,
    },
    {
        country: "Somalia",
        phoneLength: "8",
        country_code: 252,
    },
    {
        country: "South Africa",
        phoneLength: "9",
        country_code: 27,
    },
    {
        country: "South Korea",
        phoneLength: "10",
        country_code: 82,
    },
    {
        country: "Spain",
        phoneLength: "9",
        country_code: 34,
    },
    {
        country: "Sri Lanka",
        phoneLength: "7",
        country_code: 94,
    },
    {
        country: "Sweden",
        phoneLength: "7",
        country_code: 46,
    },
    {
        country: "Switzerland",
        phoneLength: "9",
        country_code: 41,
    },
    {
        country: "Syria",
        phoneLength: "9",
        country_code: 963,
    },
    {
        country: "Tanzania",
        phoneLength: "6",
        country_code: 255,
    },
    {
        country: "Thailand",
        phoneLength: "9",
        country_code: 66,
    },
    {
        country: "Togo",
        phoneLength: "8",
        country_code: 228,
    },
    {
        country: "Trinidad and Tobago",
        phoneLength: "10",
        country_code: 1868,
    },
    {
        country: "Tunisia",
        phoneLength: "8",
        country_code: 216,
    },
    {
        country: "Turkey",
        phoneLength: "10",
        country_code: 90,
    },
    {
        country: "Turks and Caicos Islands",
        phoneLength: "10",
        country_code: 1649,
    },
    {
        country: "Ukraine",
        phoneLength: "9",
        country_code: 380,
    },
    {
        country: "United Arab Emirates",
        phoneLength: "9",
        country_code: 971,
    },
    {
        country: "United Kingdom",
        phoneLength: "10",
        country_code: 44,
    },
    {
        country: "United States",
        phoneLength: "10",
        country_code: 1,
    },
    {
        country: "Venezuela",
        phoneLength: "7",
        country_code: 58,
    },
    {
        country: "Vietnam",
        phoneLength: "9",
        country_code: 84,
    },
    {
        country: "Yemen",
        phoneLength: "9",
        country_code: 967,
    },
    {
        country: "Zambia",
        phoneLength: "9",
        country_code: 260,
    },
    {
        country: "Zimbabwe",
        phoneLength: "9",
        country_code: 263,
    }
];
