import React, { useEffect, useState } from 'react'
import "./SortBy.css"
import moment from 'moment'

import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';

function SortBy({ list, column, setTimesheetApprovals, sortFilter }) {

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (sortFilter) {
            setIsActive(false)
        }
    }, [sortFilter])

    const handleSort = () => {
        setTimesheetApprovals(list.slice().sort((a, b) => {
            if (column === "Emp.Name") {
                if (a.Name < b.Name) return isActive ? 1 : -1;
                if (a.Name > b.Name) return isActive ? -1 : 1;
                else return 0;
            }
            else if (column === "Job Title") {
                if (a.JobTitle < b.JobTitle) return isActive ? 1 : -1;
                if (a.JobTitle > b.JobTitle) return isActive ? -1 : 1;
                else return 0;
            }
            else if (column === "Project Name") {
                if (a.ProjectName < b.ProjectName) return isActive ? 1 : -1;
                if (a.ProjectName > b.ProjectName) return isActive ? -1 : 1;
                else return 0;
            }
            else if (column === "Status") {
                if (a.Status < b.Status) return isActive ? 1 : -1;
                if (a.Status > b.Status) return isActive ? -1 : 1;
                else return 0;
            }
            else if (column === "Project Code") {
                if (a.ProjectCode < b.ProjectCode) return isActive ? 1 : -1;
                if (a.ProjectCode > b.ProjectCode) return isActive ? -1 : 1;
                else return 0;
            }
            else if (column === "Submitted") {
                if (a.SubmittedDate < b.SubmittedDate) return isActive ? 1 : -1;
                if (a.SubmittedDate > b.SubmittedDate) return isActive ? -1 : 1;
                else return 0;
            }
            else if (column === "WeekEnding") {
                const dateA = moment().year(a.Year).week(a.WeekNumber).endOf('week')
                const dateB = moment().year(b.Year).week(b.WeekNumber).endOf('week')
                if (isActive) {
                    return dateB.isAfter(dateA) ? 1 : -1;
                } else {
                    return dateA.isAfter(dateB) ? 1 : -1;
                }
            }
            else {
                return 0
            }
        }))

        setIsActive(!isActive)

    }

    return (
        <div onClick={handleSort} className='sort_by_container'>
            {
                isActive ?
                    <>
                        <ChangeHistoryOutlinedIcon sx={{ fontSize: 10 }} />
                        <ChangeHistoryTwoToneIcon sx={{ rotate: "180deg", fontSize: 10 }} />
                    </>
                    :
                    <>
                        <ChangeHistoryTwoToneIcon sx={{ fontSize: 10 }} />
                        <ChangeHistoryOutlinedIcon sx={{ rotate: "180deg", fontSize: 10 }} />
                    </>
            }
        </div>
    )
}

export default SortBy