import React, { useEffect, useState } from 'react'
import './ViewProfile.css'

import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import CryptoJS from 'crypto-js'

import PortraitImage from '../../../assets/employees-portrait.png'
import LandScapeImage from '../../../assets/employees-landscape.png'
import MailImage from '../../../assets/mail-icon.png'
import PhoneImage from '../../../assets/phone-icon.png'


import { RandomColor } from '../../../Components/ColorCombo'
import Back from '../../../Components/Back'
import EmptyTable from '../../../Components/NoData'
import DownloadProfile from '../../Pages/DownloadReport'
import { getInitialValues } from '../../../redux/features/InitialSlice'
import { AxiosGet } from '../../../data/utilities'

function ViewProfile() {

    const params = useParams()
    const initialData = useSelector(getInitialValues)
    const [employeesData, setEmployeesData] = useState([])

    const [isDisplay, setIsDisplay] = useState(false)
    const [mode, setMode] = useState('l')
    const [employeeDomains, setEmployeeDomains] = useState([])
    const [selectedElements, setSelectedElements] = useState([])

    // need to have on redux
    const [tempDomains, setTempDomains] = useState([])
    // const [tempProfile, setTempProfile] = useState([])

    const [toggle, setToggle] = useState(false)
    const [goBack, setGoback] = useState('')

    useEffect(() => {
        AxiosGet(`/iemployee/employeeDomains`).then((result) => {
            setEmployeeDomains(result.data.empDomains)
        })

        AxiosGet(`/iemployee/domains`).then((result) => {
            setTempDomains(result.data)
        })

        // AxiosGet(`/iemployee/getprofileimages`).then((result) => {

        //     if (result.data.isSuccess) {
        //         setTempProfile(result.data.Images)
        //     }
        //     else {
        //         setTempProfile([])
        //     }

        // })

        let value = JSON.parse(CryptoJS.AES.decrypt(params.type.split('|').join("/"), process.env.REACT_APP_SECRECT_KEY).toString(CryptoJS.enc.Utf8))

        setGoback(value.goBack)

        if (value.Type === 'view') {
            setEmployeesData(initialData.Employees.filter(item => item.Id === value.Id))
        }
        else {
            setEmployeesData(value.SelectedEmployees)

            let values = []

            value.SelectedEmployees.forEach((e, index) => {
                values.push(`view-report-l-${index}`)
            })

            setSelectedElements(values)
            setTimeout(() => {
                setIsDisplay(true)
            }, 50)
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, initialData])

    const handleToggle = () => {
        handleChangeModeElement(toggle)
        setToggle(!toggle)
        setTimeout(() => {
            setIsDisplay(true)
        }, 50)
    }

    const handleChangeModeElement = (mode) => {

        if (mode) {
            setMode('l')
            let values = []

            employeesData.forEach((e, index) => {
                values.push(`view-report-l-${index}`)
            })
            setSelectedElements(values)
        }
        else {
            setMode('p')
            let values = []

            employeesData.forEach((e, index) => {
                values.push(`view-report-p-${index}`)
            })
            setSelectedElements(values)
        }
    }

    const handleCareerDate = (careerStartDate) => {
        var dt = new Date()

        let year = dt.getFullYear() - careerStartDate.split("-")[0]
        let month = (dt.getMonth() + 1) - careerStartDate.split("-")[1]
        if (month.toString().includes("-")) {
            return (year - 1) + " Year +"
        }
        else {
            return year + " Year +"
        }

    }

    return (
        <div className='employee_profile_container'>
            <Back title={`/myprofile/${goBack}`} />
            <div className='employee_profile_header'>
                <div className='employee_profile_header_title'>
                    <span>Employee Summary</span>
                </div>
                <div className='employee_profile_header_navigations'>
                    {
                        mode === 'l' ?
                            <>
                                <div className='employee_profile_mode'>
                                    <img src={LandScapeImage} alt='landscape' />
                                </div>
                                <div className='employee_profile_mode' onClick={handleToggle}>
                                    <img src={PortraitImage} alt='landscape' />
                                </div>
                            </>
                            :
                            <>
                                <div className='employee_profile_mode' style={{ rotate: "-90deg" }} onClick={handleToggle}>
                                    <img src={PortraitImage} alt='portrait' />
                                </div>
                                <div className='employee_profile_mode' style={{ rotate: "90deg" }}>
                                    <img src={LandScapeImage} alt='portrait' />
                                </div>
                            </>
                    }

                    {
                        goBack === 'reports' && (
                            <DownloadProfile
                                mode={mode}
                                profiles={selectedElements}
                                isDisplay={isDisplay}
                                setIsDisplay={setIsDisplay}
                            />
                        )
                    }
                </div>
            </div>
            {
                employeesData.length !== 0 ?
                    <>
                        {
                            employeesData.map((item, index) => {
                                return (
                                    <>
                                        {
                                            !toggle ?
                                                <div className='employee_profile_mode_landscape' key={index} id={`view-report-l-${index}`}>
                                                    <div className='employee_profile_mode_landscape_image_container'>
                                                        <div className='dummy_profile_image' style={{ background: RandomColor(item.FirstName.substring(0, 1)) }}>
                                                            <span>{item.FirstName.substring(0, 1)}{item.LastName.substring(0, 1)}</span>
                                                        </div>
                                                        {/* {
                                                            tempProfile.filter(profile => profile.FK_EmployeeId === item.Id).length === 0 ?
                                                                <div className='dummy_profile_image' style={{ background: RandomColor(item.FirstName.substring(0, 1)) }}>
                                                                    <span>{item.FirstName.substring(0, 1)}{item.LastName.substring(0, 1)}</span>
                                                                </div>
                                                                :
                                                                <div className='employee_profile_image_container'>
                                                                    <img src={`${process.env.REACT_APP_PROXY}/Config/Uploads/Images/${tempProfile.filter(profile => profile.FK_EmployeeId === item.Id)[0].ProfileImage}`} alt='profile' />
                                                                </div>
                                                        } */}
                                                    </div>
                                                    <div className='employee_profile_mode_landscape_content'>
                                                        <div className='employee_profile_mode_landscape_content_details'>
                                                            <div className='employee_profile_l_details_span_1'>
                                                                <span>{item.FirstName + " " + item.LastName}</span>
                                                                <br />
                                                                <label>{item.Designation}</label>
                                                            </div>
                                                            <div className='employee_profile_l_details_span_2'>
                                                                <span>
                                                                    <img src={MailImage} alt='mail' />
                                                                    {item.Email}
                                                                </span>
                                                                <span>
                                                                    <img src={PhoneImage} alt='phone' />
                                                                    +{item.CountryCode} {item.PhoneNumber}
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div className='employee_profile_mode_landscape_content_span_2'>
                                                            <div className='employee_profile_mode_l_content_span_2_container'>
                                                                <label>Total Expierence</label>
                                                                <span>{item.CareerStartDate === null ? "Not Available" : handleCareerDate(item.CareerStartDate)}</span>
                                                            </div>
                                                            {/* <div className='employee_profile_mode_l_content_span_2_container'>
                                                                <label>Practice Area</label>
                                                                <span>{item.PracticeArea}</span>
                                                            </div> */}
                                                            <div className='employee_profile_mode_l_content_span_2_container'>
                                                                <label>Organization</label>
                                                                <span>{item.Organization}</span>
                                                            </div>
                                                        </div>
                                                        <div className='employee_profile_mode_landscape_content_span_3'>
                                                            <div className='employee_profile_mode_l_content_span_3_container'>
                                                                <label>Certifications</label>
                                                                <ul>
                                                                    {
                                                                        item.Certifications.split(';').map((e, index) => {
                                                                            if (e !== '') {
                                                                                return <li key={index}>{e.trim().substring(0, 1).toUpperCase() + e.trim().substring(1).toLowerCase()}</li>
                                                                            }
                                                                            else {
                                                                                return false
                                                                            }
                                                                        })
                                                                    }
                                                                </ul>
                                                            </div>
                                                            <div className='employee_profile_mode_l_content_span_3_container'>
                                                                <label>Education</label>
                                                                <span>{item.Education} in {item.Specialization}</span>
                                                            </div>
                                                            {
                                                                employeeDomains.filter(e => e.FK_EmployeeId === item.Id).length !== 0 && (
                                                                    <div className='employee_profile_mode_l_content_span_3_container'>
                                                                        <label>Domains</label>
                                                                        <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                                                                            {
                                                                                employeeDomains.filter(e => e.FK_EmployeeId === item.Id).map((dom, index) => {
                                                                                    return <li key={index}><b>{dom.DomainExperience} Year</b> in {
                                                                                        <>
                                                                                            {
                                                                                                tempDomains.filter(item => item.Id === dom.FK_DomainId).map((e) => {
                                                                                                    return <>{e.Domain}</>
                                                                                                })
                                                                                            }
                                                                                        </>
                                                                                    }</li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                        <div className='employee_profile_mode_landscape_content_span_4'>
                                                            <label>Career Highlights</label>
                                                            <ul>
                                                                {
                                                                    item.CareerHighlights.split('@$').map((e, index) => {
                                                                        return <li key={index}>{e}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                <div className='employee_profile_mode_portrait_container' key={index}>
                                                    <div className='employee_profile_mode_portrait' id={`view-report-p-${index}`}>
                                                        <div className='employee_profile_mode_portrait_span_1'>
                                                            <div className='employee_profile_mode_portrait_image_container'>
                                                                <div className='dummy_profile_image' style={{ background: RandomColor(item.FirstName.substring(0, 1)) }}>
                                                                    <span>{item.FirstName.substring(0, 1)}{item.LastName.substring(0, 1)}</span>
                                                                </div>
                                                                {/* {
                                                                    tempProfile.filter(profile => profile.FK_EmployeeId === item.Id).length === 0 ?
                                                                        <div className='dummy_profile_image' style={{ background: RandomColor(item.FirstName.substring(0, 1)) }}>
                                                                            <span>{item.FirstName.substring(0, 1)}{item.LastName.substring(0, 1)}</span>
                                                                        </div>
                                                                        :
                                                                        <div className='employee_profile_image_container'>
                                                                            <img src={`${process.env.REACT_APP_PROXY}/Config/Uploads/Images/${tempProfile.filter(profile => profile.FK_EmployeeId === item.Id)[0].ProfileImage}`} alt='profile' />
                                                                        </div>
                                                                } */}
                                                            </div>
                                                            <div className='employee_profile_mode_portrait_detials'>
                                                                <div className='employee_profile_mode_portrait_detials_span_1'>
                                                                    <span>{item.FirstName + " " + item.LastName}</span>
                                                                    <br />
                                                                    <label>{item.Designation}</label>
                                                                </div>
                                                                <div className='employee_profile_mode_portrait_detials_span_2'>
                                                                    <span>
                                                                        <img src={MailImage} alt='mail' />
                                                                        {item.Email}
                                                                    </span>
                                                                    <br />
                                                                    <span>
                                                                        <img src={PhoneImage} alt='phone' />
                                                                        +{item.CountryCode} {item.PhoneNumber}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='employee_profile_mode_portrait_span_2'>
                                                            <div className='employee_profile_mode_portrait_span_container'>
                                                                <label>Total Expierence</label>
                                                                <span>{item.CareerStartDate === null ? "Not Available" : handleCareerDate(item.CareerStartDate)}</span>
                                                            </div>
                                                            {/* <div className='employee_profile_mode_portrait_span_container'>
                                                                <label>Practice Area</label>
                                                                <span>{item.PracticeArea}</span>
                                                            </div> */}
                                                            <div className='employee_profile_mode_portrait_span_container'>
                                                                <label>Organization</label>
                                                                <span>{item.Organization}</span>
                                                            </div>
                                                        </div>
                                                        <div className='employee_profile_mode_portrait_span_3'>
                                                            {
                                                                employeeDomains.filter(e => e.FK_EmployeeId === item.Id).length !== 0 && (
                                                                    <div className='employee_profile_mode_portrait_span_container'>
                                                                        <label>Domains</label>
                                                                        <ul style={{ margin: 0, padding: 0, listStyle: "none" }}>
                                                                            {
                                                                                employeeDomains.filter(e => e.FK_EmployeeId === item.Id).map((dom, index) => {
                                                                                    return <li key={index}><b>{dom.DomainExperience} Year</b> in {
                                                                                        <>
                                                                                            {
                                                                                                tempDomains.filter(item => item.Id === dom.FK_DomainId).map((e) => {
                                                                                                    return <>{e.Domain}</>
                                                                                                })
                                                                                            }
                                                                                        </>
                                                                                    }</li>
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                )
                                                            }
                                                            <div className='employee_profile_mode_portrait_span_container'>
                                                                <label>Education</label>
                                                                <span>{item.Education} in {item.Specialization}</span>
                                                            </div>
                                                        </div>
                                                        <div className='employee_profile_mode_portrait_span_4'>
                                                            <label>Certifications</label>
                                                            <ul>
                                                                {
                                                                    item.Certifications.split(';').map((e, index) => {
                                                                        if (e !== '') {
                                                                            return <li key={index}>{e.trim().substring(0, 1).toUpperCase() + e.trim().substring(1).toLowerCase()}</li>
                                                                        }
                                                                        else {
                                                                            return false
                                                                        }
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                        <div className='employee_profile_mode_portrait_span_5'>
                                                            <label>Career Highlights</label>
                                                            <ul>
                                                                {
                                                                    item.CareerHighlights.split('@$').map((e, index) => {
                                                                        return <li key={index}>{e}</li>
                                                                    })
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                        }
                                    </>
                                )
                            })
                        }
                    </>
                    :
                    <EmptyTable title={'No Data'} />
            }
        </div>
    )
}

export default ViewProfile