import React from 'react'
import "./Navigations.css"

import { NavLink } from 'react-router-dom'

import HomeIcon from '@mui/icons-material/Home';

function Navigations() {

    return (
        <div className={'navigation_bar_main_page_projectAllocation'}>
            <div className='navigations_projectAllocation'>
                <NavLink to={'/project-allocation/home'}>
                    <HomeIcon />
                    <span>Home</span>
                </NavLink>
            </div >
        </div>
    )
}

export default Navigations