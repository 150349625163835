import React, { useEffect, useState } from 'react'
import "./EmployeeReports.css"

import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Cryptojs from 'crypto-js'

import EmptyTable from '../../../Components/NoData'
import ExportImage from '../../../assets/export-icon.png'
import { getInitialValues } from '../../../redux/features/InitialSlice'
import { setLoadingScreen } from '../../../redux/features/ActivateSlice'
import Pagination from '../../../Components/Pagination'

import { toast } from 'react-toastify'
import SortBy from './Feature/SortBy'
import Messages from '../../../data/Messages.json'

function EmployeeReports() {

    const initialData = useSelector(getInitialValues)

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [selectedEmployees, setSelectedEmployees] = useState([])
    const [selectedElements, setSelectedElements] = useState([])

    const [empData, setEmpData] = useState([])

    const [selectedRow, setSelectedRows] = useState(10)
    const [rows, setRows] = useState(0)
    const [page, setPage] = useState(0)

    useEffect(() => {

        if (initialData.Employees.length !== 0) {
            let values = []
            setSelectedElements([])
            setSelectedEmployees([])
            initialData.Employees.forEach((emp) => {
                values.push({ ...emp, check: false })
            })

            setEmpData(values)
        }

    }, [initialData])

    useEffect(() => {
        setRows(selectedRow)
    }, [selectedRow])

    const handleExport = () => {
        if (selectedEmployees.length === 0) {
            toast.warn(Messages.EmployeeReports.EmployeeReports_Select_Profile, { toastId: "iemployee-toast" })
        }
        else {
            // Set Loading here
            // and off after 3 secounds
            dispatch(setLoadingScreen(true))

            setTimeout(() => {
                const SECRET_VALUE = { SelectedEmployees: selectedEmployees, SelectedElements: selectedElements, Type: "report", goBack: "reports" }
                let SECRECT_STRING = Cryptojs.AES.encrypt(JSON.stringify(SECRET_VALUE), process.env.REACT_APP_SECRECT_KEY).toString().split('/').join("|")
                navigate(`/myprofile/reports/employee-report/${SECRECT_STRING}`)
                dispatch(setLoadingScreen(false))
            }, 3000)


        }
    }

    const handleCheckAll = (e) => {
        if (e.target.checked) {
            let values = []

            initialData.Employees.forEach((emp) => {
                values.push({ ...emp, check: true })
            })


            setEmpData(values)

            setSelectedEmployees(values)

        }
        else {
            let values = []

            initialData.Employees.forEach((emp) => {
                values.push({ ...emp, check: false })
            })


            setEmpData(values)
            setSelectedEmployees([])
        }

    }

    const handleCheck = (e, emp, id) => {
        if (e.target.checked) {
            setSelectedEmployees(item => [...item, emp])
            setSelectedElements(item => [...item, { element: "view-report", Id: id }])
            setEmpData(empData.map((e) => {
                if (e.Id === id) {
                    return { ...e, "check": true }
                }
                else {
                    return e
                }
            }))
        }
        else {
            setSelectedEmployees(selectedEmployees.filter(item => item.Id === id))
            setSelectedElements(selectedElements.filter(item => item.Id === id))
            setEmpData(empData.map((e) => {
                if (e.Id === id) {
                    return { ...e, "check": false }
                }
                else {
                    return e
                }
            }))
        }

    }

    return (
        <div className='employee_reports_container'>
            <div className='employee_reports_header'>
                <div className='employee_reports_title'>
                    <span>Employee Reports</span>
                </div>
                <div className='employee_reports_navigations'>
                    <button onClick={() => handleExport()}>
                        <img src={ExportImage} alt='export' style={{ marginRight: "5px", width: 22 }} />
                        Export
                    </button>
                </div>
            </div>
            <div className='employee_reports_content'>
                <div className='employee_reports_table'>
                    {
                        empData.length !== 0 ?
                            <table>
                                <thead>
                                    <tr>
                                        <td style={{ textAlign: "center" }}>
                                            <input type='checkbox' onClick={handleCheckAll} />
                                        </td>
                                        {
                                            tableColumns.map((col, index) => {
                                                return (
                                                    <td key={index}>
                                                        <span style={{ display: "flex", gap: "5px" }}>
                                                            {col.name}
                                                            <SortBy
                                                                list={empData}
                                                                column={col.name}
                                                                setEmployeeData={setEmpData}
                                                            />
                                                        </span>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        empData.slice(page, rows).map((row, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ textAlign: "center" }}>
                                                        <input type='checkbox'
                                                            checked={row.check}
                                                            id={row.Id}
                                                            onClick={(e) => handleCheck(e, row, row.Id)}
                                                        />
                                                    </td>
                                                    <td>
                                                        {
                                                            (row.FirstName + " " + row.LastName).split(" ").map((e) => {
                                                                return e.substring(0, 1).toUpperCase() + e.substring(1)
                                                            }).join(" ")
                                                        }
                                                    </td>
                                                    <td>{row.Designation}</td>
                                                    <td>{row.Email}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            :
                            <EmptyTable title={"No Data Found"} />
                    }
                </div>
                <Pagination
                    List={empData}
                    rows={rows}
                    setRows={setRows}
                    selectedRow={selectedRow}
                    setSelectedRow={setSelectedRows}
                    setPage={setPage}
                    page={page}
                />
            </div>
        </div>
    )
}

export default EmployeeReports

const tableColumns = [
    {
        id: 0,
        name: "Full Name"
    },
    {
        id: 1,
        name: "Job Title"
    },
    {
        id: 2,
        name: "Email Address"
    }
]