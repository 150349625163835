import React, { useEffect, useState } from 'react'
import "./Clients.css"

import { Tooltip } from '@mui/material'
import { useDispatch } from 'react-redux'

import EmptyTable from '../../../Components/NoData'
import ManageClient from '../../Pages/ManageClient'
import Back from '../../../Components/Back'

import EditIcon from '../../../assets/employee-edit-icon.png'
import DeleteIcon from '../../../assets/employee-delete-icon.png'

import { toast } from 'react-toastify'
import SortBy from './Features/SortBy'
import AlertYesNo from '../../../Components/Alerts/AlertYesNo'
import { setLoadingScreen } from '../../../redux/features/ActivateSlice'
import Messages from '../../../data/Messages.json'
import { AxiosGet, AxiosPost } from '../../../data/utilities'

function Clients() {

    const [isVisible, setIsVisible] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedClient, setSelectedClient] = useState(0)
    const dispatch = useDispatch()
    const [isActive, setIsActive] = useState(false)
    const [isSelectedId, setIsSelectedId] = useState(null)
    const [clientsData, setClientsData] = useState([])

    useEffect(() => {
        AxiosGet(`/iemployee/get-clients`).then((res) => {
            if (res.data.isSuccess) {
                setClientsData(res.data.Clients)
            }
        })

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isVisible || isEdit === false) {
            setSelectedClient(0)
        }
    }, [isEdit, isVisible])

    const handleDelete = async () => {
        setIsActive(false)
        dispatch(setLoadingScreen(true))
        let res = await AxiosPost(`/iemployee/remove-client`, {
            Id: isSelectedId
        })
        if (res.data.isSuccess) {
            setIsSelectedId(null)
            toast.success(Messages.Clients.Clients_Fail, { toastId: "iemployee-toast" })
            let result = await AxiosGet(`/iemployee/get-clients`)
            dispatch(setLoadingScreen(false))
            if (result.data.isSuccess) {
                setClientsData(result.data.Clients)
            }
        }
    }

    return (
        <>
            <div className='client_container'>
                <Back title={'/dashboard'} />
                <div className='client_header'>
                    <div className='client_header_title'>
                        <span>Client</span>
                    </div>
                    <div className='client_header_navigations'>
                        <button
                            onClick={() => setIsVisible(true)}>Add Client</button>
                    </div>
                </div>

                <div className='client_content'>
                    {
                        clientsData.length === 0 ?
                            <EmptyTable title='No Data Found' />
                            :
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            clientTableHeader.map((col, index) => {
                                                return <td key={index}>
                                                    <span style={{ display: "flex", gap: "5px" }}>
                                                        {col.name}
                                                        {
                                                            col.name !== 'Options' && (
                                                                <SortBy
                                                                    list={clientsData}
                                                                    setClients={setClientsData}
                                                                    column={col.name}
                                                                />
                                                            )
                                                        }
                                                    </span>

                                                </td>
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        clientsData.map((row, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{row.ClientName}</td>
                                                    <td>{row.TypeOfContract}</td>
                                                    <td>{row.GenzeonPOC}</td>
                                                    <td>{row.ClientPOC}</td>
                                                    <td>{row.ProjectManager}</td>
                                                    <td className='client_options'>
                                                        <Tooltip
                                                            title="Edit"
                                                            placement='top'
                                                            onClick={() => {
                                                                setIsEdit(true)
                                                                setSelectedClient(row.Id)
                                                            }}
                                                        >
                                                            <img src={EditIcon} alt='edit' />
                                                        </Tooltip>
                                                        <Tooltip
                                                            title='Delete'
                                                            placement='top'
                                                            onClick={() => {
                                                                setIsActive(true)
                                                                setIsSelectedId(row.Id)
                                                            }}
                                                        >
                                                            <img src={DeleteIcon} alt='delete' />
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                    }
                </div>
            </div>

            {/* Insert Clients */}
            <ManageClient
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                setClientsData={setClientsData}
            />

            {/* Edit Clients */}
            <ManageClient
                isVisible={isEdit}
                setIsVisible={setIsEdit}
                selectedClient={selectedClient}
                setSelectedClient={setSelectedClient}
                setClientsData={setClientsData}
            />
            <AlertYesNo
                isActive={isActive}
                variant={'warning'}
                message={'Are you Sure! You want to Delete?'}
                handleLogout={handleDelete}
                setIsActive={setIsActive}
            />
        </>

    )
}

export default Clients



const clientTableHeader = [
    {
        id: 1,
        name: "Client Name"
    },
    {
        id: 2,
        name: "Type Of Contract"
    },
    {
        id: 3,
        name: "Genzeon POC"
    },
    {
        id: 4,
        name: "Client POC"
    },
    {
        id: 5,
        name: "Project Manager"
    },
    {
        id: 6,
        name: "Options"
    }
]