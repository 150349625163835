import React, { useState } from 'react'
import "./SortBy.css"

import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';

function SortBy({ list, column, setClients }) {

    const [isActive, setIsActive] = useState(false)

    const handleSort = () => {
        let sortList = []

        if (isActive) {
            if (column === "Client Name") {
                sortList = [...list].sort((a, b) => a.ClientName.localeCompare(b.ClientName));
            }
            else if (column === "Type Of Contract") {
                sortList = [...list].sort((a, b) => a.TypeOfContract.localeCompare(b.TypeOfContract));
            }
            else if (column === "Genzeon POC") {
                sortList = [...list].sort((a, b) => a.GenzeonPOC.localeCompare(b.GenzeonPOC));
            }
            else if (column === "Client POC") {
                sortList = [...list].sort((a, b) => a.ClientPOC.localeCompare(b.ClientPOC));
            } else if (column === "Project Manager") {
                sortList = [...list].sort((a, b) => a.ProjectManager.localeCompare(b.ProjectManager));
            }

        }
        else {
            if (column === "Client Name") {
                sortList = [...list].sort((a, b) => b.ClientName.localeCompare(a.ClientName));
            }
            else if (column === "Type Of Contract") {
                sortList = [...list].sort((a, b) => b.TypeOfContract.localeCompare(a.TypeOfContract));
            }
            else if (column === "Genzeon POC") {
                sortList = [...list].sort((a, b) => b.GenzeonPOC.localeCompare(a.GenzeonPOC));
            }
            else if (column === "Client POC") {
                sortList = [...list].sort((a, b) => b.ClientPOC.localeCompare(a.ClientPOC));
            }
            else if (column === "Project Manager") {
                sortList = [...list].sort((a, b) => b.ProjectManager.localeCompare(a.ProjectManager));
            }
        }

        setClients(sortList);
        setIsActive(!isActive)

    }

    return (
        <div onClick={handleSort} className='sort_by_container'>
            {
                isActive ?
                    <>
                        <ChangeHistoryOutlinedIcon sx={{ fontSize: 10 }} />
                        <ChangeHistoryTwoToneIcon sx={{ rotate: "180deg", fontSize: 10 }} />
                    </>
                    :
                    <>
                        <ChangeHistoryTwoToneIcon sx={{ fontSize: 10 }} />
                        <ChangeHistoryOutlinedIcon sx={{ rotate: "180deg", fontSize: 10 }} />
                    </>
            }
        </div>
    )
}

export default SortBy