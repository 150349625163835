import React from 'react'
import { Route, Routes } from 'react-router-dom'

import EmployeesApp from './Screens/Employees/SubRoute'
import EmployeeReportsApp from './Screens/EmployeeReports/SubRoute'
import EmployeeAudit from './Screens/EmployeeAudit'
import EmployeeReview from './Screens/EmployeeReview'
import AddEmployee from './Screens/AddEmployee'
import ModifyUser from './Screens/ModifyUser'
import BuildProfile from './Screens/BuildProfile'

import Users from './Screens/Users'
import UsersApproval from './Screens/UserApproval'
import SendMail from './Screens/SendMails'

import Clients from './Screens/Clients'

import IsAdmin from '../Layouts/IsAdmin'
import { getIsLoggedIn, getUserData } from '../redux/features/UserSlice'
import { useSelector } from 'react-redux'
import MyProfileIsLoggedIn from './Layouts/MyProfileIsLoggedIn'
import ErrorPage from '../Components/ErrorPage/ErrorPage'
import UpdateEmployeeSummary from './Screens/UpdateEmployeeSummary'

function MyProfile({ RefreshContent }) {

    const userData = useSelector(getUserData);
    const isLoggedIn = useSelector(getIsLoggedIn);

    return (
        <Routes>
            <Route element={<MyProfileIsLoggedIn isExists={isLoggedIn} />}>

                <Route path='/employees/*' element={<EmployeesApp RefreshContent={RefreshContent} />} />

                <Route path='/reports/*' element={<EmployeeReportsApp />} />

                <Route path='/review/:employeeData' element={<EmployeeReview RefreshContent={RefreshContent} />} />

                <Route path='/build-profile' element={<BuildProfile RefreshContent={RefreshContent} />} />

                <Route path='/edit-profile/:type' element={<UpdateEmployeeSummary />} />

                <Route element={<IsAdmin isExists={userData.role !== process.env.REACT_APP_ROLE_USER || userData.role !== process.env.REACT_APP_ROLE_MANAGER} />}>

                    <Route path='/users' element={<Users RefreshContent={RefreshContent} />} />
                    <Route path='/user-approval' element={<UsersApproval RefreshContent={RefreshContent} />} />
                    <Route path='/send-mails' element={<SendMail />} />
                    <Route path='/clients' element={<Clients />} />
                    <Route path='/audit' element={<EmployeeAudit />} />
                    <Route path='/AddEmployee' element={<AddEmployee />} />
                    <Route path='/ModifyUser' element = {<ModifyUser/>} />

                </Route>

            </Route>

            <Route path='/*' element={<ErrorPage />} />

            {/* <Route path='/*' element={<ErrorPage />} /> */}
        </Routes>
    )
}

export default MyProfile