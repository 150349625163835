import React, { useEffect } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'

import TimesheetApproval from '.'
import Timesheet from '../Timesheet'
import TeamApp from '../Team/SubRoute'

import { useSelector } from 'react-redux'
import { getUserData } from '../../../redux/features/UserSlice'
import SubMenuLayout from '../../Layouts/SubMenuLayout'

function App({ AllocatedProjectCodes }) {

    const navigate = useNavigate()

    const userData = useSelector(getUserData)

    useEffect(() => {
        if (userData.role === process.env.REACT_APP_ROLE_ADMIN) {
            navigate('/dashboard')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData])

    return (
        <Routes>
            <Route element={<SubMenuLayout />}>

                <Route path='/home' element={<TimesheetApproval AllocatedProjectCodes={AllocatedProjectCodes} />} />

                <Route path='/team/*' element={<TeamApp />} />

                <Route path='/timesheet/:timesheetId' element={<Timesheet />} />

            </Route>
        </Routes>
    )
}

export default App