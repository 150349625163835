import React, { useEffect, useState } from 'react'
import "./EmployeeApproval.css"

import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import CryptoJS from 'crypto-js'

import ViewImage from '../../../assets/view-image.png'
import DeleteIcon from '../../../assets/employee-delete-icon.png'

import Back from '../../../Components/Back'
import EmptyTable from '../../../Components/NoData'

import { getInitialValues } from '../../../redux/features/InitialSlice'
import { getUserData } from '../../../redux/features/UserSlice'
import { setLoadingScreen } from '../../../redux/features/ActivateSlice'
import SortBy from './Feature/SortBy'
import { Tooltip } from '@mui/material'
import { toast } from 'react-toastify'

import { AxiosPost, TextCapaitalized } from '../../../data/utilities'

function EmployeeApproval() {

    const navigate = useNavigate()

    const initialData = useSelector(getInitialValues)
    const userData = useSelector(getUserData)
    const dispatch = useDispatch()

    const [employeeData, setEmployeeData] = useState([])

    useEffect(() => {

        if (initialData.EmployeeApprovals.length !== 0) {

            if (userData.role === process.env.REACT_APP_ROLE_ADMIN) {
                setEmployeeData(initialData.EmployeeApprovals)
            }
            else {
                let result = []

                initialData.EmployeeApprovals.forEach((item) => {
                    if (item.ReportingTo === userData.Id) {
                        result.push(item)
                    }
                })

                initialData.EmployeeApprovals.forEach((item) => {
                    userData.reportees.forEach((emp) => {
                        if (item.FK_EmployeeId === emp) {
                            result.push(item)
                        }
                    })
                })

                result = result.filter((item, index) => {
                    return index === result.findIndex((e) => item.Email === e.Email);
                });

                setEmployeeData(result)
            }

        }

    }, [initialData, userData])

    const handleReviewEmployee = (row) => {
        let SECRECT_STRING = CryptoJS.AES.encrypt(JSON.stringify(row), process.env.REACT_APP_SECRECT_KEY).toString().split('/').join("|")
        navigate(`/myprofile/employees/review/${SECRECT_STRING}`)
    }

    const handleDeleteTempEmployee = async (id) => {
        dispatch(setLoadingScreen(true))
        let result = await AxiosPost(`/temp-iemployee/delete-temp-employee`, {
            Id: id
        })

        dispatch(setLoadingScreen(false))
        if (result.data.isSuccess) {
            toast.success("Deleted Successfully", { toastId: "iemployee-toast" })
            setEmployeeData((EmployeeData) =>
                EmployeeData.filter((employee) => employee.Id !== id)
            );
        }
        else {
            toast.warn("Please try after sometime", { toastId: "iemployee-toast" })
        }

    }

    return (
        <div className='employee_approval_container'>
            <Back title={"/myprofile/employees"} />
            <div className='employee_approval_header'>
                <span>Employees Approval</span>
            </div>
            <div className='employee_approval_table_container'>
                {
                    employeeData.length === 0 ?
                        <EmptyTable title='No Data Found' />
                        :
                        <div className='employee_approval_table'>
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            tableCol.map((col, index) => {
                                                return (
                                                    <td key={index}>
                                                        <span style={{ display: "flex", gap: '5px' }}>
                                                            {col.name}
                                                            {
                                                                col.name === 'Options' || col.name === 'Comment' ? <></>
                                                                    :
                                                                    <SortBy
                                                                        list={employeeData}
                                                                        setEmployeeData={setEmployeeData}
                                                                        column={col}
                                                                    />
                                                            }
                                                        </span>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        employeeData.map((e, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td>{TextCapaitalized((e.FirstName + " " + e.LastName))}</td>
                                                    <td>{e.Designation}</td>
                                                    <td>{e.isApprove === 0 ? 'Pending' : 'Rejected'}</td>
                                                    <td>{e.Comment}</td>
                                                    <td>
                                                        <Tooltip placement='top' title='Review'>
                                                            <img
                                                                onClick={() => handleReviewEmployee(e)}
                                                                style={{ cursor: "pointer" }} src={ViewImage} alt='view' />
                                                        </Tooltip>
                                                        <Tooltip placement='top' title='Delete'>
                                                            <img src={DeleteIcon} alt='delete' onClick={() => handleDeleteTempEmployee(e.Id)} />
                                                        </Tooltip>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            <div className='employee_approval_pagination'></div>
                        </div>
                }
            </div>
        </div>
    )
}

export default EmployeeApproval


const tableCol = [
    {
        id: 1,
        name: "FullName"
    },
    {
        id: 2,
        name: "Designation"
    },
    {
        id: 3,
        name: "Status"
    },
    {
        id: 4,
        name: "Comment"
    },
    {
        id: 5,
        name: "Options"
    }
]