export const RegisterLinkMessage =
    `
Hello!

Click on the Below link to Register in iEmployee Web App.

Click : ${process.env.REACT_APP_URL}/register

Thanks and Regards
**iEmployee**
`


export const LoginMessage = (username, email, uPassword) => {

    return `
Hi ${username}

Your Guest Credentials are ready. Please use the Link below for Login to iEmployee.

username: ${email}
password: ${uPassword}

Login Link: ${process.env.REACT_APP_URL}

NOTE: 
1) Change the Password once you Logged In. You will find Change Password in the Profile Section.
2) Change your Phone Number after you Logged In.


Thank You
**iEmployee**    
    `
}



const min = 111
const max = 999
const UpperCase = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
const lowerCase = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z']
const specialCharacters = ['!', '@', '#', '$', '%']

export const GeneratePassword = () => {

    const number = Math.floor(Math.random() * (max - min) + min)
    const spacialChar = specialCharacters[Math.floor(Math.random() * specialCharacters.length)]

    return `${UpperCase[Math.floor(Math.random() * UpperCase.length)]}${lowerCase[Math.floor(Math.random() * lowerCase.length)]}${lowerCase[Math.floor(Math.random() * lowerCase.length)]}${UpperCase[Math.floor(Math.random() * UpperCase.length)]}${spacialChar}${number}`

}