import React, { useEffect, useState } from 'react'
import "./SortBy.css"
import moment from 'moment'

import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';

function SortBy({ list, columnName, setEmployeeData, sortFilter }) {

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        if (sortFilter) {
            setIsActive(false)
        }
    }, [sortFilter])

    const handleSort = () => {

        setEmployeeData(
            list.slice().sort((a, b) => {

                if (columnName === "Emp.Name") {
                    if (a.Name < b.Name) return isActive ? -1 : 1;
                    if (a.Name > b.Name) return isActive ? 1 : -1;
                    else return 0;
                }
                else if (columnName === "Job Title") {
                    if (a.JobTitle < b.JobTitle) return isActive ? -1 : 1;
                    if (a.JobTitle > b.JobTitle) return isActive ? 1 : -1;
                    else return 0;
                }
                else if (columnName === "Project Code") {
                    if (a.ProjectCode < b.ProjectCode) return isActive ? -1 : 1;
                    if (a.ProjectCode > b.ProjectCode) return isActive ? 1 : -1;
                    else return 0;
                }
                else if (columnName === "Project Name") {
                    if (a.ProjectName < b.ProjectName) return isActive ? -1 : 1;
                    if (a.ProjectName > b.ProjectName) return isActive ? 1 : -1;
                    else return 0;
                }
                else if (columnName === "Submitted") {
                    if (a.SubmittedDate < b.SubmittedDate) return isActive ? -1 : 1;
                    if (a.SubmittedDate > b.SubmittedDate) return isActive ? 1 : -1;
                    else return 0;
                }
                else if (columnName === "WeekEnding") {
                    let date1 = moment().year(a.Year).week(a.WeekNumber).endOf('week')
                    let date2 = moment().year(b.Year).week(b.WeekNumber).endOf('week')
                    if (date1 < date2) return isActive ? -1 : 1;
                    if (date1 > date2) return isActive ? 1 : -1;
                    else return 0;
                }
                else if (columnName === 'Approver') {
                    if (a.Approver < b.Approver) return isActive ? -1 : 1;
                    if (a.Approver > b.Approver) return isActive ? 1 : -1;
                    else return 0;
                }
                else {
                    if (a.Status < b.Status) return isActive ? -1 : 1;
                    if (a.Status > b.Status) return isActive ? 1 : -1;
                    else return 0;
                }

            })
        )

        setIsActive(!isActive)

    }

    return (
        <div onClick={handleSort} className='sort_by_container'>
            {
                isActive ?
                    <>
                        <ChangeHistoryOutlinedIcon sx={{ fontSize: 10 }} />
                        <ChangeHistoryTwoToneIcon sx={{ rotate: "180deg", fontSize: 10 }} />
                    </>
                    :
                    <>
                        <ChangeHistoryTwoToneIcon sx={{ fontSize: 10 }} />
                        <ChangeHistoryOutlinedIcon sx={{ rotate: "180deg", fontSize: 10 }} />
                    </>
            }
        </div>
    )
}

export default SortBy