import { React, useEffect, useState } from "react";
import './ProjectCodeEdit.css';
import { getInitialValues, setInitialValues } from "../../../redux/features/InitialSlice";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setLoadingScreen } from "../../../redux/features/ActivateSlice";
import { getUserData } from "../../../redux/features/UserSlice";
import { TextField, Autocomplete } from "@mui/material";
import { AxiosPost } from "../../../data/utilities";
import Messages from "../../../data/Messages.json";

function EditProjectCode() {
    const initialData = useSelector(getInitialValues);
    const userData = useSelector(getUserData);
    const dispatch = useDispatch();

    const [selectedProjectCode, setSelectedProjectCode] = useState('');
    const [projectCode, setProjectCode] = useState([]);
    const [projectName, setProjectName] = useState('');
    const [shortCode, setShortCode] = useState('');
    const [client, setClient] = useState('');

    const [isError, setIsError] = useState({
        isClient: false,
        isClientCode: false,
        isProject: false,
    });

    useEffect(() => {
        if (initialData.length !== 0) {
            setProjectCode(initialData.ProjectCodes);
        }
    }, [initialData]);

    const handleEditProjectCode = async () => {

        if (client === '' || projectCode === "" || projectName === '') {
            toast.error(Messages.Assign_ProjectCodes.AllocationProvideFields, { toastId: "iEmployee-id" });

            let values = isError;

            if (client === "") {
                values = { ...values, 'isClient': true };
            }
            if (shortCode === "") {
                values = { ...values, 'isClient': true };
            }
            if (projectName === '') {
                values = { ...values, 'isProject': true };
            }
            setIsError(values);
        } 
        else {
            dispatch(setLoadingScreen(true));
            let result = await AxiosPost(`/timesheet/edit-project-code`, {
                ProjectCode: selectedProjectCode,
                ProjectName: projectName,
                Client: client,
                FKEmpId: userData.Id
            });

            dispatch(setLoadingScreen(false));

            if (result.data.isSuccess) {
                handleClear();
                dispatch(setInitialValues({ ...initialData, "ProjectCodes": result.data.AllocatedProjectCodes }));
                toast.success(result.data.Message, { toastId: "iEmployee-id" });
            } else {
                toast.error(result.data.Message, { toastId: "iEmployee-id" });
            }
        }
    };

    const handleClear = () => {
        setClient("");
        setShortCode("");
        setProjectName('');
        setSelectedProjectCode('');
    };

    const handleProjectCodeChange = (event, newValue) => {
        if (newValue) {
            setSelectedProjectCode(newValue.ProjectCode);
            const selectedProject = projectCode.find(proj => proj.ProjectCode === newValue.ProjectCode);
            if (selectedProject) {
                setProjectName(selectedProject.ProjectName);
                setShortCode(selectedProject.ShortCode);
                setClient(selectedProject.Client);
            }
        } else {
            setSelectedProjectCode('');
            setProjectName('');
            setShortCode('');
            setClient('');
        }
    };

    // Filter out project codes that start with "OT-"
    const filteredProjectCodes = projectCode.filter(proj => !proj.ProjectCode.startsWith("OT-"));

    return (
        <div className="edit_project_code_container">
            <div className="edit_project_code_container_header">
                <div className="edit_project_code_container_header_title">
                    <span>Update Project Code Details</span>
                </div>
            </div>
            {/* edit_project_code */}
            <div className="edit_project_code_flex_div">
                <div className="edit_project_code">
                    <div className="edit_project_code_header">
                        <div className="edit_project_code_input_box">
                            <div className="edit_project_code_container_span">
                                <Autocomplete
                                    options={filteredProjectCodes}
                                    getOptionLabel={(option) => option.ProjectCode}
                                    onChange={handleProjectCodeChange}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            required
                                            label="Project Code"
                                            placeholder="Select Project Code"
                                        />
                                    )}
                                />
                            </div>
                            <div className="edit_project_code_container_span">
                                <TextField
                                    type="text"
                                    label='Project Name'
                                    sx={{ width: "100%" }}
                                    onChange={(e) => setProjectName(e.target.value)}
                                    value={projectName}
                                    placeholder="Project Name"
                                />
                            </div>
                        </div>

                        <div className="edit_project_code_input_box">
                            <div className="edit_project_code_container_span">
                                <TextField
                                    type="text"
                                    label='Client'
                                    sx={{ width: "100%" }}
                                    onChange={(e) => setClient(e.target.value)}
                                    value={client}
                                    placeholder="Client"
                                />
                            </div>
                            <div className="edit_project_code_container_span">
                                <TextField
                                    type="text"
                                    label='Short Code'
                                    sx={{ width: "100%" }}
                                    onChange={(e) => setShortCode(e.target.value)}
                                    value={shortCode}
                                    placeholder="Short Code"
                                    disabled
                                />
                            </div>
                        </div>
                        <div className="edit_project_code_container_navigations">
                        <div className="edit_project_code_btn">
                            <button onClick={handleEditProjectCode}>Update</button>
                        </div>
                    </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );
}

export default EditProjectCode;