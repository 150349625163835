import React, { useEffect } from 'react'
import MainRoute from './Routes/MainRoute'
import { useLocation } from 'react-router-dom';
import { MsalProvider } from '@azure/msal-react'
import Footer from './Components/Footer/Footer';
function App({ msalInstance }) {

  const location = useLocation()

  useEffect(() => {

    const pageTitle = "iEmployee";
    if (location.pathname.includes('/mytime')) {
      document.title = `${pageTitle} | My Time`;
    }
    else if (location.pathname.includes('/project-allocation')) {
      document.title = `${pageTitle} | Project Allocation`;
    }
    else if (location.pathname.includes('/project-codes')) {
      document.title = `${pageTitle} | Project Codes`;
    }
    else {
      document.title = pageTitle;
    }

  }, [location.pathname]);

  return (
    <MsalProvider instance={msalInstance}>
      <MainRoute />
      <Footer />
    </MsalProvider>

  )
}

export default App