import React, { useState } from 'react'
import "./SortBy.css"

import ChangeHistoryTwoToneIcon from '@mui/icons-material/ChangeHistoryTwoTone';
import ChangeHistoryOutlinedIcon from '@mui/icons-material/ChangeHistoryOutlined';

function SortBy({ list, column, setEmployeeData }) {

    const [isActive, setIsActive] = useState(false)

    const handleSort = () => {
        if (isActive) {
            setEmployeeData(
                list.slice().sort((a, b) => {
                    if (column === "FullName") {
                        let alpha = a.FirstName + " " + a.LastName
                        let beta = b.FirstName + " " + b.LastName
                        if (alpha > beta) return isActive ? -1 : 1;
                        if (alpha < beta) return isActive ? 1 : -1;
                        else return 0;
                    }
                    else if (column === "Status") {
                        if (a.isApprove > b.isApprove) return isActive ? -1 : 1;
                        if (a.isApprove < b.isApprove) return isActive ? 1 : -1;
                        else return 0
                    }
                    else {
                        if (a.Designation > b.Designation) return isActive ? -1 : 1;
                        if (a.Designation < b.Designation) return isActive ? 1 : -1;
                        else return 0
                    }
                })
            )
        }
        else {
            setEmployeeData(
                list.slice().sort((a, b) => {
                    if (column === "FullName") {
                        let alpha = a.FirstName + " " + a.LastName
                        let beta = b.FirstName + " " + b.LastName
                        if (alpha > beta) return isActive ? -1 : 1;
                        if (alpha < beta) return isActive ? 1 : -1;
                        else return 0;
                    }
                    else if (column === "Status") {
                        if (a.isApprove > b.isApprove) return isActive ? -1 : 1;
                        if (a.isApprove < b.isApprove) return isActive ? 1 : -1;
                        else return 0
                    }
                    else {
                        if (a.Designation > b.Designation) return isActive ? -1 : 1;
                        if (a.Designation < b.Designation) return isActive ? 1 : -1;
                        else return 0
                    }
                })
            )
        }

        setIsActive(!isActive)

    }

    return (
        <div onClick={handleSort} className='sort_by_container'>
            {
                isActive ?
                    <>
                        <ChangeHistoryOutlinedIcon sx={{ fontSize: 10 }} />
                        <ChangeHistoryTwoToneIcon sx={{ rotate: "180deg", fontSize: 10 }} />
                    </>
                    :
                    <>
                        <ChangeHistoryTwoToneIcon sx={{ fontSize: 10 }} />
                        <ChangeHistoryOutlinedIcon sx={{ rotate: "180deg", fontSize: 10 }} />
                    </>
            }
        </div>
    )
}

export default SortBy