import React, { useEffect, useState } from "react";
import "./Employees.css";

import { useSelector } from "react-redux";
import { ArrowDropDown, Search } from "@mui/icons-material";
import { MenuItem, TextField, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Cryptojs from "crypto-js";

import ViewImage from "../../../assets/view-image.png";
import ApprovalImage from '../../../assets/approval-icon.png'
import EditIcon from '../../../assets/employee-edit-icon.png'

import EmptyTable from "../../../Components/NoData";
import { getUserData } from "../../../redux/features/UserSlice";
import { getInitialValues } from "../../../redux/features/InitialSlice";
import Pagination from '../../../Components/Pagination'
import SortBy from "./Feature/SortBy";
import FilterListIcon from '@mui/icons-material/FilterList';
import FilterListOffIcon from '@mui/icons-material/FilterListOff';
import { AxiosGet } from "../../../data/utilities";

function Employees() {

    let initialData = useSelector(getInitialValues)
    let userData = useSelector(getUserData);

    const navigate = useNavigate();
    const [employeeData, setEmployeeData] = useState([]);
    const [tempEmpDomain, setTempEmpDomains] = useState([]);

    const [tempData, setTempData] = useState([]);
    const [FilterByName, setFilterByName] = useState("");
    const [FilterByClient, setFilterByClient] = useState("");

    const [FilterByDesignation, setFilterByDesignation] = useState("");
    const [FilterByEducation, setFilterByEducation] = useState("");
    const [FilterByDomain, setFilterByDomain] = useState("");
    const [FilterByCertification, setFilterByCertification] = useState("");
    const [lowerFilterValue, setLowerFilterValue] = useState(0);
    const [HigherFilterValue, setHigherFilterValue] = useState(0);

    const [selectedRow, setSelectedRows] = useState(10)
    const [rows, setRows] = useState(0)
    const [page, setPage] = useState(0)

    const [toggleFilter, setToggleFilter] = useState(false)

    useEffect(() => {
        if (initialData.length !== 0) {
            setEmployeeData(initialData.Employees);
            setTempData(initialData.Employees);
        }

        AxiosGet(
            `/iemployee/get-employee-client`
        ).then((res) => {
            if (res.data.isSuccess) {
                setTempEmpClient(res.data.EmpClient);
            }
        });

        AxiosGet(`/iemployee/employeeDomains`).then(
            (res) => {
                if (res.data.isSuccess) {
                    setTempEmpDomains(res.data.empDomains);
                }
            }
        );

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialData]);

    useEffect(() => {
        setRows(selectedRow)
    }, [selectedRow])

    const [isActiveClient, setIsActiveClient] = useState(false);
    const [isActiveDesignation, setIsActiveDesignation] = useState(false);
    const [isActiveDomain, setIsActiveDomain] = useState(false);
    const [isActiveTotalExp, setIsActiveTotalExp] = useState(false);
    const [isActiveEducation, setIsActiveEducation] = useState(false);
    const [isActiveCertification, setIsActiveCertification] = useState(false);

    const [tempEmpClient, setTempEmpClient] = useState([]);

    const handleViewProfile = (id) => {
        const SECRET_VALUE = {
            Id: id,
            Type: "view",
            goBack: "employees"
        };
        let SECRECT_STRING = Cryptojs.AES.encrypt(
            JSON.stringify(SECRET_VALUE),
            process.env.REACT_APP_SECRECT_KEY
        )
            .toString()
            .split("/")
            .join("|");

        navigate(`/myprofile/employees/employee-summary/${SECRECT_STRING}`);
    };

    const handleEdit = (id) => {
        const SECRET_VALUE = { Id: id, goBack: "employees" }
        let SECRECT_STRING = Cryptojs.AES.encrypt(JSON.stringify(SECRET_VALUE), process.env.REACT_APP_SECRECT_KEY).toString().split('/').join("|")
        navigate(`/myprofile/employees/edit-employee/${SECRECT_STRING}`)

    }

    const handleClear = () => {
        setEmployeeData(initialData.Employees);
        setFilterByName("");

        setFilterByClient("");
        setFilterByDesignation("");
        setFilterByDomain("");
        setFilterByCertification("");
        setFilterByEducation("");
        setLowerFilterValue("");
        setHigherFilterValue("");

        setIsActiveClient(false);
        setIsActiveDesignation(false);
        setIsActiveDomain(false);
        setIsActiveTotalExp(false);
        setIsActiveEducation(false);
        setIsActiveCertification(false);
    };

    const handleFilterByClient = (e) => {

        const ClientId = initialData.Clients.filter(item => item.ClientName === e.target.value)[0].Id

        const empClients = tempEmpClient.filter(item => item.FK_ClientId === ClientId)

        let newArray = [];

        empClients.forEach((item) => {
            if (initialData.Employees.filter(item2 => item2.Id === item.FK_EmployeeId).length !== 0) {
                newArray.push(initialData.Employees.filter(item2 => item2.Id === item.FK_EmployeeId)[0])
            }
        })

        setEmployeeData(newArray)

    };

    const handleFilterByDomain = (e) => {

        const DomainId = initialData.Domains.filter(item => item.Domain === e.target.value)[0].Id

        const empDomains = tempEmpDomain.filter(item => item.FK_DomainId === DomainId)

        let newArray = [];

        empDomains.forEach((item) => {
            if (initialData.Employees.filter(item2 => item2.Id === item.FK_EmployeeId).length !== 0) {
                newArray.push(initialData.Employees.filter(item2 => item2.Id === item.FK_EmployeeId)[0])
            }
        })

        setEmployeeData(newArray)

    };

    const handleFilterByName = (e) => {
        if (e.target.value === "") {
            setEmployeeData(initialData.Employees);
        } else {
            setEmployeeData(
                initialData.Employees.filter((item) =>
                    (item.FirstName + item.LastName)
                        .toLowerCase()
                        .includes(e.target.value.toLowerCase())
                )
            );
        }

        setFilterByName(e.target.value);
    };

    const handleFilterByDesignation = (e) => {
        setFilterByDesignation(e.target.value);
        setEmployeeData(
            initialData.Employees.filter((item) => item.Designation === e.target.value)
        );
    };

    const handleFilterByEducation = (e) => {
        setFilterByEducation(e.target.value);

        setEmployeeData(
            tempData.filter((item) => item.Education === e.target.value)
        );
    };

    const handleFilterByCertification = (e) => {
        setFilterByCertification(e.target.value);
        if (e.target.value === "") {
            setEmployeeData(initialData.Employees);
            // alert
        } else {
            setEmployeeData(
                initialData.Employees.filter((item) =>
                    item.Certifications.toLowerCase()
                        .toString()
                        .includes(FilterByCertification.trim().toLowerCase())
                )
            );
        }
    };

    const handleFilterByTotalExpeirence = () => {
        var dt = new Date();

        let year = dt.getFullYear();

        setEmployeeData(
            initialData.Employees.filter(
                (item) =>
                    year - Number.parseInt(item.CareerStartDate.split("-")[0]) >=
                    Number.parseInt(lowerFilterValue) &&
                    year - Number.parseInt(item.CareerStartDate.split("-")[0]) <=
                    Number.parseInt(HigherFilterValue)
            )
        );
    };

    return (
        <div className="employees_main_screen">

            <div className="employees_filters_container" style={{ width: !toggleFilter ? "0%" : "22%" }}>
                <div className="employees_filters_container_toggle" onClick={() => setToggleFilter(!toggleFilter)}>
                    {
                        toggleFilter ?
                            <Tooltip placement="top" title={'Hide'}>
                                <FilterListOffIcon />
                            </Tooltip>
                            :
                            <Tooltip placement="top" title={'Filter'}>
                                <FilterListIcon />
                            </Tooltip>
                    }
                </div>
                <div className="employees_filters">
                    <div className="employees_filters_header">
                        <div className="employees_filters_header_title">
                            <span>Filter</span>
                        </div>
                        <div className="employees_filters_header_clear">
                            <label htmlFor="cl">
                                <span>Clear all</span>
                            </label>
                            <button hidden onClick={() => handleClear()} id="cl">
                                Clear all
                            </button>
                        </div>
                    </div>
                    {
                        (userData.role === process.env.REACT_APP_ROLE_SU || userData.role === process.env.REACT_APP_ROLE_ADMIN) && (
                            <div className="employees_filter_container">
                                <div
                                    className="employees_filter_container_header"
                                    style={{
                                        borderBottom: isActiveClient ? "1px solid #C4C4C4" : "none"
                                    }}
                                >
                                    <span>Search By Client</span>
                                    <ArrowDropDown
                                        style={{
                                            color: "#252525",
                                            cursor: "pointer"
                                        }}
                                        onClick={() => {
                                            setIsActiveClient(!isActiveClient);
                                            setIsActiveDesignation(false);
                                            setIsActiveDomain(false);
                                            setIsActiveTotalExp(false);
                                            setIsActiveEducation(false);
                                            setIsActiveCertification(false);
                                        }}
                                    />
                                </div>
                                {isActiveClient && (
                                    <div className="employees_filter_container_content">
                                        <TextField
                                            id="filterByClient"
                                            select
                                            label="Select"
                                            defaultValue={initialData.Clients[0].ClientName}
                                            helperText="Please select Client Name"
                                            onChange={(e) => handleFilterByClient(e)}
                                            value={FilterByClient}
                                        >
                                            {initialData.Clients.map((option) => (
                                                <MenuItem key={option.ClientName} value={option.ClientName}>
                                                    {option.ClientName}{" "}
                                                </MenuItem>
                                            ))}{" "}
                                        </TextField>
                                    </div>
                                )}{" "}
                            </div>
                        )
                    }
                    <div className="employees_filter_container">
                        <div
                            className="employees_filter_container_header"
                            style={{
                                borderBottom: isActiveDesignation ? "1px solid #C4C4C4" : "none"
                            }}
                        >
                            <span>Search By Job Title</span>
                            <ArrowDropDown
                                style={{
                                    color: "#252525",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    setIsActiveDesignation(!isActiveDesignation);
                                    setIsActiveClient(false);
                                    setIsActiveDomain(false);
                                    setIsActiveTotalExp(false);
                                    setIsActiveEducation(false);
                                    setIsActiveCertification(false);
                                }}
                            />
                        </div>
                        {isActiveDesignation && (
                            <div className="employees_filter_container_content">
                                <TextField
                                    id="filterByDesignation"
                                    select
                                    label="Select"
                                    defaultValue={initialData.Designations[0].Designation}
                                    helperText="Please select Job Title"
                                    sx={{ width: "100%" }}
                                    onChange={(e) => {
                                        handleFilterByDesignation(e);
                                    }}
                                    value={FilterByDesignation}
                                >
                                    {initialData.Designations.map((option) => (
                                        <MenuItem key={option.Designation} value={option.Designation}>
                                            {option.Designation}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </div>
                        )}
                    </div>
                    <div className="employees_filter_container">
                        <div
                            className="employees_filter_container_header"
                            style={{
                                borderBottom: isActiveDomain ? "1px solid #C4C4C4" : "none"
                            }}
                        >
                            <span>Search By Domain</span>
                            <ArrowDropDown
                                style={{
                                    color: "#252525",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    setIsActiveDomain(!isActiveDomain);
                                    setIsActiveClient(false);
                                    setIsActiveDesignation(false);

                                    setIsActiveTotalExp(false);
                                    setIsActiveEducation(false);
                                    setIsActiveCertification(false);
                                }}
                            />
                        </div>
                        {isActiveDomain && (
                            <div className="employees_filter_container_content">
                                <TextField
                                    id="filterByDomains"
                                    select
                                    label="Select"
                                    defaultValue={initialData.Domains[0].Domain}
                                    helperText="Please select Domain"
                                    onChange={(e) => handleFilterByDomain(e)}
                                    value={FilterByDomain}
                                >
                                    {initialData.Domains.map((option) => (
                                        <MenuItem
                                            key={option.Domain}
                                            value={option.Domain}
                                        // onChange={(e) => handleDomainChange(e)}
                                        >
                                            {option.Domain}{" "}
                                        </MenuItem>
                                    ))}{" "}
                                </TextField>
                            </div>
                        )}{" "}
                    </div>
                    <div className="employees_filter_container">
                        <div
                            className="employees_filter_container_header"
                            style={{
                                borderBottom: isActiveTotalExp ? "1px solid #C4C4C4" : "none"
                            }}
                        >
                            <span>Search By Total Exp</span>
                            <ArrowDropDown
                                style={{
                                    color: "#252525",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    setIsActiveTotalExp(!isActiveTotalExp);
                                    setIsActiveClient(false);
                                    setIsActiveDesignation(false);
                                    setIsActiveDomain(false);
                                    setIsActiveEducation(false);
                                    setIsActiveCertification(false);
                                }}
                            />
                        </div>
                        {
                            isActiveTotalExp && (
                                <div className="employees_filter_container_content_span_1">
                                    <div className="employees_filter_container_content_span">
                                        <input
                                            type="number"
                                            min={0}
                                            max={HigherFilterValue}
                                            name="lower_exp"
                                            required
                                            onChange={(e) => setLowerFilterValue(e.target.value)}
                                            value={lowerFilterValue}
                                        />
                                        <span>TO</span>
                                        <input
                                            type="number"
                                            min={lowerFilterValue}
                                            name="high_exp"
                                            required
                                            onChange={(e) => {
                                                setHigherFilterValue(e.target.value);
                                            }}
                                            value={HigherFilterValue}
                                        />
                                    </div>
                                    <button onClick={() => handleFilterByTotalExpeirence()}>
                                        Search
                                    </button>
                                </div>
                            )
                        }
                    </div>
                    <div className="employees_filter_container">
                        <div
                            className="employees_filter_container_header"
                            style={{
                                borderBottom: isActiveEducation ? "1px solid #C4C4C4" : "none"
                            }}
                        >
                            <span>Search By Education</span>
                            <ArrowDropDown
                                style={{
                                    color: "#252525",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    setIsActiveEducation(!isActiveEducation);
                                    setIsActiveClient(false);
                                    setIsActiveDesignation(false);
                                    setIsActiveDomain(false);
                                    setIsActiveTotalExp(false);
                                    setIsActiveCertification(false);
                                }}
                            />
                        </div>
                        {isActiveEducation && (
                            <div className="employees_filter_container_content">
                                <TextField
                                    id="filterByEducations"
                                    select
                                    label="Select"
                                    defaultValue={initialData.Educations[0].Education}
                                    helperText="Please select Education"
                                    onChange={(e) => handleFilterByEducation(e)}
                                    value={FilterByEducation}
                                >
                                    {initialData.Educations.map((option) => (
                                        <MenuItem key={option.Education} value={option.Education}>
                                            {option.Education}{" "}
                                        </MenuItem>
                                    ))}{" "}
                                </TextField>
                            </div>
                        )}
                    </div>
                    <div className="employees_filter_container">
                        <div
                            className="employees_filter_container_header"
                            style={{
                                borderBottom: isActiveCertification ? "1px solid #C4C4C4" : "none"
                            }}
                        >
                            <span>Search By Certification</span>
                            <ArrowDropDown
                                style={{
                                    color: "#252525",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    setIsActiveCertification(!isActiveCertification);
                                    setIsActiveClient(false);
                                    setIsActiveDesignation(false);
                                    setIsActiveDomain(false);
                                    setIsActiveTotalExp(false);
                                    setIsActiveEducation(false);
                                }}
                            />
                        </div>
                        {isActiveCertification && (
                            <div className="employees_filter_container_content">
                                <TextField
                                    id="certifications"
                                    label="Certifications"
                                    placeholder="Enter Certificate"
                                    onChange={(e) => handleFilterByCertification(e)}
                                    value={FilterByCertification}
                                />
                            </div>
                        )}{" "}
                    </div>
                </div>
            </div>

            <div className="employees_container">
                <div className="employees_container_search_by_name">
                    <div className="employees_container_search_by_name_center">
                        <input
                            type="text"
                            placeholder="Search By Name, Email Address"
                            value={FilterByName}
                            onChange={handleFilterByName}
                        />
                        <div className="employees_container_search_btn">
                            <Search style={{ color: "#252525" }} />
                        </div>
                    </div>
                </div>
                <div className="employees_container_header">
                    <div className="employees_container_header_title">
                        <span>Employees</span>
                        {
                            employeeData.length !== 0 && (
                                <label>{employeeData.length}</label>
                            )
                        }
                    </div>
                    <div className="employees_container_header_navigations">
                        {
                            (userData.role === process.env.REACT_APP_ROLE_MANAGER
                                ||
                                userData.role === process.env.REACT_APP_ROLE_ADMIN
                                || (userData.role === process.env.REACT_APP_ROLE_SU && userData.reportees.length !== 0)) && (
                                <button onClick={() => navigate("/myprofile/employees/approval")}>
                                    <img src={ApprovalImage} alt='approve' style={{ marginRight: "5px", width: 15 }} />
                                    Approve
                                </button>
                            )
                        }
                    </div>
                </div>
                <div className="employee_content_table">
                    <div className="employees_container_table">
                        {
                            employeeData.length === 0 ?
                                <EmptyTable title="No Data Found" />
                                :
                                <table>
                                    <thead>
                                        <tr>
                                            {
                                                tableCol.map((col, index) => {
                                                    return <td key={index}>
                                                        <span style={{ display: 'flex', gap: '5px' }}>
                                                            {col.name}
                                                            {
                                                                col.name === 'Options' ?
                                                                    <></>
                                                                    :
                                                                    <SortBy
                                                                        list={employeeData}
                                                                        column={col.name}
                                                                        setEmployeeData={setEmployeeData}
                                                                    />
                                                            }
                                                        </span>
                                                    </td>
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            employeeData.slice(page, rows).map((e, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ textTransform: "capitalize" }}>{e.FirstName + " " + e.LastName}</td>
                                                        <td>{e.Designation}</td>
                                                        <td style={{ textTransform: "capitalize" }}>{e.Email}</td>
                                                        <td>
                                                            {
                                                                userData.role === process.env.REACT_APP_ROLE_USER ? (
                                                                    <img
                                                                        onClick={() => handleViewProfile(e.Id)}
                                                                        style={{ cursor: "pointer" }}
                                                                        src={ViewImage}
                                                                        alt="view"
                                                                    />
                                                                ) : (
                                                                    <div style={{ display: "flex", gap: "10px" }}>
                                                                        <Tooltip placement="top" title={"View"}>
                                                                            <img
                                                                                onClick={() => handleViewProfile(e.Id)}
                                                                                style={{ cursor: "pointer" }}
                                                                                src={ViewImage}
                                                                                alt="view"
                                                                            />
                                                                        </Tooltip>
                                                                        {
                                                                            userData.role !== process.env.REACT_APP_ROLE_MANAGER && (
                                                                                <Tooltip placement="top" title={"Edit"}>
                                                                                    <img src={EditIcon} alt='edit' onClick={() => handleEdit(e.Id)} />
                                                                                </Tooltip>
                                                                            )
                                                                        }
                                                                    </div>
                                                                )
                                                            }
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        }
                                    </tbody>
                                </table>
                        }
                    </div>
                    {
                        employeeData.length !== 0 &&
                        (
                            <Pagination
                                List={employeeData}
                                selectedRow={selectedRow}
                                setSelectedRow={setSelectedRows}
                                rows={rows}
                                setRows={setRows}
                                setPage={setPage}
                                page={page}
                            />
                        )
                    }
                </div>
            </div>
        </div>
    );
}

export default Employees;

const tableCol = [
    {
        id: 1,
        name: "FullName"
    },
    {
        id: 2,
        name: "Job Title"
    },
    {
        id: 3,
        name: "Email Address"
    },
    {
        id: 5,
        name: "Options"
    }
];
