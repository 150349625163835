import React, { useEffect, useState } from 'react'
import "./UploadProjectCode.css"

import { Close, Delete } from '@mui/icons-material'
import { utils, read } from 'xlsx'
import { useDispatch, useSelector } from 'react-redux'

import DragDropImage from '../../../assets/drag-drop.png'
import FileImage from '../../../assets/image-file.png'

import { setLoadingScreen } from '../../../redux/features/ActivateSlice'
import { getUserData } from '../../../redux/features/UserSlice'
import { getInitialValues } from '../../../redux/features/InitialSlice'

import { toast } from 'react-toastify'
import Messages from '../../../data/Messages.json'
import { AxiosPost } from '../../../data/utilities'

function UploadProjectCode({ setIsActiveProjectCodes }) {

    const dispatch = useDispatch()
    const userData = useSelector(getUserData)
    const initialData = useSelector(getInitialValues)

    const [uploadedData, setUploadedData] = useState([])

    const [projectCodes, setProjectCodes] = useState([])

    const [isExists, setIsExists] = useState(false)

    useEffect(() => {

        setProjectCodes(initialData.ProjectCodes.filter(item => item.projecttype !== false))

    }, [initialData])

    useEffect(() => {
        if (uploadedData.length !== 0) {
            uploadedData.filter(item => item.length !== 0).forEach((item) => {
                let result = projectCodes.filter(e => e.ProjectCode === item[0])
                if (result.length !== 0) {
                    setIsExists(true)
                    return false
                }
            })
        }

    }, [uploadedData, projectCodes])

    useEffect(() => {
        if (isExists) {
            toast.error(Messages.Project_Codes.Project_Codes_Exist, { toastId: "iemployee-toast" })
        }
    }, [isExists])

    const handleFileUpload = (event) => {
        const file = event.target.files[0];

        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = read(data, { type: 'array' });

            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            let excelData = utils.sheet_to_json(worksheet, { header: 1, raw: false });
            excelData = excelData.filter(item => item.length !== 0)
            excelData.splice(0, 1)
            // Use the extracted data as needed
            setUploadedData(excelData)
        };

        reader.readAsArrayBuffer(file);
    }

    const handleProjectCodes = async () => {
        if (uploadedData.length !== 0) {

            if (isExists) {
                toast.error(Messages.Project_Codes.Project_Codes_check, { toastId: "iemployee-toast" })
                return false
            }

            dispatch(setLoadingScreen(true))

            let result = await AxiosPost(`/timesheet/insert-project-codes`, {
                ProjectCodes: uploadedData.filter(item => item.length !== 0),
                FKEmpId: userData.Id
            })

            if (result.data.isSuccess) {
                dispatch(setLoadingScreen(false))
                setIsActiveProjectCodes(false)
                setUploadedData([])
                toast.success(Messages.Project_Codes.Project_Codes_Success, { toastId: "iemployee-toast" })
            }
            else {
                dispatch(setLoadingScreen(false))
                toast.error(Messages.Project_Codes.Project_Codes_Error, { toastId: "iemployee-toast" })
            }

        }
        else {
            toast.error(Messages.Project_Codes.Project_Codes_Add_Comments, { toastId: "iemployee-toast" })
        }
    }

    const handleCheckDuplicate = (value) => {

        if (projectCodes.filter(item => item.ProjectCode === value).length > 0) {
            return true
        }
        else {
            return false
        }

    }

    const handleRemoveProjectCodes = (value) => {
        setUploadedData(uploadedData.filter(item => item.length !== 0).filter(item => item[0] !== value))

        uploadedData.filter(item => item.length !== 0).filter(item => item[0] !== value).forEach((item) => {
            let result = projectCodes.filter(e => e.ProjectCode === item[0])
            if (result.length !== 0) {
                setIsExists(true)
                return false
            }
            else {
                setIsExists(false)
                return true
            }
        })
    }

    const handleDragOver = (e) => {
        e.preventDefault()
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = read(data, { type: "array" });
            const worksheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[worksheetName];
            const excelData = utils.sheet_to_json(worksheet, {
                header: 1,
                raw: false,
            });
            // Use the extracted data as needed
            excelData.splice(0, 1)
            setUploadedData(excelData);
        };
        reader.readAsArrayBuffer(file);

    };

    return (
        <div className='project_codes_main_screen'>
            <div className='project_codes_card'>
                <div className='project_codes_card_header'>
                    <div className='project_codes_card_header_title'>
                        <span>Project Codes</span>
                    </div>
                    <div className='project_codes_card_header_close'>
                        <Close style={{ cursor: "pointer" }} onClick={() => setIsActiveProjectCodes(false)} />
                    </div>
                </div>
                {
                    uploadedData.length !== 0 ?
                        <div className='project_code_extracted_data_table'>
                            {
                                uploadedData.length !== 0 ?
                                    <table>
                                        <thead>
                                            <tr>
                                                {
                                                    TableCol.map((item, index) => {
                                                        return <td key={index}>{item.name}</td>
                                                    })
                                                }
                                                <td style={{ textAlign: "center" }}>Option</td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                uploadedData.filter(item => item.length !== 0).map((item, index) => {
                                                    return (
                                                        <tr key={index}>
                                                            {
                                                                item.map((row, index) => {
                                                                    return <td key={index} className={handleCheckDuplicate(item[0]) && "project_codes_container_duplicate"} >{row}</td>
                                                                })
                                                            }
                                                            <td style={{ textAlign: 'center' }} className={handleCheckDuplicate(item[0]) && "project_codes_container_duplicate"}>
                                                                <Delete sx={{ cursor: "pointer" }} onClick={() => handleRemoveProjectCodes(item[0])} />
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    :
                                    <div className='project_codes_no_data'>No Data</div>
                            }

                        </div>
                        :
                        <div className='project_codes_card_content'>
                            <div className='project_codes_card_upload_box'>
                                <div className='project_codes_card_upload_drag_drop'
                                    onDragOver={handleDragOver}
                                    onDrop={handleDrop}
                                >
                                    <div style={{ textAlign: "center" }}>
                                        <img src={DragDropImage} alt='img-upload' />
                                        <br />
                                        <span>Drag and Drop File</span>
                                    </div>
                                </div>
                                <p style={{ textAlign: "center", margin: "20px 0" }}>Or</p>
                                <div className='project_codes_card_upload_file'>
                                    <label htmlFor='upload_file'>
                                        <img src={FileImage} alt='choosefile' />
                                        Choose File
                                    </label>
                                    <input
                                        type='file'
                                        id='upload_file'
                                        style={{ display: "none" }}
                                        onChange={handleFileUpload}
                                    />
                                </div>
                            </div>
                        </div>
                }

                <div className='project_codes_card_actions'>
                    {
                        uploadedData.length > 0 && (
                            <button onClick={() => {
                                setUploadedData([])
                            }}>Clear All</button>
                        )
                    }
                    <button onClick={handleProjectCodes}>Upload</button>
                </div>

            </div>
        </div>
    )
}

export default UploadProjectCode


const TableCol = [
    {
        id: 0,
        name: "Project Code"
    },
    {
        id: 1,
        name: "Project Name"
    },
    {
        id: 2,
        name: "Client Name"
    },
    {
        id: 3,
        name: "Expiry Date(MM/DD/YYYY)"
    }
]