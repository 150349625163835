import React from 'react'
import "./Loading.css"

import { useSelector } from 'react-redux'

import { getLoader } from '../../redux/features/ActivateSlice';
// import IIMage from '../../assets/load.svg'

function Loading() {

    const loader = useSelector(getLoader)

    return (
        <>
            {
                loader && (
                    <div className='iemployee-loading-container'>

                        <div className='iemployee-loading-spans'>
                            <div className='iemployee-loading-spans1'></div>
                            <div className='iemployee-loading-spans2'></div>
                            <div className='iemployee-loading-spans3'></div>
                            <div className='iemployee-loading-spans4'></div>
                            <div className='iemployee-loading-spans5'></div>
                        </div>

                    </div>

                )
            }</>
    )
}

export default Loading


// <div className='i_loading'>
//                         <div className='i_center_loader'>
//                             <img src={IIMage} alt='h' />
//                             <h3>Loading...</h3>
//                         </div>
//                     </div>


// <div className='i-employee-loading'>
//                         <div className='i-employee-loading-content'>
//                             <h2>Loading...</h2>
//                             <div className='i-employee-loading-container'>
//                                 <span></span>
//                             </div>
//                         </div>
//                     </div>