import React from 'react'
import "./Dashboard.css"
import { useNavigate } from 'react-router-dom'
import MyTimeImage from '../../assets/ProductImages/04-iEmployee-MyTime.svg'
import MyProfileImage from '../../assets/ProductImages/02-iEmployee-MyProfile.svg'
import ProjectAllocationImage from '../../assets/ProductImages/03-iEmployee-ProjectAllocation.svg'
import ProjectCodeGenerationImage from '../../assets/ProductImages/05-iEmployee-ProjectCodeGeneration.svg'
import GenzeonLogo from '../../assets/genzeon-logo-2.png'
import { useSelector } from 'react-redux'
import { getUserData } from '../../redux/features/UserSlice'
import HelpButton from '../../assets/Help.png'
import { toast } from 'react-toastify'
import Messages from '../../data/Messages.json'

function Dashboard() {

    const navigate = useNavigate()
    const userData = useSelector(getUserData)

      // Handler to open Microsoft Teams link
      const openTeamsLink = () => {
        window.open(
            'https://teams.microsoft.com/l/channel/19%3Aa4dcb104b3494824b0cd70379ca5f982%40thread.tacv2/iEmployee%20-%20MyTime?groupId=20721af8-2850-4933-bdd3-6ea206011121', 
            '_blank'
        )
    }

    return (
        <div className='iemployee-dashboard-container'>
            {/* About Us- iemployee */}
            <div className='iemployee-about-us-container'>
                <div className='iemployee-about-us-container-card'>
                    <div className='iemployee-about-us-container-span'>
                        <img src={GenzeonLogo} alt='genzeon' />
                    </div>
                    <div className='iemployee-about-us-container-span'>
                        <span>Dream Big,</span>
                        <span>Deliver Excellence</span>
                    </div>
                </div>
            </div>
            {/* Modules */}
            <div className='iemployee-modules-container'>
                <div className='iemployee-module-card-content'>
                    <div className='iemployee-module-card' onClick={() => navigate('/myprofile/employees')}>
                        <div className='iemployee-module-card-img-container'>
                            <img src={MyProfileImage} alt='my-profile' />
                        </div>
                        <span>My Profile</span>
                    </div>
                    <div className='iemployee-module-card' onClick={() => {
                        if (userData.role === process.env.REACT_APP_ROLE_ADMIN) {
                            navigate('/mytime/reports/home')
                            return false;
                        }
                        if (userData.Id !== null) {
                            if (userData.reportingTo !== null && userData.location !== null) {
                                navigate('/mytime/home')
                            }
                            else {
                                toast.warn(Messages.Weekly_Timesheet.Weekly_Timesheet_Warning_Edit_Profile, { toastId: "iemployee-toast" })
                                navigate('/profile')
                            }
                        }
                        else {
                            toast.warn(Messages.Weekly_Timesheet.Weekly_Timesheet_Warning, { toastId: "iemployee-toast" })
                            navigate('/myprofile/build-profile')
                        }
                    }}>
                        <div className='iemployee-module-card-img-container'>
                            <img src={MyTimeImage} alt='my-time' />
                        </div>
                        <span>My Time</span>
                    </div>
                    {
                        (userData.role === process.env.REACT_APP_ROLE_MANAGER || userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU) && (
                            <div className='iemployee-module-card' onClick={() => { navigate('/project-allocation/home') }}>
                                <div className='iemployee-module-card-img-container'>
                                    <img src={ProjectAllocationImage} alt='project-allocations' />
                                </div>
                                <span>Project Allocation</span>
                            </div>
                        )
                    }
                    {
                        (userData.role === process.env.REACT_APP_ROLE_ADMIN || userData.role === process.env.REACT_APP_ROLE_SU) && (
                            <div className='iemployee-module-card' onClick={() => { navigate('/project-codes/home') }}>
                                <div className='iemployee-module-card-img-container'>
                                    <img src={ProjectCodeGenerationImage} alt='project-codes' />
                                </div>
                                <span>Project Code Generation</span>
                            </div>
                        )
                    }
                     {/* Button to open Microsoft Teams link */}
<button className='iemployee-teams-button' alt="Help" onClick={openTeamsLink}>
    {/* <svg 
      className='iemployee-teams-svg' 
      xmlns="http://www.w3.org/2000/svg" 
      x="0px" y="0px" width="48" height="48" viewBox="0 0 48 48">
      <linearGradient id="4AyzHamWc1NW2HwElCy6Xa_0URHMFHwQRGo_gr1" x1="9.997" x2="21.78" y1="5.934" y2="30.894" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#33bef0"></stop>
        <stop offset="1" stop-color="#22a5e2"></stop>
      </linearGradient>
      <path fill="url(#4AyzHamWc1NW2HwElCy6Xa_0URHMFHwQRGo_gr1)" d="M18.5,3C11.044,3,5,9.044,5,16.5c0,2.059,0.474,4.002,1.299,5.748c-0.013,0.03-0.029,0.054-0.04,0.088l-3.167,7.206c-0.392,0.892,0.551,1.783,1.419,1.341l6.369-3.243C13.049,29.127,15.671,30,18.5,30C25.956,30,32,23.956,32,16.5C32,9.044,25.956,3,18.5,3z"></path>
      <linearGradient id="4AyzHamWc1NW2HwElCy6Xb_0URHMFHwQRGo_gr2" x1="21.104" x2="39.047" y1="16.026" y2="46.288" gradientUnits="userSpaceOnUse">
        <stop offset="0" stop-color="#0176d0"></stop>
        <stop offset="1" stop-color="#16538c"></stop>
      </linearGradient>
      <path fill="url(#4AyzHamWc1NW2HwElCy6Xb_0URHMFHwQRGo_gr2)" d="M44.909,43.729l-3.657-8.32C42.367,33.355,43,31.001,43,28.5C43,20.492,36.508,14,28.5,14S14,20.492,14,28.5S20.492,43,28.5,43c2.613,0,5.058-0.701,7.175-1.91l7.815,3.979C44.358,45.511,45.301,44.62,44.909,43.729z"></path>
      <path fill="#fff" d="M27.166,31.749c-0.066-0.172-0.208-0.682-0.208-1.187c0-2.874,2.959-2.984,2.959-5.116c0-1.342-1.326-1.425-1.563-1.425c-1.506,0-2.682,0.861-3.176,1.296v-2.979c0.508-0.303,1.67-0.91,3.523-0.91c4.29,0,4.344,2.949,4.344,3.582c0,3.404-3.513,3.657-3.513,5.838c0,0.455,0.165,0.788,0.237,0.9H27.166z M28.61,36.428c-0.817,0-1.68-0.51-1.68-1.499c0-0.99,0.897-1.481,1.68-1.481c0.783,0,1.653,0.439,1.653,1.481C30.263,35.971,29.427,36.428,28.61,36.428z"></path>
    </svg> */}
    <img src={ HelpButton } alt="Help" />
</button>

                </div>
            </div>
        </div>
    )
}

export default Dashboard