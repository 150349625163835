import CryptoJS from "crypto-js";
import { AxiosPost } from "../../../data/utilities";

export const handleUserStatus = async (
    email,
    userData,
    dispatch,
    setUserLoggedInData,
    RefreshContent,
    navigate,
    setLoadingScreen,                
    employees,
    isActive
) => {
    let res = await AxiosPost(`/iemployee/modify-user`,{
        Email : email.toLowerCase(),
        isActive : Number.parseInt(isActive)

    })

    if (res.data.submitted) {
        let SECURE_LOGIN = CryptoJS.AES.encrypt(JSON.stringify({
            Id: userData.Id,
            username: userData.username,
            email: userData.email,
            password: userData.password,
            phoneNumber: userData.phoneNumber,
            organization: userData.organization,
            role: userData.role,
            FkRoleId: userData.FKRoleId,
            FKEmpId: userData.Id,
            countryCode: userData.countryCode
        }), process.env.REACT_APP_SECRECT_KEY).toString()

        localStorage.setItem('__iemployee__user', SECURE_LOGIN)

        dispatch(setUserLoggedInData(
            {
                Id: userData.Id,
                username: userData.username,
                email: userData.email,
                password: userData.password,
                phoneNumber: userData.phoneNumber,
                organization: userData.organization,
                role: userData.role,
                FkRoleId: userData.FkRoleId,
                FkEmpId: userData.Id,
                countryCode: userData.countryCode
            }

        ))

        RefreshContent()
    }
    dispatch(setLoadingScreen(false))
    // navigate('/')
    return true

}