import React from 'react'
import { Route, Routes } from 'react-router-dom'
import Team from '.'
import WeeklyTimesheet from '../WeeklyTimesheet'
import Timesheet from '../Timesheet'

function App() {
    return (
        <Routes>
            <Route path='/' element={<Team />} />

            <Route path='/weeklytimesheet/:EmpId' element={<WeeklyTimesheet />} />

            <Route path='/timesheet/:timesheetId' element={<Timesheet />} />
        </Routes>
    )
}

export default App