import React, { useEffect, useState } from 'react'
import "./EmployeeAudit.css"

import { useSelector } from 'react-redux'

import { getInitialValues } from '../../../redux/features/InitialSlice'
import EmptyTable from '../../../Components/NoData'
import Pagination from '../../../Components/Pagination'
import SortBy from './Feature/SortBy'

function EmployeeAudit() {

    const initialData = useSelector(getInitialValues)

    const [rows, setRows] = useState(0)
    const [selectedRow, setSelectedRow] = useState(10)
    const [page, setPage] = useState(0)

    const [EmployeeAudit, setEmployeeAudit] = useState([])

    useEffect(() => {
        setEmployeeAudit([...initialData.EmployeeAudit])
    }, [initialData])

    useEffect(() => {
        setRows(selectedRow)
    }, [selectedRow])

    const handleDate = (date) => {

        const localZoneDate = new Date(date)
        return {
            Date: localZoneDate.toLocaleString().split(',')[0],
            Time: localZoneDate.toLocaleString().split(',')[1]
        }

    }

    return (
        <div className='employee_audit_container'>
            <div className='employee_audit_header'>
                <div className='employee_audit_title'>
                    <span>Employee Audit</span>
                </div>
            </div>
            <div className='employee_audit_content'>
                <div className='employee_audit_table_container'>
                    {
                        EmployeeAudit.length !== 0 || EmployeeAudit !== undefined ?
                            <table>
                                <thead>
                                    <tr>
                                        {
                                            tableCol.filter(item => item.name !== 'Phone Number').map((col, index) => {
                                                return (
                                                    <td key={index} style={{ width: col.width }}>
                                                        <span style={{ display: 'flex', gap: '5px' }}>
                                                            {col.name}
                                                            {
                                                                col.name === 'Comments' ?
                                                                    <></>
                                                                    :
                                                                    <SortBy
                                                                        list={EmployeeAudit}
                                                                        setEmployeeAudit={setEmployeeAudit}
                                                                        column={col.name}
                                                                    />
                                                            }
                                                        </span>
                                                    </td>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        EmployeeAudit.slice(page, rows).map((row, index) => {
                                            return (
                                                <tr key={index}>
                                                    <td style={{ textTransform: "capitalize" }}>{row.FirstName + " " + row.LastName}</td>
                                                    <td style={{ textTransform: "capitalize" }}>{row.Email}</td>
                                                    <td>{row.UpdatedStatus}</td>
                                                    <td>{handleDate(row.Created_dt).Date} <br /> {handleDate(row.Created_dt).Time}</td>
                                                    <td>{row.Comment}</td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                            :
                            <EmptyTable title={'No Data Found'} />
                    }
                </div>
                {/* Pagination */}
                <Pagination
                    List={initialData.EmployeeAudit}
                    rows={rows}
                    selectedRow={selectedRow}
                    setRows={setRows}
                    setSelectedRow={setSelectedRow}
                    setPage={setPage}
                    page={page}
                />
            </div>
        </div>
    )
}

export default EmployeeAudit


const tableCol = [
    {
        id: 1,
        name: "FullName",
        width: "20%"
    },
    // {
    //     id: 2,
    //     name: "Designation"
    // },
    {
        id: 3,
        name: "Email Address",
        width: "20%"
    },
    {
        id: 4,
        name: "Status",
        width: "20%"
    },
    {
        id: 5,
        name: "Date",
        width: "20%"
    },
    {
        id: 6,
        name: "Comments",
        width: "20%"
    }
]