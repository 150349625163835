import React, { useEffect, useState } from 'react'
import { Route, Routes } from 'react-router-dom'
import WeeklyTimesheetApp from './Screens/WeeklyTimesheet/SubRoute'
import MyTeamApp from './Screens/TimesheetApproval/SubRoute'
import { useSelector } from 'react-redux'
import { getIsLoggedIn, getUserData } from '../redux/features/UserSlice'
import MyTimeIsLoggedIn from './Layouts/MyTimeIsLoggedIn'
import ErrorPage from '../Components/ErrorPage/ErrorPage'
import TimesheetReportsSubRoute from './Screens/TimesheetReports/SubRoute'
import { AxiosPost } from '../data/utilities'
import { getInitialValues } from '../redux/features/InitialSlice'

function MyTime() {

    const [AllocatedProjectCodes, setAllocatedProjectCodes] = useState([])
    const isLoggedIn = useSelector(getIsLoggedIn)
    const userData = useSelector(getUserData)
    const initialData = useSelector(getInitialValues)

    useEffect(() => {
        if (userData.Id !== undefined) {
            if (userData.role === process.env.REACT_APP_ROLE_SU) {
                setAllocatedProjectCodes(initialData.ProjectCodes)
            } else {

                AxiosPost(
                    `/timesheet/get-allocated-project-codes`, {
                    Approver: userData.Id
                })
                    .then((respo) => {
                        if (respo.data.isSuccess) {
                            setAllocatedProjectCodes(respo.data.ProjectCodes);
                        }
                    })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData])

    return (
        <Routes>

            <Route element={<MyTimeIsLoggedIn isExists={isLoggedIn} />}>

                <Route path='/home/*' element={<WeeklyTimesheetApp />} />

                <Route path='/reports/*' element={<TimesheetReportsSubRoute AllocatedProjectCodes={AllocatedProjectCodes} />} />

                <Route path='/my-team/*' element={<MyTeamApp AllocatedProjectCodes={AllocatedProjectCodes} />} />

                <Route path='/*' element={<ErrorPage />} />

            </Route>
            {/* <Route path='/*' element={<ErrorPage />} /> */}
        </Routes>
    )
}

export default MyTime